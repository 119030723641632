import { Fragment, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "../QuestionBoard.scss";
import { Colors } from "../../../themes/colors";

import { Button } from "../../../components/Button";
import { TpModal } from "../../../components/TpModal";

import ScribbleLoopIcon from "../../../components/SvgIcon/ScribbleLoopIcon";
import CropIcon from "../../../components/SvgIcon/CropIcon";
// import pdfImg from "../../../assets/images/placeholder-pdf.jpg";
import { getPdfText } from "../../../utils/mediaUtils";

const AttachmentEditModal = (props) => {
    const { userType, showAttachmentEditModal, mediaObj, handleModalClose } = props;

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (mediaObj?.mediaType === 6 && mediaObj?.fileObj && showAttachmentEditModal) {
            getPdfText(mediaObj?.fileObj, 1).then((totalPage) => {
                setTotalPage(totalPage);
            });
        }
    }, []);

    const handleNextClick = () => {
        if (page < totalPage) {
            setIsLoading(true);
            getPdfText(mediaObj?.fileObj, page + 1).then((res) => {
                setIsLoading(false);
            });
            setPage(page + 1);
        }
    };

    const handlePrevClick = () => {
        if (page > 1) {
            setIsLoading(true);
            getPdfText(mediaObj?.fileObj, page - 1).then((res) => {
                setIsLoading(false);
            });
            setPage(page - 1);
        }
    };

    return (
        <TpModal
            isOpen={showAttachmentEditModal}
            centered
            className={`tp-modal-${userType} size-4`}
            children={
                <Fragment>
                    <Row className="align-items-center">
                        <Col md={2}>
                            <CropIcon color={Colors.black} className="tpw-30 tph-30 pointer mr2" />
                            <ScribbleLoopIcon
                                color={Colors.black}
                                className="tpw-30 tph-30 pointer"
                            />
                        </Col>
                        <Col md={10} className="text-end">
                            <Button
                                className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                                title="Discard"
                                onClick={handleModalClose}
                            />
                            <Button
                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                title="Attach"
                            />
                        </Col>
                    </Row>
                    <div className="text-center mt5">
                        {/* <img src={pdfImg} className="img-fluid" alt="" /> */}
                        <canvas id="pdfCanvas" className="w-100 h-100" style={{ border: "0.5px solid #06729D" }}></canvas>
                        <h3 className="ff-sf-pro-rounded">{`${page}/${totalPage}`}</h3>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button className="mr2" disabled={isLoading} onClick={handlePrevClick}>
                            Prev
                        </button>
                        <button disabled={isLoading} onClick={handleNextClick}>
                            Next
                        </button>
                    </div>
                </Fragment>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(AttachmentEditModal);
