const FileArrowIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.532 21.875H5.46875C5.26155 21.875 5.06284 21.7927 4.91632 21.6462C4.76981 21.4997 4.6875 21.301 4.6875 21.0938V3.90625C4.6875 3.69905 4.76981 3.50034 4.91632 3.35382C5.06284 3.20731 5.26155 3.125 5.46875 3.125H14.8445L20.3133 8.59375V21.0938C20.3133 21.1963 20.2931 21.2979 20.2538 21.3927C20.2145 21.4875 20.157 21.5736 20.0844 21.6462C20.0119 21.7187 19.9258 21.7763 19.831 21.8155C19.7362 21.8548 19.6346 21.875 19.532 21.875Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.8438 3.125V8.59375H20.3133"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.8906 17.5781L15.625 14.8437L12.8906 12.1094"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.375 14.8438L15.625 14.8437"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default FileArrowIcon;
