import React, { useState, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./QuestionBoard.scss";

import { Colors } from "../../themes/colors";
import ArrowDropdown from "../../components/SvgIcon/ArrowDown";
import { TpDropdownPopup } from "../../components/TpDropdownPopup";
import ImagePreviewModal from "./AskQuestionModals/ImagePreviewModal";
import AttachmentEditModal from "./AskQuestionModals/AttachmentEditModal";

const Attachement = (props) => {
    const { userType, mediaObj, handleRemoveMedia } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [openPdfModal, setOpenPdfModal] = useState(false);

    const handleMediaRemove = () => {
        setIsOpen(false);
        handleRemoveMedia(mediaObj.uuid);
    };

    const handlePreviewModalToggle = () => {
        if (mediaObj.mediaType === 6) {
            setOpenPdfModal(!openPdfModal);
            return;
        }
        setOpenPreviewModal(!openPreviewModal);
    };

    console.log("mediaObj", mediaObj);

    return (
        <Col md={3}>
            <TpDropdownPopup
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                className={`mb4 size-xs tp-dropdown-popup-${userType}`}
                trigger={
                    <div className="dropdown-trigger-wrap pointer">
                        <div className="d-flex">
                            <div
                                className={`file-holder ff-sf-pro-rounded file-holder-${userType}`}
                            >
                                {mediaObj.type.toUpperCase()}
                            </div>
                            <div className="file-info">
                                <h5 className="text-trim tpw-128">{mediaObj.fileObj.name}</h5>
                                <h5 className="mb0">{mediaObj.fileSize}</h5>
                            </div>
                        </div>
                        <div className="trigger-icon">
                            <ArrowDropdown color={Colors.black} className="pointer" />
                        </div>
                    </div>
                }
                children={
                    <Fragment>
                        <div className="ff-sf-pro-rounded">
                            <h5 className="mb2 pointer">Edit</h5>
                            <h5 className="mb2 pointer" onClick={handlePreviewModalToggle}>
                                Open
                            </h5>
                            <h5 className="pointer" onClick={handleMediaRemove}>
                                Remove
                            </h5>
                        </div>
                    </Fragment>
                }
            />
            {openPreviewModal && (
                <ImagePreviewModal
                    isOpen={openPreviewModal}
                    mediaObj={mediaObj}
                    localPreviewImage={mediaObj.previewUrl}
                    handleModalClose={handlePreviewModalToggle}
                />
            )}
            {openPdfModal && (
                <AttachmentEditModal
                    showAttachmentEditModal={openPdfModal}
                    mediaObj={mediaObj}
                    handleModalClose={handlePreviewModalToggle}
                />
            )}
        </Col>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(Attachement);
