const StudentIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="186"
            height="217"
            viewBox="0 0 186 217"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M168.6 47.2021V88.6237"
                stroke={color}
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M92.7254 165.351C122.243 165.351 146.172 141.329 146.172 111.697C146.172 82.0654 122.243 58.0439 92.7254 58.0439C63.2079 58.0439 39.2793 82.0654 39.2793 111.697C39.2793 141.329 63.2079 165.351 92.7254 165.351Z"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
            />
            <path
                d="M177.464 214C160.295 184.81 128.726 165.351 92.726 165.351C57.2799 165.351 25.9876 184.254 8.81836 212.888"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M93 88.6231L3 47.2016L93 3L183 47.2016L93 88.6231Z"
                fill="#F7F8FC"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default StudentIcon;
