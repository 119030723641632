import { useState, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import KeyIcon from "../../components/SvgIcon/KeyIcon";

const ChangeEmail = (props) => {
    const { userType } = props;
    const history = useHistory();
    const location = useLocation();
    const [validPass, setValidPass] = useState(false);
    const validPassClass = validPass ? "not-found" : "";

    return (
        <div className="tp-settings tp-change-email">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Change {location.state.routeParam}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to profile"
                                className={`mt9 mb4s tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner change-email-inner bg-clr-light">
                                <div className="change-email-wrap text-center">
                                    <KeyIcon
                                        className="tpw-140 tph-140"
                                        color={Colors[`${userType}Primary`]}
                                    />
                                    {validPass ? (
                                        <h3 className="mt9 mb9">
                                            Password was not recognised, please try again
                                        </h3>
                                    ) : (
                                        <Fragment>
                                            <h3 className="mt9 mb4">
                                                For security purposes, please confirm your password
                                                for this email address:
                                            </h3>
                                            <h3 className="mb9 fw-600">cmoyanoi@hotmail.com</h3>
                                        </Fragment>
                                    )}
                                    <AvForm>
                                        <TextInput
                                            name="password"
                                            placeholder="Password"
                                            type="password"
                                            className={`bdr-0 radius-7 tp-text-input-${userType} ${validPassClass}`}
                                            options={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required"
                                                }
                                            }}
                                        />
                                    </AvForm>
                                    <Button
                                        title="Next"
                                        className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                        onClick={() =>
                                            history.push(
                                                {
                                                    pathname: getRoute(
                                                        APP_ROUTES.CHANGE_EMAIL_CODE_VERIFICATION
                                                    )
                                                },
                                                {
                                                    routeParam: location.state.routeParam
                                                }
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(ChangeEmail);
