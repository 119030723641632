const DocumentIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="178"
            height="178"
            viewBox="0 0 178 178"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M128.062 128.063H174.938V3.0625H49.9375V49.9375"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M128.062 49.9375H3.0625V174.938H128.062V49.9375Z"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M19 75H110" stroke={color} strokeWidth="5" />
            <path d="M19 88H110" stroke={color} strokeWidth="5" />
            <path d="M19 101H110" stroke={color} strokeWidth="5" />
            <path d="M19 114H110" stroke={color} strokeWidth="5" />
        </svg>
    );
};
export default DocumentIcon;
