const SettingOptionIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.5 26.7188C24.83 26.7188 26.7188 24.83 26.7188 22.5C26.7188 20.17 24.83 18.2812 22.5 18.2812C20.17 18.2812 18.2812 20.17 18.2812 22.5C18.2812 24.83 20.17 26.7188 22.5 26.7188Z"
                stroke={color}
                strokeMiterlimit="10"
            />
            <path
                d="M22.5 12.6562C24.83 12.6562 26.7188 10.7675 26.7188 8.4375C26.7188 6.10755 24.83 4.21875 22.5 4.21875C20.17 4.21875 18.2812 6.10755 18.2812 8.4375C18.2812 10.7675 20.17 12.6562 22.5 12.6562Z"
                stroke={color}
                strokeMiterlimit="10"
            />
            <path
                d="M22.5 40.7812C24.83 40.7812 26.7188 38.8925 26.7188 36.5625C26.7188 34.2325 24.83 32.3438 22.5 32.3438C20.17 32.3438 18.2812 34.2325 18.2812 36.5625C18.2812 38.8925 20.17 40.7812 22.5 40.7812Z"
                stroke={color}
                strokeMiterlimit="10"
            />
        </svg>
    );
};
export default SettingOptionIcon;
