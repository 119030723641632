const RocketIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="180"
            height="180"
            viewBox="0 0 180 180"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M66.141 129.773C58.186 153.638 26.3662 153.638 26.3662 153.638C26.3662 153.638 26.3662 121.818 50.2311 113.863"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M137.733 74.0943L90.0029 121.824L58.1831 90.0042L105.913 42.2745C123.986 24.2013 142.059 24.3776 149.767 25.5276C150.954 25.7045 152.052 26.2579 152.901 27.1064C153.749 27.9548 154.303 29.0533 154.48 30.2401C155.63 37.9478 155.806 56.0211 137.733 74.0943Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M129.775 82.0449V127.445C129.775 128.183 129.629 128.915 129.347 129.597C129.064 130.28 128.65 130.9 128.127 131.422L105.387 154.162C104.667 154.882 103.765 155.393 102.778 155.641C101.79 155.889 100.754 155.864 99.7794 155.569C98.805 155.274 97.9289 154.72 97.2446 153.966C96.5602 153.212 96.0934 152.286 95.8937 151.288L90 121.82"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M97.9565 50.2285H52.5567C51.818 50.2285 51.0866 50.374 50.4041 50.6567C49.7217 50.9394 49.1016 51.3537 48.5792 51.876L25.8389 74.6163C25.119 75.3363 24.608 76.2381 24.3603 77.2256C24.1127 78.2132 24.1377 79.2494 24.4328 80.2239C24.7278 81.1983 25.2818 82.0744 26.0356 82.7587C26.7895 83.443 27.7149 83.9099 28.7133 84.1096L58.1817 90.0033"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default RocketIcon;
