const UrgentQuestionIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.4924 15.2743C20.4924 16.4851 20.4924 17.6958 20.4924 18.9066C20.4924 19.838 19.8405 20.49 19.0022 20.49C18.164 20.49 17.5586 19.7914 17.5586 18.9066C17.5586 16.4851 17.5586 14.0635 17.5586 11.5954C17.5586 10.7106 18.2106 10.0586 19.0488 10.0586C19.887 10.0586 20.4924 10.7106 20.4924 11.5954C20.4924 12.8061 20.4924 14.0635 20.4924 15.2743Z"
                fill={color}
            />
            <path
                d="M16.7656 25.704C16.7656 24.4933 17.7436 23.5153 19.0009 23.4688C20.2583 23.4688 21.2362 24.4467 21.2362 25.704C21.2362 26.9148 20.2117 27.9393 18.9543 27.9393C17.7436 27.9393 16.7656 26.9148 16.7656 25.704Z"
                fill={color}
            />
            <path
                d="M19.8396 0C25.847 0.232843 31.2024 3.02696 34.9744 8.70833C39.8641 16.1593 38.7465 26.1716 32.3666 32.4583C26.4524 38.326 17.5577 39.6765 10.1999 35.8578C10.0136 35.7647 9.68764 35.7181 9.50137 35.8113C7.8249 36.277 6.14843 36.7426 4.51852 37.2083C3.40088 37.5343 2.42294 37.3015 1.63127 36.5098C0.839602 35.7181 0.56019 34.7402 0.886171 33.6225C1.35186 31.9461 1.81754 30.2696 2.3298 28.6397C2.42294 28.3137 2.42294 28.0809 2.23666 27.7549C0.467053 24.402 -0.231476 20.7696 0.23421 16.9975C0.93274 10.5245 4.19254 5.58824 9.87392 2.375C12.7146 0.745098 15.7881 0 19.8396 0ZM3.63372 34.3676C3.86656 34.3211 3.9597 34.2745 4.09941 34.2745C5.86901 33.7623 7.63862 33.2966 9.40823 32.7377C10.0136 32.5515 10.5259 32.6446 11.0847 32.9706C14.3445 34.8333 17.8837 35.4853 21.6092 34.8799C32.3666 33.1103 38.2808 21.4681 33.3911 11.7819C29.619 4.28431 20.7244 0.931373 12.994 4.09804C7.2195 6.51961 3.91313 10.9902 3.0749 17.1838C2.60921 20.6765 3.35431 23.9828 5.07735 27.0098C5.35676 27.5221 5.4499 28.0343 5.26362 28.6397C4.98421 29.4779 4.75137 30.3627 4.51852 31.2475C4.23911 32.2255 3.91313 33.25 3.63372 34.3676Z"
                fill={color}
            />
        </svg>
    );
};
export default UrgentQuestionIcon;
