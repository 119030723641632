const OtherIcon = (props) => {
    const { className, onClick, color, bgColor } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="150"
            height="150"
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="75" cy="75" r="75" fill={bgColor} />
            <path
                d="M75.0003 79.3991C77.4297 79.3991 79.3991 77.4297 79.3991 75.0003C79.3991 72.571 77.4297 70.6016 75.0003 70.6016C72.571 70.6016 70.6016 72.571 70.6016 75.0003C70.6016 77.4297 72.571 79.3991 75.0003 79.3991Z"
                fill={color}
            />
            <path
                d="M75.0003 55.9391C77.4297 55.9391 79.3991 53.9697 79.3991 51.5404C79.3991 49.111 77.4297 47.1416 75.0003 47.1416C72.571 47.1416 70.6016 49.111 70.6016 51.5404C70.6016 53.9697 72.571 55.9391 75.0003 55.9391Z"
                fill={color}
            />
            <path
                d="M75.0003 102.859C77.4297 102.859 79.3991 100.89 79.3991 98.4603C79.3991 96.0309 77.4297 94.0615 75.0003 94.0615C72.571 94.0615 70.6016 96.0309 70.6016 98.4603C70.6016 100.89 72.571 102.859 75.0003 102.859Z"
                fill={color}
            />
            <path
                d="M75 115.25C97.2295 115.25 115.25 97.2295 115.25 75C115.25 52.7705 97.2295 34.75 75 34.75C52.7705 34.75 34.75 52.7705 34.75 75C34.75 97.2295 52.7705 115.25 75 115.25Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default OtherIcon;
