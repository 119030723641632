import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";

import "./Login.scss";

import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { login } from "../../store/actions";

import Logo from "../../components/SvgIcon/Logo";
import BgShape from "../../components/SvgIcon/BgShape";

import { UserTypes } from "../../constants";

// import { setSelectedUser } from "../../store/actions";

const Login = (props) => {
    const { setSelectedUser, isAuthenticated, userType, user } = props;
    const history = useHistory();
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const validEmailClass = validEmail ? "not-found" : "";

    const handleLoginSubmit = () => {
        setIsLoading(true);
        const bodyData = {
            Email: email,
            Password: password,
            Type: user.Type
        };
        dispatch(login(bodyData))
            .then((res) => {})
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <div className="tp-login text-center full-height content-centered">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="logo mb9">
                            <Logo color={Colors[`${userType}Primary`]} />
                        </div>
                        <h1 className="mb9">
                            {validEmail
                                ? "Please enter a valid email adress and password"
                                : "Please enter your email address"}
                        </h1>
                        <AvForm className="text-start" onValidSubmit={handleLoginSubmit}>
                            <TextInput
                                name="email"
                                placeholder="Type the Email Address"
                                type="email"
                                value={email}
                                className={`tp-text-input-${userType} ${validEmailClass} with-icon mb4`}
                                onChange={(e) => setEmail(e.target.value)}
                                errorMessage="This is a required field"
                                options={{
                                    required: { value: true },
                                    email: {
                                        errorMessage: "Please enter a valid email ID"
                                    },
                                    maxLength: {
                                        value: 64,
                                        errorMessage: "Max 64 characters are allowed"
                                    }
                                }}
                            />
                            <TextInput
                                name="password"
                                placeholder="Type the password"
                                className={`tp-text-input-${userType} with-icon mb4`}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    maxLength: {
                                        value: 128,
                                        errorMessage: "Max 128 characters are allowed"
                                    }
                                }}
                            />
                            <Col xs={12} className="text-center">
                                <Button
                                    title="Forgotten Password?"
                                    className={`clr-${userType}-primary text-decoration-underline mb6`}
                                    onClick={() =>
                                        history.push(getRoute(APP_ROUTES.FORGOT_PASSWORD))
                                    }
                                />
                            </Col>
                            <Col xs={12} className="text-center">
                                <Button
                                    title="Log In"
                                    className={`tp-btn-primary tp-btn-primary-${userType}`}
                                    isLoading={isLoading}
                                    type="submit"
                                />
                            </Col>
                        </AvForm>
                    </Col>
                    <Col xs={12}>
                        <Row className="flex-column">
                            <Col xs={12}>
                                <Button
                                    title="Sign Up"
                                    className={`tp-btn-primary tp-btn-primary-${userType} mt4`}
                                    onClick={() => history.push(getRoute(APP_ROUTES.CHOOSE_USER))}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    // setSelectedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
