const GuardianIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="313"
            height="175"
            viewBox="0 0 313 175"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M90.0973 121.659C120.708 121.659 145.523 96.8672 145.523 66.2847C145.523 35.7022 120.708 10.9102 90.0973 10.9102C59.4867 10.9102 34.6719 35.7022 34.6719 66.2847C34.6719 96.8672 59.4867 121.659 90.0973 121.659Z"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
            />
            <path
                d="M177.914 172C160.279 141.795 127.527 121.659 90.0971 121.659C53.0268 121.659 20.6354 141.436 3 170.561"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M243.413 134.604C266.271 134.604 284.802 116.09 284.802 93.2525C284.802 70.4149 266.271 51.9014 243.413 51.9014C220.554 51.9014 202.023 70.4149 202.023 93.2525C202.023 116.09 220.554 134.604 243.413 134.604Z"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
            />
            <path
                d="M177.912 171.999C191.229 149.706 215.702 134.604 243.415 134.604C271.128 134.604 295.241 149.346 308.558 171.28"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M309.994 35.7217V66.2856"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M243.056 66.2852L176.113 35.7213L243.056 3L309.998 35.7213L243.056 66.2852Z"
                fill="#F7F8FC"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default GuardianIcon;
