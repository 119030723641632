const FoodHealthIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="250"
            height="246"
            viewBox="0 0 250 246"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M61.18 107.389C61.2175 104.924 62.0008 102.529 63.4266 100.518C64.8524 98.5073 66.8539 96.9756 69.1674 96.1248"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M107.089 143.464C106.632 156.106 101.406 167.102 94.5726 169.616L68.8202 179.088C58.8053 182.771 39.6146 170.339 32.774 151.74C31.2503 147.597 30.9583 143.102 31.9332 138.796C32.9081 134.491 35.1083 130.56 38.2681 127.478C41.428 124.396 45.4124 122.294 49.7405 121.427C54.0685 120.559 58.5551 120.963 62.6588 122.589L62.658 122.589C64.125 119.829 66.1419 117.4 68.5843 115.451C71.0266 113.502 73.8426 112.074 76.8583 111.255C79.8741 110.436 83.0256 110.244 86.1183 110.691C89.2111 111.138 92.1795 112.214 94.8403 113.852L94.8402 113.853C92.7968 116.408 91.4761 119.466 91.0157 122.706C90.5554 125.945 90.9723 129.249 92.223 132.273C93.4737 135.297 95.5124 137.931 98.1267 139.899C100.741 141.867 103.835 143.098 107.087 143.464L107.089 143.464Z"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M137.698 93.6526C137.698 93.6526 94.6109 80.4531 88.9534 54.3223C87.9542 49.7036 88.5855 44.8814 90.7399 40.6756C92.8943 36.4698 96.4389 33.14 100.771 31.2522C105.103 29.3645 109.955 29.0353 114.502 30.3208C119.05 31.6063 123.012 34.4269 125.714 38.3033L125.714 38.3033C126.571 33.6562 129.012 29.4494 132.62 26.3982C136.228 23.347 140.782 21.6396 145.507 21.5664C150.232 21.4932 154.836 23.0587 158.537 25.9968C162.239 28.9348 164.808 33.064 165.809 37.6823C171.466 63.8131 137.698 93.6526 137.698 93.6526Z"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M241.351 160.621C240.441 182.624 227.551 202.703 206.733 217.538C185.134 232.93 155.167 242.529 121.951 242.529C88.735 242.529 58.7681 232.93 37.1696 217.538C16.3518 202.703 3.46193 182.624 2.55166 160.621H241.351Z"
                fill="none"
                stroke={color}
                strokeWidth="5"
            />
            <path
                d="M213.415 103.242L167.684 148.974"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M176.83 139.827C158.537 109.34 182.928 78.8518 234.757 81.9006C237.806 133.73 207.318 158.12 176.83 139.827Z"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default FoodHealthIcon;
