import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { ToggleBtn } from "../../components/ToggleBtn";
import { Button } from "../../components/Button";

const NotificationSettings = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [toggleSwitch, setToggleSwitch] = useState(false);

    return (
        <div className="tp-settings tp-notification-settings">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Notifications
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to settings"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <Row>
                                <Col md={6}>
                                    <div className="content-inner notification-inner bg-clr-dark">
                                        <h2 className={`clr-${userType}-primary`}>Questions</h2>
                                        <div className="change-setting-wrap d-flex align-items-center justify-content-between bg-white radius-7 pt2 pb2 pr4 pl4 mt4">
                                            <h2 className="mb0">New Question</h2>
                                            <ToggleBtn
                                                className={`tp-toggle-switch-btn-${userType}`}
                                                checked={toggleSwitch}
                                                onChange={() => {
                                                    setToggleSwitch(!toggleSwitch);
                                                }}
                                            />
                                        </div>
                                        <div className="change-setting-wrap d-flex align-items-center justify-content-between bg-white radius-7 pt2 pb2 pr4 pl4 mt4">
                                            <h2 className="mb0">Need Clarity</h2>
                                            <ToggleBtn
                                                className={`tp-toggle-switch-btn-${userType}`}
                                            />
                                        </div>
                                        <div className="change-setting-wrap d-flex align-items-center justify-content-between bg-white radius-7 pt2 pb2 pr4 pl4 mt4">
                                            <h2 className="mb0">Follow up Question</h2>
                                            <ToggleBtn
                                                className={`tp-toggle-switch-btn-${userType}`}
                                            />
                                        </div>
                                        <div className="change-setting-wrap d-flex align-items-center justify-content-between bg-white radius-7 pt2 pb2 pr4 pl4 mt4">
                                            <h2 className="mb0">Urgent Question</h2>
                                            <ToggleBtn
                                                className={`tp-toggle-switch-btn-${userType}`}
                                            />
                                        </div>
                                        <div className="change-setting-wrap d-flex align-items-center justify-content-between bg-white radius-7 pt2 pb2 pr4 pl4 mt4">
                                            <h2 className="mb0">Expiry Question  </h2>
                                            <ToggleBtn
                                                className={`tp-toggle-switch-btn-${userType}`}
                                            />
                                        </div>
                                        <div className="change-setting-wrap d-flex align-items-center justify-content-between bg-white radius-7 pt2 pb2 pr4 pl4 mt4">
                                            <h2 className="mb0">Answer declined</h2>
                                            <ToggleBtn
                                                className={`tp-toggle-switch-btn-${userType}`}
                                            />
                                        </div>
                                        <div className="change-setting-wrap d-flex align-items-center justify-content-between bg-white radius-7 pt2 pb2 pr4 pl4 mt4">
                                            <h2 className="mb0">Withdrawn Question</h2>
                                            <ToggleBtn
                                                className={`tp-toggle-switch-btn-${userType}`}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="content-inner bg-clr-light notification-inner bg-clr-dark">
                                        <h2 className={`clr-${userType}-primary`}>Questions</h2>
                                        <div className="change-setting-wrap d-flex align-items-center justify-content-between bg-white radius-7 pt2 pb2 pr4 pl4 mt4">
                                            <h2 className="mb0">New Invite</h2>
                                            <ToggleBtn
                                                className={`tp-toggle-switch-btn-${userType}`}
                                            />
                                        </div>
                                        <div className="change-setting-wrap d-flex align-items-center justify-content-between bg-white radius-7 pt2 pb2 pr4 pl4 mt4">
                                            <h2 className="mb0">Invite Accepted</h2>
                                            <ToggleBtn
                                                className={`tp-toggle-switch-btn-${userType}`}
                                            />
                                        </div>
                                        <div className="change-setting-wrap d-flex align-items-center justify-content-between bg-white radius-7 pt2 pb2 pr4 pl4 mt4">
                                            <h2 className="mb0">Invite Declined</h2>
                                            <ToggleBtn
                                                className={`tp-toggle-switch-btn-${userType}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="content-inner bg-clr-light notification-inner bg-clr-dark mt4">
                                        <h2 className={`clr-${userType}-primary`}>Questions</h2>
                                        <div className="change-setting-wrap d-flex align-items-center justify-content-between bg-white radius-7 pt2 pb2 pr4 pl4 mt4">
                                            <h2 className="mb0">New Payment</h2>
                                            <ToggleBtn
                                                className={`tp-toggle-switch-btn-${userType}`}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(NotificationSettings);
