import { useState, Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";

import "../QuestionBoard.scss";

import { Button } from "../../../components/Button";
import { TpModal } from "../../../components/TpModal";
import { TextInput } from "../../../components/TextInput";
import { StarRating } from "../../../components/StarRating";
import MessageModal from "./MessageModal";
import { sentQuestionFeedback } from "../../../store/actions/questionBoardActions";

const FeedbackDescriptionModal = (props) => {
    const { userType, isOpen, handleClose, description, onChange, handleSendBtnPress, isLoading } =
        props;
    return (
        <TpModal
            isOpen={isOpen}
            centered
            className={`tp-modal-${userType} size-4`}
            children={
                <div>
                    <h3 className="h4 text-center mb3">
                        We noticed a very low feedback score. Was there a particular reason for
                        this?
                    </h3>
                    <AvForm>
                        <TextInput
                            name="answerquestion"
                            type="textarea"
                            value={description}
                            onChange={onChange}
                            className={`radius-7 resize-none size-sm tp-text-input-${userType}`}
                            options={{
                                required: {
                                    value: true,
                                    errorMessage: "This field is required"
                                }
                            }}
                        />
                        <div className="text-center d-flex justify-content-center mt6">
                            <Button
                                onClick={handleClose}
                                className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border-${userType} mr2`}
                                disabled={isLoading}
                                title="Cancel"
                            />
                            <Button
                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                title="Submit"
                                isLoading={isLoading}
                                onClick={handleSendBtnPress}
                            />
                        </div>
                    </AvForm>
                </div>
            }
        />
    );
};

const RatingModal = (props) => {
    const { userType, openRatingModal, handleModalClose } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();

    const [clarityStar, setClarityStar] = useState(2);
    const [contentQualityStar, setContentQualtiyStar] = useState(2);
    const [responseTimeStar, setResponseTimeStar] = useState(2);
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [description, setDescription] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitFeedback = () => {
        let payload = {
            ...(description && { Comment: description }),
            QuestionId: Number(id),
            ContentRating: contentQualityStar,
            ClarityRating: clarityStar,
            TimeRating: responseTimeStar
        };
        setIsLoading(true);
        dispatch(sentQuestionFeedback(payload))
            .then((res) => {
                setShowDescriptionModal(null);
                setIsLoading(false);
                setShowSuccessModal(true);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const handleSubmitClick = () => {
        if (clarityStar < 3 || contentQualityStar < 3 || responseTimeStar < 3) {
            setShowDescriptionModal(true);
            return;
        } else {
            handleSubmitFeedback();
        }
    };

    const handleSendBtnPress = () => {
        handleSubmitFeedback();
    };

    return (
        <>
            {showDescriptionModal !== null && !showDescriptionModal && (
                <TpModal
                    isOpen={openRatingModal}
                    centered
                    className={`tp-modal-${userType} size-2`}
                    children={
                        <Fragment>
                            <h3 className="mb5 text-center">
                                Sorry! It looks like you missed a category
                            </h3>
                            <div className="d-flex mb4 justify-content-between">
                                <h3 className="mb0 mr3">Content Clarity</h3>
                                <StarRating
                                    rating={clarityStar}
                                    changeRating={(val) => {
                                        setClarityStar(val);
                                    }}
                                />
                            </div>

                            <div className="d-flex mb4 justify-content-between">
                                <h3 className="mb0 mr3">Content Quality</h3>
                                <StarRating
                                    rating={contentQualityStar}
                                    changeRating={(val) => {
                                        setContentQualtiyStar(val);
                                    }}
                                />
                            </div>

                            <div className="d-flex justify-content-between">
                                <h3 className="mb0 mr3">Response Time</h3>
                                <StarRating
                                    rating={responseTimeStar}
                                    changeRating={(val) => {
                                        setResponseTimeStar(val);
                                    }}
                                />
                            </div>
                            <Button
                                title="Skip"
                                onClick={handleModalClose}
                                className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mt9 mr4`}
                            />
                            <Button
                                title="Submit"
                                className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                onClick={handleSubmitClick}
                                isLoading={isLoading}
                            />
                        </Fragment>
                    }
                />
            )}
            {showDescriptionModal && (
                <FeedbackDescriptionModal
                    userType={userType}
                    description={description}
                    isOpen={showDescriptionModal}
                    onChange={(evt) => setDescription(evt.target.value)}
                    isLoading={isLoading}
                    handleClose={() => setShowDescriptionModal(false)}
                    handleSendBtnPress={handleSendBtnPress}
                />
            )}
            <MessageModal
                showMessageModal={showSuccessModal}
                text="Thank you for your feedback. This will help us improve your TutorPin experience"
                handleClick={() => history.goBack()}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(RatingModal);
