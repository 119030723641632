import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";

import "../QuestionBoard.scss";
import { Colors } from "../../../themes/colors";

import { TextInput } from "../../../components/TextInput";
import { Button } from "../../../components/Button";
import { TpModal } from "../../../components/TpModal";

import { CreatableDropdown } from "../../../components/CreatableDropdown";

import PlusIcon from "../../../components/SvgIcon/PlusIcon";

import AvatarImg from "../../../assets/images/placeholder-profile.jpg";

const ResourceDetailsModal = (props) => {
    const { userType, showResourceDetailModal } = props;
    return (
        <TpModal
            isOpen={showResourceDetailModal}
            centered
            className={`tp-modal-${userType} size-9 resource-upload-modal`}
            children={
                <AvForm>
                    <div className="text-center">
                        <h3 className="mb4">Please tell us more about this resource</h3>
                    </div>
                    <Row className="align-items-center mt4">
                        <Col md={3}>
                            <h3 className="mb0">Resource Uploaded:</h3>
                        </Col>
                        <Col md={9}>
                            <div
                                className={`uploaded-resource radius-3 uploaded-resource-${userType}`}
                            >
                                <Row>
                                    <Col xs={6}>
                                        <img
                                            src={AvatarImg}
                                            className="img-fluid radius-4 objfit-cover"
                                            alt=""
                                        />
                                    </Col>
                                    <Col xs={6} className="d-flex">
                                        <h6 className="mb0 mt-auto">Nugget.jpg</h6>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center mt4">
                        <Col md={3}>
                            <h3 className="mb0">Subject:</h3>
                        </Col>
                        <Col md={9}>
                            <CreatableDropdown
                                placeholder="Select Subject"
                                options={[
                                    {
                                        label: "Math"
                                    },
                                    {
                                        label: "Biology"
                                    },
                                    {
                                        label: "Spanish"
                                    },
                                    {
                                        label: "German"
                                    },
                                    {
                                        label: "Italian"
                                    }
                                ]}
                                className={`mw-100 dropdown-size-sm tp-creatable-dropdown-${userType}`}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center mt4">
                        <Col md={3}>
                            <h3 className="mb0">Topic:</h3>
                        </Col>
                        <Col md={9}>
                            <CreatableDropdown
                                placeholder="Select Topic"
                                options={[
                                    {
                                        label: "Math"
                                    },
                                    {
                                        label: "Biology"
                                    },
                                    {
                                        label: "Spanish"
                                    },
                                    {
                                        label: "German"
                                    },
                                    {
                                        label: "Italian"
                                    }
                                ]}
                                className={`mw-100 dropdown-size-sm tp-creatable-dropdown-${userType}`}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center mt4">
                        <Col md={3}>
                            <h3 className="mb0">Name of the resource:</h3>
                        </Col>
                        <Col md={9}>
                            <TextInput
                                placeholder="Type the name of the resouce"
                                name="nameofresource"
                                className={`radius-7 tp-text-input-${userType}`}
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This field is required"
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center mt4">
                        <Col md={3}>
                            <h3 className="mb0">Suggested time:</h3>
                        </Col>
                        <Col md={9}>
                            <TextInput
                                placeholder="5 minutes"
                                name="suggestedtime"
                                className={`radius-7 tp-text-input-${userType}`}
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This field is required"
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center mt4">
                        <Col md={3}>
                            <h3 className="mb0">Tags:</h3>
                        </Col>
                        <Col md={9}>
                            <Row>
                                <Col md={6}>
                                    <TextInput
                                        placeholder="Type tags"
                                        name="tags"
                                        className={`radius-7 tp-text-input-${userType}`}
                                        options={{
                                            required: {
                                                value: true,
                                                errorMessage: "This field is required"
                                            }
                                        }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Button
                                        className={`w-100 radius-7 tp-btn-add tp-btn-add-${userType}`}
                                        title={<PlusIcon color={Colors[`${userType}Primary`]} />}
                                    ></Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="text-center mt9">
                        <Button
                            className={`mt9 tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                            title="Cancel"
                        />
                        <Button
                            className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                            title="Add resource"
                        />
                    </div>
                </AvForm>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(ResourceDetailsModal);
