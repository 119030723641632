import { useHistory, Redirect, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import "./Signup.scss";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import BgShape from "../../components/SvgIcon/BgShape";
import CheckIconCircle from "../../components/SvgIcon/CheckIconCircle";

const InviteConfirmation = ({ isAuthenticated, userType }) => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isInvited = params.get("isInvited");
    const isGuardian = params.get("guardian");

    if (!isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: getRoute(APP_ROUTES.SETUP_PASSWORD),
                    search: isInvited ? "?isInvited=true" : ""
                }}
            />
        );
    }

    return (
        <div className="tp-invite-confirmation full-height content-centered text-center">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="confirmation-msg">
                            <CheckIconCircle type={`${userType}`} />
                            <h1 className="confirmation-text mb0 mt9">
                                An email invite has been sent to the guardian.
                                {isGuardian ? "They" : "The student and guardian"}
                                <br /> will be added to your network once they have <br />
                                accepted the invite. You can send more invites from <br />
                                the dashboard.
                            </h1>
                            <Button
                                title="OK"
                                className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                onClick={() => history.push(getRoute(APP_ROUTES.DASHBOARD))}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps)(InviteConfirmation);
