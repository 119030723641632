const HandIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M33.3339 23.3333V28.3333C33.3339 32.0152 30.3491 35 26.6672 35H16.7033C14.5847 35 12.5921 33.9929 11.3356 32.2871L5.57627 24.468C4.69239 23.268 4.91355 21.5842 6.07734 20.6532C7.26318 19.7045 8.97284 19.7998 10.046 20.8743L13.3339 24.1667V21.6667H13.3405L13.3339 18.3333L13.363 10.8333C13.363 9.45262 14.4823 8.33333 15.863 8.33333C17.2437 8.33333 18.363 9.45262 18.363 10.8333V18.3333V7.5C18.363 6.11929 19.4823 5 20.863 5C22.2437 5 23.363 6.11929 23.363 7.5V18.3333V9.16667C23.363 7.78595 24.4823 6.66667 25.863 6.66667C27.2437 6.66667 28.363 7.78595 28.363 9.16667V18.3333V12.5C28.363 11.1193 29.4823 10 30.863 10C32.2437 10 33.363 11.1193 33.363 12.5L33.3437 23.3333H33.3339Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default HandIcon;
