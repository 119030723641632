const AllEarningBg = (props) => {
    const { className, onClick } = props;
    return (
        <svg
            width="670"
            height="201"
            viewBox="0 0 670 201"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <path
                d="M148.659 16.4167C106.684 -44.4486 32.0636 78.5063 0 147.592V200L669.5 200.796V148.388C669.5 148.388 651 138.908 626.5 132.908C602 126.908 549.472 165.306 504 153.5C458.528 141.694 449.473 130.539 377.768 147.592C306.062 164.645 201.126 92.4983 148.659 16.4167Z"
                fill="url(#paint0_linear)"
                fill-opacity="0.1"
            />
            <path
                d="M149.5 79.2536C79.5314 55.3619 32.0636 78.2599 0 147.346V199.754L669.5 200.55V148.142C669.5 148.142 628.5 54.5021 583.5 50.5021C538.5 46.5021 493.472 151.559 448 139.754C402.528 127.948 381.206 146.701 309.5 163.754C237.794 180.806 211 100.254 149.5 79.2536Z"
                fill="url(#paint1_linear)"
                fill-opacity="0.1"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="609.5"
                    y1="0"
                    x2="609.5"
                    y2="200"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#06729D" />
                    <stop offset="0.963542" stop-color="#06729D" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="609.5"
                    y1="-0.248514"
                    x2="609.5"
                    y2="199.751"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#06729D" />
                    <stop offset="0.963542" stop-color="#06729D" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};
export default AllEarningBg;
