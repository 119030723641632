import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtil";

export const getNetwork = (params) => (dispatch, getState) => {
    return fetchUtil({
        url: appendQueryParams("/network", params)
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const sendStudentInvite = (payload) => (dispatch, getState) => {
    let body = JSON.stringify(payload);
    let token = getState().auth.user.Token;
    return fetchUtil({
        url: "/network/invite/student",
        method: "POST",
        token,
        body
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const sendGuardianInvite = (payload) => (dispatch, getState) => {
    let body = JSON.stringify(payload);
    let token = getState().auth.user.Token;
    return fetchUtil({
        url: "/network/invite/guardian",
        method: "POST",
        token,
        body
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
