import "./RadioBtn.scss";

const RadioBtn = (props) => {
    const { checked = false, value = null, onClick = () => {}, className = "" } = props;

    return (
        <div className={`tp-radio-btn d-flex ${className}`}>
            <input
                className={`pointer ${checked ? "checked" : ""}`}
                type="radio"
                checked={checked}
                onClick={onClick}
            />
        </div>
    );
};
export default RadioBtn;
