import React from "react";
import OwlCarousel from "react-owl-carousel";

import "./Slider.scss";
import "owl.carousel/dist/assets/owl.carousel.css";

const Slider = (props) => {
    const { className = "", options, children } = props;

    return (
        <OwlCarousel
            className={`tp-slider ${className}`}
            margin={options.margin}
            items={options.items}
            dots={options.dots}
            nav={options.nav}
            center={options.center}
            loop={options.loop}
        >
            {children}
        </OwlCarousel>
    );
};
export default Slider;
