const GeneralIcon = (props) => {
    const { className, onClick, color, bgColor } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="150"
            height="150"
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="75" cy="75" r="75" fill={bgColor} />
            <path
                d="M124.255 51H24.7448C22.6852 51 21 52.7368 21 54.8597V83.8742C21 85.9972 22.6852 87.7339 24.7448 87.7339H67.711C68.3788 87.7347 69.0282 87.9595 69.561 88.3743C70.0939 88.7891 70.4813 89.3714 70.6646 90.0331C70.7056 90.1798 70.751 90.3325 70.8011 90.4889C71.1508 91.5832 71.9692 93.458 73.4246 95.1293C73.6 95.3302 73.7823 95.5293 73.9751 95.7235C77.6571 99.4283 82.6917 99.0915 84.2513 98.8868C83.4143 98.4346 82.6429 97.8636 81.9597 97.1905C80.505 95.7542 79.5321 93.8831 79.0409 91.5772C78.6208 89.6035 80.0802 87.735 82.041 87.735H124.255C126.315 87.735 128 85.9983 128 83.8753V54.8597C128 52.7368 126.315 51 124.255 51Z"
                stroke={color}
                strokeWidth="4"
            />
        </svg>
    );
};
export default GeneralIcon;
