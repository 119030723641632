import { useHistory, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useState } from "react";
import "./SignupInvite.scss";
import { Colors } from "../../themes/colors";
import { AvForm } from "availity-reactstrap-validation";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { connect } from "react-redux";
import Logo from "../../components/SvgIcon/Logo";
import BgShape from "../../components/SvgIcon/BgShape";
import { sendEmailVerification, setPersonalDetails, updateDeviceId } from "../../store/actions";
import { UserTypes } from "../../constants";

const EnterEmail = ({
    updateDeviceId,
    sendEmailVerification,
    setPersonalDetails,
    emailAddress,
    isAuthenticated,
    userType
}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        if (emailAddress) {
            setLoading(true);
            let payload = {
                Email: emailAddress,
                Type: UserTypes.TUTOR
            };
            sendEmailVerification(payload)
                .then((res) => {
                    setPersonalDetails({ selectedUserType: UserTypes.TUTOR });
                    updateDeviceId(res.Data.DeviceUUID);

                    history.push({
                        pathname: getRoute(APP_ROUTES.VERIFICATION_CODE_CONFIRMATION),
                        search: "?isInvited=true"
                    });
                })
                .catch((err) => {})
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    if (isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: getRoute(APP_ROUTES.DASHBOARD)
                }}
            />
        );
    }
    return (
        <div className="tp-enter-email text-center full-height content-centered">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="logo mb9">
                            <Logo color={Colors[`${userType}Primary`]} />
                        </div>
                        <h1 className="mb9">Please enter your email address</h1>
                        <AvForm className="text-start" onValidSubmit={handleSubmit}>
                            <TextInput
                                name="email"
                                placeholder="Email Address"
                                type="email"
                                className={`tp-text-input-${userType} with-icon`}
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    email: {
                                        errorMessage: "Please enter a valid email address"
                                    },

                                    maxLength: {
                                        value: 64,
                                        errorMessage: "Email address must not exceed 64 characters "
                                    }
                                }}
                                value={emailAddress}
                                onChange={(e) =>
                                    setPersonalDetails({ emailAddress: e.target.value })
                                }
                            />
                            <Col xs={12} className="text-center mt9">
                                <Button
                                    title="Next"
                                    className={`tp-btn-primary tp-btn-primary-${userType} mt4`}
                                    type="submit"
                                    isLoading={loading}
                                />
                            </Col>
                        </AvForm>
                    </Col>
                    <Col xs={12} className="text-center">
                        <h3 className="consent-text mb0 mt4">
                            By continuing you agree to our{" "}
                            <span className={`clr-${userType}-primary`}>terms and conditions </span>{" "}
                            and <span className={`clr-${userType}-primary`}>privacy policy.</span>
                        </h3>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
const mapDispatchToProps = {
    sendEmailVerification,
    setPersonalDetails,
    updateDeviceId
};
const mapStateToProps = (state) => {
    const emailAddress = state.root.auth.emailAddress;
    const isAuthenticated = state.auth.isAuthenticated;
    return {
        emailAddress,
        isAuthenticated,
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EnterEmail);
