import { combineReducers } from "redux";
import { SIGN_OUT } from "../actions";

import root from "./rootReducer";
import authReducer from "./authReducer";
import deviceIdReducer from "./deviceIdReducer";
import questionBoardReducer from "./questionBoardReducer";

const appReducer = combineReducers({
    root: root,
    auth: authReducer,
    deviceId: deviceIdReducer,
    questionBoard: questionBoardReducer
});

const rootReducer = (state, action) => {
    if (action.type === SIGN_OUT) {
        const { deviceId } = state;
        state = { deviceId };
    }

    return appReducer(state, action);
};

export default rootReducer;
