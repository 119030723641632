import React, { useState, useEffect } from "react";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import "./Signup.scss";
import { Colors } from "../../themes/colors";
import { getSubjectListing, sendStudentInvite } from "../../store/actions";
import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import BgShape from "../../components/SvgIcon/BgShape";
import PlusIcon from "../../components/SvgIcon/PlusIcon";

const StudentInviteForm = ({ getSubjectListing, isAuthenticated, sendStudentInvite, userType }) => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isInvited = params.get("isInvited");

    const [subjects, setSubjects] = useState([]);
    let [interSub, setInterSub] = useState("initialState");
    const [subjectErr, setSubjectErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedSubjectsArr, setSelectedSubjectsArr] = useState([
        { label: "", Id: "", hours: "" }
    ]);
    const [inviteData, setInviteData] = useState({
        studentName: "",
        studentEmail: "",
        guardianName: "",
        guardianEmail: ""
    });

    const formatSubjects = (subjects) => {
        return subjects.map((item) => {
            return {
                ...item,
                label: item.Title
            };
        });
    };
    useEffect(() => {
        getSubjectListing()
            .then((res) => {
                setInterSub(formatSubjects([...res?.Data?.Subjects]));
                setSubjects(formatSubjects([...res?.Data?.Subjects]));
            })
            .catch((err) => {});
    }, []);

    const handleAddSubject = () => {
        let subjectId = selectedSubjectsArr[selectedSubjectsArr.length - 1].Id;
        let subjectHours = selectedSubjectsArr[selectedSubjectsArr.length - 1].hours;
        if (subjectId && subjectHours) {
            setSubjectErr(false);
            setSelectedSubjectsArr([...selectedSubjectsArr, { Id: "", label: "", hours: "" }]);
        } else if (!subjectId) {
            setSubjectErr(true);
        }
    };

    const handleSubmit = () => {
        let subjectId = selectedSubjectsArr[selectedSubjectsArr.length - 1].Id;
        let subjectHours = selectedSubjectsArr[selectedSubjectsArr.length - 1].hours;
        if (subjectId) {
            setSubjectErr(false);
            if (subjectHours) {
                const finalSubjectWithHoursArr = selectedSubjectsArr.map((subject) => {
                    return { SubjectId: subject.Id, HourlyRate: parseFloat(subject.hours) };
                });

                setLoading(true);
                const { guardianName, studentName, studentEmail, guardianEmail } = inviteData;
                const payload = {
                    GuardianName: guardianName,
                    StudentName: studentName,
                    StudentEmail: studentEmail,
                    GuardianEmail: guardianEmail,
                    SubjectRate: finalSubjectWithHoursArr
                };

                sendStudentInvite(payload)
                    .then((res) => {
                        setLoading(false);
                        history.push({
                            pathname: getRoute(APP_ROUTES.INVITE_CONFIRMATION),
                            search: isInvited ? "?isInvited=true" : ""
                        });
                    })
                    .catch((err) => {
                        setLoading(false);
                    });
            }
        } else {
            setSubjectErr(true);
        }
    };

    const handleInvalidSubmit = () => {
        let subjectId = selectedSubjectsArr[selectedSubjectsArr.length - 1].Id;
        if (subjectId) {
            setSubjectErr(false);
        } else {
            setSubjectErr(true);
        }
    };
    const handleOnChange = (option, index) => {
        setSubjectErr(false);

        const newSubjectArr = selectedSubjectsArr.map((subject, subjectIndex) => {
            if (index === subjectIndex) {
                subject.Id = option.Id;
                subject.label = option.label;
            }
            return subject;
        });
        let filtered = subjects.filter(
            (item) => !selectedSubjectsArr.map((subject) => subject.Id).includes(item.Id)
        );
        setSelectedSubjectsArr(newSubjectArr);
        setInterSub(filtered);
    };

    const handleOnChangeHours = (value, index) => {
        const newSubjectArr = selectedSubjectsArr.map((subject, subjectIndex) => {
            if (index === subjectIndex) {
                subject.hours = value;
            }
            return subject;
        });

        setSelectedSubjectsArr(newSubjectArr);
    };
    if (!isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: getRoute(APP_ROUTES.SETUP_PASSWORD),
                    search: isInvited ? "?isInvited=true" : ""
                }}
            />
        );
    }
    return (
        <div className="tp-student-invite-form full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12}>
                        <h1 className="text-center mb0 mt9">Please enter the student’s details</h1>
                        <AvForm onValidSubmit={handleSubmit} onInvalidSubmit={handleInvalidSubmit}>
                            <TextInput
                                name="studentname"
                                placeholder="Student’s Name"
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    maxLength: {
                                        value: 55,
                                        errorMessage:
                                            "A valid name must be of min 6 & max 55 characters with no special characters"
                                    },
                                    minLength: {
                                        value: 6,
                                        errorMessage:
                                            "A valid name must be of min 6 & max 55 characters with no special characters"
                                    },
                                    pattern: {
                                        value: "/^[a-zA-Z ]+$/",
                                        errorMessage:
                                            "A valid name must be of min 6 & max 55 characters with no special characters"
                                    }
                                }}
                                value={inviteData.studentName}
                                onChange={(e) =>
                                    setInviteData({ ...inviteData, studentName: e.target.value })
                                }
                                className={`tp-text-input-${userType} mt9`}
                            />
                            <TextInput
                                name="studentemail"
                                placeholder="Student’s Email Address"
                                type="email"
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    email: {
                                        errorMessage: "Please enter a valid email address"
                                    },
                                    maxLength: {
                                        value: 64,
                                        errorMessage: "Email address must not exceed 64 characters "
                                    }
                                }}
                                value={inviteData.studentEmail}
                                onChange={(e) =>
                                    setInviteData({ ...inviteData, studentEmail: e.target.value })
                                }
                                className={`tp-text-input-${userType} mt9`}
                            />

                            <div className="subject-select-dropdown">
                                <Row>
                                    <Col>
                                        {selectedSubjectsArr.map((item, index) => {
                                            return (
                                                <Row className="align-items-end mt9" key={index}>
                                                    <Col>
                                                        <CreatableDropdown
                                                            key={index}
                                                            placeholder="Select Subject"
                                                            onChange={(option) =>
                                                                handleOnChange(option, index)
                                                            }
                                                            options={interSub}
                                                            className={`tp-creatable-dropdown-${userType}`}
                                                            dropIconColor={
                                                                Colors[`${userType}Primary`]
                                                            }
                                                        />
                                                    </Col>

                                                    <Col>
                                                        <TextInput
                                                            name={`selectsubject-${index}`}
                                                            placeholder="Subject Hourly Rate"
                                                            options={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage:
                                                                        "This is a required field"
                                                                },
                                                                max: {
                                                                    value: 100,
                                                                    errorMessage:
                                                                        "Valid hourly rate must be minimum 1 GBP and must not exceed 100 GBP"
                                                                },
                                                                min: {
                                                                    value: 1,
                                                                    errorMessage:
                                                                        "Valid hourly rate must be minimum 1 GBP and must not exceed 100 GBP"
                                                                },
                                                                pattern: {
                                                                    value: "/^[0-9]+$/",
                                                                    errorMessage:
                                                                        "Valid hourly rate must be minimum 1 GBP and must not exceed 100 GBP"
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                handleOnChangeHours(
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                            className={`tp-text-input-${userType}`}
                                                        />
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                        {subjectErr && (
                                            <div className="date-error  position-relative ">
                                                <span className="is-invalid"></span>
                                                <div className="invalid-feedback position-absolute">
                                                    This is a required field
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                    <Col md={"auto"}>
                                        {Boolean(interSub?.length) && (
                                            <Button
                                                className={`tp-btn-add tp-btn-add-${userType} mt9`}
                                                onClick={handleAddSubject}
                                                title={
                                                    <PlusIcon
                                                        color={Colors[`${userType}Primary`]}
                                                    />
                                                }
                                            ></Button>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                            <h3 className="sub-title mt9">Please enter the guardian details</h3>
                            <TextInput
                                name="guardianname"
                                placeholder="Guardian’s Name"
                                value={inviteData.guardianName}
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    maxLength: {
                                        value: 55,
                                        errorMessage:
                                            " A valid name must be of min 6 & max 55 characters with no special characters "
                                    },
                                    minLength: {
                                        value: 6,
                                        errorMessage:
                                            "A valid name must be of min 6 & max 55 characters with no special characters"
                                    },
                                    pattern: {
                                        value: "/^[a-zA-Z ]+$/",
                                        errorMessage:
                                            " A valid name must be of min 6 & max 55 characters with no special characters"
                                    }
                                }}
                                onChange={(e) =>
                                    setInviteData({ ...inviteData, guardianName: e.target.value })
                                }
                                className={`tp-text-input-${userType} mt9`}
                            />
                            <TextInput
                                name="guardianemail"
                                placeholder="Guardian’s Email Address"
                                type="email"
                                value={inviteData.guardianEmail}
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    email: {
                                        errorMessage: "Please enter a valid email address"
                                    },
                                    maxLength: {
                                        value: 64,
                                        errorMessage: "Email address must not exceed 64 characters "
                                    }
                                }}
                                onChange={(e) =>
                                    setInviteData({ ...inviteData, guardianEmail: e.target.value })
                                }
                                className={`tp-text-input-${userType} mt9`}
                            />
                            <Col xs={12} className="text-center mt9">
                                <Button
                                    title="Send Invite"
                                    className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                    type="submit"
                                    isLoading={loading}
                                />
                            </Col>
                        </AvForm>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapDispatchToProps = {
    getSubjectListing,
    sendStudentInvite
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentInviteForm);
