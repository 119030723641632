import { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";

import "./QuestionBoard.scss";
import { Colors } from "../../themes/colors";
import { QuestionStatus, QuestionTypes, UserTypes } from "../../constants";

import { Button } from "../../components/Button";
import { Question } from "../../components/Question";
import { TpDropdownPopup } from "../../components/TpDropdownPopup";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import PaperClipIcon from "../../components/SvgIcon/PaperClipIcon";
import FilterIcon from "../../components/SvgIcon/FilterIcon";
import ClarityQuestionIcon from "../../components/SvgIcon/ClarityQuestionIcon";
import FollowupQuestionIcon from "../../components/SvgIcon/FollowupQuestionIcon";
import UrgentQuestionIcon from "../../components/SvgIcon/UrgentQuestionIcon";
import { getQuestionsListing, getUserSubjectListing } from "../../store/actions";
import { Spinner } from "../../components/Loader";
import moment from "moment";

const QuestionsList = (props) => {
    const {
        subjects: { data },
        userType,
        user
    } = props;

    const [questionsList, setQuestionsList] = useState([]);
    const [questionCount, setQuestionCount] = useState(0);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [questionStatus, setQuestionStatus] = useState(QuestionStatus.UN_ANSWERED);
    const [selectedFilter, setSelectedFilter] = useState(QuestionTypes.ALL);
    const [page, setPage] = useState(1);

    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        if (data && !data.length) {
            // TODO: hard code UserId will be removed once network api available.
            dispatch(
                getUserSubjectListing({ ...(user.Type === UserTypes.GUARDIAN && { UserId: 251 }) })
            );
        }
    }, []);

    useEffect(() => {
        getUserQuestionsListing(1);
    }, [questionStatus, selectedFilter]);

    const getUserQuestionsListing = (page) => {
        setIsLoading(true);
        dispatch(
            getQuestionsListing({
                Status: questionStatus,
                Page: page,
                SubjectId: id,
                ...(selectedFilter && { Type: selectedFilter }),
                ...(user.Type === UserTypes.GUARDIAN && { UserId: 251 })
            })
        )
            .then((res) => {
                setQuestionsList(res?.Questions);
                setQuestionCount(res?.Count);
                setPage(page++);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const canAddBorderClass = (type) => {
        if (type === selectedFilter) return `tp-btn-secondary-border-${userType}`;
    };

    const handleFilterClick = (type) => {
        setOpenFilterModal(false);
        setSelectedFilter(type);
    };

    const handleQuestionClick = (question) => {
        if (questionStatus === QuestionStatus.DRAFT) {
            history.push(getRoute(APP_ROUTES.DRAFT_QUESTION, { id: question.Id }));
            return;
        }
        if (questionStatus === QuestionStatus.ANSWERED) {
            history.push(
                getRoute(APP_ROUTES.ANSWERED_QUESTION_DETAILS, {
                    id: question.RefId || question.Id
                })
            );
            return;
        }
        history.push(getRoute(APP_ROUTES.QUESTION_DETAILS, { id: question.RefId || question.Id }));
    };

    return (
        <div className="tp-question-board tp-questions-lists">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary mb9`}>
                            Question Board
                        </div>
                        <div className="content-inner-wrap">
                            <div className="content-inner questions-list-inner no-padding">
                                <Row>
                                    <Col md={9}>
                                        <div className="question-actions-filters d-flex align-items-center mb4">
                                            <div className="question-actions-btns">
                                                <Button
                                                    title="Unanswered"
                                                    className={`tp-btn-secondary tp-btn-secondary-${userType} ${
                                                        questionStatus ===
                                                            QuestionStatus.UN_ANSWERED &&
                                                        `tp-btn-secondary-border-${userType}`
                                                    }`}
                                                    onClick={() =>
                                                        setQuestionStatus(
                                                            QuestionStatus.UN_ANSWERED
                                                        )
                                                    }
                                                    counter={
                                                        QuestionStatus.UN_ANSWERED ===
                                                            questionStatus && questionCount
                                                    }
                                                />
                                                <Button
                                                    title="Answered"
                                                    className={`tp-btn-secondary tp-btn-secondary-${userType} ml4 ${
                                                        questionStatus ===
                                                            QuestionStatus.ANSWERED &&
                                                        `tp-btn-secondary-border-${userType}`
                                                    }`}
                                                    onClick={() =>
                                                        setQuestionStatus(QuestionStatus.ANSWERED)
                                                    }
                                                    counter={
                                                        QuestionStatus.ANSWERED ===
                                                            questionStatus && questionCount
                                                    }
                                                />
                                                {user.Type === UserTypes.STUDENT && (
                                                    <Button
                                                        title="Draft"
                                                        className={`tp-btn-secondary tp-btn-secondary-${userType} ml4 ${
                                                            questionStatus ===
                                                                QuestionStatus.DRAFT &&
                                                            `tp-btn-secondary-border-${userType}`
                                                        }`}
                                                        onClick={() =>
                                                            setQuestionStatus(QuestionStatus.DRAFT)
                                                        }
                                                        counter={
                                                            QuestionStatus.DRAFT ===
                                                                questionStatus && questionCount
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="question-filters ms-auto mr4 d-flex align-items-center">
                                                <TpDropdownPopup
                                                    className={`size-sm tp-dropdown-popup-${userType}`}
                                                    isOpen={openFilterModal}
                                                    toggle={() =>
                                                        setOpenFilterModal(!openFilterModal)
                                                    }
                                                    trigger={
                                                        <div className="d-flex align-items-center">
                                                            <h4 className="ff-sf-pro-rounded mb0">
                                                                <FilterIcon
                                                                    color={Colors.black}
                                                                    className="mr1 tpw-16 tph-16"
                                                                />
                                                                Filter
                                                            </h4>
                                                        </div>
                                                    }
                                                    children={
                                                        <div className="text-center">
                                                            <Button
                                                                title="All questions"
                                                                className={`tp-btn-secondary tp-btn-secondary-${userType} ${canAddBorderClass(
                                                                    QuestionTypes.ALL
                                                                )}`}
                                                                onClick={() =>
                                                                    handleFilterClick(
                                                                        QuestionTypes.ALL
                                                                    )
                                                                }
                                                            />
                                                            <Button
                                                                title={
                                                                    <Fragment>
                                                                        <ClarityQuestionIcon
                                                                            color={
                                                                                Colors[
                                                                                    `${userType}Primary`
                                                                                ]
                                                                            }
                                                                            className="mr1 tpw-20 tph-20"
                                                                        />
                                                                        Clarity questions
                                                                    </Fragment>
                                                                }
                                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt2 ${canAddBorderClass(
                                                                    QuestionTypes.CLARITY
                                                                )}`}
                                                                onClick={() =>
                                                                    handleFilterClick(
                                                                        QuestionTypes.CLARITY
                                                                    )
                                                                }
                                                            />
                                                            <Button
                                                                title={
                                                                    <Fragment>
                                                                        <FollowupQuestionIcon
                                                                            color={
                                                                                Colors[
                                                                                    `${userType}Primary`
                                                                                ]
                                                                            }
                                                                            className="mr1 tpw-20 tph-20"
                                                                        />
                                                                        Follow up questions
                                                                    </Fragment>
                                                                }
                                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt2 ${canAddBorderClass(
                                                                    QuestionTypes.FOLLOWUP
                                                                )}`}
                                                                onClick={() =>
                                                                    handleFilterClick(
                                                                        QuestionTypes.FOLLOWUP
                                                                    )
                                                                }
                                                            />
                                                            <Button
                                                                title={
                                                                    <Fragment>
                                                                        <UrgentQuestionIcon
                                                                            color={
                                                                                Colors[
                                                                                    `${userType}Primary`
                                                                                ]
                                                                            }
                                                                            className="mr1 tpw-20 tph-20"
                                                                        />
                                                                        Urgent questions
                                                                    </Fragment>
                                                                }
                                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt2 ${canAddBorderClass(
                                                                    QuestionTypes.URGENT
                                                                )}`}
                                                                onClick={() =>
                                                                    handleFilterClick(
                                                                        QuestionTypes.URGENT
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    }
                                                />
                                                {user.Type === UserTypes.STUDENT && (
                                                    <Button
                                                        title="Ask a question"
                                                        className={`tp-btn-primary tp-btn-primary-${userType} ml2`}
                                                        onClick={() =>
                                                            history.push(
                                                                getRoute(APP_ROUTES.ASK_QUESTION, {
                                                                    subjectId: id
                                                                })
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={9}>
                                        {(isLoading || (!isLoading && !questionsList.length)) && (
                                            <Spinner
                                                isLoading={isLoading}
                                                showDataNotAvailableText={
                                                    !isLoading && !questionsList.length
                                                }
                                            />
                                        )}
                                        {!!questionsList?.length && !isLoading && (
                                            <div
                                                className={`scrolling-container scrolling-container-${userType}`}
                                            >
                                                {questionsList.map((question, index) => {
                                                    return (
                                                        <Question
                                                            key={index}
                                                            text={
                                                                <Fragment>
                                                                    {question.Description}
                                                                    <PaperClipIcon
                                                                        color={Colors.black}
                                                                        className="pointer"
                                                                    />
                                                                </Fragment>
                                                            }
                                                            className={`${index ? "mt4" : ""} ${
                                                                !question.ReadStatus
                                                                    ? `tp-question-border tp-question-border-${userType}`
                                                                    : ""
                                                            }`}
                                                            type={question.Type}
                                                            date={moment(question.CreatedAt).format(
                                                                "dddd"
                                                            )}
                                                            onClick={() =>
                                                                handleQuestionClick(question)
                                                            }
                                                        />
                                                    );
                                                })}
                                                {/* <Question
                                                    text={
                                                        <Fragment>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do eiusmod tempor
                                                            incididunt ut labore dolor sit amet,
                                                            consectetur adipiscing elit?
                                                            <PaperClipIcon
                                                                color={Colors.black}
                                                                className="pointer"
                                                            />
                                                        </Fragment>
                                                    }
                                                    className="mt4"
                                                    type={QuestionTypes.URGENT}
                                                    date="Monday"
                                                />
                                                <Question
                                                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolor sit amet, consectetur adipiscing elit?"
                                                    className="mt4"
                                                    date="Yesterday"
                                                />
                                                <Question
                                                    text={
                                                        <Fragment>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do eiusmod tempor
                                                            incididunt ut labore dolor sit amet,
                                                            consectetur adipiscing elit?
                                                            <PaperClipIcon
                                                                color={Colors.black}
                                                                className="pointer"
                                                            />
                                                        </Fragment>
                                                    }
                                                    className="mt4"
                                                    type={QuestionTypes.CLARITY}
                                                    date="Monday"
                                                />
                                                <Question
                                                    text={
                                                        <Fragment>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do eiusmod tempor
                                                            incididunt ut labore dolor sit amet,
                                                            consectetur adipiscing elit?
                                                            <PaperClipIcon
                                                                color={Colors.black}
                                                                className="pointer"
                                                            />
                                                        </Fragment>
                                                    }
                                                    className="mt4"
                                                    type={QuestionTypes.FOLLOWUP}
                                                    date="20/20/2020"
                                                /> */}
                                            </div>
                                        )}
                                    </Col>
                                    <Col md={3} className="text-md-end">
                                        {data &&
                                            data.length &&
                                            data.map((subject, index) => {
                                                return (
                                                    <div className={index ? "mt4" : ""} key={index}>
                                                        <Button
                                                            title={subject.Title}
                                                            className={`tp-btn-secondary tp-btn-secondary-${userType} ${
                                                                Number(id) === subject.Id
                                                                    ? `tp-btn-secondary-border-${userType}`
                                                                    : ""
                                                            }`}
                                                            counter={index + 1}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        subjects: state.questionBoard.subjects,
        userType: state.auth.userType,
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(QuestionsList);
