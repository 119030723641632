import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { AccordionTab } from "../../components/AccordionTab";

const Faq = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [toggleTab, setToggleTab] = useState(true);

    return (
        <div className="tp-settings tp-faq">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>FAQ’s</div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to help center"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className={`scrolling-container scrolling-container-${userType}`}>
                                <div className="content-inner bg-clr-light faq-inner">
                                    <AccordionTab
                                        title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                        content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                        isOpen={toggleTab}
                                        onClick={() => {
                                            setToggleTab(!toggleTab);
                                        }}
                                        titleColor={userType}
                                        dropDownColor={Colors[`${userType}Primary`]}
                                    />
                                    <AccordionTab
                                        title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                        content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                        className="mt4"
                                        titleColor={userType}
                                        dropDownColor={Colors[`${userType}Primary`]}
                                    />
                                    <AccordionTab
                                        title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                        content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                        className="mt4"
                                        titleColor={userType}
                                        dropDownColor={Colors[`${userType}Primary`]}
                                    />
                                    <AccordionTab
                                        title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                        content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                        className="mt4"
                                        titleColor={userType}
                                        dropDownColor={Colors[`${userType}Primary`]}
                                    />
                                    <AccordionTab
                                        title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                        content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                        className="mt4"
                                        titleColor={userType}
                                        dropDownColor={Colors[`${userType}Primary`]}
                                    />
                                    <AccordionTab
                                        title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                        content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                        className="mt4"
                                        titleColor={userType}
                                        dropDownColor={Colors[`${userType}Primary`]}
                                    />
                                    <AccordionTab
                                        title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                        content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                        className="mt4"
                                        titleColor={userType}
                                        dropDownColor={Colors[`${userType}Primary`]}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(Faq);
