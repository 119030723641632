import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./QuestionBoard.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import { getMediaPath } from "../../utils/mediaUtils";
import { UserTypes } from "../../constants";
import QuestionTypeModal from "./AskQuestionModals/QuestionTypeModal";
import RatingModal from "./AskQuestionModals/RatingModal";
import WithDrawQuestionModal from "./AskQuestionModals/WithDrawQuestionModal";
import MessageModal from "./AskQuestionModals/MessageModal";
import QuestionAnswerThread from "./QuestionAnswerThread";
import { questionRemindMeLater, withdrawQuestion } from "../../store/actions/questionBoardActions";

const QuestionDetails = (props) => {
    const { userType, user, questionThreads, questionFeedback } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [openQuestionTypeModal, setOpenQuestionTypeModal] = useState(false);
    const [openWithDrawModal, setOpenWithDrawModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openRatingModal, setOpenRatingModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleAnswerNowClick = () => {
        if (user.Type === UserTypes.TUTOR) {
            history.push(getRoute(APP_ROUTES.ANSWER_QUESTION, { id }));
            return;
        }
        setOpenQuestionTypeModal(true);
    };

    const isAnsweredRoute = () => {
        return history.location.pathname.includes("answered");
    };

    const handleRemindMeLatter = () => {
        setIsLoading(true);
        let lastThread = questionThreads[questionThreads.length - 1];
        dispatch(questionRemindMeLater({ QuestionId: lastThread.Id }))
            .then((res) => {
                history.goBack();
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const renderTutorStackBtn = () => {
        return (
            <div className="question-details-actions text-center">
                <Button
                    title={"Answer now"}
                    className={`tp-btn-primary tp-btn-primary-${userType} mt4 mr4`}
                    onClick={handleAnswerNowClick}
                />

                <Button
                    title={"Answer offline"}
                    className={`tp-btn-primary tp-btn-primary-${userType} mt4 mr4`}
                />
                <Button
                    title="Remind me later"
                    className={`tp-btn-primary tp-btn-primary-${userType} mt4 mr4`}
                    onClick={handleRemindMeLatter}
                    isLoading={isLoading}
                />
                <Button
                    title="Report abuse"
                    className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mt4`}
                />
            </div>
        );
    };

    const canShowButtons = () => {
        if (questionThreads.length) {
            let lastThread = questionThreads[questionThreads.length - 1];
            if (lastThread.QuestionId) {
                return true;
            }
            return false;
        } else return false;
    };

    const renderStudentStackBtn = () => {
        if (isAnsweredRoute() && canShowButtons() && !questionFeedback) {
            return (
                <div className="question-details-actions text-center">
                    <Button
                        title={"I don't understand"}
                        className={`tp-btn-primary tp-btn-primary-${userType} mt4 mr4`}
                        onClick={handleAnswerNowClick}
                    />

                    <Button
                        title={"I Understand"}
                        className={`tp-btn-primary tp-btn-primary-${userType} mt4 mr4`}
                        onClick={() => setOpenRatingModal(true)}
                    />
                    <Button
                        title="Report abuse"
                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mt4`}
                    />
                </div>
            );
        } else if (!isAnsweredRoute()) {
            return (
                <div className="question-details-actions" style={{ textAlign: "right" }}>
                    <Button
                        title={"Withdraw question"}
                        className={`tp-btn-primary tp-btn-primary-${userType} mt4 mr4`}
                        onClick={() => setOpenWithDrawModal(true)}
                    />
                </div>
            );
        }
        return null;
    };

    const renderGuardianStackBtn = () => {
        return (
            <div className="question-details-actions text-center">
                <Button
                    title={"Leave feedback"}
                    onClick={() => setOpenRatingModal(true)}
                    className={`tp-btn-primary tp-btn-primary-${userType} mt4 mr4`}
                />
                <Button
                    title="Report abuse"
                    className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mt4`}
                />
            </div>
        );
    };

    const handleWithdrawPress = () => {
        let lastThread = questionThreads[questionThreads.length - 1];
        if (lastThread) {
            setIsLoading(true);
            dispatch(withdrawQuestion(lastThread.Id))
                .then((res) => {
                    setIsLoading(false);
                    setOpenWithDrawModal(false);
                    setShowSuccessModal(true);
                })
                .catch((err) => {
                    setOpenWithDrawModal(false);
                    setIsLoading(false);
                });
        }
    };

    return (
        <div className="tp-question-board tp-question-details">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Unanswered Questions
                        </div>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to question board"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner question-details-inner no-padding">
                                <QuestionAnswerThread />
                                {!!questionThreads.length && (
                                    <>
                                        {user.Type === UserTypes.TUTOR &&
                                            !isAnsweredRoute() &&
                                            renderTutorStackBtn()}
                                        {user.Type === UserTypes.STUDENT && renderStudentStackBtn()}
                                        {user.Type === UserTypes.GUARDIAN &&
                                            isAnsweredRoute() &&
                                            !questionFeedback &&
                                            renderGuardianStackBtn()}
                                    </>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {openQuestionTypeModal && (
                <QuestionTypeModal isOpen={openQuestionTypeModal} questionId={id} />
            )}
            {openRatingModal && (
                <RatingModal
                    openRatingModal={openRatingModal}
                    handleModalClose={() => setOpenRatingModal(false)}
                />
            )}
            {openWithDrawModal && (
                <WithDrawQuestionModal
                    isOpen={openWithDrawModal}
                    handleClose={() => setOpenWithDrawModal(false)}
                    handleSubmitPress={handleWithdrawPress}
                    isLoading={isLoading}
                />
            )}
            <MessageModal
                showMessageModal={showSuccessModal}
                text="The tutor has been notifed that this question has been withdrawn"
                handleClick={() => history.goBack()}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType,
        user: state.auth.user,
        questionThreads: state.questionBoard.questionThreads,
        questionFeedback: state.questionBoard.questionFeedback
    };
};

export default connect(mapStateToProps)(QuestionDetails);
