import React, { useState, useEffect, Fragment } from "react";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import "./Signup.scss";
import { Colors } from "../../themes/colors";
import { Button } from "../../components/Button";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import BgShape from "../../components/SvgIcon/BgShape";
import PlusIcon from "../../components/SvgIcon/PlusIcon";
import { setPersonalDetails, getSubjectListing } from "../../store/actions";

const SubjectSelection = ({ setPersonalDetails, getSubjectListing, fullName, userType }) => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isInvited = params.get("isInvited");
    const [subjects, setSubjects] = useState([]);
    const [subjectErr, setSubjectErr] = useState(false);
    let [interSub, setInterSub] = useState("initialState");
    const [selectedSubjectsArr, setSelectedSubjectsArr] = useState([{ label: "", Id: "" }]);

    const formatSubjects = (subjects) => {
        return subjects.map((item) => {
            return {
                ...item,
                label: item.Title
            };
        });
    };

    useEffect(() => {
        getSubjectListing()
            .then((res) => {
                setInterSub(formatSubjects([...res?.Data?.Subjects]));
                setSubjects(formatSubjects([...res?.Data?.Subjects]));
            })
            .catch((err) => {});
        return () => {
            setInterSub({});
            setSubjects({});
        };
    }, []);

    if (!fullName) {
        return (
            <Redirect
                to={{
                    pathname: getRoute(APP_ROUTES.PERSONAL_DETAILS),
                    search: isInvited ? "?isInvited=true" : ""
                }}
            />
        );
    }

    const handleAddSubject = () => {
        let checkSubject = selectedSubjectsArr[selectedSubjectsArr.length - 1].label;
        if (checkSubject) {
            setSubjectErr(false);
            setSelectedSubjectsArr([...selectedSubjectsArr, { Id: "", label: "" }]);
        } else if (!checkSubject) {
            setSubjectErr(true);
        }
    };

    const handleSubmit = () => {
        let subjectId = selectedSubjectsArr[selectedSubjectsArr.length - 1].Id;
        if (subjectId) {
            setSubjectErr(false);
            setPersonalDetails({ selectedSubjects: selectedSubjectsArr.map((item) => item.Id) });
            if (isInvited) {
                history.push({
                    pathname: getRoute(APP_ROUTES.CONFIRM_NETWORKS),
                    search: "?isInvited=true"
                });
            } else {
                history.push(getRoute(APP_ROUTES.VIDEO_INTRODUCTION));
            }
        } else {
            setSubjectErr(true);
        }
    };

    const handleOnChange = (option, index) => {
        setSubjectErr(false);
        const newSubjectArr = selectedSubjectsArr.map((subject, subjectIndex) => {
            if (index === subjectIndex) {
                subject.Id = option.Id;
                subject.label = option.label;
            }
            return subject;
        });

        let filtered = subjects.filter(
            (item) => !newSubjectArr.map((subject) => subject.Id).includes(item.Id)
        );
        setInterSub(filtered);
        setSelectedSubjectsArr(newSubjectArr);
    };

    return (
        <div className="tp-subject-selection full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12} className="mt9 text-center">
                        <h1 className="mb0">
                            <span className="d-block mb4">Hello Zain!</span>
                            <span>
                                Nice to meet you! We’d love to know which subjects <br /> you teach
                            </span>
                        </h1>
                        <div className="mt9">
                            <span className="d-inline-block w-100 ">
                                {selectedSubjectsArr.map((item, index) => {
                                    return (
                                        <CreatableDropdown
                                            key={index}
                                            placeholder="Select Subject"
                                            onChange={(option) => handleOnChange(option, index)}
                                            options={interSub}
                                            className={`mx-auto mb2 tp-creatable-dropdown-${userType}`}
                                            dropIconColor={Colors[`${userType}Primary`]}
                                        />
                                    );
                                })}
                                {subjectErr && (
                                    <div className="date-error  position-relative mb6 mt0">
                                        <span className="is-invalid"></span>
                                        <div className="invalid-feedback position-absolute">
                                            This is a required field
                                        </div>
                                    </div>
                                )}
                            </span>
                            {Boolean(interSub?.length) && (
                                <Button
                                    className={`tp-btn-add tp-btn-add-lg tp-btn-add-${userType}`}
                                    onClick={handleAddSubject}
                                    title={<PlusIcon color={Colors[`${userType}Primary`]} />}
                                ></Button>
                            )}
                        </div>
                    </Col>
                    <Col xs={12} className="text-center">
                        <Button
                            title="Next"
                            className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                            onClick={handleSubmit}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapDispatchToProps = {
    setPersonalDetails,
    getSubjectListing
};

const mapStateToProps = (state) => {
    const fullName = state.root.auth.fullName;
    return {
        fullName,
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubjectSelection);
