import { CircularProgressbarWithChildren } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

import "./ProgressCircle.scss";

const ProgressCircle = (props) => {
    const { value = null, text, className = "" } = props;
    return (
        <div className={`tp-progress-circle ${className}`}>
            <CircularProgressbarWithChildren value={value} counterClockwise={true}>
                {text}
            </CircularProgressbarWithChildren>
        </div>
    );
};
export default ProgressCircle;
