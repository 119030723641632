const EmailIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="138"
            height="106"
            viewBox="0 0 138 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.5 2.375H136.5V98C136.5 99.4918 135.907 100.923 134.852 101.977C133.798 103.032 132.367 103.625 130.875 103.625H7.125C5.63316 103.625 4.20242 103.032 3.14753 101.977C2.09263 100.923 1.5 99.4918 1.5 98V2.375Z"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M136.5 2.375L69 64.25L1.5 2.375"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default EmailIcon;
