import React, { Component } from "react";
import Select from "react-select";
import "./CreatableDropdown.scss";

import ArrowDown from "../SvgIcon/ArrowDown";

// const selectStyles = {
//   menu: (provided) => ({
//     ...provided,
//     zIndex: 99,
//   }),
//   control: (provided) => ({
//     ...provided,
//     border: "1px solid #ced4da",
//     borderRadius: "5px",
//     borderColor: $tutor - primary,
//     "&:hover": {
//       borderColor: "transaparent",
//     },
//   }),
// };
class CreatableDropdown extends Component {
    render() {
        let {
            className = "",
            options,
            onInputChange = undefined,
            onChange,
            // customStyles = selectStyles,
            maxMenuHeight,
            isClearable = false,
            placeholder = "",
            isLoading = false,
            value,
            isMulti = false,
            handleScroll = undefined,
            handleOnFocus = undefined,
            selectRef,
            isOptionDisabled = undefined,
            closeMenuOnSelect = true,
            isDisabled = false,
            hideArrow = false,
            dropIconColor
        } = this.props;
        return (
            <Select
                // menuIsOpen={true}
                components={{
                    DropdownIndicator: () =>
                        hideArrow === false ? (
                            <ArrowDown color={dropIconColor} className="pointer tpw-25 tph-25" />
                        ) : (
                            ""
                        )
                }}
                isDisabled={isDisabled}
                onFocus={handleOnFocus}
                ref={selectRef}
                onMenuScrollToBottom={handleScroll}
                className={`tp-creatable-dropdown ${className} ${isDisabled && "disabled"}`}
                options={options}
                onInputChange={onInputChange}
                onChange={onChange}
                // styles={customStyles || selectStyles}
                maxMenuHeight={maxMenuHeight}
                isClearable={isClearable}
                placeholder={placeholder}
                closeMenuOnSelect={closeMenuOnSelect}
                isLoading={isLoading}
                value={value}
                // theme={(theme) => ({
                //   ...theme,
                //   borderRadius: 0,
                //   colors: {
                //     ...theme.colors,
                //     primary: "#556ee6",
                //   },
                // })}
                isOptionDisabled={isOptionDisabled}
                isMulti={isMulti}
            />
        );
    }
}
export default CreatableDropdown;
