const TodoListIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="28"
            height="33"
            viewBox="0 0 28 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.0003 3.25H25.25C25.5815 3.25 25.8995 3.3817 26.1339 3.61612C26.3683 3.85054 26.5 4.16848 26.5 4.5V30.75C26.5 31.0815 26.3683 31.3995 26.1339 31.6339C25.8995 31.8683 25.5815 32 25.25 32H2.75C2.41848 32 2.10054 31.8683 1.86612 31.6339C1.6317 31.3995 1.5 31.0815 1.5 30.75V4.5C1.5 4.16848 1.6317 3.85054 1.86612 3.61612C2.10054 3.3817 2.41848 3.25 2.75 3.25H8.99966"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.75 8.25V7C7.75 5.3424 8.40848 3.75269 9.58058 2.58058C10.7527 1.40848 12.3424 0.75 14 0.75C15.6576 0.75 17.2473 1.40848 18.4194 2.58058C19.5915 3.75269 20.25 5.3424 20.25 7V8.25H7.75Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2344 19.3535H20.4696"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2344 14.6465H20.4696"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2344 24.0586H20.4697"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.5293 14.3539L8.70577 13.7656V17.8828"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.61019 21.1581C7.66609 21.0258 7.74903 20.9067 7.85368 20.8084C7.95834 20.7101 8.0824 20.6348 8.21789 20.5873C8.35338 20.5398 8.49731 20.5211 8.64043 20.5326C8.78356 20.544 8.92271 20.5852 9.04895 20.6536C9.1752 20.722 9.28574 20.816 9.37349 20.9297C9.46123 21.0433 9.52424 21.1741 9.55846 21.3135C9.59268 21.4529 9.59735 21.598 9.57218 21.7394C9.54701 21.8807 9.49256 22.0152 9.41231 22.1343L7.5293 24.647H9.58812"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default TodoListIcon;
