import { Fragment } from "react";

const BgShape = (props) => {
    const { type } = props;
    return (
        <Fragment>
            {type === "guardianPrimary" && (
                <svg
                    className="bg-shape bg-guardian-primary"
                    width="1920"
                    height="1080"
                    viewBox="0 0 1920 1080"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        cx="1718.9"
                        cy="6.48077"
                        r="3.50605"
                        transform="rotate(45 1718.9 6.48077)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1733.78"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1733.78 21.3558)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1748.65"
                        cy="36.2308"
                        r="3.50605"
                        transform="rotate(45 1748.65 36.2308)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1763.53"
                        cy="51.1048"
                        r="3.50605"
                        transform="rotate(45 1763.53 51.1048)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1778.4"
                        cy="65.9808"
                        r="3.50605"
                        transform="rotate(45 1778.4 65.9808)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1793.28"
                        cy="80.8558"
                        r="3.50605"
                        transform="rotate(45 1793.28 80.8558)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1808.15"
                        cy="95.7298"
                        r="3.50605"
                        transform="rotate(45 1808.15 95.7298)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1823.03"
                        cy="110.606"
                        r="3.50605"
                        transform="rotate(45 1823.03 110.606)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1837.9"
                        cy="125.48"
                        r="3.50605"
                        transform="rotate(45 1837.9 125.48)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1748.65"
                        cy="6.48077"
                        r="3.50605"
                        transform="rotate(45 1748.65 6.48077)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1763.53"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1763.53 21.3558)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1778.4"
                        cy="36.2308"
                        r="3.50605"
                        transform="rotate(45 1778.4 36.2308)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1793.28"
                        cy="51.1048"
                        r="3.50605"
                        transform="rotate(45 1793.28 51.1048)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1808.15"
                        cy="65.9808"
                        r="3.50605"
                        transform="rotate(45 1808.15 65.9808)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1823.03"
                        cy="80.8558"
                        r="3.50605"
                        transform="rotate(45 1823.03 80.8558)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1837.9"
                        cy="95.7298"
                        r="3.50605"
                        transform="rotate(45 1837.9 95.7298)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1852.78"
                        cy="110.606"
                        r="3.50605"
                        transform="rotate(45 1852.78 110.606)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1778.4"
                        cy="6.48077"
                        r="3.50605"
                        transform="rotate(45 1778.4 6.48077)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1793.28"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1793.28 21.3558)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1808.15"
                        cy="36.2308"
                        r="3.50605"
                        transform="rotate(45 1808.15 36.2308)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1823.03"
                        cy="51.1048"
                        r="3.50605"
                        transform="rotate(45 1823.03 51.1048)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1837.9"
                        cy="65.9808"
                        r="3.50605"
                        transform="rotate(45 1837.9 65.9808)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1852.78"
                        cy="80.8558"
                        r="3.50605"
                        transform="rotate(45 1852.78 80.8558)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1867.65"
                        cy="95.7298"
                        r="3.50605"
                        transform="rotate(45 1867.65 95.7298)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1808.15"
                        cy="6.48077"
                        r="3.50605"
                        transform="rotate(45 1808.15 6.48077)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1823.03"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1823.03 21.3558)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1837.9"
                        cy="36.2308"
                        r="3.50605"
                        transform="rotate(45 1837.9 36.2308)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1852.78"
                        cy="51.1048"
                        r="3.50605"
                        transform="rotate(45 1852.78 51.1048)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1867.65"
                        cy="65.9808"
                        r="3.50605"
                        transform="rotate(45 1867.65 65.9808)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1882.53"
                        cy="80.8558"
                        r="3.50605"
                        transform="rotate(45 1882.53 80.8558)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1837.9"
                        cy="6.48077"
                        r="3.50605"
                        transform="rotate(45 1837.9 6.48077)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1852.78"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1852.78 21.3558)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1867.65"
                        cy="36.2308"
                        r="3.50605"
                        transform="rotate(45 1867.65 36.2308)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1882.53"
                        cy="51.1048"
                        r="3.50605"
                        transform="rotate(45 1882.53 51.1048)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1897.4"
                        cy="65.9808"
                        r="3.50605"
                        transform="rotate(45 1897.4 65.9808)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1867.65"
                        cy="6.48077"
                        r="3.50605"
                        transform="rotate(45 1867.65 6.48077)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1882.53"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1882.53 21.3558)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1897.4"
                        cy="36.2308"
                        r="3.50605"
                        transform="rotate(45 1897.4 36.2308)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1912.28"
                        cy="51.1048"
                        r="3.50605"
                        transform="rotate(45 1912.28 51.1048)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1897.4"
                        cy="6.48077"
                        r="3.50605"
                        transform="rotate(45 1897.4 6.48077)"
                        fill="#1EA99D"
                    />
                    <circle
                        cx="1912.28"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1912.28 21.3558)"
                        fill="#1EA99D"
                    />
                    <circle cx="4.41068" cy="1059.4" r="4.41068" fill="#1EA99D" />
                    <circle cx="30.8775" cy="1059.4" r="4.41068" fill="#1EA99D" />
                    <circle cx="57.3384" cy="1059.4" r="4.41068" fill="#1EA99D" />
                    <circle cx="83.8013" cy="1059.4" r="4.41068" fill="#1EA99D" />
                    <circle cx="110.266" cy="1059.4" r="4.41068" fill="#1EA99D" />
                    <circle cx="136.733" cy="1059.4" r="4.41068" fill="#1EA99D" />
                    <circle cx="163.196" cy="1059.4" r="4.41068" fill="#1EA99D" />
                    <circle cx="189.661" cy="1059.4" r="4.41068" fill="#1EA99D" />
                    <circle cx="216.124" cy="1059.4" r="4.41068" fill="#1EA99D" />
                    <circle cx="242.588" cy="1059.4" r="4.41068" fill="#1EA99D" />
                    <circle cx="4.41068" cy="1032.94" r="4.41068" fill="#1EA99D" />
                    <circle cx="30.8775" cy="1032.94" r="4.41068" fill="#1EA99D" />
                    <circle cx="57.3384" cy="1032.94" r="4.41068" fill="#1EA99D" />
                    <circle cx="83.8013" cy="1032.94" r="4.41068" fill="#1EA99D" />
                    <circle cx="110.266" cy="1032.94" r="4.41068" fill="#1EA99D" />
                    <circle cx="136.733" cy="1032.94" r="4.41068" fill="#1EA99D" />
                    <circle cx="163.196" cy="1032.94" r="4.41068" fill="#1EA99D" />
                    <circle cx="189.661" cy="1032.94" r="4.41068" fill="#1EA99D" />
                    <circle cx="216.124" cy="1032.94" r="4.41068" fill="#1EA99D" />
                    <circle cx="242.588" cy="1032.94" r="4.41068" fill="#1EA99D" />
                    <circle cx="4.41068" cy="1006.48" r="4.41068" fill="#1EA99D" />
                    <circle cx="30.8775" cy="1006.48" r="4.41068" fill="#1EA99D" />
                    <circle cx="57.3384" cy="1006.48" r="4.41068" fill="#1EA99D" />
                    <circle cx="83.8013" cy="1006.48" r="4.41068" fill="#1EA99D" />
                    <circle cx="110.266" cy="1006.48" r="4.41068" fill="#1EA99D" />
                    <circle cx="136.733" cy="1006.48" r="4.41068" fill="#1EA99D" />
                    <circle cx="163.196" cy="1006.48" r="4.41068" fill="#1EA99D" />
                    <circle cx="189.661" cy="1006.48" r="4.41068" fill="#1EA99D" />
                    <circle cx="216.124" cy="1006.48" r="4.41068" fill="#1EA99D" />
                    <circle cx="242.588" cy="1006.48" r="4.41068" fill="#1EA99D" />
                    <circle cx="4.41068" cy="980.008" r="4.41068" fill="#1EA99D" />
                    <circle cx="30.8775" cy="980.008" r="4.41068" fill="#1EA99D" />
                    <circle cx="57.3384" cy="980.008" r="4.41068" fill="#1EA99D" />
                    <circle cx="83.8013" cy="980.008" r="4.41068" fill="#1EA99D" />
                    <circle cx="110.266" cy="980.008" r="4.41068" fill="#1EA99D" />
                    <circle cx="136.729" cy="980.008" r="4.41068" fill="#1EA99D" />
                    <circle cx="163.2" cy="980.008" r="4.41068" fill="#1EA99D" />
                    <circle cx="189.661" cy="980.008" r="4.41068" fill="#1EA99D" />
                    <circle cx="216.124" cy="980.008" r="4.41068" fill="#1EA99D" />
                    <circle cx="242.588" cy="980.008" r="4.41068" fill="#1EA99D" />
                    <circle cx="4.41068" cy="953.545" r="4.41068" fill="#1EA99D" />
                    <circle cx="30.8775" cy="953.545" r="4.41068" fill="#1EA99D" />
                    <circle cx="57.3384" cy="953.545" r="4.41068" fill="#1EA99D" />
                    <circle cx="83.8013" cy="953.545" r="4.41068" fill="#1EA99D" />
                    <circle cx="110.266" cy="953.545" r="4.41068" fill="#1EA99D" />
                    <circle cx="136.733" cy="953.545" r="4.41068" fill="#1EA99D" />
                    <circle cx="163.196" cy="953.545" r="4.41068" fill="#1EA99D" />
                    <circle cx="189.661" cy="953.545" r="4.41068" fill="#1EA99D" />
                    <circle cx="216.124" cy="953.545" r="4.41068" fill="#1EA99D" />
                    <circle cx="242.585" cy="953.545" r="4.41068" fill="#1EA99D" />
                    <circle cx="4.41068" cy="927.083" r="4.41068" fill="#1EA99D" />
                    <circle cx="30.8775" cy="927.083" r="4.41068" fill="#1EA99D" />
                    <circle cx="57.3384" cy="927.083" r="4.41068" fill="#1EA99D" />
                    <circle cx="83.8013" cy="927.083" r="4.41068" fill="#1EA99D" />
                    <circle cx="110.266" cy="927.083" r="4.41068" fill="#1EA99D" />
                    <circle cx="136.733" cy="927.083" r="4.41068" fill="#1EA99D" />
                    <circle cx="163.196" cy="927.083" r="4.41068" fill="#1EA99D" />
                    <circle cx="189.661" cy="927.083" r="4.41068" fill="#1EA99D" />
                    <circle cx="216.124" cy="927.083" r="4.41068" fill="#1EA99D" />
                    <circle cx="242.585" cy="927.083" r="4.41068" fill="#1EA99D" />
                    <path
                        d="M2157.63 60.6132C2041.48 234.12 1806.67 280.617 1633.16 164.468C1459.65 48.3186 1413.16 -186.494 1529.31 -360"
                        stroke="#FABC71"
                    />
                    <path
                        d="M2157.63 60.6132C2041.48 234.12 1806.67 280.617 1633.16 164.468C1459.65 48.3186 1413.16 -186.494 1529.31 -360"
                        stroke="#1EA99D"
                        strokeWidth="3"
                    />
                    <path
                        d="M-214.999 1107.72C-187.323 900.769 2.88118 755.437 209.833 783.114C416.785 810.79 562.116 1000.99 534.44 1207.95"
                        stroke="#1EA99D"
                        strokeWidth="3"
                    />
                    <rect
                        x="1614.4"
                        y="957.23"
                        width="343.647"
                        height="343.647"
                        transform="rotate(45.978 1614.4 957.23)"
                        fill="#B6F1EC"
                    />
                    <rect
                        x="1633.04"
                        y="896.414"
                        width="183.053"
                        height="183.053"
                        transform="rotate(45.978 1633.04 896.414)"
                        stroke="#1EA99D"
                        strokeWidth="2"
                    />
                    <rect
                        x="210.406"
                        y="-306.273"
                        width="343.647"
                        height="343.647"
                        transform="rotate(45.3987 210.406 -306.273)"
                        fill="#DFF7F4"
                    />
                    <rect
                        x="117.996"
                        y="-340.586"
                        width="341.647"
                        height="341.647"
                        transform="rotate(45 117.996 -340.586)"
                        stroke="#1EA99D"
                        strokeWidth="2"
                    />
                </svg>
            )}
            {type === "guardianSecondary" && (
                <svg
                    className="bg-shape bg-guardian-secondary"
                    width="1920"
                    height="1080"
                    viewBox="0 0 1920 1080"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="276.5" cy="840.5" r="151.5" fill="#B6F1EC" />
                    <circle cx="443" cy="698" r="259.5" stroke="#1EA99D" />
                    <path
                        d="M2157.63 60.6122C2041.48 234.119 1806.67 280.616 1633.16 164.467C1459.65 48.3176 1413.16 -186.495 1529.31 -360.001"
                        stroke="#FABC71"
                    />
                    <circle cx="39" cy="39" r="146" fill="#DFF7F4" />
                    <circle cx="-21" cy="108" r="145" stroke="#1EA99D" strokeWidth="2" />
                    <circle cx="1484" cy="-108" r="254" fill="#B6F1EC" />
                    <circle
                        cx="1882.03"
                        cy="1014.03"
                        r="254"
                        transform="rotate(-55.6471 1882.03 1014.03)"
                        fill="#DFF7F4"
                    />
                    <path
                        d="M2157.63 60.6122C2041.48 234.119 1806.67 280.616 1633.16 164.467C1459.65 48.3176 1413.16 -186.495 1529.31 -360.001"
                        stroke="#1EA99D"
                        strokeWidth="3"
                    />
                </svg>
            )}
            {type === "studentPrimary" && (
                <svg
                    className="bg-shape bg-student-primary"
                    width="1920"
                    height="1080"
                    viewBox="0 0 1920 1080"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        cx="1718.9"
                        cy="6.48028"
                        r="3.50605"
                        transform="rotate(45 1718.9 6.48028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1733.78"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1733.78 21.3558)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1748.65"
                        cy="36.2303"
                        r="3.50605"
                        transform="rotate(45 1748.65 36.2303)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1763.53"
                        cy="51.1053"
                        r="3.50605"
                        transform="rotate(45 1763.53 51.1053)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1778.4"
                        cy="65.9803"
                        r="3.50605"
                        transform="rotate(45 1778.4 65.9803)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1793.28"
                        cy="80.8553"
                        r="3.50605"
                        transform="rotate(45 1793.28 80.8553)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1808.15"
                        cy="95.7303"
                        r="3.50605"
                        transform="rotate(45 1808.15 95.7303)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1823.03"
                        cy="110.605"
                        r="3.50605"
                        transform="rotate(45 1823.03 110.605)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1837.9"
                        cy="125.48"
                        r="3.50605"
                        transform="rotate(45 1837.9 125.48)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1748.65"
                        cy="6.48028"
                        r="3.50605"
                        transform="rotate(45 1748.65 6.48028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1763.53"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1763.53 21.3558)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1778.4"
                        cy="36.2303"
                        r="3.50605"
                        transform="rotate(45 1778.4 36.2303)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1793.28"
                        cy="51.1053"
                        r="3.50605"
                        transform="rotate(45 1793.28 51.1053)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1808.15"
                        cy="65.9803"
                        r="3.50605"
                        transform="rotate(45 1808.15 65.9803)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1823.03"
                        cy="80.8553"
                        r="3.50605"
                        transform="rotate(45 1823.03 80.8553)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1837.9"
                        cy="95.7303"
                        r="3.50605"
                        transform="rotate(45 1837.9 95.7303)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1852.78"
                        cy="110.605"
                        r="3.50605"
                        transform="rotate(45 1852.78 110.605)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1778.4"
                        cy="6.48028"
                        r="3.50605"
                        transform="rotate(45 1778.4 6.48028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1793.28"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1793.28 21.3558)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1808.15"
                        cy="36.2303"
                        r="3.50605"
                        transform="rotate(45 1808.15 36.2303)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1823.03"
                        cy="51.1053"
                        r="3.50605"
                        transform="rotate(45 1823.03 51.1053)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1837.9"
                        cy="65.9803"
                        r="3.50605"
                        transform="rotate(45 1837.9 65.9803)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1852.78"
                        cy="80.8553"
                        r="3.50605"
                        transform="rotate(45 1852.78 80.8553)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1867.65"
                        cy="95.7303"
                        r="3.50605"
                        transform="rotate(45 1867.65 95.7303)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1808.15"
                        cy="6.48028"
                        r="3.50605"
                        transform="rotate(45 1808.15 6.48028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1823.03"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1823.03 21.3558)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1837.9"
                        cy="36.2303"
                        r="3.50605"
                        transform="rotate(45 1837.9 36.2303)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1852.78"
                        cy="51.1053"
                        r="3.50605"
                        transform="rotate(45 1852.78 51.1053)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1867.65"
                        cy="65.9803"
                        r="3.50605"
                        transform="rotate(45 1867.65 65.9803)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1882.53"
                        cy="80.8553"
                        r="3.50605"
                        transform="rotate(45 1882.53 80.8553)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1837.9"
                        cy="6.48028"
                        r="3.50605"
                        transform="rotate(45 1837.9 6.48028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1852.78"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1852.78 21.3558)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1867.65"
                        cy="36.2303"
                        r="3.50605"
                        transform="rotate(45 1867.65 36.2303)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1882.53"
                        cy="51.1053"
                        r="3.50605"
                        transform="rotate(45 1882.53 51.1053)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1897.4"
                        cy="65.9803"
                        r="3.50605"
                        transform="rotate(45 1897.4 65.9803)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1867.65"
                        cy="6.48028"
                        r="3.50605"
                        transform="rotate(45 1867.65 6.48028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1882.53"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1882.53 21.3558)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1897.4"
                        cy="36.2303"
                        r="3.50605"
                        transform="rotate(45 1897.4 36.2303)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1912.28"
                        cy="51.1053"
                        r="3.50605"
                        transform="rotate(45 1912.28 51.1053)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1897.4"
                        cy="6.48028"
                        r="3.50605"
                        transform="rotate(45 1897.4 6.48028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1912.28"
                        cy="21.3558"
                        r="3.50605"
                        transform="rotate(45 1912.28 21.3558)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="4.41068" cy="1059.4" r="4.41068" fill="#FABC71" fillOpacity="0.5" />
                    <circle cx="30.8765" cy="1059.4" r="4.41068" fill="#FABC71" fillOpacity="0.5" />
                    <circle cx="57.3384" cy="1059.4" r="4.41068" fill="#FABC71" fillOpacity="0.5" />
                    <circle cx="83.8003" cy="1059.4" r="4.41068" fill="#FABC71" fillOpacity="0.5" />
                    <circle cx="110.267" cy="1059.4" r="4.41068" fill="#FABC71" fillOpacity="0.5" />
                    <circle cx="136.733" cy="1059.4" r="4.41068" fill="#FABC71" fillOpacity="0.5" />
                    <circle cx="163.195" cy="1059.4" r="4.41068" fill="#FABC71" fillOpacity="0.5" />
                    <circle cx="189.662" cy="1059.4" r="4.41068" fill="#FABC71" fillOpacity="0.5" />
                    <circle cx="216.124" cy="1059.4" r="4.41068" fill="#FABC71" fillOpacity="0.5" />
                    <circle cx="242.589" cy="1059.4" r="4.41068" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="4.41068"
                        cy="1032.94"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="30.8765"
                        cy="1032.94"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="57.3384"
                        cy="1032.94"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="83.8003"
                        cy="1032.94"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="110.267"
                        cy="1032.94"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="136.733"
                        cy="1032.94"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="163.195"
                        cy="1032.94"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="189.662"
                        cy="1032.94"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="216.124"
                        cy="1032.94"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="242.589"
                        cy="1032.94"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="4.41068"
                        cy="1006.48"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="30.8765"
                        cy="1006.48"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="57.3384"
                        cy="1006.48"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="83.8003"
                        cy="1006.48"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="110.267"
                        cy="1006.48"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="136.733"
                        cy="1006.48"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="163.195"
                        cy="1006.48"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="189.662"
                        cy="1006.48"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="216.124"
                        cy="1006.48"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="242.589"
                        cy="1006.48"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="4.41068"
                        cy="980.008"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="30.8765"
                        cy="980.008"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="57.3384"
                        cy="980.008"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="83.8003"
                        cy="980.008"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="110.267"
                        cy="980.008"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="136.729"
                        cy="980.008"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="163.2" cy="980.008" r="4.41068" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="189.662"
                        cy="980.008"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="216.124"
                        cy="980.008"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="242.589"
                        cy="980.008"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="4.41068"
                        cy="953.545"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="30.8765"
                        cy="953.545"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="57.3384"
                        cy="953.545"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="83.8003"
                        cy="953.545"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="110.267"
                        cy="953.545"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="136.733"
                        cy="953.545"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="163.195"
                        cy="953.545"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="189.662"
                        cy="953.545"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="216.124"
                        cy="953.545"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="242.585"
                        cy="953.545"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="4.41068"
                        cy="927.082"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="30.8765"
                        cy="927.082"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="57.3384"
                        cy="927.082"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="83.8003"
                        cy="927.082"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="110.267"
                        cy="927.082"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="136.733"
                        cy="927.082"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="163.195"
                        cy="927.082"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="189.662"
                        cy="927.082"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="216.124"
                        cy="927.082"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="242.585"
                        cy="927.082"
                        r="4.41068"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <path
                        d="M2157.63 60.6132C2041.48 234.12 1806.67 280.617 1633.16 164.468C1459.65 48.3186 1413.16 -186.494 1529.31 -360"
                        stroke="#FABC71"
                    />
                    <path
                        d="M2157.63 60.6132C2041.48 234.12 1806.67 280.617 1633.16 164.468C1459.65 48.3186 1413.16 -186.494 1529.31 -360"
                        stroke="#FABC71"
                        strokeWidth="3"
                    />
                    <path
                        d="M-214.999 1107.72C-187.323 900.769 2.88118 755.437 209.833 783.114C416.785 810.79 562.116 1000.99 534.44 1207.95"
                        stroke="#FABC71"
                        strokeOpacity="0.5"
                        strokeWidth="3"
                    />
                    <rect
                        x="1614.4"
                        y="957.23"
                        width="343.647"
                        height="343.647"
                        transform="rotate(45.978 1614.4 957.23)"
                        fill="#FCDDB8"
                    />
                    <rect
                        x="1633.04"
                        y="896.414"
                        width="183.053"
                        height="183.053"
                        transform="rotate(45.978 1633.04 896.414)"
                        stroke="#FABC71"
                        strokeOpacity="0.8"
                        strokeWidth="2"
                    />
                    <rect
                        x="210.406"
                        y="-306.273"
                        width="343.647"
                        height="343.647"
                        transform="rotate(45.3987 210.406 -306.273)"
                        fill="#FFF4E9"
                    />
                    <rect
                        x="117.995"
                        y="-340.586"
                        width="341.647"
                        height="341.647"
                        transform="rotate(45 117.995 -340.586)"
                        stroke="#FABC71"
                        strokeOpacity="0.8"
                        strokeWidth="2"
                    />
                </svg>
            )}
            {type === "studentSecondary" && (
                <svg
                    className="bg-shape bg-student-secondary"
                    width="1920"
                    height="1080"
                    viewBox="0 0 1920 1080"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="1432.34"
                        y="223.549"
                        width="342.647"
                        height="342.647"
                        transform="rotate(84.0254 1432.34 223.549)"
                        stroke="#FABC71"
                        strokeOpacity="0.6"
                    />
                    <circle
                        cx="1770.1"
                        cy="712.862"
                        r="3.41939"
                        transform="rotate(60 1770.1 712.862)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1780.36"
                        cy="730.632"
                        r="3.41939"
                        transform="rotate(60 1780.36 730.632)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1790.61"
                        cy="748.398"
                        r="3.41939"
                        transform="rotate(60 1790.61 748.398)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1800.87"
                        cy="766.163"
                        r="3.41939"
                        transform="rotate(60 1800.87 766.163)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1811.13"
                        cy="783.933"
                        r="3.41939"
                        transform="rotate(60 1811.13 783.933)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1821.39"
                        cy="801.702"
                        r="3.41939"
                        transform="rotate(60 1821.39 801.702)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1831.65"
                        cy="819.472"
                        r="3.41939"
                        transform="rotate(60 1831.65 819.472)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1841.9"
                        cy="837.237"
                        r="3.41939"
                        transform="rotate(60 1841.9 837.237)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1852.16"
                        cy="855.003"
                        r="3.41939"
                        transform="rotate(60 1852.16 855.003)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1862.42"
                        cy="872.773"
                        r="3.41939"
                        transform="rotate(60 1862.42 872.773)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1787.86"
                        cy="702.605"
                        r="3.41939"
                        transform="rotate(60 1787.86 702.605)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1798.12"
                        cy="720.374"
                        r="3.41939"
                        transform="rotate(60 1798.12 720.374)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1808.38"
                        cy="738.14"
                        r="3.41939"
                        transform="rotate(60 1808.38 738.14)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1818.64"
                        cy="755.909"
                        r="3.41939"
                        transform="rotate(60 1818.64 755.909)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1828.89"
                        cy="773.675"
                        r="3.41939"
                        transform="rotate(60 1828.89 773.675)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1839.15"
                        cy="791.444"
                        r="3.41939"
                        transform="rotate(60 1839.15 791.444)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1849.41"
                        cy="809.214"
                        r="3.41939"
                        transform="rotate(60 1849.41 809.214)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1859.67"
                        cy="826.98"
                        r="3.41939"
                        transform="rotate(60 1859.67 826.98)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1869.93"
                        cy="844.745"
                        r="3.41939"
                        transform="rotate(60 1869.93 844.745)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1880.19"
                        cy="862.515"
                        r="3.41939"
                        transform="rotate(60 1880.19 862.515)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1805.63"
                        cy="692.347"
                        r="3.41939"
                        transform="rotate(60 1805.63 692.347)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1815.89"
                        cy="710.116"
                        r="3.41939"
                        transform="rotate(60 1815.89 710.116)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1826.14"
                        cy="727.882"
                        r="3.41939"
                        transform="rotate(60 1826.14 727.882)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1836.4"
                        cy="745.648"
                        r="3.41939"
                        transform="rotate(60 1836.4 745.648)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1846.66"
                        cy="763.417"
                        r="3.41939"
                        transform="rotate(60 1846.66 763.417)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1856.92"
                        cy="781.187"
                        r="3.41939"
                        transform="rotate(60 1856.92 781.187)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1867.18"
                        cy="798.952"
                        r="3.41939"
                        transform="rotate(60 1867.18 798.952)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1877.44"
                        cy="816.722"
                        r="3.41939"
                        transform="rotate(60 1877.44 816.722)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1887.7"
                        cy="834.487"
                        r="3.41939"
                        transform="rotate(60 1887.7 834.487)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1897.96"
                        cy="852.253"
                        r="3.41939"
                        transform="rotate(60 1897.96 852.253)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1823.4"
                        cy="682.089"
                        r="3.41939"
                        transform="rotate(60 1823.4 682.089)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1833.65"
                        cy="699.858"
                        r="3.41939"
                        transform="rotate(60 1833.65 699.858)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1843.91"
                        cy="717.624"
                        r="3.41939"
                        transform="rotate(60 1843.91 717.624)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1854.17"
                        cy="735.394"
                        r="3.41939"
                        transform="rotate(60 1854.17 735.394)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1864.43"
                        cy="753.159"
                        r="3.41939"
                        transform="rotate(60 1864.43 753.159)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1874.69"
                        cy="770.929"
                        r="3.41939"
                        transform="rotate(60 1874.69 770.929)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1884.95"
                        cy="788.694"
                        r="3.41939"
                        transform="rotate(60 1884.95 788.694)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1895.21"
                        cy="806.464"
                        r="3.41939"
                        transform="rotate(60 1895.21 806.464)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1905.46"
                        cy="824.23"
                        r="3.41939"
                        transform="rotate(60 1905.46 824.23)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1915.72"
                        cy="841.995"
                        r="3.41939"
                        transform="rotate(60 1915.72 841.995)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1841.17"
                        cy="671.831"
                        r="3.41939"
                        transform="rotate(60 1841.17 671.831)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1851.42"
                        cy="689.601"
                        r="3.41939"
                        transform="rotate(60 1851.42 689.601)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1861.68"
                        cy="707.366"
                        r="3.41939"
                        transform="rotate(60 1861.68 707.366)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1871.94"
                        cy="725.132"
                        r="3.41939"
                        transform="rotate(60 1871.94 725.132)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1882.2"
                        cy="742.901"
                        r="3.41939"
                        transform="rotate(60 1882.2 742.901)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1892.46"
                        cy="760.671"
                        r="3.41939"
                        transform="rotate(60 1892.46 760.671)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1902.71"
                        cy="778.437"
                        r="3.41939"
                        transform="rotate(60 1902.71 778.437)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1912.97"
                        cy="796.206"
                        r="3.41939"
                        transform="rotate(60 1912.97 796.206)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1923.23"
                        cy="813.972"
                        r="3.41939"
                        transform="rotate(60 1923.23 813.972)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1858.93"
                        cy="661.573"
                        r="3.41939"
                        transform="rotate(60 1858.93 661.573)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1869.19"
                        cy="679.339"
                        r="3.41939"
                        transform="rotate(60 1869.19 679.339)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1879.45"
                        cy="697.108"
                        r="3.41939"
                        transform="rotate(60 1879.45 697.108)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1889.71"
                        cy="714.874"
                        r="3.41939"
                        transform="rotate(60 1889.71 714.874)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1899.97"
                        cy="732.644"
                        r="3.41939"
                        transform="rotate(60 1899.97 732.644)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1910.22"
                        cy="750.413"
                        r="3.41939"
                        transform="rotate(60 1910.22 750.413)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1920.48"
                        cy="768.179"
                        r="3.41939"
                        transform="rotate(60 1920.48 768.179)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1876.7"
                        cy="651.312"
                        r="3.41939"
                        transform="rotate(60 1876.7 651.312)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1886.96"
                        cy="669.081"
                        r="3.41939"
                        transform="rotate(60 1886.96 669.081)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1897.22"
                        cy="686.851"
                        r="3.41939"
                        transform="rotate(60 1897.22 686.851)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1907.47"
                        cy="704.616"
                        r="3.41939"
                        transform="rotate(60 1907.47 704.616)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1917.73"
                        cy="722.386"
                        r="3.41939"
                        transform="rotate(60 1917.73 722.386)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1894.47"
                        cy="641.054"
                        r="3.41939"
                        transform="rotate(60 1894.47 641.054)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1904.73"
                        cy="658.823"
                        r="3.41939"
                        transform="rotate(60 1904.73 658.823)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1914.98"
                        cy="676.593"
                        r="3.41939"
                        transform="rotate(60 1914.98 676.593)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1912.23"
                        cy="630.796"
                        r="3.41939"
                        transform="rotate(60 1912.23 630.796)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1922.49"
                        cy="648.565"
                        r="3.41939"
                        transform="rotate(60 1922.49 648.565)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="155.864"
                        cy="-2.91294"
                        r="3.24358"
                        transform="rotate(60 155.864 -2.91294)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="165.594"
                        cy="13.9425"
                        r="3.24358"
                        transform="rotate(60 165.594 13.9425)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="175.324"
                        cy="30.798"
                        r="3.24358"
                        transform="rotate(60 175.324 30.798)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="185.055"
                        cy="47.6535"
                        r="3.24358"
                        transform="rotate(60 185.055 47.6535)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="194.785"
                        cy="64.505"
                        r="3.24358"
                        transform="rotate(60 194.785 64.505)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="204.516"
                        cy="81.3605"
                        r="3.24358"
                        transform="rotate(60 204.516 81.3605)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="214.248"
                        cy="98.2121"
                        r="3.24358"
                        transform="rotate(60 214.248 98.2121)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="182.449"
                        cy="4.20816"
                        r="3.24358"
                        transform="rotate(60 182.449 4.20816)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="192.18"
                        cy="21.0636"
                        r="3.24358"
                        transform="rotate(60 192.18 21.0636)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="201.91"
                        cy="37.9191"
                        r="3.24358"
                        transform="rotate(60 201.91 37.9191)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="211.641"
                        cy="54.7746"
                        r="3.24358"
                        transform="rotate(60 211.641 54.7746)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="221.371"
                        cy="71.63"
                        r="3.24358"
                        transform="rotate(60 221.371 71.63)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="231.104"
                        cy="88.4855"
                        r="3.24358"
                        transform="rotate(60 231.104 88.4855)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="209.033"
                        cy="11.3371"
                        r="3.24358"
                        transform="rotate(60 209.033 11.3371)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="218.764"
                        cy="28.1886"
                        r="3.24358"
                        transform="rotate(60 218.764 28.1886)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="228.492"
                        cy="45.0441"
                        r="3.24358"
                        transform="rotate(60 228.492 45.0441)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="238.225"
                        cy="61.8996"
                        r="3.24358"
                        transform="rotate(60 238.225 61.8996)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="247.955"
                        cy="78.7511"
                        r="3.24358"
                        transform="rotate(60 247.955 78.7511)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="225.887"
                        cy="1.6066"
                        r="3.24358"
                        transform="rotate(60 225.887 1.6066)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="235.615"
                        cy="18.4582"
                        r="3.24358"
                        transform="rotate(60 235.615 18.4582)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="245.346"
                        cy="35.3136"
                        r="3.24358"
                        transform="rotate(60 245.346 35.3136)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="255.076"
                        cy="52.1691"
                        r="3.24358"
                        transform="rotate(60 255.076 52.1691)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="264.807"
                        cy="69.0207"
                        r="3.24358"
                        transform="rotate(60 264.807 69.0207)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="252.469"
                        cy="8.72378"
                        r="3.24358"
                        transform="rotate(60 252.469 8.72378)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="262.201"
                        cy="25.5793"
                        r="3.24358"
                        transform="rotate(60 262.201 25.5793)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="271.932"
                        cy="42.4347"
                        r="3.24358"
                        transform="rotate(60 271.932 42.4347)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="281.662"
                        cy="59.2902"
                        r="3.24358"
                        transform="rotate(60 281.662 59.2902)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="269.326"
                        cy="-1.00278"
                        r="3.24358"
                        transform="rotate(60 269.326 -1.00278)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="279.057"
                        cy="15.8527"
                        r="3.24358"
                        transform="rotate(60 279.057 15.8527)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="288.787"
                        cy="32.7082"
                        r="3.24358"
                        transform="rotate(60 288.787 32.7082)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="298.518"
                        cy="49.5636"
                        r="3.24358"
                        transform="rotate(60 298.518 49.5636)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="295.912"
                        cy="6.11831"
                        r="3.24358"
                        transform="rotate(60 295.912 6.11831)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="305.643"
                        cy="22.9738"
                        r="3.24358"
                        transform="rotate(60 305.643 22.9738)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="315.373"
                        cy="39.8332"
                        r="3.24358"
                        transform="rotate(60 315.373 39.8332)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="312.764"
                        cy="-3.60825"
                        r="3.24358"
                        transform="rotate(60 312.764 -3.60825)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="322.498"
                        cy="13.2433"
                        r="3.24358"
                        transform="rotate(60 322.498 13.2433)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="332.229"
                        cy="30.0988"
                        r="3.24358"
                        transform="rotate(60 332.229 30.0988)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="339.35"
                        cy="3.51285"
                        r="3.24358"
                        transform="rotate(60 339.35 3.51285)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="349.08"
                        cy="20.3683"
                        r="3.24358"
                        transform="rotate(60 349.08 20.3683)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="365.934"
                        cy="10.6378"
                        r="3.24358"
                        transform="rotate(60 365.934 10.6378)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1718.9"
                        cy="6.47784"
                        r="3.50605"
                        transform="rotate(45 1718.9 6.47784)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1733.78"
                        cy="21.3528"
                        r="3.50605"
                        transform="rotate(45 1733.78 21.3528)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1748.65"
                        cy="36.2278"
                        r="3.50605"
                        transform="rotate(45 1748.65 36.2278)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1763.53"
                        cy="51.1028"
                        r="3.50605"
                        transform="rotate(45 1763.53 51.1028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1778.4"
                        cy="65.9778"
                        r="3.50605"
                        transform="rotate(45 1778.4 65.9778)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1793.28"
                        cy="80.8528"
                        r="3.50605"
                        transform="rotate(45 1793.28 80.8528)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1808.15"
                        cy="95.7278"
                        r="3.50605"
                        transform="rotate(45 1808.15 95.7278)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1823.03"
                        cy="110.603"
                        r="3.50605"
                        transform="rotate(45 1823.03 110.603)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1837.9"
                        cy="125.478"
                        r="3.50605"
                        transform="rotate(45 1837.9 125.478)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1748.65"
                        cy="6.47784"
                        r="3.50605"
                        transform="rotate(45 1748.65 6.47784)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1763.53"
                        cy="21.3528"
                        r="3.50605"
                        transform="rotate(45 1763.53 21.3528)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1778.4"
                        cy="36.2278"
                        r="3.50605"
                        transform="rotate(45 1778.4 36.2278)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1793.28"
                        cy="51.1028"
                        r="3.50605"
                        transform="rotate(45 1793.28 51.1028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1808.15"
                        cy="65.9778"
                        r="3.50605"
                        transform="rotate(45 1808.15 65.9778)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1823.03"
                        cy="80.8528"
                        r="3.50605"
                        transform="rotate(45 1823.03 80.8528)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1837.9"
                        cy="95.7278"
                        r="3.50605"
                        transform="rotate(45 1837.9 95.7278)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1852.78"
                        cy="110.603"
                        r="3.50605"
                        transform="rotate(45 1852.78 110.603)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1778.4"
                        cy="6.47784"
                        r="3.50605"
                        transform="rotate(45 1778.4 6.47784)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1793.28"
                        cy="21.3528"
                        r="3.50605"
                        transform="rotate(45 1793.28 21.3528)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1808.15"
                        cy="36.2278"
                        r="3.50605"
                        transform="rotate(45 1808.15 36.2278)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1823.03"
                        cy="51.1028"
                        r="3.50605"
                        transform="rotate(45 1823.03 51.1028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1837.9"
                        cy="65.9778"
                        r="3.50605"
                        transform="rotate(45 1837.9 65.9778)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1852.78"
                        cy="80.8528"
                        r="3.50605"
                        transform="rotate(45 1852.78 80.8528)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1867.65"
                        cy="95.7278"
                        r="3.50605"
                        transform="rotate(45 1867.65 95.7278)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1808.15"
                        cy="6.47784"
                        r="3.50605"
                        transform="rotate(45 1808.15 6.47784)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1823.03"
                        cy="21.3528"
                        r="3.50605"
                        transform="rotate(45 1823.03 21.3528)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1837.9"
                        cy="36.2278"
                        r="3.50605"
                        transform="rotate(45 1837.9 36.2278)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1852.78"
                        cy="51.1028"
                        r="3.50605"
                        transform="rotate(45 1852.78 51.1028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1867.65"
                        cy="65.9778"
                        r="3.50605"
                        transform="rotate(45 1867.65 65.9778)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1882.53"
                        cy="80.8528"
                        r="3.50605"
                        transform="rotate(45 1882.53 80.8528)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1837.9"
                        cy="6.47784"
                        r="3.50605"
                        transform="rotate(45 1837.9 6.47784)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1852.78"
                        cy="21.3528"
                        r="3.50605"
                        transform="rotate(45 1852.78 21.3528)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1867.65"
                        cy="36.2278"
                        r="3.50605"
                        transform="rotate(45 1867.65 36.2278)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1882.53"
                        cy="51.1028"
                        r="3.50605"
                        transform="rotate(45 1882.53 51.1028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1897.4"
                        cy="65.9778"
                        r="3.50605"
                        transform="rotate(45 1897.4 65.9778)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1867.65"
                        cy="6.47784"
                        r="3.50605"
                        transform="rotate(45 1867.65 6.47784)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1882.53"
                        cy="21.3528"
                        r="3.50605"
                        transform="rotate(45 1882.53 21.3528)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1897.4"
                        cy="36.2278"
                        r="3.50605"
                        transform="rotate(45 1897.4 36.2278)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1912.28"
                        cy="51.1028"
                        r="3.50605"
                        transform="rotate(45 1912.28 51.1028)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1897.4"
                        cy="6.47784"
                        r="3.50605"
                        transform="rotate(45 1897.4 6.47784)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="1912.28"
                        cy="21.3528"
                        r="3.50605"
                        transform="rotate(45 1912.28 21.3528)"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="3.41939"
                        cy="1063.67"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="23.937" cy="1063.67" r="3.41939" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="44.4526"
                        cy="1063.67"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="64.9663"
                        cy="1063.67"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="85.4838"
                        cy="1063.67"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="106.003"
                        cy="1063.67"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="126.517"
                        cy="1063.67"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="147.035"
                        cy="1063.67"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="167.55" cy="1063.67" r="3.41939" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="188.068"
                        cy="1063.67"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="3.41939"
                        cy="1043.15"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="23.937" cy="1043.15" r="3.41939" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="44.4526"
                        cy="1043.15"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="64.9663"
                        cy="1043.15"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="85.4838"
                        cy="1043.15"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="106.003"
                        cy="1043.15"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="126.517"
                        cy="1043.15"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="147.035"
                        cy="1043.15"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="167.55" cy="1043.15" r="3.41939" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="188.068"
                        cy="1043.15"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="3.41939"
                        cy="1022.63"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="23.937" cy="1022.63" r="3.41939" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="44.4526"
                        cy="1022.63"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="64.9663"
                        cy="1022.63"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="85.4838"
                        cy="1022.63"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="105.999"
                        cy="1022.63"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="126.521"
                        cy="1022.63"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="147.035"
                        cy="1022.63"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="167.55" cy="1022.63" r="3.41939" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="188.068"
                        cy="1022.63"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="3.41939"
                        cy="1002.12"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="23.937" cy="1002.12" r="3.41939" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="44.4526"
                        cy="1002.12"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="64.9663"
                        cy="1002.12"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="85.4838"
                        cy="1002.12"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="106.003"
                        cy="1002.12"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="126.517"
                        cy="1002.12"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="147.035"
                        cy="1002.12"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="167.55" cy="1002.12" r="3.41939" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="188.064"
                        cy="1002.12"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="3.41939"
                        cy="981.603"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="23.937" cy="981.603" r="3.41939" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="44.4526"
                        cy="981.603"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="64.9663"
                        cy="981.603"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="85.4838"
                        cy="981.603"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="106.003"
                        cy="981.603"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="126.517"
                        cy="981.603"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle
                        cx="147.035"
                        cy="981.603"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <circle cx="167.55" cy="981.603" r="3.41939" fill="#FABC71" fillOpacity="0.5" />
                    <circle
                        cx="188.064"
                        cy="981.603"
                        r="3.41939"
                        fill="#FABC71"
                        fillOpacity="0.5"
                    />
                    <path
                        d="M2157.63 60.6122C2041.48 234.119 1806.67 280.616 1633.16 164.467C1459.65 48.3176 1413.16 -186.495 1529.31 -360.001"
                        stroke="#FABC71"
                    />
                    <path
                        d="M2157.63 60.6122C2041.48 234.119 1806.67 280.616 1633.16 164.467C1459.65 48.3176 1413.16 -186.495 1529.31 -360.001"
                        stroke="#FABC71"
                    />
                    <path
                        d="M301.526 1159.76C329.203 952.812 519.407 807.48 726.359 835.157C933.31 862.833 1078.64 1053.04 1050.97 1259.99"
                        stroke="#FABC71"
                    />
                    <rect
                        x="1614.38"
                        y="957.937"
                        width="342.647"
                        height="342.647"
                        transform="rotate(45.978 1614.38 957.937)"
                        stroke="#FABC71"
                        strokeOpacity="0.6"
                    />
                </svg>
            )}
            {type === "tutorPrimary" && (
                <svg
                    className="bg-shape bg-tutor-primary"
                    width="1920"
                    height="1080"
                    viewBox="0 0 1920 1080"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="230.469"
                        y="604"
                        width="295.713"
                        height="295.713"
                        transform="rotate(62.9947 230.469 604)"
                        fill="#B7DAEA"
                    />
                    <rect
                        x="355.48"
                        y="409.414"
                        width="414.684"
                        height="414.684"
                        transform="rotate(45.978 355.48 409.414)"
                        stroke="#06729D"
                        strokeWidth="2"
                    />
                    <rect
                        x="1536.67"
                        y="-39"
                        width="322.176"
                        height="322.176"
                        transform="rotate(45.978 1536.67 -39)"
                        fill="#B7DAEA"
                    />
                    <rect
                        x="1536.67"
                        y="-39"
                        width="322.176"
                        height="322.176"
                        transform="rotate(45.978 1536.67 -39)"
                        fill="#B7DAEA"
                    />
                    <rect
                        x="1747.44"
                        y="-223.686"
                        width="433.392"
                        height="433.392"
                        transform="rotate(66.7257 1747.44 -223.686)"
                        stroke="#06729D"
                        strokeWidth="2"
                    />
                    <rect
                        x="311.596"
                        y="-517.346"
                        width="599.942"
                        height="599.942"
                        transform="rotate(18.1557 311.596 -517.346)"
                        fill="#EBF2F5"
                    />
                    <rect
                        x="2152.16"
                        y="589.14"
                        width="704.257"
                        height="704.257"
                        transform="rotate(62.2081 2152.16 589.14)"
                        fill="#EBF2F5"
                    />
                </svg>
            )}
            {type === "tutorSecondary" && (
                <svg
                    className="bg-shape bg-tutor-secondary"
                    width="1733"
                    height="1080"
                    viewBox="0 0 1733 1080"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="1143.96"
                        y="144.349"
                        width="274.147"
                        height="274.147"
                        transform="rotate(84.0254 1143.96 144.349)"
                        stroke="#06729D"
                        strokeOpacity="0.3"
                        strokeWidth="2"
                    />
                    <rect
                        x="1641.34"
                        y="-129.686"
                        width="228.633"
                        height="228.633"
                        transform="rotate(66.7257 1641.34 -129.686)"
                        stroke="#06729D"
                        strokeOpacity="0.3"
                        strokeWidth="2"
                    />
                    <rect
                        x="147.452"
                        y="-120.586"
                        width="202.87"
                        height="202.87"
                        transform="rotate(45.978 147.452 -120.586)"
                        stroke="#06729D"
                        strokeOpacity="0.3"
                        strokeWidth="2"
                    />
                    <rect
                        x="1404.07"
                        y="892.539"
                        width="274.147"
                        height="274.147"
                        transform="rotate(45.978 1404.07 892.539)"
                        stroke="#06729D"
                        strokeOpacity="0.3"
                        strokeWidth="2"
                    />
                </svg>
            )}
        </Fragment>
    );
};
export default BgShape;
