import { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

// import "./Settings.scss";

import { Button } from "../../components/Button";
import { Slider } from "../../components/Slider";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import AvatarImg from "../../assets/images/placeholder-profile.jpg";

const GuardianUsers = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [selectedReason, setSelectedReason] = useState(false);

    const selectedReasonClass = selectedReason ? " selected-reason selected-reason-student" : "";

    const options = {
        margin: 72,
        items: 4,
        dots: false,
        nav: true
    };

    const renderUsers = (name) => {
        return (
            <div
                className={"mt9 " + selectedReasonClass}
                onClick={() => history.push(APP_ROUTES.QUESTION_BOARD)}
            >
                <img src={AvatarImg} className="img-fluid thumbnail-rounded mx-auto" alt="" />
                <h3 className="ff-sf-pro-rounded mb0 mt3">{name}</h3>
            </div>
        );
    };

    return (
        <div className="tp-settings tp-report-users">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Question Board
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm mt8">
                            <div className="content-inner report-users-inner bg-clr-light text-center">
                                <Row>
                                    <Col xs={12}>
                                        <h3 className="mb0">Please select the student</h3>
                                    </Col>
                                    <Col xs={12}>
                                        <Slider
                                            className={`tp-slider-${userType}`}
                                            options={options}
                                            children={
                                                <Fragment>
                                                    {renderUsers("Jane")}
                                                    {renderUsers("Marly")}
                                                    {renderUsers("James")}
                                                    {renderUsers("Carolina")}
                                                    {renderUsers("Carolina")}
                                                    {renderUsers("Carolina")}
                                                </Fragment>
                                            }
                                        />
                                    </Col>
                                    <Col xs="12">
                                        <Button
                                            title="Next"
                                            className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                            onClick={() =>
                                                history.push(
                                                    getRoute(APP_ROUTES.DELETE_ACCOUNT_REASON)
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(GuardianUsers);
