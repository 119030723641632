import { useState } from "react";
import { useHistory } from "react-router";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./EarningBilling.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { TpModal } from "../../components/TpModal";
import { ProgressBar } from "../../components/ProgressBar";
import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { DateRangeCalendar } from "../../components/DateRangeCalendar";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import AllEarningBg from "../../components/SvgIcon/AllEarningBg";
import CurrentEarningBg from "../../components/SvgIcon/CurrentEarningBg";
import DownloadIcon from "../../components/SvgIcon/DownloadIcon";
import CloseIconCircle from "../../components/SvgIcon/CloseIconCircle";

const EarningBilling = (props) => {
    const { setSelectedUser, isAuthenticated, userType } = props;
    const history = useHistory();
    const [getReportModal, setGetReportModal] = useState(false);
    return (
        <div className="tp-earning-and-billing tp-earning-and-billing-main">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Earnings & Billings
                        </div>
                        <div className="content-inner-wrap mt9">
                            <Row>
                                <Col md={6}>
                                    <Button
                                        title="Withdraw earnings"
                                        className={`tp-btn-secondary tp-btn-secondary-${userType} mr4`}
                                    />
                                    <Button
                                        title="Manage bank account"
                                        className={`tp-btn-secondary tp-btn-secondary-${userType} mr4`}
                                    />
                                    <Button
                                        title="Get a report"
                                        className={`tp-btn-secondary tp-btn-secondary-${userType}`}
                                        onClick={() => {
                                            setGetReportModal(!getReportModal);
                                        }}
                                    />
                                    <TpModal
                                        isOpen={getReportModal}
                                        centered
                                        className={`tp-modal-${userType} size-10`}
                                        children={
                                            <AvForm>
                                                <div className="close-btn">
                                                    <CloseIconCircle
                                                        className="pointer tpw-40 tph-40"
                                                        color={Colors[`${userType}Primary`]}
                                                    />
                                                </div>
                                                <h2 className="mb3 ff-sf-pro-rounded">
                                                    Get a Report
                                                </h2>
                                                <h3 className="mb4">
                                                    What would you like the report to show?
                                                </h3>
                                                <CreatableDropdown
                                                    placeholder="Select Subject"
                                                    options={[
                                                        {
                                                            label: "Math"
                                                        },
                                                        {
                                                            label: "Biology"
                                                        },
                                                        {
                                                            label: "Spanish"
                                                        },
                                                        {
                                                            label: "German"
                                                        },
                                                        {
                                                            label: "Italian"
                                                        }
                                                    ]}
                                                    className={`mb4 bg-fill mw-100 dropdown-size-sm bdr-none tp-creatable-dropdown-${userType}`}
                                                    dropIconColor={Colors[`${userType}Primary`]}
                                                />
                                                <CreatableDropdown
                                                    placeholder="Select Select the Student"
                                                    options={[
                                                        {
                                                            label: "Math"
                                                        },
                                                        {
                                                            label: "Biology"
                                                        },
                                                        {
                                                            label: "Spanish"
                                                        },
                                                        {
                                                            label: "German"
                                                        },
                                                        {
                                                            label: "Italian"
                                                        }
                                                    ]}
                                                    className={`bg-fill mw-100 dropdown-size-sm bdr-none tp-creatable-dropdown-${userType}`}
                                                    dropIconColor={Colors[`${userType}Primary`]}
                                                />
                                                <div className="d-flex align-items-center mt4">
                                                    <TextInput
                                                        name="datefrom"
                                                        placeholder="dd-mm-yyyy"
                                                        className={`size-sm bdr-0 bg-clr-gray-secondary tp-text-input-${userType}`}
                                                    />
                                                    <h3 className="ml4 mr4">to</h3>
                                                    <TextInput
                                                        name="dateto"
                                                        placeholder="dd-mm-yyyy"
                                                        className={`size-sm bdr-0 bg-clr-gray-secondary tp-text-input-${userType}`}
                                                    />
                                                </div>
                                                <DateRangeCalendar className="mt4 ms-0" />
                                                <div className="text-end mt4">
                                                    <Button
                                                        title="Get Report"
                                                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                        onClick={() =>
                                                            history.push(
                                                                getRoute(
                                                                    APP_ROUTES.EARNING_AND_BILLING_REPORT
                                                                )
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </AvForm>
                                        }
                                    />
                                    <div className="earning-holder mt9 text-center">
                                        <AllEarningBg className="earning-bg" />
                                        <h2 className="ff-sf-pro-rounded mb7">All earnings</h2>
                                        <h2 className="ff-sf-pro-rounded fs-72 mb7">£4,500</h2>
                                        <h4 className="ff-sf-pro-rounded clr-gray-dark-2 mb0">
                                            From 1 sep 2020 to 12 april 2021
                                        </h4>
                                    </div>
                                    <div className="earning-holder mt9 text-center">
                                        <CurrentEarningBg className="earning-bg" />
                                        <h2 className="ff-sf-pro-rounded mb7">Current Balance</h2>
                                        <h2 className="ff-sf-pro-rounded fs-72 mb7">£450</h2>
                                        <h4 className="ff-sf-pro-rounded clr-gray-dark-2 mb0">
                                            today
                                        </h4>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="content-inner earning-billing-highlight bg-clr-dark">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h2 className="ff-sf-pro-rounded mb0">Highlights</h2>
                                            <DownloadIcon
                                                color={Colors.black}
                                                className="pointer tpw-40 tph-40"
                                            />
                                        </div>
                                        <h4 className="mt5 mb3">
                                            So far this month, you are averaging fewer earnings than
                                            last month.
                                        </h4>
                                        <h4>£30/ day</h4>
                                        <ProgressBar
                                            value={24}
                                            text="March"
                                            className={`tp-progress-bar-${userType}`}
                                        />
                                        <h4 className="mt2">£40/ day</h4>
                                        <ProgressBar
                                            value={55}
                                            text="February"
                                            className={`tp-progress-bar-${userType}`}
                                        />
                                        <h4 className="mt5 mb3">
                                            So far this week, you are averaging fewer earnings than
                                            last week.
                                        </h4>
                                        <h4>£30/ day</h4>
                                        <ProgressBar
                                            value={44}
                                            text="This  Week"
                                            className={`tp-progress-bar-${userType}`}
                                        />
                                        <h4 className="mt2">£40/ day</h4>
                                        <ProgressBar
                                            value={33}
                                            text="Last Week"
                                            className={`tp-progress-bar-${userType}`}
                                        />
                                        <h4 className="mt5 mb3">
                                            So far this year, you are averaging fewer earnings than
                                            last year.
                                        </h4>
                                        <h4>2021</h4>
                                        <ProgressBar
                                            value={55}
                                            text="This  Week"
                                            className={`tp-progress-bar-${userType}`}
                                        />
                                        <h4 className="mt2">2020</h4>
                                        <ProgressBar
                                            value={95}
                                            text="Last Week"
                                            className={`tp-progress-bar-${userType}`}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    // setSelectedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(EarningBilling);
