import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./MyNetwork.scss";
import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { TpModal } from "../../components/TpModal";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import CheckIconCircle from "../../components/SvgIcon/CheckIconCircle";

const GuardianNetworkInvite = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [inviteConfirmModal, setInviteConfirmModal] = useState(false);

    return (
        <div className="tp-my-network tp-network-invite-guardian">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>Send Invite</div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to send invite"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner network-invite-guardian-inner bg-clr-light">
                                <AvForm>
                                    <h3 className="mb0 text-center">
                                        Please enter the guardian’s details
                                    </h3>
                                    <div className="mt9">
                                        <TextInput
                                            className={`bdr-0 tp-text-input-${userType}`}
                                            name="guardianname"
                                            placeholder="Guardian’s Name"
                                            options={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required"
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="mt4">
                                        <TextInput
                                            name="guardianemail"
                                            placeholder="Guardian’s Email Address"
                                            type="email"
                                            options={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required"
                                                }
                                            }}
                                            className={`bdr-0 tp-text-input-${userType}`}
                                        />
                                    </div>
                                    <div className="text-center">
                                        <Button
                                            title="Send Invite"
                                            className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                            onClick={() => {
                                                setInviteConfirmModal(true);
                                            }}
                                        />
                                    </div>
                                </AvForm>
                                <TpModal
                                    isOpen={inviteConfirmModal}
                                    centered
                                    className={`tp-modal-${userType} size-1`}
                                    children={
                                        <div className="text-center">
                                            <CheckIconCircle type={`${userType}`} />
                                            <h3 className="mt9 mb9">
                                                An email invite has been sent to the guardian. They
                                                will be added to your network once they have
                                                accepted the invite
                                            </h3>
                                            <Button
                                                onClick={() => {
                                                    setInviteConfirmModal(false);
                                                }}
                                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                title="OK"
                                            />
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(GuardianNetworkInvite);
