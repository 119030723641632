import { useHistory, Redirect, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import "./Signup.scss";
import { useState } from "react";
import { Colors } from "../../themes/colors";
import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { sendGuardianInvite } from "../../store/actions";
import BgShape from "../../components/SvgIcon/BgShape";

const GuardianInviteForm = ({ sendGuardianInvite, userType, isAuthenticated }) => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isInvited = params.get("isInvited");
    const [guardianName, setGuardianName] = useState("");
    const [guardianEmail, setGuardianEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        const payload = {
            GuardianName: guardianName,
            Email: guardianEmail
        };
        sendGuardianInvite(payload)
            .then((res) => {
                setLoading(false);
                history.push({
                    pathname: getRoute(APP_ROUTES.INVITE_CONFIRMATION),
                    search: isInvited ? "?isInvited=true&guardian=true" : "?guardian=true"
                });
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    if (!isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: getRoute(APP_ROUTES.SETUP_PASSWORD),
                    search: isInvited ? "?isInvited=true" : ""
                }}
            />
        );
    }
    return (
        <div className="tp-guardian-invite-form full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12}>
                        <h1 className="mb0 text-center mt9">Please enter the guardian’s details</h1>
                        <AvForm onValidSubmit={handleSubmit}>
                            <TextInput
                                name="guardianname"
                                placeholder="Guardian’s Name"
                                value={guardianName}
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    maxLength: {
                                        value: 55,
                                        errorMessage:
                                            " A valid name must be of min 6 & max 55 characters with no special characters "
                                    },
                                    minLength: {
                                        value: 6,
                                        errorMessage:
                                            "A valid name must be of min 6 & max 55 characters with no special characters"
                                    },
                                    pattern: {
                                        value: "/^[a-zA-Z ]+$/",
                                        errorMessage:
                                            " A valid name must be of min 6 & max 55 characters with no special characters"
                                    }
                                }}
                                onChange={(e) => setGuardianName(e.target.value)}
                                className={`tp-text-input-${userType} mt9`}
                            />
                            <TextInput
                                name="guardianemail"
                                placeholder="Guardian’s Email Address"
                                type="email"
                                value={guardianEmail}
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    email: {
                                        errorMessage: "Please enter a valid email address"
                                    },
                                    maxLength: {
                                        value: 64,
                                        errorMessage: "Email address must not exceed 64 characters "
                                    }
                                }}
                                onChange={(e) => setGuardianEmail(e.target.value)}
                                className={`tp-text-input-${userType} mt9`}
                            />
                            <Col xs={12} className="text-center mt9">
                                <Button
                                    title="Send Invite"
                                    className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                    type="submit"
                                    isLoading={loading}
                                />
                            </Col>
                        </AvForm>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
const mapDispatchToProps = {
    sendGuardianInvite
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(GuardianInviteForm);
