import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import {
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";

import { Button } from "../../components/Button";
import { NotificationMsg } from "../../components/NotificationMsg";
import { UserStatus } from "../../components/UserStatus";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import "./TopNavbar.scss";
import { Colors } from "../../themes/colors";
import { QuestionTypes } from "../../constants";

import Logo from "../../components/SvgIcon/Logo";
import BellIcon from "../../components/SvgIcon/BellIcon";
import SettingOptionIcon from "../../components/SvgIcon/SettingOptionIcon";
import SettingsIcon from "../../components/SvgIcon/SettingsIcon";
import NetworkIcon from "../../components/SvgIcon/NetworkIcon";
import LogoutIcon from "../../components/SvgIcon/LogoutIcon";
import UserImg from "../../assets/images/placeholder-profile.jpg";
import { SIGN_OUT } from "../../store/actions";

const TopNavbar = (props) => {
    const { className = "", userType } = props;

    const mockData = [
        {
            id: 1,
            title: "New Question",
            shortText: "You have a new math question from Mughda",
            type: QuestionTypes.FOLLOWUP,
            className: userType,
            iconColor: Colors[`${userType}Primary`],
            unRead: true
        },
        {
            id: 2,
            title: "New Question",
            shortText: "You have a new math question from Mughda",
            type: QuestionTypes.FOLLOWUP,
            className: userType,
            iconColor: Colors[`${userType}Primary`],
            unRead: true
        },
        {
            id: 3,
            title: "New Question",
            shortText: "You have a new math question from Mughda",
            type: QuestionTypes.CLARITY,
            className: userType,
            iconColor: Colors[`${userType}Primary`],
            unRead: true
        },
        {
            id: 4,
            title: "New Question",
            shortText: "You have a new math question from Mughda",
            type: QuestionTypes.CLARITY,
            className: userType,
            iconColor: Colors[`${userType}Primary`],
            unRead: true
        }
    ];

    const history = useHistory();
    const [changeStatus, setChangeStatus] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [notifyDropdown, setNotifyDropdown] = useState(false);
    const [data, setData] = useState(mockData);
    const toggle = () => setIsOpen(!isOpen);
    const dispatch = useDispatch();

    const handleLogout = () => {
        localStorage.clear();
        dispatch({ type: SIGN_OUT });
    };

    const handleSeeMore = () => {
        let temp = [...data];

        temp.push({
            id: 5,
            title: "New Question",
            shortText: "You have a new math question from Mughda",
            type: QuestionTypes.NORMAL_QUESTION,
            className: userType,
            iconColor: Colors.black,
            unRead: false
        });

        setData(temp);
    };

    return (
        <Navbar light expand="md" className={`top-navbar pt0 pb0 container-fluid ${className}`}>
            <NavbarToggler onClick={toggle} />
            <NavbarBrand href="/" className="logo d-lg-none">
                <Logo color={Colors[`${userType}Primary`]} className="tpw-115 tph-24" />
            </NavbarBrand>
            <Nav className="ms-auto align-items-center" navbar>
                <UncontrolledDropdown
                    nav
                    inNavbar
                    className={`tp-menu-dropdown notification notification-${userType} mr4`}
                    isOpen={notifyDropdown}
                >
                    <DropdownToggle
                        nav
                        onClick={() => {
                            setNotifyDropdown(!notifyDropdown);
                        }}
                    >
                        <span className="status active">
                            <BellIcon color={Colors.black} className="tpw-45 tph-45" />
                        </span>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <div
                            className={`${
                                data.length > 5 &&
                                `scrolling-container scrolling-container-${userType}`
                            }`}
                        >
                            {data.map((item, index) => {
                                const { title, shortText, type, className, iconColor, unRead } =
                                    item;
                                return (
                                    <DropdownItem key={index}>
                                        <NotificationMsg
                                            title={title}
                                            shortText={shortText}
                                            type={type}
                                            className={`tp-notification-msg-${className} mb4`}
                                            iconColor={iconColor}
                                            unRead={unRead}
                                        />
                                    </DropdownItem>
                                );
                            })}
                            <DropdownItem>
                                <div className="text-center">
                                    <Button
                                        title="See more"
                                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType}`}
                                        onClick={handleSeeMore}
                                    />
                                </div>
                            </DropdownItem>
                        </div>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar className="tp-menu-dropdown status mr4">
                    <DropdownToggle nav>
                        <span className="status active">
                            <img
                                src={UserImg}
                                className="img-fluid rounded-circle objfit-cover tpw-50 tph-50"
                                alt="Avatar"
                            />
                        </span>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem>
                            <input type="checkbox" className="checked" />
                            Active
                        </DropdownItem>
                        <DropdownItem className="mt4" onClick={() => setChangeStatus(true)}>
                            <input type="checkbox" />
                            Away
                        </DropdownItem>
                        {changeStatus && <UserStatus userType={userType} />}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar className="tp-menu-dropdown setting">
                    <DropdownToggle nav>
                        <SettingOptionIcon color={Colors.black} className="tpw-45 tph-45" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => history.push(getRoute(APP_ROUTES.SETTINGS))}>
                            <SettingsIcon color={Colors.black} className="tpw-40 tph-40 mr2" />
                            Settings
                        </DropdownItem>
                        <DropdownItem
                            className="mt4"
                            onClick={() => history.push(getRoute(APP_ROUTES.MY_NETWORK))}
                        >
                            <NetworkIcon color={Colors.black} className="tpw-40 tph-40 mr2" />
                            My Network
                        </DropdownItem>
                        <DropdownItem onClick={handleLogout} className="mt4">
                            <LogoutIcon color={Colors.black} className="tpw-40 tph-40 mr2" />
                            Logout
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Nav>
        </Navbar>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(TopNavbar);
