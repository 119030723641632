const ResourceIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 20L20 20"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 15L20 15"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 10L15 10"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.4822 1.25L2.5 1.25C2.16848 1.25 1.85054 1.3817 1.61612 1.61612C1.3817 1.85054 1.25 2.16848 1.25 2.5L1.25 27.5C1.25 27.8315 1.3817 28.1495 1.61612 28.3839C1.85054 28.6183 2.16848 28.75 2.5 28.75L27.5 28.75C27.8315 28.75 28.1495 28.6183 28.3839 28.3839C28.6183 28.1495 28.75 27.8315 28.75 27.5L28.75 10.5178C28.75 10.3536 28.7177 10.1911 28.6549 10.0394C28.592 9.88776 28.5 9.74996 28.3839 9.63388L20.3661 1.61612C20.25 1.50005 20.1122 1.40797 19.9606 1.34515C19.8089 1.28233 19.6464 1.25 19.4822 1.25V1.25Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.6369 10.001L20 10.001L20 1.36348"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default ResourceIcon;
