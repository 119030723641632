import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import EarningIcon from "../../components/SvgIcon/EarningIcon";
import QuestionBoardIconCircle from "../../components/SvgIcon/QuestionBoardIconCircle";
import NetworkIcon from "../../components/SvgIcon/NetworkIcon";
import KnowledgeBankCircleIcon from "../../components/SvgIcon/KnowledgeBankCircleIcon";
import OtherIcon from "../../components/SvgIcon/OtherIcon";

const ReportTechnicalIssues = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [selectedReason, setSelectedReason] = useState(false);

    const selectedReasonClass = selectedReason ? " selected-reason selected-reason-student" : "";

    return (
        <div className="tp-settings tp-report-technical-issues">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Report a technical Issue
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to report"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner bg-clr-light report-technical-issues-inner text-center">
                                <Row>
                                    <Col xs={12}>
                                        <h3 className="mb0">
                                            Which feature are you having trouble with?
                                        </h3>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className="justify-content-between">
                                            <Col md={"auto"}>
                                                <div
                                                    className={
                                                        "delete-account-reason-type mt9" +
                                                        selectedReasonClass
                                                    }
                                                >
                                                    <EarningIcon
                                                        color={Colors[`${userType}Primary`]}
                                                        bgColor={Colors[`${userType}Secondary`]}
                                                        onClick={() => {
                                                            setSelectedReason(!selectedReason);
                                                        }}
                                                        className="pointer tph-150 tpw-150"
                                                    />
                                                    <h3 className="mt4 mb0">Earnings</h3>
                                                </div>
                                            </Col>
                                            <Col md={"auto"}>
                                                <div className="delete-account-reason-type mt9">
                                                    <QuestionBoardIconCircle
                                                        color={Colors[`${userType}Primary`]}
                                                        bgColor={Colors[`${userType}Secondary`]}
                                                        className="pointer tph-150 tpw-150"
                                                    />
                                                    <h3 className="mt4 mb0">Question Board</h3>
                                                </div>
                                            </Col>
                                            <Col md={"auto"}>
                                                <div className="delete-account-reason-type mt9">
                                                    <NetworkIcon
                                                        color={Colors[`${userType}Primary`]}
                                                        bgColor={Colors[`${userType}Secondary`]}
                                                        className="pointer tph-150 tpw-150"
                                                    />
                                                    <h3 className="mt4 mb0">My Network</h3>
                                                </div>
                                            </Col>
                                            <Col md={"auto"}>
                                                <div className="delete-account-reason-type mt9">
                                                    <KnowledgeBankCircleIcon
                                                        color={Colors[`${userType}Primary`]}
                                                        bgColor={Colors[`${userType}Secondary`]}
                                                        className="pointer tph-150 tpw-150"
                                                    />
                                                    <h3 className="mt4 mb0">Knowledge Bank</h3>
                                                </div>
                                            </Col>
                                            <Col md={"auto"}>
                                                <div className="delete-account-reason-type mt9">
                                                    <OtherIcon
                                                        color={Colors[`${userType}Primary`]}
                                                        bgColor={Colors[`${userType}Secondary`]}
                                                        className="pointer tph-150 tpw-150"
                                                    />
                                                    <h3 className="mt4 mb0">Other</h3>
                                                </div>
                                            </Col>
                                            <Col xs="12">
                                                <Button
                                                    title="Next"
                                                    className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                                    onClick={() =>
                                                        history.push(
                                                            getRoute(
                                                                APP_ROUTES.REPORT_TECHNICAL_REASON
                                                            )
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(ReportTechnicalIssues);
