import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";

import "./PictureUpload.scss";

const PictureUpload = (props) => {
    const { componentRef, className = "" } = props;
    return (
        <div className={`profile-picture-crop ${className}`}>
            <ProfilePicture ref={componentRef} />
        </div>
    );
};
export default PictureUpload;
