const TodoListIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="34"
            height="24"
            viewBox="0 0 34 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 15.75C25.9746 15.75 33.25 12.3921 33.25 8.25C33.25 4.10786 25.9746 0.75 17 0.75C8.02537 0.75 0.75 4.10786 0.75 8.25C0.75 12.3921 8.02537 15.75 17 15.75Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M0.75 8.25V15.75C0.75 19.5 7 23.25 17 23.25C27 23.25 33.25 19.5 33.25 15.75V8.25"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default TodoListIcon;
