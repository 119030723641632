const EmailIconSmall = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="32"
            height="24"
            viewBox="0 0 32 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 0.75H31V22C31 22.3315 30.8683 22.6495 30.6339 22.8839C30.3995 23.1183 30.0815 23.25 29.75 23.25H2.25C1.91848 23.25 1.60054 23.1183 1.36612 22.8839C1.1317 22.6495 1 22.3315 1 22V0.75Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31 0.75L16 14.5L1 0.75"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default EmailIconSmall;
