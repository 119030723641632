const KeyIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="144"
            height="144"
            viewBox="0 0 144 144"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M44.8435 71.138C40.5239 60.3635 40.0569 48.428 43.5214 37.349C46.986 26.27 54.1696 16.7269 63.858 10.333C73.5464 3.93898 85.1453 1.08626 96.6942 2.257C108.243 3.42773 119.034 8.55011 127.242 16.7583C135.45 24.9665 140.572 35.757 141.743 47.3059C142.914 58.8548 140.061 70.4538 133.667 80.1422C127.273 89.8305 117.73 97.0141 106.651 100.479C95.5719 103.943 83.6364 103.476 72.862 99.1565L72.8624 99.1554L63.6391 108.379H46.8285V125.189H30.0178V142H2V113.982L44.8448 71.1374L44.8435 71.138Z"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M115.5 38C115.5 41.5899 112.59 44.5 109 44.5C105.41 44.5 102.5 41.5899 102.5 38C102.5 34.4101 105.41 31.5 109 31.5C112.59 31.5 115.5 34.4101 115.5 38Z"
                stroke={color}
                strokeWidth="3"
            />
        </svg>
    );
};
export default KeyIcon;
