import "./NotificationMsg.scss";
import { Colors } from "../../themes/colors";
import { connect } from "react-redux";

import { QuestionTypes } from "../../constants";

import ClarityQuestionIcon from "../SvgIcon/ClarityQuestionIcon";
import FollowupQuestionIcon from "../SvgIcon/FollowupQuestionIcon";
import QuestionIcon from "../SvgIcon/QuestionIcon";
import ReportUserIcon from "../SvgIcon/ReportUserIcon";
import CheckIconNormal from "../SvgIcon/CheckIconNormal";

const NotificationMsg = (props) => {
    const {
        title,
        shortText,
        className = "",
        type = "",
        onClick = () => {},
        unRead,
        iconColor,
        userType
    } = props;
    return (
        <div
            className={`tp-notification-msg d-flex align-items-stretch ${className} ${
                unRead && "unread"
            }`}
            onClick={onClick}
        >
            <div className="notification-details w-100 d-flex align-items-stretch">
                <div className="notification-type">
                    {type === QuestionTypes.CLARITY ? (
                        <div className="notification-type-holder">
                            <ClarityQuestionIcon color={iconColor} className="tpw-40 tph-40" />
                        </div>
                    ) : type === QuestionTypes.NORMAL_QUESTION ? (
                        <div className="notification-type-holder">
                            <QuestionIcon color={iconColor} className="tpw-40 tph-40" />
                        </div>
                    ) : type === QuestionTypes.FOLLOWUP ? (
                        <div className="notification-type-holder">
                            <FollowupQuestionIcon color={iconColor} className="tpw-40 tph-40" />
                        </div>
                    ) : type === "report" ? (
                        <div className="notification-type-holder">
                            <ReportUserIcon color={iconColor} className="tpw-40 tph-40" />
                        </div>
                    ) : null}
                </div>
                <h3 className="notification-info mb0 align-self-center">
                    <span
                        className={`mb0 fw-600 d-block ${
                            unRead ? `clr-${userType}-primary` : null
                        }`}
                    >
                        {title}
                    </span>
                    <span>{shortText}</span>
                </h3>
            </div>
            {unRead ? (
                <div className="notification-action">
                    <CheckIconNormal color={iconColor} className="pointer tpw-40 tph-40" />
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps, null)(NotificationMsg);
