import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { RadioBtn } from "../../components/RadioBtn";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import EarningIcon from "../../components/SvgIcon/EarningIcon";
// import AvatarImg from "../../assets/images/placeholder-profile.jpg";

const DeleteAccountReason = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [radioChecked, setRadioChecked] = useState(false);

    return (
        <div className="tp-settings tp-delete-account tp-delete-reason-list">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Delete Account
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Go back"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner  bg-clr-light tp-delete-reason-inner text-center">
                                <div className="delete-reason-list-options mx-auto">
                                    <Row>
                                        <Col xs={12}>
                                            <div
                                                className={`selected-reason-type-${userType} selected-reason-type d-flex align-items-center justify-content-center`}
                                            >
                                                <EarningIcon
                                                    color={Colors[`${userType}Primary`]}
                                                    bgColor={Colors[`${userType}Secondary`]}
                                                    className="pointer mr4 tpw-56 tph-56"
                                                />
                                                <h2 className="mb0">Earnings</h2>
                                            </div>
                                            <h3 className="mb0 mt4">
                                                Was there anything in particular?
                                            </h3>
                                            <div className="delete-reason d-flex align-items-center justify-content-between bg-white">
                                                <h3 className="mb0">
                                                    Not earning enough on TutorPin
                                                </h3>
                                                <RadioBtn
                                                    className={`tp-radio-btn-${userType}`}
                                                    checked={radioChecked}
                                                    onChange={() => {
                                                        setRadioChecked(!radioChecked);
                                                    }}
                                                />
                                            </div>
                                            <div className="delete-reason d-flex align-items-center justify-content-between bg-white">
                                                <h3 className="mb0">
                                                    Don’t have any or enough students to make it
                                                    worthwhile
                                                </h3>
                                                <RadioBtn
                                                    className={`tp-radio-btn-${userType}`}
                                                    checked={radioChecked}
                                                />
                                            </div>
                                            <div className="delete-reason d-flex align-items-center justify-content-between bg-white">
                                                <h3 className="mb0">Commission is too high </h3>
                                                <RadioBtn
                                                    className={`tp-radio-btn-${userType}`}
                                                    checked={radioChecked}
                                                />
                                            </div>
                                            <div className="delete-reason d-flex align-items-center justify-content-between bg-white">
                                                <h3 className="mb0">
                                                    My students think its too expensive
                                                </h3>
                                                <RadioBtn
                                                    className={`tp-radio-btn-${userType}`}
                                                    checked={radioChecked}
                                                />
                                            </div>
                                            <div className="delete-reason d-flex align-items-center justify-content-between bg-white">
                                                <h3 className="mb0">Other </h3>
                                                <RadioBtn
                                                    className={`tp-radio-btn-${userType}`}
                                                    checked={radioChecked}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="12">
                                            <Button
                                                title="Next"
                                                className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                                onClick={() =>
                                                    history.push(
                                                        getRoute(
                                                            APP_ROUTES.DELETE_ACCOUNT_REASON_DESCRIPTION
                                                        )
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(DeleteAccountReason);
