const TopicIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.75 84.375C18.75 81.8886 19.7377 79.504 21.4959 77.7459C23.254 75.9877 25.6386 75 28.125 75H81.25V12.5H28.125C25.6386 12.5 23.254 13.4877 21.4959 15.2459C19.7377 17.004 18.75 19.3886 18.75 21.875V84.375Z"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.75 84.375V87.5H75"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.375 23.4375H60.9375"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
            />
            <path d="M34.375 34.375H53.125" stroke={color} strokeWidth="3" strokeLinecap="round" />
            <path d="M34.375 46.875H46.875" stroke={color} strokeWidth="3" strokeLinecap="round" />
        </svg>
    );
};
export default TopicIcon;
