const PlayIconCircle = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="78"
            height="78"
            viewBox="0 0 78 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M39 76.5C59.7107 76.5 76.5 59.7107 76.5 39C76.5 18.2893 59.7107 1.5 39 1.5C18.2893 1.5 1.5 18.2893 1.5 39C1.5 59.7107 18.2893 76.5 39 76.5Z"
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M51.5 39L32.75 26.5V51.5L51.5 39Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default PlayIconCircle;
