const DownloadIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="34"
            height="31"
            viewBox="0 0 34 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.5 17H32C32.3315 17 32.6495 17.1317 32.8839 17.3661C33.1183 17.6005 33.25 17.9185 33.25 18.25V28.25C33.25 28.5815 33.1183 28.8995 32.8839 29.1339C32.6495 29.3683 32.3315 29.5 32 29.5H2C1.66848 29.5 1.35054 29.3683 1.11612 29.1339C0.881696 28.8995 0.75 28.5815 0.75 28.25V18.25C0.75 17.9185 0.881696 17.6005 1.11612 17.3661C1.35054 17.1317 1.66848 17 2 17H9.5"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 0.75V17"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.5 9.5L17 17L24.5 9.5"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.375 25.125C27.4105 25.125 28.25 24.2855 28.25 23.25C28.25 22.2145 27.4105 21.375 26.375 21.375C25.3395 21.375 24.5 22.2145 24.5 23.25C24.5 24.2855 25.3395 25.125 26.375 25.125Z"
                fill={color}
            />
        </svg>
    );
};
export default DownloadIcon;
