const SheildCloseIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="128"
            height="134"
            viewBox="0 0 128 134"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.125 49V7.75C2.125 6.25816 2.71763 4.82742 3.77253 3.77253C4.82742 2.71763 6.25816 2.125 7.75 2.125H120.25C121.742 2.125 123.173 2.71763 124.227 3.77253C125.282 4.82742 125.875 6.25816 125.875 7.75V49C125.875 108.073 75.738 127.645 65.727 130.964C64.6079 131.349 63.3921 131.349 62.273 130.964C52.262 127.645 2.125 108.073 2.125 49Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M80.4991 73.4982L47.5008 40.4999"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
            />
            <path
                d="M80.4992 40.4999L47.5009 73.4982"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
            />
        </svg>
    );
};
export default SheildCloseIcon;
