import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { TpModal } from "../../../components/TpModal";
import { Button } from "../../../components/Button";
import CheckIconCircle from "../../../components/SvgIcon/CheckIconCircle";
import { APP_ROUTES } from "../../../helpers/routeHelpers";

const MessageModal = (props) => {
    const {
        userType,
        showDiscardedText = false,
        showQuestionSentText = false,
        text = null,
        showMessageModal,
        handleClick = undefined
    } = props;
    const history = useHistory();

    const getModalText = () => {
        if (showDiscardedText) {
            return "Your question has been dicarded";
        } else if (showQuestionSentText) {
            return "Your question has been sent";
        }
        return "Your question has been saved in draft questions. It will expire in 2 days so make sure to send it before that";
    };

    return (
        <TpModal
            isOpen={showMessageModal}
            centered
            className={`tp-modal-${userType} size-1`}
            children={
                <div className="text-center">
                    <CheckIconCircle type={`${userType}`} />
                    <h3 className="h3 mt7 mb7">{text || getModalText()}</h3>
                    <Button
                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                        title="OK"
                        onClick={
                            handleClick
                                ? handleClick
                                : () => history.push(APP_ROUTES.QUESTION_BOARD)
                        }
                    />
                </div>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(MessageModal);
