import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import { TpModal } from "../../components/TpModal";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import Logo from "../../components/SvgIcon/Logo";
import ProfileIcon from "../../components/SvgIcon/ProfileIcon";
import BellIcon from "../../components/SvgIcon/BellIcon";
import AccountIcon from "../../components/SvgIcon/AccountIcon";
import AddUserIcon from "../../components/SvgIcon/AddUserIcon";
import HelpIcon from "../../components/SvgIcon/HelpIcon";
import LogoutIcon from "../../components/SvgIcon/LogoutIcon";
import DeleteIcon from "../../components/SvgIcon/DeleteIcon";

// import { setSelectedUser } from "../../store/actions";
const Settings = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [logoutModal, setLogoutModal] = useState(false);
    const [deleteAccountModal, setDeleteAccountModal] = useState(false);

    const handleCardClick = (routeName, type) => {
        // setSelectedUser(type);
        history.push(getRoute(routeName));
    };

    const handleDeleteConfirm = () => {
        setLogoutModal(!deleteAccountModal);
        history.push(getRoute(APP_ROUTES.DELETE_ACCOUNT));
    };

    return (
        <div className="tp-settings tp-settings-main">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>Settings</div>
                        <div className="content-inner-wrap">
                            <Row>
                                <Col md={3}>
                                    <Card
                                        title="Profile"
                                        onClick={() => handleCardClick(APP_ROUTES.PROFILE_SETTINGS)}
                                        svg={<ProfileIcon color={Colors[`${userType}Primary`]} />}
                                        className={`mt9 tp-card-md tp-card-${userType}`}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Card
                                        title="Notifications "
                                        onClick={() =>
                                            handleCardClick(APP_ROUTES.NOTIFICATION_SETTINGS)
                                        }
                                        svg={<BellIcon color={Colors[`${userType}Primary`]} />}
                                        className={`mt9 tp-card-md tp-card-${userType}`}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Card
                                        title="Manage Account"
                                        svg={<AccountIcon color={Colors[`${userType}Primary`]} />}
                                        className={`mt9 tp-card-md tp-card-${userType}`}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Card
                                        title="Invite User"
                                        svg={<AddUserIcon color={Colors[`${userType}Primary`]} />}
                                        className={`mt9 tp-card-md tp-card-${userType}`}
                                        onClick={() => handleCardClick(APP_ROUTES.SETTINGS_INVITE)}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Card
                                        title="Help Center"
                                        onClick={() => handleCardClick(APP_ROUTES.HELP_CENTER)}
                                        svg={<HelpIcon color={Colors[`${userType}Primary`]} />}
                                        className={`mt9 tp-card-md tp-card-${userType}`}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Card
                                        title="About TutorPin"
                                        onClick={() => handleCardClick(APP_ROUTES.ABOUT_US)}
                                        svg={<Logo color={Colors[`${userType}Primary`]} />}
                                        className={`mt9 tp-card-md tp-card-${userType}`}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Card
                                        title="Log Out"
                                        onClick={() => setLogoutModal(!logoutModal)}
                                        svg={<LogoutIcon color={Colors[`${userType}Primary`]} />}
                                        className={`mt9 tp-card-md tp-card-${userType}`}
                                    />
                                    <TpModal
                                        isOpen={logoutModal}
                                        centered
                                        className={`tp-modal-${userType} size-3`}
                                        children={
                                            <div className="text-center">
                                                <h3 className="mb9">
                                                    Are you sure you want to log out?
                                                </h3>
                                                <Button
                                                    className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr9`}
                                                    title="No"
                                                    onClick={() => {
                                                        setLogoutModal(!logoutModal);
                                                    }}
                                                />
                                                <Button
                                                    className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                    title="Yes"
                                                    onClick={() =>
                                                        handleCardClick(APP_ROUTES.LOGIN)
                                                    }
                                                />
                                            </div>
                                        }
                                    />
                                </Col>
                                <Col md={3}>
                                    <Card
                                        title="Delete Account"
                                        onClick={() => setDeleteAccountModal(!deleteAccountModal)}
                                        svg={<DeleteIcon color={Colors[`${userType}Primary`]} />}
                                        className={`mt9 tp-card-md tp-card-${userType}`}
                                    />
                                    <TpModal
                                        isOpen={deleteAccountModal}
                                        centered
                                        className={`tp-modal-${userType} size-3`}
                                        children={
                                            <div className="text-center">
                                                <h3 className="mb9">
                                                    Are you sure you want to leave TutorPin?
                                                    <br />
                                                    This will also delete all your data and settings
                                                </h3>
                                                <Button
                                                    className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr9`}
                                                    title="No"
                                                    onClick={() => {
                                                        setDeleteAccountModal(!deleteAccountModal);
                                                    }}
                                                />
                                                <Button
                                                    className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                    title="Yes"
                                                    onClick={handleDeleteConfirm}
                                                />
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(Settings);
