import {
    CHANGE_SOCKET_CONNECTIVITY_STATUS,
    SET_PERSONAL_DETAILS,
    ME_REQUEST,
    ME_SUCCESS,
    ME_ERROR
} from "../actions";

const initialState = {
    tokenVerified: false,
    isLoading: true,
    socketConnected: false,
    auth: {
        selectedUserType: null,
        fullName: null,
        emailAddress: null,
        dateOfBirth: null,
        countryId: null,
        selectedSubjects: null
    }
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case ME_REQUEST:
            return { ...state, isLoading: true };
        case ME_SUCCESS:
            return { ...state, tokenVerified: true };
        case CHANGE_SOCKET_CONNECTIVITY_STATUS:
            return {
                ...state,
                socketConnected: action.payload
            };

        case SET_PERSONAL_DETAILS:
            return {
                ...state,
                auth: { ...state.auth, ...action.payload }
            };
        case ME_ERROR:
            return { ...state, isLoading: false };

        default:
            return state;
    }
};

export default rootReducer;
