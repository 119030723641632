import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { TpModal } from "../../components/TpModal";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import EarningIcon from "../../components/SvgIcon/EarningIcon";
import CheckIconCircle from "../../components/SvgIcon/CheckIconCircle";
// import AvatarImg from "../../assets/images/placeholder-profile.jpg";

const ReportAccountReasonDescription = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [reportAccountConfirmModal, setReportAccountConfirmModal] = useState(false);

    return (
        <div className="tp-settings tp-report-account tp-report-reason-desc">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Report a technical Issue
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Go back"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner bg-clr-light tp-report-reason-inner text-center">
                                <div className="tp-report-reason-list-wrap mx-auto">
                                    <Row>
                                        <Col xs={12}>
                                            <div
                                                className={`selected-reason-type-${userType} selected-reason-type d-flex align-items-center justify-content-center`}
                                            >
                                                <EarningIcon
                                                    color={Colors[`${userType}Primary`]}
                                                    bgColor={Colors[`${userType}Secondary`]}
                                                    className="pointer mr4 tpw-56 tph-56"
                                                />
                                                <h2 className="mb0">Earnings</h2>
                                            </div>
                                            <h3 className="mb0 mt4">
                                                We’re sorry to hear that. Please help us by
                                                describing the issue you are having
                                            </h3>
                                            <div className="delete-reason-desc mt9">
                                                <h3 className="text-start mb3 pl3">
                                                    <span className="fw-600">Issue reported:</span>I
                                                    cant add a new bank account
                                                </h3>
                                                <AvForm className="text-start">
                                                    <TextInput
                                                        type="textarea"
                                                        name="reasondec"
                                                        placeholder="Add your comments"
                                                        className={`size-md tp-text-input-${userType}`}
                                                        options={{
                                                            required: {
                                                                value: true,
                                                                errorMessage:
                                                                    "This field is required"
                                                            }
                                                        }}
                                                    />
                                                    <div className="text-center">
                                                        <Button
                                                            title="Next"
                                                            className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                                            onClick={() => {
                                                                setReportAccountConfirmModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                </AvForm>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <TpModal
                isOpen={reportAccountConfirmModal}
                centered
                className={`tp-modal-${userType} size-1`}
                children={
                    <div className="text-center">
                        <CheckIconCircle type={`${userType}`} />
                        <h3 className="mt9 mb9">
                            Your report has been submitted. We will aim to resolve it within 24
                            hours. You will receive a notification when there’s an update
                        </h3>
                        <Button
                            onClick={() => history.push(getRoute(APP_ROUTES.VIEW_REPORT))}
                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                            title="OK"
                        />
                    </div>
                }
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(ReportAccountReasonDescription);
