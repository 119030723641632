import { Fragment } from "react";

import "./Button.scss";

import BackIcon from "../../components/SvgIcon/BackIcon";

const Button = (props) => {
    const {
        onClick,
        className = "",
        title,
        isLoading = false,
        backIcon = "",
        type = "button",
        counter,
        id,
        backIconColor,
        disabled
    } = props;
    return (
        <button
            disabled={isLoading || disabled}
            onClick={onClick}
            className={`tp-btn ${className} ${backIcon && "tp-btn-back"}`}
            type={type}
            id={id}
        >
            {backIcon && !isLoading && <BackIcon color={backIconColor} />}
            {isLoading ? (
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            ) : (
                <Fragment>
                    {title}
                    {Boolean(counter) && (
                        <span className="counter rounded-circle text-white d-inline-flex align-items-center justify-content-center">
                            {counter}
                        </span>
                    )}
                </Fragment>
            )}
        </button>
    );
};
export default Button;
