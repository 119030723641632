import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import "./DefaultLayout.scss";

import { TopNavbar } from "../../components/TopNavbar";
import { SideNavbar } from "../../components/SideNavbar";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { authRoutes } from "../../routes/AuthRoutes";
import { Spinner } from "../../components/Loader";
import { Page500 } from "../../components/Utility";
import { getMe } from "../../store/actions";

import BgShape from "../../components/SvgIcon/BgShape";

const DefaultLayout = (props) => {
    const { isAuthenticated, tokenVerified, isLoading, userType, user } = props;
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthenticated) {
            if (!tokenVerified) {
                dispatch(getMe());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isAuthenticated) {
        return <Redirect to={getRoute(APP_ROUTES.SELECT_USER)} />;
    }

    let routesToShow = authRoutes.filter((item) => item.userPermission.includes(user.Type));

    return tokenVerified ? (
        <React.Fragment>
            <BgShape type={`${userType}Secondary`} />
            <SideNavbar />
            <main id="tp-main" className="tp-default-layout">
                <TopNavbar />
                <div className="tp-content-wrapper">
                    <Switch>
                        {routesToShow.map((route, index) => {
                            const { path, exact, component } = route;
                            return (
                                <Route
                                    path={path}
                                    exact={exact}
                                    key={index}
                                    component={component}
                                />
                            );
                        })}
                        <Redirect to={APP_ROUTES.DASHBOARD} />
                    </Switch>
                </div>
            </main>
        </React.Fragment>
    ) : isLoading ? (
        <Spinner classes="vh-100" showVerifyText={true} />
    ) : (
        <Page500 />
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isLoading: state.root.isLoading,
        tokenVerified: state.root.tokenVerified,
        userType: state.auth.userType,
        user: state.auth.user
    };
};

export default connect(mapStateToProps, null)(DefaultLayout);
