import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";

import { Slider } from "../Slider";
import { Card } from "../Card";
import { TpDropdownPopup } from "../TpDropdownPopup";
import { Button } from "../Button";
import { TpModal } from "../TpModal";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import "./AddDropdown.scss";
import { Colors } from "../../themes/colors";

import SubjectIcon from "../../components/SvgIcon/SubjectIcon";
import TopicIcon from "../../components/SvgIcon/TopicIcon";
import SocialScienceIcon from "../../components/SvgIcon/SocialScienceIcon";
import ResourceIcon from "../../components/SvgIcon/ResourceIcon";
import LanguagesIcon from "../../components/SvgIcon/LanguagesIcon";
import OtherSubjectIcon from "../../components/SvgIcon/OtherSubjectIcon";
import FoodHealthIcon from "../../components/SvgIcon/FoodHealthIcon";
import PlusIcon from "../../components/SvgIcon/PlusIcon";
import CloseIconCircle from "../../components/SvgIcon/CloseIconCircle";

const AddDropdown = (props) => {
    const history = useHistory();
    const { onClick = () => {}, className = "", userType } = props;
    const [addSubjectModal, setAddSubjectModal] = useState(false);

    const optionsTopicModal = {
        margin: 32,
        items: 3,
        dots: false,
        nav: true,
        center: true,
        loop: true
    };

    return (
        <div className={`tp-add-dropdown ${className}`}>
            <TpDropdownPopup
                className={`size-lg tp-dropdown-popup-${userType}`}
                fancy={true}
                onClick={onClick}
                trigger={
                    <Button
                        className={`tp-btn-primary tp-btn-primary-${userType} ml4`}
                        title={
                            <>
                                <PlusIcon color={Colors.white} className="tpw-24 tph-24 mr1" />
                                <span>Add</span>
                            </>
                        }
                    />
                }
                children={
                    <Row>
                        <Col md={4}>
                            <Card
                                svg={<SubjectIcon color={Colors[`${userType}Primary`]} />}
                                className={`tp-card-xss tp-card-${userType}`}
                                title="Subjects"
                                onClick={() => history.push(getRoute(APP_ROUTES.ADD_SUBJECT))}
                            />
                        </Col>
                        <Col md={4}>
                            <Card
                                svg={<TopicIcon color={Colors[`${userType}Primary`]} />}
                                className={`tp-card-xss tp-card-${userType}`}
                                title="Topics"
                                onClick={() => setAddSubjectModal(true)}
                            />
                        </Col>
                        <Col md={4}>
                            <Card
                                svg={<ResourceIcon color={Colors[`${userType}Primary`]} />}
                                className={`tp-card-xss tp-card-${userType}`}
                                title="Resources"
                                onClick={() => history.push(getRoute(APP_ROUTES.ADD_RESOURCE))}
                            />
                        </Col>
                    </Row>
                }
            />
            <TpModal
                isOpen={addSubjectModal}
                centered
                className={`tp-modal-${userType} size-7`}
                children={
                    <Fragment>
                        <div className="close-btn">
                            <CloseIconCircle
                                className="pointer tpw-40 tph-40"
                                onClick={() => setAddSubjectModal(false)}
                                color={Colors[`${userType}Primary`]}
                            />
                        </div>
                        <h3 className="text-center mb0">Please select a subject</h3>
                        <Slider
                            className={`tp-center-mode-active-big tp-slider-${userType} text-webkit-center`}
                            options={optionsTopicModal}
                            children={
                                <Fragment>
                                    <Card
                                        title="Social Science"
                                        svg={
                                            <SocialScienceIcon
                                                color={Colors[`${userType}Primary`]}
                                            />
                                        }
                                        className={`mt9 tp-card-sm tp-card-${userType}`}
                                    />
                                    <Card
                                        svg={
                                            <OtherSubjectIcon
                                                color={Colors[`${userType}Primary`]}
                                            />
                                        }
                                        className={`mt9 tp-card-sm tp-card-${userType}`}
                                        title="Others"
                                    />
                                    <Card
                                        svg={<LanguagesIcon color={Colors[`${userType}Primary`]} />}
                                        className={`mt9 tp-card-sm tp-card-${userType}`}
                                        title="Languages"
                                    />
                                    <Card
                                        svg={
                                            <FoodHealthIcon color={Colors[`${userType}Primary`]} />
                                        }
                                        className={`mt9 tp-card-sm tp-card-${userType}`}
                                        title="Food & Health"
                                    />
                                </Fragment>
                            }
                        />
                        <div className="text-center">
                            <Button
                                className={`tp-btn-primary tp-btn-primary-${userType} mt4`}
                                title={"Next"}
                                onClick={() => history.push(getRoute(APP_ROUTES.ADD_TOPIC))}
                            />
                        </div>
                    </Fragment>
                }
            />
        </div>
    );
};
export default AddDropdown;
