const SendIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.4398 11.8176L4.94422 2.58007C4.80545 2.50236 4.64626 2.46886 4.48793 2.48404C4.32961 2.49922 4.17969 2.56236 4.05821 2.66503C3.93673 2.76769 3.84948 2.90499 3.80812 3.05857C3.76677 3.21215 3.77327 3.3747 3.82676 3.52448L6.9382 12.2365C6.99888 12.4064 6.99888 12.5921 6.9382 12.762L3.82676 21.474C3.77327 21.6238 3.76677 21.7864 3.80812 21.9399C3.84948 22.0935 3.93673 22.2308 4.05821 22.3335C4.17969 22.4361 4.32961 22.4993 4.48794 22.5145C4.64626 22.5296 4.80545 22.4961 4.94422 22.4184L21.4398 13.1809C21.561 13.113 21.6619 13.0141 21.7321 12.8943C21.8023 12.7745 21.8393 12.6381 21.8393 12.4993C21.8393 12.3604 21.8023 12.224 21.7321 12.1042C21.6619 11.9844 21.561 11.8855 21.4398 11.8176V11.8176Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.03125 12.5H13.2812"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default SendIcon;
