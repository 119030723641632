import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";

const PrivacyPolicy = (props) => {
    const { userType } = props;
    const history = useHistory();

    return (
        <div className="tp-about-us tp-privacy-policy">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Privacy Policy
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to About Us"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />

                            <div className={`scrolling-container scrolling-container-${userType}`}>
                                <div className="content-inner bg-clr-light privacy-policy-inner">
                                    <h2 className="heading">Privacy Policy</h2>
                                    <article>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore, ipsum dolor sit
                                        amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut laboreipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor incididunt ut
                                        laboreipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut laboreipsum dolor sit amet,
                                        consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut laborev
                                    </article>
                                    <article>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore, ipsum dolor sit
                                        amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut laboreipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor incididunt ut
                                        laboreipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut laboreipsum dolor sit amet,
                                        consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut laborev
                                    </article>
                                    <article>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore, ipsum dolor sit
                                        amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut laboreipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor incididunt ut
                                        laboreipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut laboreipsum dolor sit amet,
                                        consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut laborev
                                    </article>
                                    <article>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore, ipsum dolor sit
                                        amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut laboreipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor incididunt ut
                                        laboreipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut laboreipsum dolor sit amet,
                                        consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut laborev
                                    </article>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(PrivacyPolicy);
