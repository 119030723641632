const AddNetworkIconCircle = (props) => {
    const { className, onClick, color, bgColor } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="83"
            height="83"
            viewBox="0 0 83 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="41.5" cy="41.5" r="41.5" fill={bgColor} />
            <path
                d="M18.6758 43.5742H62.2508"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M41.5 20.75V64.325"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default AddNetworkIconCircle;
