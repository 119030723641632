const ZoomInIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.125 14.125H19.125"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.125 27.25C21.3737 27.25 27.25 21.3737 27.25 14.125C27.25 6.87626 21.3737 1 14.125 1C6.87626 1 1 6.87626 1 14.125C1 21.3737 6.87626 27.25 14.125 27.25Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.4062 23.4062L31.0001 31.0001"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default ZoomInIcon;
