import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";

import "./QuestionBoard.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import AskQuestion from "./AskQuestion";
import QuestionAnswerThread from "./QuestionAnswerThread";
import MessageModal from "./AskQuestionModals/MessageModal";
import { TextInput } from "../../components/TextInput";
import { getMediaThumbPath } from "../../utils/mediaService";
import { QuestionTypes } from "../../constants";
import { askQuestion, updateClarityQuestionCount } from "../../store/actions";
import ClarityCountModal from "./AskQuestionModals/ClarityCountModal";

const AskClarityQuestion = (props) => {
    const { userType, user, questionThreads } = props;
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState(false);
    const [showClarityCountModal, setShowClarityCountModal] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();

    const handleRequestClarity = () => {
        setIsLoading(true);
        let payload = {
            Description: description,
            RefId: Number(id),
            Type: QuestionTypes.CLARITY,
            SubjectId: questionThreads[0].SubjectId
        };
        dispatch(askQuestion(payload))
            .then((res) => {
                dispatch(updateClarityQuestionCount());
                setIsLoading(false);
                setShowClarityCountModal(false);
                setShowSuccessModal(true);
            })
            .catch((err) => {
                setShowClarityCountModal(false);
                setIsLoading(false);
            });
    };

    const canShowTextArea = () => {
        if (questionThreads.length) {
            let lastThread = questionThreads[questionThreads.length - 1];
            if (lastThread.QuestionId) {
                return true;
            }
            return false;
        } else return false;
    };

    return (
        <div className="tp-question-board tp-question-details">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Question Board
                        </div>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to answered questions"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner question-details-inner no-padding">
                                <QuestionAnswerThread />
                                {canShowTextArea() && (
                                    <div className="question-details-wrap mt4">
                                        <Row>
                                            <Col md={"auto"}>
                                                <div className="avatar-img">
                                                    <img
                                                        src={getMediaThumbPath(user?.Media)}
                                                        className="img-fluid rounded-circle tpw-50 tph-50 objfit-cover"
                                                    />
                                                </div>
                                                <h4 className="ff-sf-pro-rounded mt1 text-center text-trim tpw-60">
                                                    {user.FullName}
                                                </h4>
                                            </Col>
                                            <Col md={11}>
                                                <div className="question-details">
                                                    <AvForm
                                                        onValidSubmit={() =>
                                                            setShowClarityCountModal(true)
                                                        }
                                                    >
                                                        <div className="answer-add-files-wrap">
                                                            <TextInput
                                                                name="answerquestion"
                                                                type="textarea"
                                                                value={description}
                                                                onChange={(evt) =>
                                                                    setDescription(evt.target.value)
                                                                }
                                                                placeholder="Type your question here"
                                                                className={`bdr-0 radius-7 resize-none tp-text-input-${userType} size-sm`}
                                                                options={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage:
                                                                            "This field is required"
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div class="answer-question-actions mt4 d-flex justify-content-between align-items-center">
                                                            <div />
                                                            <Button
                                                                type="submit"
                                                                title="Request clarity"
                                                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                            />
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ClarityCountModal
                isOpen={showClarityCountModal}
                handleClose={() => setShowClarityCountModal(!showClarityCountModal)}
                handlePress={handleRequestClarity}
            />
            <MessageModal text={`Your clarity has been sent`} showMessageModal={showSuccessModal} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType,
        user: state.auth.user,
        questionThreads: state.questionBoard.questionThreads
    };
};

export default connect(mapStateToProps)(AskClarityQuestion);
