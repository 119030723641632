import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Wallet.scss";
import { Colors } from "../../themes/colors";

import { Slider } from "../../components/Slider";
import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import LanguagesIcon from "../../components/SvgIcon/LanguagesIcon";
import OtherSubjectIcon from "../../components/SvgIcon/OtherSubjectIcon";
import FoodHealthIcon from "../../components/SvgIcon/FoodHealthIcon";

const WalletSubjectSelection = (props) => {
    const { setSelectedUser, isAuthenticated, userType } = props;
    const history = useHistory();
    const options = {
        margin: 102,
        items: 3,
        dots: false,
        nav: true,
        center: true,
        loop: true
    };
    return (
        <div className="tp-wallet tp-wallet-subject-selection">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Request Balance
                        </div>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to wallet"
                                className={`mt9 mb3 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="text-center">
                                <h2 className="ff-sf-pro-rounded mb9">
                                    Which subject would you like to request time for?
                                </h2>
                            </div>
                            <Slider
                                className={`tp-center-mode-active-big tp-slider-${userType}`}
                                options={options}
                                children={
                                    <Fragment>
                                        <Card
                                            title="Others"
                                            svg={
                                                <OtherSubjectIcon
                                                    color={Colors[`${userType}Primary`]}
                                                />
                                            }
                                            className={`mt9 tp-card-xl tp-card-${userType}`}
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.PACKAGES_LIST))
                                            }
                                        />
                                        <Card
                                            svg={
                                                <LanguagesIcon
                                                    color={Colors[`${userType}Primary`]}
                                                />
                                            }
                                            className={`mt9 tp-card-xl tp-card-${userType}`}
                                            title="Languages"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.PACKAGES_LIST))
                                            }
                                        />
                                        <Card
                                            svg={
                                                <FoodHealthIcon
                                                    color={Colors[`${userType}Primary`]}
                                                />
                                            }
                                            className={`mt9 tp-card-xl tp-card-${userType}`}
                                            title="Food & Health"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.PACKAGES_LIST))
                                            }
                                        />
                                    </Fragment>
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    // setSelectedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletSubjectSelection);
