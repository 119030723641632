import { Modal, ModalBody } from "reactstrap";

import "./TpModal.scss";

const TpModal = (props) => {
    const { isOpen, className = "", children, centered = true } = props;
    return (
        <Modal isOpen={isOpen} className={`tp-modal ${className}`} centered={centered}>
            <ModalBody>{children}</ModalBody>
        </Modal>
    );
};
export default TpModal;
