import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./MyNetwork.scss";
import { Colors } from "../../themes/colors";

import { TpDropdownPopup } from "../../components/TpDropdownPopup";
import { AvForm } from "availity-reactstrap-validation";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { TpModal } from "../../components/TpModal";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import { RadioBtn } from "../../components/RadioBtn";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import DecisionModal from "./NetworkModals/DecisionModal";

import CheckIconCircle from "../../components/SvgIcon/CheckIconCircle";
import ArrowSortIcon from "../../components/SvgIcon/ArrowSortIcon";
import ArrowDown from "../../components/SvgIcon/ArrowDown";
import PlusIcon from "../../components/SvgIcon/PlusIcon";
import FilterIcon from "../../components/SvgIcon/FilterIcon";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";

const MyNetwork = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [removeConfirmModal, setRemoveConfirmModal] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [pendingInvitesModal, setPendingInvitesModal] = useState(false);
    const [declineInviteModal, setDeclineInviteModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [removeSuccessModal, setRemoveSuccessModal] = useState(false);
    const [removeAdditionalCommentModal, setRemoveAdditionalCommentModal] = useState(false);

    const [pendingInviteDeclineAlert, setPendingInviteDeclineAlert] = useState(false);
    const [pendingInviteDeclineConfirmModal, setPendingInviteDeclineConfirmModal] = useState(false);
    const [pendingInviteAcceptConfirmModal, setPendingInviteAcceptConfirmModal] = useState(false);

    const handleRemoveConfirmYes = () => {
        setRemoveConfirmModal(false);
        // setRemoveStudentReasonModal(true);
        setRemoveModal(true);
    };

    const handleRemoveModal = () => {
        setShowRemoveModal(false);
        setRemoveModal(true);
    };

    const handleRemoveReason = () => {
        setRemoveModal(false);
        setRemoveSuccessModal(true);
    };

    const handleRemoveSuccess = () => {
        setRemoveSuccessModal(false);
        setRemoveAdditionalCommentModal(true);
    };

    const handlePendingInviteDeclineAlertConfirm = () => {
        setPendingInviteDeclineAlert(false);
        setDeclineInviteModal(true);
    };

    const handlePendingInviteDeclineDescriptionCancel = () => {
        setDeclineInviteModal(false);
        setPendingInviteDeclineAlert(true);
    };

    const handlePendingInviteDeclineDescriptionSubmit = () => {
        setDeclineInviteModal(false);
        setPendingInviteDeclineConfirmModal(true);
    };

    const handlePendingInviteDone = () => {
        setPendingInvitesModal(false);
        setPendingInviteAcceptConfirmModal(true);
    };

    return (
        <div className="tp-my-network tp-my-network-main">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>My Network</div>
                        <div className="content-inner-wrap mt9">
                            <Row className="align-items-center mb4">
                                <Col md={6}>
                                    <div className="d-flex justify-content-end">
                                        <TpDropdownPopup
                                            className={`size-sm mr4 tp-dropdown-popup-${userType}`}
                                            trigger={
                                                <h4 className="ff-sf-pro-rounded mb0">
                                                    <ArrowSortIcon
                                                        color={Colors.black}
                                                        className="mr1 tpw-18 tph-18"
                                                    />
                                                    Sort
                                                </h4>
                                            }
                                            children={
                                                <div className="text-center">
                                                    <Button
                                                        title="A to Z"
                                                        className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border tp-btn-secondary-border-${userType}`}
                                                    />
                                                    <Button
                                                        title="Z to A"
                                                        className={`tp-btn-secondary tp-btn-secondary-${userType} mt4`}
                                                    />
                                                    <Button
                                                        title="Date Added"
                                                        className={`tp-btn-secondary tp-btn-secondary-${userType} mt4`}
                                                    />
                                                </div>
                                            }
                                        />
                                        <TpDropdownPopup
                                            className={`size-sm mr4 tp-dropdown-popup-${userType}`}
                                            trigger={
                                                <h4 className="ff-sf-pro-rounded mb0">
                                                    <FilterIcon
                                                        color={Colors.black}
                                                        className="mr1 tpw-16 tph-16"
                                                    />
                                                    Filter
                                                </h4>
                                            }
                                            children={
                                                <div className="text-center">
                                                    <h5
                                                        className={`mb0 ff-sf-pro-rounded text-start clr-${userType}-primary`}
                                                    >
                                                        Subject
                                                    </h5>
                                                    <Button
                                                        title="Maths"
                                                        className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border tp-btn-secondary-border-${userType} mt2`}
                                                    />
                                                    <Button
                                                        title="English"
                                                        className={`tp-btn-secondary tp-btn-secondary-${userType} mt2`}
                                                    />
                                                    <Button
                                                        title="Biology"
                                                        className={`tp-btn-secondary tp-btn-secondary-${userType} mt2`}
                                                    />
                                                    <h5
                                                        className={`mt4 mb0 ff-sf-pro-rounded text-start clr-${userType}-primary`}
                                                    >
                                                        Name
                                                    </h5>
                                                    <Button
                                                        title="Stuti"
                                                        className={`tp-btn-secondary tp-btn-secondary-${userType} mt2`}
                                                    />
                                                    <Button
                                                        title="Zain"
                                                        className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border tp-btn-secondary-border-${userType} mt2`}
                                                    />
                                                    <Button
                                                        title="Carolina"
                                                        className={`tp-btn-secondary tp-btn-secondary-${userType} mt2`}
                                                    />
                                                    <Button
                                                        title="Riya"
                                                        className={`tp-btn-secondary tp-btn-secondary-${userType} mt2`}
                                                    />
                                                </div>
                                            }
                                        />
                                        <TpDropdownPopup
                                            className={`size-md tp-dropdown-popup-${userType}`}
                                            trigger={
                                                <h4 className="ff-sf-pro-rounded mb0">
                                                    Pending invites
                                                    <span
                                                        className={`bg-clr-${userType}-primary ml1 rounded-circle tpw-18 d-inline-flex aligm-items-center justify-content-center
                                                     tph-18 clr-white
                                                     `}
                                                    >
                                                        5
                                                    </span>
                                                    <ArrowDown
                                                        color={Colors.black}
                                                        className="ml1 tpw-18 tph-18"
                                                    />
                                                </h4>
                                            }
                                            children={
                                                <Fragment>
                                                    <Row className="text-center">
                                                        <Col md={5}>
                                                            <img
                                                                src={AvatarImg}
                                                                className="img-fluid tph-120 tpw-120 objfit-cover rounded-circle"
                                                                alt=""
                                                            />
                                                            <h3 className="mb0 ff-sf-pro-rounded mt4">
                                                                Marly
                                                            </h3>
                                                        </Col>
                                                        <Col md={7}>
                                                            <Button
                                                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                                title="Accept"
                                                                onClick={() => {
                                                                    setPendingInvitesModal(true);
                                                                }}
                                                            />
                                                            <Button
                                                                className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mt4`}
                                                                title="Decline"
                                                                onClick={() => {
                                                                    setPendingInviteDeclineAlert(
                                                                        true
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <span
                                                        className={`sep-1px bg-clr-${userType}-primary`}
                                                    ></span>
                                                    <Row className="text-center">
                                                        <Col md={5}>
                                                            <img
                                                                src={AvatarImg}
                                                                className="img-fluid tph-120 tpw-120 objfit-cover rounded-circle"
                                                                alt=""
                                                            />
                                                            <h3 className="mb0 ff-sf-pro-rounded mt4">
                                                                Marly
                                                            </h3>
                                                        </Col>
                                                        <Col md={7}>
                                                            <Button
                                                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                                title="Accept"
                                                                onClick={() => {
                                                                    setPendingInvitesModal(true);
                                                                }}
                                                            />
                                                            <Button
                                                                className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mt4`}
                                                                title="Decline"
                                                                onClick={() => {
                                                                    setPendingInviteDeclineAlert(
                                                                        true
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                            }
                                        />
                                        <TpModal
                                            isOpen={pendingInviteDeclineAlert}
                                            className={`tp-modal-${userType} size-3`}
                                            children={
                                                <div className="text-center">
                                                    <h3 className="mb9">
                                                        Are you sure that you want decline this
                                                        invite?
                                                    </h3>
                                                    <Button
                                                        onClick={() => {
                                                            setPendingInviteDeclineAlert(false);
                                                        }}
                                                        className={`tp-btn-primary tp-btn-primary-${userType} mr4`}
                                                        title="No"
                                                    />
                                                    <Button
                                                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType}`}
                                                        title="Yes"
                                                        onClick={
                                                            handlePendingInviteDeclineAlertConfirm
                                                        }
                                                    />
                                                </div>
                                            }
                                        />
                                        <TpModal
                                            isOpen={declineInviteModal}
                                            className={`tp-modal-${userType} size-6`}
                                            children={
                                                <AvForm className="text-center">
                                                    <h3 className="mb4">
                                                        Please tell us why you are <br /> declining
                                                        the invite
                                                    </h3>
                                                    <TextInput
                                                        name="addreason"
                                                        placeholder="Add comments here"
                                                        type="textarea"
                                                        className={`size-sm tp-text-input-${userType}`}
                                                        options={{
                                                            required: {
                                                                value: true,
                                                                errorMessage:
                                                                    "This field is required"
                                                            }
                                                        }}
                                                    />
                                                    <Button
                                                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mt9`}
                                                        title="Cancel"
                                                        onClick={
                                                            handlePendingInviteDeclineDescriptionCancel
                                                        }
                                                    />
                                                    <Button
                                                        className={`tp-btn-primary tp-btn-primary-${userType} mt9 ml4`}
                                                        title="Submit"
                                                        onClick={
                                                            handlePendingInviteDeclineDescriptionSubmit
                                                        }
                                                    />
                                                </AvForm>
                                            }
                                        />
                                        <TpModal
                                            isOpen={pendingInviteDeclineConfirmModal}
                                            className={`tp-modal-${userType} size-1`}
                                            children={
                                                <div className="text-center">
                                                    <CheckIconCircle type={`${userType}`} />
                                                    <h3 className="mt9 mb9">
                                                        The invite has been declined
                                                    </h3>
                                                    <Button
                                                        onClick={() => {
                                                            setPendingInviteDeclineConfirmModal(
                                                                false
                                                            );
                                                        }}
                                                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                        title="OK"
                                                    />
                                                </div>
                                            }
                                        />
                                        <TpModal
                                            isOpen={pendingInvitesModal}
                                            className={`tp-modal-${userType} size-5`}
                                            children={
                                                <AvForm>
                                                    <div className="networks-info-wrap mx-auto">
                                                        <div className="network-info mb9">
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <h3 className="mb9 text-center">
                                                                        Please add the subject and
                                                                        the subject hourly rate for
                                                                        the student:
                                                                    </h3>
                                                                </Col>
                                                                <Col md={3}>
                                                                    <span className="d-inline-block text-center">
                                                                        <img
                                                                            src={AvatarImg}
                                                                            className="img-fluid tpw-120 tph-120 objfit-cover rounded-circle"
                                                                            alt=""
                                                                        />
                                                                        <h3 className="mt4 mb0">
                                                                            Stuti
                                                                        </h3>
                                                                    </span>
                                                                </Col>
                                                                <Col md={9}>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <CreatableDropdown
                                                                                placeholder="Select Subject"
                                                                                options={[
                                                                                    {
                                                                                        label: "Math"
                                                                                    },
                                                                                    {
                                                                                        label: "Biology"
                                                                                    },
                                                                                    {
                                                                                        label: "Spanish"
                                                                                    },
                                                                                    {
                                                                                        label: "German"
                                                                                    },
                                                                                    {
                                                                                        label: "Italian"
                                                                                    }
                                                                                ]}
                                                                                className={`mb4 tp-creatable-dropdown-${userType}`}
                                                                                dropIconColor={
                                                                                    Colors[
                                                                                        `${userType}Primary`
                                                                                    ]
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col md={9}>
                                                                            <TextInput
                                                                                name="selectsubject"
                                                                                placeholder="Subject Hourly Rate"
                                                                                options={{
                                                                                    required: {
                                                                                        value: true,
                                                                                        errorMessage:
                                                                                            "This field is required"
                                                                                    }
                                                                                }}
                                                                                className={`tp-text-input-${userType}`}
                                                                            />
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <Button
                                                                                className={`minw-100 tp-btn-add tp-btn-add-${userType}`}
                                                                                title={
                                                                                    <PlusIcon
                                                                                        color={
                                                                                            Colors[
                                                                                                `${userType}Primary`
                                                                                            ]
                                                                                        }
                                                                                    />
                                                                                }
                                                                            ></Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col
                                                                    xs={12}
                                                                    className="text-center"
                                                                >
                                                                    <Button
                                                                        className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                                                        title="Done"
                                                                        onClick={
                                                                            handlePendingInviteDone
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </AvForm>
                                            }
                                        />
                                        <TpModal
                                            isOpen={pendingInviteAcceptConfirmModal}
                                            className={`tp-modal-${userType} size-1`}
                                            children={
                                                <div className="text-center">
                                                    <CheckIconCircle type={`${userType}`} />
                                                    <h3 className="mt9 mb9">
                                                        Zain and Stuti have been added to your
                                                        network
                                                    </h3>
                                                    <Button
                                                        onClick={() => {
                                                            setPendingInviteAcceptConfirmModal(
                                                                false
                                                            );
                                                        }}
                                                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                        title="OK"
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col md={{ size: 5, offset: 1 }}>
                                    <AvForm className="d-flex justify-content-end">
                                        <div className="w-100">
                                            <TextInput
                                                name="search"
                                                placeholder=""
                                                type="search"
                                                className={`mw-100 tp-text-input-${userType}`}
                                            />
                                        </div>
                                        <Button
                                            className={`tp-btn-primary tp-btn-primary-${userType} ml4`}
                                            title={
                                                <>
                                                    <PlusIcon
                                                        color={Colors.white}
                                                        className="tpw-24 tph-24 mr1"
                                                    />
                                                    <span>Send invite</span>
                                                </>
                                            }
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.NETWORK_INVITE))
                                            }
                                        />
                                    </AvForm>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col xs={12}>
                                            <h2
                                                className={`ff-sf-pro-rounded fw-600 mb0 clr-${userType}-primary`}
                                            >
                                                Students
                                            </h2>
                                        </Col>
                                        <Col md={3}>
                                            <span
                                                className={`d-inline-block text-center selected-reason bdr-thin selected-reason-${userType}`}
                                            >
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid tph-120 tpw-120 objfit-cover rounded-circle mt4 selected-reason"
                                                    alt=""
                                                />
                                                <h3 className="mb0 ff-sf-pro-rounded mt4">Marly</h3>
                                            </span>
                                        </Col>
                                        <Col md={3}>
                                            <span className="d-inline-block text-center">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid tph-120 tpw-120 objfit-cover rounded-circle mt4 selected-reason"
                                                    alt=""
                                                />
                                                <h3 className="mb0 ff-sf-pro-rounded mt4">Marly</h3>
                                            </span>
                                        </Col>
                                        <Col md={3}>
                                            <span className="d-inline-block text-center">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid tph-120 tpw-120 objfit-cover rounded-circle mt4 selected-reason"
                                                    alt=""
                                                />
                                                <h3 className="mb0 ff-sf-pro-rounded mt4">Marly</h3>
                                            </span>
                                        </Col>
                                        <Col md={3}>
                                            <span className="d-inline-block text-center">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid tph-120 tpw-120 objfit-cover rounded-circle mt4 selected-reason"
                                                    alt=""
                                                />
                                                <h3 className="mb0 ff-sf-pro-rounded mt4">Marly</h3>
                                            </span>
                                        </Col>
                                        <Col xs={12}>
                                            <h2
                                                className={`ff-sf-pro-rounded fw-600 mb0 mt10 clr-${userType}-primary`}
                                            >
                                                Guardians
                                            </h2>
                                        </Col>
                                        <Col md={3}>
                                            <span className="d-inline-block text-center">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid tph-120 tpw-120 objfit-cover rounded-circle mt4 selected-reason"
                                                    alt=""
                                                />
                                                <h3 className="mb0 ff-sf-pro-rounded mt4">Marly</h3>
                                            </span>
                                        </Col>
                                        <Col md={3}>
                                            <span className="d-inline-block text-center">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid tph-120 tpw-120 objfit-cover rounded-circle mt4 selected-reason"
                                                    alt=""
                                                />
                                                <h3 className="mb0 ff-sf-pro-rounded mt4">Marly</h3>
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={{ size: 5, offset: 1 }}>
                                    <div className="content-inner my-network-info-inner bg-clr-dark radius-15 text-center">
                                        <img
                                            src={AvatarImg}
                                            className="img-fluid thumbnail-rounded mb4"
                                            alt=""
                                        />
                                        <h2
                                            className={`mb4 ff-sf-pro-rounded clr-${userType}-primary`}
                                        >
                                            Jane
                                        </h2>
                                        <h2 className={`mb4 clr-${userType}-primary`}>Guardian:</h2>
                                        <h3 className="mb4">Marly Mcmaster</h3>
                                        <h2 className={`mb4 clr-${userType}-primary`}>Subjects:</h2>
                                        <Row className="align-items-center">
                                            <Col md={6}>
                                                <h3
                                                    className={`bg-clr-${userType}-secondary mb0 radius-14 pt1 pb1 pl1 pr1`}
                                                >
                                                    English - £40/hour
                                                </h3>
                                            </Col>
                                            <Col md={6}>
                                                <h3
                                                    className={`bg-clr-${userType}-secondary mb0 radius-14 pt1 pb1 pl1 pr1`}
                                                >
                                                    Maths - £70/hour
                                                </h3>
                                            </Col>
                                        </Row>
                                        <Button
                                            title="Remove student"
                                            className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-primary tp-btn-secondary-border-${userType} mt9 bg-white`}
                                            onClick={() => {
                                                setRemoveConfirmModal(true);
                                            }}
                                        />
                                        {/* Enable for guardian <h2 className="mb4 clr-tutor-primary">Added On:</h2>
                                        <h3 className="mb0">20-20-2020</h3> */}
                                        <TpModal
                                            isOpen={showRemoveModal}
                                            className={`tp-modal-${userType} size-3`}
                                            children={
                                                <div className="text-center">
                                                    <h3 className="mb9">
                                                        Are you sure you want remove this student?
                                                        {/* 
                                                        change for guardian
                                                        Are you sure you want remove this
                                                        guardian? This will also remove all the
                                                        students associated with the guardian. */}
                                                    </h3>
                                                    <Button
                                                        onClick={() => {
                                                            setShowRemoveModal(false);
                                                        }}
                                                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                                                        title="No"
                                                    />
                                                    <Button
                                                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                        title="Yes"
                                                        onClick={handleRemoveModal}
                                                    />
                                                </div>
                                            }
                                        />
                                        <TpModal
                                            isOpen={removeModal}
                                            className={`tp-modal-${userType} size-2`}
                                            children={
                                                <div className="text-center">
                                                    <h3 className="mb0">
                                                        Could you tell us why you are removing this
                                                        student?
                                                    </h3>
                                                    <div className="d-flex align-items-center mt4">
                                                        <RadioBtn
                                                            checked={true}
                                                            className={`tp-radio-btn-${userType}`}
                                                        />
                                                        <h3 className="mb0 ml1">
                                                            I no longer work with this student
                                                        </h3>
                                                    </div>
                                                    <div className="d-flex align-items-center mt4">
                                                        <RadioBtn
                                                            className={`tp-radio-btn-${userType}`}
                                                        />
                                                        <h3 className="mb0 ml1">
                                                            The student has found another tutor
                                                        </h3>
                                                    </div>
                                                    <div className="d-flex align-items-center mt4">
                                                        <RadioBtn
                                                            className={`tp-radio-btn-${userType}`}
                                                        />
                                                        <h3 className="mb0 ml1">Other</h3>
                                                    </div>
                                                    <Button
                                                        className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                                        title="Submit"
                                                        onClick={handleRemoveReason}
                                                    />
                                                </div>
                                            }
                                        />
                                        <TpModal
                                            isOpen={removeSuccessModal}
                                            className={`tp-modal-${userType} size-1`}
                                            children={
                                                <div className="text-center">
                                                    <CheckIconCircle type={`${userType}`} />
                                                    <h3 className="mt9 mb9">
                                                        The student has been removed
                                                        {/* The guardian has been removed */}
                                                    </h3>
                                                    <Button
                                                        onClick={handleRemoveSuccess}
                                                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                        title="OK"
                                                    />
                                                </div>
                                            }
                                        />
                                        <TpModal
                                            isOpen={removeAdditionalCommentModal}
                                            className={`tp-modal-${userType} size-6`}
                                            children={
                                                <div className="text-center">
                                                    <h3 className="mb9">
                                                        Please tell us more about the problem
                                                    </h3>
                                                    <AvForm>
                                                        <TextInput
                                                            name="senddescription"
                                                            placeholder="Add your comments"
                                                            type="textarea"
                                                            className={`radius-7 resize-none size-md tp-text-input-${userType}`}
                                                            options={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage:
                                                                        "This field is required"
                                                                }
                                                            }}
                                                        />
                                                        <Button
                                                            className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                                            title="Submit"
                                                            onClick={() => {
                                                                setRemoveAdditionalCommentModal(
                                                                    false
                                                                );
                                                            }}
                                                        />
                                                    </AvForm>
                                                </div>
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <DecisionModal
                text="Are you sure you want remove this student?"
                showDecisionModal={removeConfirmModal}
                onNoClick={() => {
                    setRemoveConfirmModal(false);
                }}
                onYesClick={handleRemoveConfirmYes}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(MyNetwork);
