import { useState } from "react";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./SignupInvite.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { TpModal } from "../../components/TpModal";
import { VerifcationCodeInput } from "../../components/VerifcationCodeInput";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { connect } from "react-redux";
import { sendVerificationCode, reSendVerificationCode } from "../../store/actions";
import BgShape from "../../components/SvgIcon/BgShape";

const VerificationCodeConfirmation = ({
    sendVerificationCode,
    reSendVerificationCode,
    deviceUUID,
    mail,
    userType
}) => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isInvited = params.get("isInvited");
    const [sendCode, setSendCode] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);

    const handleResendCode = () => {
        setLoading(true);
        let payload = {
            DeviceUUID: deviceUUID,
            Email: mail
        };
        reSendVerificationCode(payload)
            .then((res) => {
                setSendCode(!sendCode);
            })
            .catch((err) => {
                setSendCode(sendCode);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleNext = (completeCode = null) => {
        setLoading(true);
        let payload = {
            DeviceUUID: deviceUUID,
            Code: completeCode || code
        };
        sendVerificationCode(payload)
            .then((res) => {
                setCodeError(false);
                setLoading(false);
                if (isInvited) {
                    history.replace({
                        pathname: getRoute(APP_ROUTES.PERSONAL_DETAILS),
                        search: "?isInvited=true"
                    });
                } else {
                    history.replace(getRoute(APP_ROUTES.SUBJECT_SELECTION));
                }
            })
            .catch((err) => {
                setCodeError(true);
                setLoading(false);
            });
    };

    const handleChange = (e) => {
        setCode(e);
        if (codeError) {
            setCodeError(false);
        }
    };

    const handleComplete = (e) => {
        setCode(e);
        handleNext(e);
    };
    if (!deviceUUID && !isInvited) {
        return <Redirect to={APP_ROUTES.PERSONAL_DETAILS} />;
    } else if (!deviceUUID && isInvited) {
        return <Redirect to={APP_ROUTES.ENTER_EMAIL} />;
    }

    return (
        <div className="tp-verification-code-confirmation full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12}>
                        <div className="confirmation-wrap text-center mt9 mx-auto">
                            <h1 className="mb0">
                                {codeError
                                    ? "Please try the verification code again"
                                    : "Please enter the verification code"}
                            </h1>
                            <VerifcationCodeInput
                                className={`tp-verification-code-input-wrap-${userType} mx-auto w-100 mt9 ${
                                    codeError && "error"
                                }`}
                                width={50}
                                height={67}
                                value={code}
                                onChange={handleChange}
                                onComplete={handleComplete}
                            />
                        </div>
                    </Col>
                    <Col xs={12} className="text-center">
                        <Button
                            onClick={handleResendCode}
                            loading={loading}
                            className={`clr-${userType}-primary text-decoration-underline mt9`}
                            title={"Resend verification code"}
                        />
                        <TpModal
                            isOpen={sendCode}
                            centered
                            className={`tp-modal-${userType} size-3`}
                            children={
                                <div className="text-center">
                                    <h3 className="mb0">
                                        We have re-sent a verification code to:
                                        <span className="d-block mt4 mb4 fw-bold">{mail}</span>
                                        If you do not receive the verification code within a few
                                        minutes, please check your spam or junk email folder.
                                    </h3>
                                    <Button
                                        onClick={() => setSendCode(!sendCode)}
                                        className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                        title="OK"
                                    />
                                </div>
                            }
                        />
                    </Col>
                    <Col xs={12} className="text-center">
                        <Button
                            title="Next"
                            className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                            onClick={handleNext}
                            isLoading={loading}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
const mapDispatchToProps = {
    sendVerificationCode,
    reSendVerificationCode
};

const mapStateToProps = (state) => {
    const deviceUUID = state.deviceId.deviceUUID;
    const mail = state.root.auth.emailAddress;
    return {
        deviceUUID,
        mail,
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(VerificationCodeConfirmation);
