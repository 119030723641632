const GradientCircle = (props) => {
    const { className, startColor, endColor, id } = props;

    return (
        <svg style={{ height: 0 }} className={className}>
            <defs>
                <linearGradient id={id}>
                    <stop offset="0%" stopColor={startColor} />
                    <stop offset="100%" stopColor={endColor} />
                </linearGradient>
            </defs>
        </svg>
    );
};
export default GradientCircle;
