import { Fragment } from "react";
import "./ToggleBtn.scss";

const ToggleBtn = (props) => {
    const { checked = false, value = null, onChange = () => {}, className = "" } = props;

    return (
        <Fragment>
            <div className={`tp-toggle-switch-btn d-flex ${className}`}>
                <input
                    className={checked ? "switched" : ""}
                    type="checkbox"
                    checked={checked}
                    onChange={() => onChange(value)}
                    id="switch-toggle"
                />
                <label for="switch-toggle"></label>
            </div>
        </Fragment>
    );
};
export default ToggleBtn;
