const UsabilityIcon = (props) => {
    const { className, onClick, color, bgColor } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="150"
            height="150"
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="75" cy="75" r="75" fill={bgColor} />
            <path
                d="M63.9629 41.3308C64.8757 35.4136 74.3811 35.5395 74.5385 41.8344C74.3811 35.5395 64.9071 35.4136 63.9629 41.3308Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M63.963 41.3311C63.963 56.4705 63.963 71.6414 63.963 86.7809C60.4693 82.0282 57.007 77.2754 53.5133 72.5227C48.7291 65.9759 39.9476 72.5227 44.0708 78.6603C47.4701 84.2629 50.8694 89.8654 54.2687 95.468C60.1545 107.208 68.9675 113.062 80.7077 113C108.5 111.363 108.5 94.146 106.171 69.4382C106.014 63.1432 96.5396 63.0173 95.5953 68.9346C95.4379 62.6396 85.964 62.5137 85.0197 68.431C84.8623 62.136 75.3884 62.0101 74.4441 67.9274C74.4441 59.2088 74.4441 50.5217 74.4441 41.8032"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default UsabilityIcon;
