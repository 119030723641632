const KnowledgeBankCircleIcon = (props) => {
    const { className, onClick, color, bgColor } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="150"
            height="150"
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="75" cy="75" r="75" fill={bgColor} />
            <path
                d="M72.7472 49.0025C72.4234 47.9232 70.9124 44.3616 67.1349 42.3109C64.9763 41.1237 62.8177 40.9079 61.6305 40.7999C50.9456 40.7999 40.1527 40.7999 29.4678 40.7999V101.456H58.2847C59.1482 101.456 60.1195 101.564 63.2494 102.319"
                stroke={color}
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M116.027 79.978V40.7999C105.343 40.7999 94.5496 40.7999 83.8647 40.7999C82.6775 40.7999 80.6268 41.1237 78.3603 42.3109C74.5828 44.4695 73.0718 48.0311 72.748 49.0025V74.1499"
                stroke={color}
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M116.136 121.853L105.667 111.492"
                stroke={color}
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M92.0673 116.674C102.737 116.674 111.387 108.024 111.387 97.3545C111.387 86.6848 102.737 78.0353 92.0673 78.0353C81.3976 78.0353 72.748 86.6848 72.748 97.3545C72.748 108.024 81.3976 116.674 92.0673 116.674Z"
                fill={bgColor}
                stroke={color}
                strokeWidth="4"
                strokeMiterlimit="10"
            />
        </svg>
    );
};
export default KnowledgeBankCircleIcon;
