import { Fragment, useState } from "react";

import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import DateRangeCalendar from "../../components/DateRangeCalendar/DateRangeCalendar";
import { Button } from "../Button";
import { TpModal } from "../TpModal";

import "./UserStatus.scss";
import { Colors } from "../../themes/colors";

import RocketIcon from "../../components/SvgIcon/RocketIcon";
import CheckIconCircle from "../../components/SvgIcon/CheckIconCircle";
import CloseIconCircle from "../../components/SvgIcon/CloseIconCircle";

const UserStatus = (props) => {
    const { onClick = () => {}, className = "", userType } = props;
    const [statusConfirmModal, setStatusConfirmModal] = useState(true);
    const [statusCalendarModal, setStatusCalendarModal] = useState(false);
    const [statusCalendarConfirm, setStatusCalendarConfirm] = useState(false);
    const [statusConfirmedModal, setstatusConfirmedModal] = useState(false);

    const handleStatusChange = () => {
        setStatusConfirmModal(!statusConfirmModal);
        setStatusCalendarModal(!statusCalendarModal);
    };

    const handleConfirmSelectDate = () => {
        setStatusCalendarConfirm(!statusCalendarConfirm);
    };

    const handleChangeStatus = () => {
        setStatusCalendarModal(!statusCalendarModal);
        setstatusConfirmedModal(!statusConfirmedModal);
    };

    return (
        <div className={`tp-status-modal ${className}`}>
            <TpModal
                isOpen={statusConfirmModal}
                centered
                className={`tp-modal-${userType} size-3`}
                children={
                    <div className="text-center">
                        <h3 className="mb0">Are you sure you want to set your status as away?</h3>
                        <h3 className="mt4 mb9">
                            Note: We only recommend changing your status if you will be away for
                            more than 2 days. If you are changing your status immediately, this will
                            delete all unanswered questions.
                        </h3>
                        <Button
                            className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                            title="Cancel"
                            onClick={() => {
                                setStatusConfirmModal(false);
                            }}
                        />
                        <Button
                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                            title="Change status"
                            onClick={handleStatusChange}
                        />
                    </div>
                }
            />
            <TpModal
                isOpen={statusCalendarModal}
                centered
                className={`tp-modal-${userType} size-3`}
                children={
                    <div className="text-center">
                        <div className="close-btn">
                            <CloseIconCircle
                                className="pointer tpw-40 tph-40"
                                onClick={() => {
                                    setStatusCalendarModal(!statusCalendarModal);
                                }}
                                color={Colors[`${userType}Primary`]}
                            />
                        </div>
                        <RocketIcon color={Colors[`${userType}Primary`]} />
                        <h3 className="mt9 mb0">
                            {statusCalendarConfirm
                                ? "Is this correct?"
                                : "Please enter the dates you will be away"}
                        </h3>
                        <div className="calendar-range-wrap">
                            {statusCalendarConfirm ? (
                                <AvForm className="mt4">
                                    <TextInput
                                        name="statusfrom"
                                        placeholder=""
                                        options={{
                                            required: {
                                                value: true,
                                                errorMessage: "This field is required"
                                            }
                                        }}
                                        className={`tp-text-input-${userType}`}
                                    />
                                    <h3 className="date-range-to mt2 mb2">to</h3>
                                    <TextInput
                                        name="statusto"
                                        placeholder=""
                                        options={{
                                            required: {
                                                value: true,
                                                errorMessage: "This field is required"
                                            }
                                        }}
                                        className={`tp-text-input-${userType}`}
                                    />
                                </AvForm>
                            ) : (
                                <DateRangeCalendar className="mt4" />
                            )}
                        </div>
                        {statusCalendarConfirm ? (
                            <Fragment>
                                <Button
                                    className={`mr4 mt9 tp-btn-primary-inverse tp-btn-primary-inverse-${userType}`}
                                    title="Edit dates"
                                    onClick={() => {
                                        setStatusCalendarConfirm(!statusCalendarConfirm);
                                    }}
                                />
                                <Button
                                    className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                    title="Change status"
                                    onClick={handleChangeStatus}
                                />
                            </Fragment>
                        ) : (
                            <Button
                                className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                title="Confirm"
                                onClick={handleConfirmSelectDate}
                            />
                        )}
                    </div>
                }
            />
            <TpModal
                isOpen={statusConfirmedModal}
                centered
                className={`tp-modal-${userType} size-1`}
                children={
                    <div className="text-center">
                        <CheckIconCircle type={`${userType}`} />
                        <h3 className="h3 mt9 mb9">Your status has been changed to away</h3>
                        <Button
                            onClick={() => {
                                setstatusConfirmedModal(!statusConfirmedModal);
                            }}
                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                            title="OK"
                        />
                    </div>
                }
            />
        </div>
    );
};
export default UserStatus;
