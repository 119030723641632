const FilterIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.48 16.5368H19M2.68 10L1 10.044M2.68 10C2.68 11.3255 3.75451 12.4 5.08 12.4C6.40548 12.4 7.48 11.3255 7.48 10C7.48 8.67452 6.40548 7.6 5.08 7.6C3.75451 7.6 2.68 8.67452 2.68 10ZM8.16898 10.0441H19M10.801 3.55124L1 3.55124M19 3.55124H16.48M1 16.5368H10.801M15.88 16.6C15.88 17.9255 14.8055 19 13.48 19C12.1545 19 11.08 17.9255 11.08 16.6C11.08 15.2745 12.1545 14.2 13.48 14.2C14.8055 14.2 15.88 15.2745 15.88 16.6ZM15.88 3.4C15.88 4.72548 14.8055 5.8 13.48 5.8C12.1545 5.8 11.08 4.72548 11.08 3.4C11.08 2.07452 12.1545 1 13.48 1C14.8055 1 15.88 2.07452 15.88 3.4Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};
export default FilterIcon;
