import { connect } from "react-redux";

import "../QuestionBoard.scss";

import { Button } from "../../../components/Button";
import { TpModal } from "../../../components/TpModal";

const ClarityCountModal = (props) => {
    const { userType, isLoading, handlePress, isOpen, user, handleClose } = props;

    const getClarityCount = () => {
        if (user.AllowedClarityQuestion === 3) {
            return "first";
        } else if (user.AllowedClarityQuestion === 2) {
            return "second";
        } else if (user.AllowedClarityQuestion === 1) {
            return "last";
        }
    };

    const getModalText = () => {
        if (user.AllowedClarityQuestion < 1) {
            return "You can make up to 3 clarity requests for every 10 questions, you have reached your limit";
        }
        return `This is your ${getClarityCount()} clarity request, you can make up to 3 clarity requests for every 10 questions`;
    };

    const handleOkPress = () => {
        if (user.AllowedClarityQuestion < 1) {
            handleClose();
            return;
        }
        handlePress();
    };

    return (
        <TpModal
            isOpen={isOpen}
            centered
            className={`tp-modal-${userType} size-3`}
            children={
                <div className="text-center">
                    <h3 className="mb5">{getModalText()}</h3>
                    <Button
                        isLoading={isLoading}
                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                        onClick={handleOkPress}
                        title="OK"
                    />
                </div>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType,
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(ClarityCountModal);
