import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Card } from "../../components/Card";
import { Button } from "../../components/Button";

import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import HelpIcon from "../../components/SvgIcon/HelpIcon";
import Faq from "../../components/SvgIcon/FaqIcon";

// import { setSelectedUser } from "../../store/actions";
const HelpCenter = (props) => {
    const { userType } = props;
    const history = useHistory();

    const handleCardClick = (routeName, type) => {
        // setSelectedUser(type);
        history.push(getRoute(routeName));
    };

    return (
        <div className="tp-settings tp-help-center">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>Help Center</div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to settings"
                                className={`mt9 mb3 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <h1 className="mb0 text-center">How can we help you?</h1>
                            <Row className="text-webkit-center">
                                <Col md={6}>
                                    <Card
                                        title="FAQ’s"
                                        onClick={() => handleCardClick(APP_ROUTES.FAQ)}
                                        className={`mt9 tp-card-lg tp-card-${userType}`}
                                        svg={<Faq color={Colors[`${userType}Primary`]} />}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Card
                                        title="Report"
                                        onClick={() => handleCardClick(APP_ROUTES.REPORT)}
                                        className={`mt9 tp-card-lg tp-card-${userType}`}
                                        svg={<HelpIcon color={Colors[`${userType}Primary`]} />}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(HelpCenter);
