const AllEarningBg = (props) => {
    const { className, onClick } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="670"
            height="201"
            viewBox="0 0 670 201"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M148.659 16.4167C106.684 -44.4486 32.0636 78.5063 0 147.592V200L669.5 200.796V148.388C669.5 148.388 651 138.908 626.5 132.908C602 126.908 549.472 165.306 504 153.5C458.528 141.694 449.473 130.539 377.768 147.592C306.062 164.645 201.126 92.4983 148.659 16.4167Z"
                fill="url(#paint0_linear)"
                fill-opacity="0.1"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="609.5"
                    y1="0"
                    x2="609.5"
                    y2="200"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#06729D" />
                    <stop offset="0.963542" stop-color="#06729D" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};
export default AllEarningBg;
