var Config = {
    LIMIT: "10",
    PERSIST_SECRET_KEY: "!!tutorpin$$",
    ENVIRONMENT: "DEVELOPMENT",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "https://dev-api.tutorpin.stackfrost.com"
        },
        DEVELOPMENT: {
            API_URL: "https://dev-api.tutorpin.stackfrost.com"
        },
        QA: {
            API_URL: "https://dev-api.tutorpin.stackfrost.com"
        },
        STAGING: {
            API_URL: "https://dev-api.tutorpin.stackfrost.com"
        },
        PRODUCTION: {
            API_URL: "https://dev-api.tutorpin.stackfrost.com"
        }
    }
};

Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;