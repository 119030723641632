const ScribbleLoopIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="36"
            height="28"
            viewBox="0 0 36 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.75 8.5C0.75 8.5 5.75 1.5 13.25 1.5C29.5 1.5 28.25 26.5 13.25 26.5C3.25 26.5 3.25 11.5 18.25 11.5C28.25 11.5 34.5 19 34.5 19"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default ScribbleLoopIcon;
