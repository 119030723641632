import { useHistory, useLocation, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./Signup.scss";
import { Colors } from "../../themes/colors";
import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { connect } from "react-redux";
import BgShape from "../../components/SvgIcon/BgShape";
import IconHome from "../../components/SvgIcon/IconHome";
import AddUser from "../../components/SvgIcon/AddUserIcon";

const WelcomeScreen = ({ isAuthenticated, userType }) => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isInvited = params.get("isInvited");
    const handleClick = () => {
        history.push({
            pathname: getRoute(APP_ROUTES.USER_INVITE),
            search: isInvited ? "?isInvited=true" : ""
        });
    };

    if (!isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: getRoute(APP_ROUTES.SETUP_PASSWORD),
                    search: isInvited ? "?isInvited=true" : ""
                }}
            />
        );
    }
    return (
        <div className="tp-welcome-screen full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12}>
                        <h1 className="mb0 mt9 text-center">
                            <span className="d-block mb4">
                                Its official! Welcome to Tutorpin Zain!
                            </span>
                            <span>What would you like to do first?</span>
                        </h1>
                    </Col>
                    <Col md={{ size: 5, offset: 1 }}>
                        <Card
                            svg={<IconHome color={Colors[`${userType}Primary`]} />}
                            title="Go to Dashboard"
                            onClick={() => history.push(getRoute(APP_ROUTES.DASHBOARD))}
                            className={`mt9 tp-card-lg tp-card-${userType}`}
                        />
                    </Col>
                    <Col md={{ size: 5, offset: 1 }}>
                        <Card
                            svg={<AddUser color={Colors[`${userType}Primary`]} />}
                            title="Invite User"
                            onClick={handleClick}
                            className={`mt9 tp-card-lg tp-card-${userType}`}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps)(WelcomeScreen);
