import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./KnowledgeBank.scss";
import { Colors } from "../../themes/colors";

import { Slider } from "../../components/Slider";
import { Card } from "../../components/Card";
import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { AddDropdown } from "../../components/AddDropdown";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import SocialScienceIcon from "../../components/SvgIcon/SocialScienceIcon";
import LanguagesIcon from "../../components/SvgIcon/LanguagesIcon";
import OtherSubjectIcon from "../../components/SvgIcon/OtherSubjectIcon";
import FoodHealthIcon from "../../components/SvgIcon/FoodHealthIcon";

const KnowledgeBank = (props) => {
    const { setSelectedUser, isAuthenticated, userType } = props;
    const history = useHistory();

    const options = {
        margin: 102,
        items: 3,
        dots: false,
        nav: true,
        center: true,
        loop: true
    };

    return (
        <div className="tp-knowledge-bank tp-knowledge-bank-main">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Knowledge Bank
                        </div>
                        <div className="content-inner-wrap mt9">
                            <AvForm className="d-flex justify-content-end">
                                <TextInput
                                    name="search"
                                    placeholder=""
                                    type="search"
                                    className={`bdr-0 tp-text-input-${userType}`}
                                    onClick={() => history.push(getRoute(APP_ROUTES.SEARCH_RESULT))}
                                />
                                <AddDropdown userType={userType} />
                            </AvForm>
                            <Slider
                                className={`tp-center-mode-active-big tp-slider-${userType}`}
                                options={options}
                                children={
                                    <Fragment>
                                        <Card
                                            title="Social Science"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.TOPIC_LISTINGS))
                                            }
                                            svg={
                                                <SocialScienceIcon
                                                    color={Colors[`${userType}Primary`]}
                                                />
                                            }
                                            className={`mt10 tp-card-xl tp-card-${userType}`}
                                        />
                                        <Card
                                            svg={
                                                <OtherSubjectIcon
                                                    color={Colors[`${userType}Primary`]}
                                                />
                                            }
                                            className={`mt10 tp-card-xl tp-card-${userType}`}
                                            title="Others"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.TOPIC_LISTINGS))
                                            }
                                        />
                                        <Card
                                            svg={
                                                <LanguagesIcon
                                                    color={Colors[`${userType}Primary`]}
                                                />
                                            }
                                            className={`mt10 tp-card-xl tp-card-${userType}`}
                                            title="Languages"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.TOPIC_LISTINGS))
                                            }
                                        />
                                        <Card
                                            svg={
                                                <FoodHealthIcon
                                                    color={Colors[`${userType}Primary`]}
                                                />
                                            }
                                            className={`mt10 tp-card-xl tp-card-${userType}`}
                                            title="Food & Health"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.TOPIC_LISTINGS))
                                            }
                                        />
                                    </Fragment>
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    // setSelectedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBank);
