import { UserTypes } from "../constants";
import { APP_ROUTES } from "../helpers/routeHelpers";
import {
    ChooseUser,
    SignupTerms,
    PersonalDetails,
    SubjectSelection,
    VideoIntroduction,
    ProfilePicture,
    SetupPassword,
    WelcomeScreen,
    UserInvite,
    GuardianInviteForm,
    StudentInviteForm,
    InviteConfirmation,
    EnterEmail,
    VerificationCodeConfirmation,
    ConfirmNetworks,
    NetworkDetails,
    Login,
    ForgotPassword,
    ResetPassword,
    Settings,
    ProfileSettings,
    ChangeEmail,
    ChangeEmailCodeVerify,
    SetupNewEmail,
    NotificationSettings,
    AboutUs,
    TermsAndConditions,
    PrivacyPolicy,
    AboutTutorPin,
    HelpCenter,
    Faq,
    Report,
    DeleteAccount,
    CodeConfirmation,
    DeleteAccountReason,
    DeleteAccountReasonDescription,
    MyReports,
    ReportTechnicalIssues,
    ViewReport,
    ReportUsers,
    QuestionBoard,
    QuestionsList,
    QuestionDetails,
    AnswerQuestion,
    TopicListings,
    SearchResult,
    ResourceDetails,
    AddSubject,
    MyNetwork,
    NetworkInvite,
    Dashboard,
    TodoList,
    StudentNetworkInvite,
    GuardianNetworkInvite,
    EarningBilling,
    EarningBillingReport,
    Wallet,
    WalletSubjectSelection,
    PackagesList,
    KnowledgeBank,
    TopicResourceListings,
    AddResource,
    AddResourceUploadView,
    AddResourceUploadViewDetails,
    AddTopic,
    SettingsInvite,
    SettingsInviteGuardian,
    SettingsInviteStudent,
    GuardianUsers,
    ReportTechnicalReason,
    ReportTechnicalReasonDescription,
    ReportUserReason,
    ReportUserReasonDescription,
    EditResourceImage,
    AskQuestion,
    AskFollowUpQuestion,
    AskClarityQuestion,
    ChooseLoginUser
} from "../views";

export const unAuthRoutes = [
    {
        path: APP_ROUTES.LOGIN,
        exact: true,
        component: Login
    },
    {
        path: APP_ROUTES.SELECT_USER,
        exact: true,
        component: ChooseLoginUser
    },
    {
        path: APP_ROUTES.FORGOT_PASSWORD,
        exact: true,
        component: ForgotPassword
    },
    {
        path: APP_ROUTES.RESET_PASSWORD,
        exact: true,
        component: ResetPassword
    },
    {
        path: APP_ROUTES.CHOOSE_USER,
        exact: true,
        component: ChooseUser
    },
    {
        path: APP_ROUTES.SIGNUP_TERMS,
        exact: true,
        component: SignupTerms
    },
    {
        path: APP_ROUTES.PERSONAL_DETAILS,
        exact: true,
        component: PersonalDetails
    },
    {
        path: APP_ROUTES.SUBJECT_SELECTION,
        exact: true,
        component: SubjectSelection
    },
    {
        path: APP_ROUTES.VIDEO_INTRODUCTION,
        exact: true,
        component: VideoIntroduction
    },
    {
        path: APP_ROUTES.PROFILE_PICTURE,
        exact: true,
        component: ProfilePicture
    },
    {
        path: APP_ROUTES.SETUP_PASSWORD,
        exact: true,
        component: SetupPassword
    },
    {
        path: APP_ROUTES.WELCOME_SCREEN,
        exact: true,
        component: WelcomeScreen
    },
    {
        path: APP_ROUTES.USER_INVITE,
        exact: true,
        component: UserInvite
    },
    {
        path: APP_ROUTES.GUARDIAN_INVITE_FORM,
        exact: true,
        component: GuardianInviteForm
    },
    {
        path: APP_ROUTES.STUDENT_INVITE_FORM,
        exact: true,
        component: StudentInviteForm
    },
    {
        path: APP_ROUTES.INVITE_CONFIRMATION,
        exact: true,
        component: InviteConfirmation
    },
    {
        path: APP_ROUTES.ENTER_EMAIL,
        exact: true,
        component: EnterEmail
    },
    {
        path: APP_ROUTES.VERIFICATION_CODE_CONFIRMATION,
        exact: true,
        component: VerificationCodeConfirmation
    },
    {
        path: APP_ROUTES.RESET_PASSWORD_CODE_CONFIRMATION,
        exact: true,
        component: CodeConfirmation
    },
    {
        path: APP_ROUTES.CONFIRM_NETWORKS,
        exact: true,
        component: ConfirmNetworks
    },
    {
        path: APP_ROUTES.NETWORKS_DETAILS,
        exact: true,
        component: NetworkDetails
    }
];

export const authRoutes = [
    {
        path: APP_ROUTES.SETTINGS,
        exact: true,
        component: Settings,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.PROFILE_SETTINGS,
        exact: true,
        component: ProfileSettings,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.CHANGE_EMAIL,
        exact: true,
        component: ChangeEmail,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.CHANGE_EMAIL_CODE_VERIFICATION,
        exact: true,
        component: ChangeEmailCodeVerify,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.SETUP_NEW_EMAIL,
        exact: true,
        component: SetupNewEmail,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.NOTIFICATION_SETTINGS,
        exact: true,
        component: NotificationSettings,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.ABOUT_US,
        exact: true,
        component: AboutUs,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.TERMS_AND_CONDITIONS,
        exact: true,
        component: TermsAndConditions,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.PRIVACY_POLICY,
        exact: true,
        component: PrivacyPolicy,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.ABOUT_TUTORPIN,
        exact: true,
        component: AboutTutorPin,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.HELP_CENTER,
        exact: true,
        component: HelpCenter,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.FAQ,
        exact: true,
        component: Faq,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.REPORT,
        exact: true,
        component: Report,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.DELETE_ACCOUNT,
        exact: true,
        component: DeleteAccount,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.DELETE_ACCOUNT_REASON,
        exact: true,
        component: DeleteAccountReason,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.DELETE_ACCOUNT_REASON_DESCRIPTION,
        exact: true,
        component: DeleteAccountReasonDescription,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.MY_REPORTS,
        exact: true,
        component: MyReports,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.REPORT_TECHNICAL_ISSUES,
        exact: true,
        component: ReportTechnicalIssues,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.VIEW_REPORT,
        exact: true,
        component: ViewReport,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.REPORT_USERS,
        exact: true,
        component: ReportUsers,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.QUESTION_BOARD,
        exact: true,
        component: QuestionBoard,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT, UserTypes.GUARDIAN]
    },
    {
        path: APP_ROUTES.GUARDIAN_USERS,
        exact: true,
        component: GuardianUsers,
        userPermission: [UserTypes.GUARDIAN]
    },
    {
        path: APP_ROUTES.QUESTIONS_LIST,
        exact: true,
        component: QuestionsList,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT, UserTypes.GUARDIAN]
    },
    {
        path: APP_ROUTES.QUESTION_DETAILS,
        exact: true,
        component: QuestionDetails,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT, UserTypes.GUARDIAN]
    },
    {
        path: APP_ROUTES.ASK_FOLLOW_UP_QUESTION,
        exact: true,
        component: AskFollowUpQuestion,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.ASK_CLARITY_QUESTION,
        exact: true,
        component: AskClarityQuestion,
        userPermission: [UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.ANSWERED_QUESTION_DETAILS,
        exact: true,
        component: QuestionDetails,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT, UserTypes.GUARDIAN]
    },
    {
        path: APP_ROUTES.ANSWER_QUESTION,
        exact: true,
        component: AnswerQuestion,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.ASK_QUESTION,
        exact: true,
        component: AskQuestion,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.DRAFT_QUESTION,
        exact: true,
        component: AskQuestion,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.TOPIC_LISTINGS,
        exact: true,
        component: TopicListings,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.SEARCH_RESULT,
        exact: true,
        component: SearchResult,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.RESOURCE_DETAILS,
        exact: true,
        component: ResourceDetails,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.ADD_SUBJECT,
        exact: true,
        component: AddSubject,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.MY_NETWORK,
        exact: true,
        component: MyNetwork,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.NETWORK_INVITE,
        exact: true,
        component: NetworkInvite,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.GUARDIAN_NETWORK_INVITE_FORM,
        exact: true,
        component: GuardianNetworkInvite,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.STUDENT_NETWORK_INVITE_FORM,
        exact: true,
        component: StudentNetworkInvite,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.TODOLIST,
        exact: true,
        component: TodoList,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.DASHBOARD,
        exact: true,
        component: Dashboard,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT, UserTypes.GUARDIAN]
    },
    {
        path: APP_ROUTES.EARNING_AND_BILLING,
        exact: true,
        component: EarningBilling,
        userPermission: [UserTypes.TUTOR]
    },
    {
        path: APP_ROUTES.EARNING_AND_BILLING_REPORT,
        exact: true,
        component: EarningBillingReport,
        userPermission: [UserTypes.TUTOR]
    },
    {
        path: APP_ROUTES.WALLET,
        exact: true,
        component: Wallet,
        userPermission: [UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.WALLET_SUBJECT_SELECTION,
        exact: true,
        component: WalletSubjectSelection,
        userPermission: [UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.PACKAGES_LIST,
        exact: true,
        component: PackagesList,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.KNOWLEDGE_BANK,
        exact: true,
        component: KnowledgeBank,
        userPermission: [UserTypes.TUTOR]
    },
    {
        path: APP_ROUTES.TOPIC_RESOURCE_LISTINGS,
        exact: true,
        component: TopicResourceListings,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.ADD_RESOURCE,
        exact: true,
        component: AddResource,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.ADD_RESOURCE_UPLOAD_VIEW,
        exact: true,
        component: AddResourceUploadView,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.ADD_RESOURCE_UPLOAD_VIEW_DETAILS,
        exact: true,
        component: AddResourceUploadViewDetails,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.ADD_TOPIC,
        exact: true,
        component: AddTopic,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.SETTINGS_INVITE,
        exact: true,
        component: SettingsInvite,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.SETTINGS_INVITE_GUARDIAN,
        exact: true,
        component: SettingsInviteGuardian,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.SETTINGS_INVITE_STUDENT,
        exact: true,
        component: SettingsInviteStudent,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    },
    {
        path: APP_ROUTES.REPORT_TECHNICAL_REASON,
        exact: true,
        component: ReportTechnicalReason,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT, UserTypes.GUARDIAN]
    },
    {
        path: APP_ROUTES.REPORT_USER_REASON,
        exact: true,
        component: ReportUserReason,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT, UserTypes.GUARDIAN]
    },
    {
        path: APP_ROUTES.REPORT_TECHNICAL_REASON_DESCRIPTION,
        exact: true,
        component: ReportTechnicalReasonDescription,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT, UserTypes.GUARDIAN]
    },
    {
        path: APP_ROUTES.REPORT_USER_REASON_DESCRIPTION,
        exact: true,
        component: ReportUserReasonDescription,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT, UserTypes.GUARDIAN]
    },
    {
        path: APP_ROUTES.EDIT_RESOURCE_IMAGE,
        exact: true,
        component: EditResourceImage,
        userPermission: [UserTypes.TUTOR, UserTypes.STUDENT]
    }
];
