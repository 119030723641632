const LogoutIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="120"
            height="131"
            viewBox="0 0 120 131"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M117.748 23.75L2.24805 23.75"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.75 2.75H86.25"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M107.25 23.75V123.5C107.25 124.892 106.697 126.228 105.712 127.212C104.728 128.197 103.392 128.75 102 128.75H18C16.6076 128.75 15.2723 128.197 14.2877 127.212C13.3031 126.228 12.75 124.892 12.75 123.5V23.75"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default LogoutIcon;
