const BagIcon = (props) => {
    const { className, onClick, color, bgColor } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="160"
            height="160"
            viewBox="0 0 160 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M135 45H25C22.2386 45 20 47.2386 20 50V130C20 132.761 22.2386 135 25 135H135C137.761 135 140 132.761 140 130V50C140 47.2386 137.761 45 135 45Z"
                fill={bgColor}
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M55 65V45C55 38.3696 57.6339 32.0107 62.3223 27.3223C67.0107 22.6339 73.3696 20 80 20C86.6304 20 92.9893 22.6339 97.6777 27.3223C102.366 32.0107 105 38.3696 105 45V65"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default BagIcon;
