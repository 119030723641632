import { Fragment } from "react";
import { connect } from "react-redux";

import "./TodoItem.scss";
import { Colors } from "../../themes/colors";

import { TpDropdownPopup } from "../TpDropdownPopup";
import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../TextInput";
import { Button } from "../Button";

import DeleteIcon from "../SvgIcon/DeleteIcon";
import ArrowDown from "../SvgIcon/ArrowDown";
import AddUserIcon from "../SvgIcon/AddUserIcon";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";

const TodoItem = (props) => {
    const {
        text,
        className = "",
        date,
        onClick = () => {},
        done,
        user,
        action,
        actionList,
        editing,
        editdone,
        deleteTask,
        userType
    } = props;
    return (
        <div
            className={`tp-todo-item d-flex w-100 ${className} ${
                editing ? "todo-item-edit" : deleteTask ? "" : "align-items-center"
            }`}
            onClick={onClick}
        >
            {user && (
                <div className="todo-list-user mr2 text-center">
                    <img
                        src={AvatarImg}
                        className="tpw-50 tph-50 rounded-circle objfit-cover"
                        alt=""
                    />
                    <h5 className="mt1 mb0 ff-sf-pro-rounded">Jane</h5>
                </div>
            )}
            <div className="todo-list-details w-100">
                {editing ? (
                    <Fragment>
                        <AvForm className="h-100 todo-edit-form">
                            <TextInput
                                name="addtask"
                                type="textarea"
                                placeholder="Type Task Here"
                                className={`bdr-0 rounded-0 p-0 bg-transparent resize-none w-100 h-100 clr-black tp-text-input-${userType}`}
                            />
                            <Button
                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                title="Done"
                            />
                        </AvForm>
                    </Fragment>
                ) : (
                    <Fragment>
                        <h6 className="post-date mb1 opacity-50">{date}</h6>
                        <h3
                            className={`todo-text mb0 clr-${userType}-primary ${
                                done && "text-decoration-line-through"
                            }`}
                        >
                            <span className={`clr-black`}>{text}</span>
                        </h3>
                        {editdone && (
                            <Button
                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                title="Done"
                            />
                        )}
                        {deleteTask && (
                            <Button
                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                title="Send to question board"
                            />
                        )}
                    </Fragment>
                )}
            </div>
            <div
                className={`todo-list-actions ml9 ${editing && "align-self-center"} ${
                    editdone && "align-self-start"
                }`}
            >
                {editing && (
                    <TpDropdownPopup
                        className={`size-xs tp-dropdown-popup-${userType}`}
                        trigger={
                            <AddUserIcon
                                color={Colors[`${userType}Primary`]}
                                className="tpw-40 tph-40 pointer"
                            />
                        }
                        children={
                            <Fragment>
                                <h5 className={`mb2 ff-sf-pro-rounded clr-${userType}-primary`}>
                                    Stuti
                                </h5>
                                <h5 className={`mb2 ff-sf-pro-rounded clr-${userType}-primary`}>
                                    Zain
                                </h5>
                                <h5 className={`mb0 ff-sf-pro-rounded clr-${userType}-primary`}>
                                    Carolina
                                </h5>
                            </Fragment>
                        }
                    />
                )}
                {deleteTask && (
                    <div className="todo-list-action-details d-flex">
                        <DeleteIcon className="tpw-40 tph-40 pointer" color={Colors.black} />
                    </div>
                )}

                {actionList && (
                    <div className="todo-list-action-details d-flex align-items-center">
                        <TpDropdownPopup
                            className={`mr3 size-xs tp-dropdown-popup-${userType}`}
                            trigger={
                                <Fragment>
                                    <img
                                        src={AvatarImg}
                                        className="tpw-40 tph-40 rounded-circle objfit-cover mb1"
                                        alt=""
                                    />
                                    <h4 className="ff-sf-pro-rounded mb0 d-flex">
                                        Stuti
                                        <ArrowDown
                                            color={Colors[`${userType}Primary`]}
                                            className="ml1 tpw-16 tph-16"
                                        />
                                    </h4>
                                </Fragment>
                            }
                            children={
                                <Fragment>
                                    <h5 className={`mb2 ff-sf-pro-rounded clr-${userType}-primary`}>
                                        Stuti
                                    </h5>
                                    <h5 className={`mb2 ff-sf-pro-rounded clr-${userType}-primary`}>
                                        Zain
                                    </h5>
                                    <h5 className={`mb0 ff-sf-pro-rounded clr-${userType}-primary`}>
                                        Carolina
                                    </h5>
                                </Fragment>
                            }
                        />

                        <DeleteIcon className="tpw-40 tph-40 pointer" color={Colors.black} />
                    </div>
                )}
                {action && (
                    <div className="todo-list-action-trigger text-center">
                        <img
                            src={AvatarImg}
                            alt=""
                            className="tpw-40 tph-40 rounded-circle objfit-cover"
                        />
                        <h5 className="mt1 mb0 ff-sf-pro-rounded">Stuti</h5>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(TodoItem);
