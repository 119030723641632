const UploadIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.1875 12.5H21.875C22.0822 12.5 22.2809 12.5823 22.4274 12.7288C22.5739 12.8753 22.6562 13.074 22.6562 13.2812V19.5312C22.6562 19.7385 22.5739 19.9372 22.4274 20.0837C22.2809 20.2302 22.0822 20.3125 21.875 20.3125H3.125C2.9178 20.3125 2.71909 20.2302 2.57257 20.0837C2.42606 19.9372 2.34375 19.7385 2.34375 19.5312V13.2812C2.34375 13.074 2.42606 12.8753 2.57257 12.7288C2.71909 12.5823 2.9178 12.5 3.125 12.5H7.8125"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 12.5V2.34375"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.8125 7.03125L12.5 2.34375L17.1875 7.03125"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3594 17.5781C19.0066 17.5781 19.5312 17.0535 19.5312 16.4062C19.5312 15.759 19.0066 15.2344 18.3594 15.2344C17.7122 15.2344 17.1875 15.759 17.1875 16.4062C17.1875 17.0535 17.7122 17.5781 18.3594 17.5781Z"
                fill={color}
            />
        </svg>
    );
};
export default UploadIcon;
