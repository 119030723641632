import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { TpModal } from "../../../components/TpModal";
import { Button } from "../../../components/Button";
import { APP_ROUTES } from "../../../helpers/routeHelpers";

const DecisionModal = (props) => {
    const {
        userType,
        text = null,
        showDecisionModal,
        onYesClick = undefined,
        onNoClick = undefined
    } = props;
    const history = useHistory();

    return (
        <TpModal
            isOpen={showDecisionModal}
            className={`tp-modal-${userType} size-3`}
            children={
                <div className="text-center">
                    <h3 className="mb9">{text}</h3>
                    <Button
                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                        title="No"
                        onClick={onNoClick ? onNoClick : () => history.push(APP_ROUTES.MY_NETWORK)}
                    />
                    <Button
                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                        title="Yes"
                        onClick={
                            onYesClick ? onYesClick : () => history.push(APP_ROUTES.MY_NETWORK)
                        }
                    />
                </div>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(DecisionModal);
