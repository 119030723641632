const AddUserIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="166"
            height="157"
            viewBox="0 0 166 157"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M122.375 22.9375H161.75"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M142.062 3.25V42.625"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.41797 154.18C11.4862 140.214 23.0875 128.618 37.0564 120.556C51.0253 112.494 66.8698 108.25 82.9982 108.25C99.1267 108.25 114.971 112.495 128.94 120.557C142.909 128.62 154.51 140.216 162.578 154.181"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M132.261 73.9468C128.751 83.4221 122.581 91.6826 114.491 97.736C106.4 103.789 96.7346 107.378 86.6542 108.071C76.5737 108.765 66.5076 106.532 57.6649 101.643C48.8223 96.7543 41.5793 89.4161 36.8061 80.5104C32.0329 71.6047 29.9324 61.5103 30.7571 51.4397C31.5818 41.3692 35.2966 31.751 41.4551 23.7405C47.6136 15.73 55.954 9.66799 65.4742 6.28269C74.9945 2.89738 85.2897 2.33278 95.123 4.65672"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default AddUserIcon;
