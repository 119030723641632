import React from "react";
import { Spinner } from "reactstrap";

const CustomSpinner = (props) => {
    const {
        classes = "",
        size = "md",
        color = "primary",
        showVerifyText = false,
        text = "Verifying",
        showDataNotAvailableText = false,
        isLoading = true,
        textMessage = "No Data Available"
    } = props;
    return (
        <div
            className={`tp-loader d-flex flex-column justify-content-center align-items-center w-100 ${classes}`}
            style={{ height: "calc(65vh)" }}
        >
            {isLoading && <Spinner size={size} color={color} />}
            {showVerifyText && <p>{text}</p>}
            {showDataNotAvailableText && <p>{textMessage}</p>}
        </div>
    );
};

export default CustomSpinner;
