import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import Logo from "../../components/SvgIcon/Logo";
import SheildIcon from "../../components/SvgIcon/SheildCheckIcon";
import DocumentIcon from "../../components/SvgIcon/DocumentIcon";

// import { setSelectedUser } from "../../store/actions";
const AboutUs = (props) => {
    const { userType } = props;
    const history = useHistory();

    const handleCardClick = (routeName, type) => {
        // setSelectedUser(type);
        history.push(getRoute(routeName));
    };

    return (
        <div className="tp-about-us">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            About TutorPin
                        </div>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to Settings"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <Row>
                                <Col md={4}>
                                    <Card
                                        title="Terms & Conditions"
                                        onClick={() =>
                                            handleCardClick(APP_ROUTES.TERMS_AND_CONDITIONS)
                                        }
                                        svg={<DocumentIcon color={Colors[`${userType}Primary`]} />}
                                        className={`tp-card-lg tp-card-${userType}`}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Card
                                        className={`tp-card-lg tp-card-${userType}`}
                                        title="Privacy Policy"
                                        onClick={() => handleCardClick(APP_ROUTES.PRIVACY_POLICY)}
                                        svg={<SheildIcon color={Colors[`${userType}Primary`]} />}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Card
                                        title="About TutorPin"
                                        onClick={() => handleCardClick(APP_ROUTES.ABOUT_TUTORPIN)}
                                        className={`tp-card-lg tp-card-${userType}`}
                                        svg={<Logo color={Colors[`${userType}Primary`]} />}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(AboutUs);
