import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import ReportUserIcon from "../../components/SvgIcon/ReportUserIcon";
import ReportIcon from "../../components/SvgIcon/ReportIcon";
import ReportTechnicalIcon from "../../components/SvgIcon/ReportTechnicalIcon";

// import { setSelectedUser } from "../../store/actions";

const Report = (props) => {
    const { userType } = props;
    const history = useHistory();

    const handleCardClick = (routeName, type) => {
        // setSelectedUser(type);
        history.push(getRoute(routeName));
    };

    return (
        <div className="tp-settings tp-reports">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>Report</div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to help center"
                                className={`mt9 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <Row>
                                <Col md={4}>
                                    <Card
                                        title="My Reports"
                                        onClick={() => handleCardClick(APP_ROUTES.MY_REPORTS)}
                                        svg={<ReportIcon color={Colors[`${userType}Primary`]} />}
                                        className={`mt9 tp-card-lg tp-card-${userType}`}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Card
                                        title="Report a User"
                                        onClick={() => handleCardClick(APP_ROUTES.REPORT_USERS)}
                                        svg={
                                            <ReportUserIcon color={Colors[`${userType}Primary`]} />
                                        }
                                        className={`mt9 tp-card-lg tp-card-${userType}`}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Card
                                        title="Report a technical Issue"
                                        onClick={() =>
                                            handleCardClick(APP_ROUTES.REPORT_TECHNICAL_ISSUES)
                                        }
                                        svg={
                                            <ReportTechnicalIcon
                                                color={Colors[`${userType}Primary`]}
                                            />
                                        }
                                        className={`mt9 tp-card-lg tp-card-${userType}`}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(Report);
