const documentTypes = [
    "pdf"
    // "doc",
    // "docx",
    // "xls",
    // "xlsx",
    // "ppt",
    // "pptx",
    // "txt",
    // "csv",
    // "vnd.ms-excel",
    // "rtf"
];
const videoTypes = [
    "webm",
    "mpg",
    "mpeg",
    "mpe",
    "mpv",
    "ogg",
    "mp4",
    "m4p",
    "m4v",
    "avi",
    "wmv",
    "mov",
    "qt",
    "flv",
    "swf",
    "avchd"
];
const audioType = ["M4A", "MP3", "WAV", "FLAC"];

const imageType = ["jpg", "jpeg", "png", "gif"];

export default { documentTypes, videoTypes, audioType, imageType };
