import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { TpModal } from "../../components/TpModal";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import CheckIconCircle from "../../components/SvgIcon/CheckIconCircle";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";

const ReportUserReasonDescription = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [reportUserAlertModal, setReportUserAlertModal] = useState(false);
    const [reportUserConfirmModal, setReportUserConfirmModal] = useState(false);

    const handleReportAlertYes = () => {
        setReportUserAlertModal(false);
        setReportUserConfirmModal(true);
    };

    return (
        <div className="tp-settings tp-report-account tp-report-reason-desc">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Report a User
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Go back"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner bg-clr-light tp-report-reason-inner text-center">
                                <div className="tp-report-reason-list-wrap mx-auto">
                                    <Row>
                                        <Col xs={12}>
                                            <div
                                                className={`selected-reason-type selected-reason-type-${userType}`}
                                            >
                                                <h3 className="mb0">What are you reporting?</h3>
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid pointer mt9 thumbnail-rounded"
                                                    alt=""
                                                />
                                            </div>
                                            <h3 className="mb0 mt4 ff-sf-pro-rounded">Carolina</h3>
                                            <div className="delete-reason-desc mt9">
                                                <h3 className="text-start mb3 pl3">
                                                    <span className="fw-600">Issue reported:</span>I
                                                    cant add a new bank account
                                                </h3>
                                                <AvForm className="text-start">
                                                    <TextInput
                                                        type="textarea"
                                                        name="reasondec"
                                                        placeholder="Add your comments"
                                                        className={`size-md tp-text-input-${userType}`}
                                                        options={{
                                                            required: {
                                                                value: true,
                                                                errorMessage:
                                                                    "This field is required"
                                                            }
                                                        }}
                                                    />
                                                    <div className="text-center">
                                                        <Button
                                                            title="Next"
                                                            className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                                            onClick={() => {
                                                                setReportUserAlertModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                </AvForm>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <TpModal
                isOpen={reportUserAlertModal}
                centered
                className={`tp-modal-${userType} size-3`}
                children={
                    <div className="text-center">
                        <h3 className="mb9">
                            Are you sure you want to submit this report?
                            <br />
                            Note: The user will be blocked effective immediately until TutorPin
                            reviews and resolves your complaint.
                        </h3>
                        <Button
                            className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr9`}
                            title="No"
                            onClick={() => {
                                setReportUserAlertModal(false);
                            }}
                        />
                        <Button
                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                            title="Yes"
                            onClick={handleReportAlertYes}
                        />
                    </div>
                }
            />
            <TpModal
                isOpen={reportUserConfirmModal}
                centered
                className={`tp-modal-${userType} size-1`}
                children={
                    <div className="text-center">
                        <CheckIconCircle type={`${userType}`} />
                        <h3 className="mt9 mb9">
                            Your report has been submitted. We will aim to resolve it within 24
                            hours. You will receive a notification when there’s an update.
                        </h3>
                        <Button
                            onClick={() => history.push(getRoute(APP_ROUTES.VIEW_REPORT))}
                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                            title="OK"
                        />
                    </div>
                }
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(ReportUserReasonDescription);
