import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import EarningIcon from "../../components/SvgIcon/EarningIcon";

const ViewReport = (props) => {
    const { userType } = props;
    const history = useHistory();

    return (
        <div className="tp-settings tp-view-report">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Report a technical Issue
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to report"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner bg-clr-light tp-view-report-inner">
                                <Row>
                                    <Col
                                        xs={"auto"}
                                        className={`selected-reason selected-reason-${userType}`}
                                    >
                                        <EarningIcon
                                            color={Colors[`${userType}Primary`]}
                                            bgColor={Colors[`${userType}Secondary`]}
                                        />
                                        {/* enable this text for user report 
                                        <h3 className="ff-sf-pro-rounded mb0 text-center mt3">Carolina</h3>*/}
                                    </Col>
                                    <Col>
                                        <div className="report-info">
                                            <h2 className="ff-sf-pro-rounded mb4">Earnings</h2>
                                            <h3 className="mb4">
                                                <span className="fw-600">Report Number:</span>
                                                123344456
                                            </h3>
                                            <h3 className="mb4">
                                                <span className="fw-600">Reported on:</span> 20
                                                march 2021, at 10:00 am
                                            </h3>
                                            <h3 className="mb0">
                                                <span className="fw-600">Type of report:</span> I
                                                cant add a new bank account
                                            </h3>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="report-details mt9">
                                            <h3 className="mb0">
                                                <span
                                                    className={`d-block mb4 fw-600 clr-${userType}-primary`}
                                                >
                                                    Description:
                                                </span>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                                elit, sed do eiusmod tempor incididunt ut labore,
                                                ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut laboreipsum
                                                dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut laboreipsum dolor sit
                                                amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut laboreipsum dolor sit amet,
                                                consectetur adipiscing elit, sed do eiusmod tempor
                                                incididunt ut laborev ut laboreipsum dolor sit amet,
                                                consectetur adipiscing elit, sed do eiusmod tempor
                                                incididunt ut laboreipsum dolor sit amet,
                                                consectetur adipiscing elit, sed do eiusmod tempor
                                                incididunt ut laboreipsum dolor sit amet,
                                                consectetur adipiscing elit, sed do eiusmod.
                                            </h3>
                                        </div>
                                    </Col>
                                    <Col xs={12} className="text-center">
                                        <Button
                                            title="OK"
                                            className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.SETTINGS))
                                            }
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(ViewReport);
