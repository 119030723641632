import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./MyNetwork.scss";
import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { TpModal } from "../../components/TpModal";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import { RadioBtn } from "../../components/RadioBtn";

import PlusIcon from "../../components/SvgIcon/PlusIcon";
import CheckIconCircle from "../../components/SvgIcon/CheckIconCircle";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";

const StudentNetworkInvite = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [inviteConfirmModal, setInviteConfirmModal] = useState(false);
    const [selectedSubjects, setSelectedSubjects] = useState([{}]);
    const handleAddSubject = () => {
        let arr = [...selectedSubjects];

        arr.push({});

        setSelectedSubjects(arr);
    };

    return (
        <div className="tp-my-network tp-network-invite-student">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>Send Invite</div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to send invite"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className={`scrolling-container scrolling-container-${userType}`}>
                                <div className="content-inner network-invite-student-inner bg-clr-light">
                                    <AvForm>
                                        <h3 className="mb0 text-center">
                                            Please enter the student’s details
                                        </h3>
                                        <div className="mt9">
                                            <TextInput
                                                className={`bdr-0 tp-text-input-${userType}`}
                                                name="studentname"
                                                placeholder="Student’s Name"
                                                options={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "This field is required"
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="mt4">
                                            <TextInput
                                                className={`bdr-0 tp-text-input-${userType}`}
                                                name="studentemail"
                                                placeholder="Student’s Email Address"
                                                type="email"
                                                options={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "This field is required"
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="subject-select-dropdown">
                                            <Row>
                                                <Col>
                                                    {selectedSubjects.map((item, index) => {
                                                        return (
                                                            <Row
                                                                className="align-items-end mt4"
                                                                key={index}
                                                            >
                                                                <Col>
                                                                    <CreatableDropdown
                                                                        placeholder="Select Subject"
                                                                        options={[
                                                                            {
                                                                                label: "Math"
                                                                            },
                                                                            {
                                                                                label: "Biology"
                                                                            },
                                                                            {
                                                                                label: "Spanish"
                                                                            },
                                                                            {
                                                                                label: "German"
                                                                            },
                                                                            {
                                                                                label: "Italian"
                                                                            }
                                                                        ]}
                                                                        className={`bdr-none tp-creatable-dropdown-${userType}`}
                                                                        dropIconColor={
                                                                            Colors[
                                                                                `${userType}Primary`
                                                                            ]
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <TextInput
                                                                        className={`bdr-0 tp-text-input-${userType}`}
                                                                        name={`selectsubject-${index}`}
                                                                        placeholder="Subject Hourly Rate"
                                                                        options={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage:
                                                                                    "This field is required"
                                                                            }
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                                </Col>
                                                <Col md={"auto"}>
                                                    <Button
                                                        className={`mt4 bdr-0 tp-btn-add tp-btn-add-${userType}`}
                                                        onClick={handleAddSubject}
                                                        title={
                                                            <PlusIcon
                                                                color={Colors[`${userType}Primary`]}
                                                            />
                                                        }
                                                    ></Button>
                                                </Col>
                                            </Row>
                                        </div>
                                        <h3 className="mt4">Please enter the guardian details</h3>
                                        <div className="mt4">
                                            <TextInput
                                                className={`bdr-0 tp-text-input-${userType}`}
                                                name="guardianname"
                                                placeholder="Guardian’s Name"
                                                options={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "This field is required"
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="mt4">
                                            <TextInput
                                                className={`bdr-0 tp-text-input-${userType}`}
                                                name="guardianemail"
                                                placeholder="Guardian’s Email Address"
                                                type="email"
                                                options={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "This field is required"
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center mt4">
                                            <RadioBtn
                                                className={`tp-radio-btn-${userType}`}
                                                checked={true}
                                            />
                                            <h3 className="mb0 ml1">
                                                Is the guardian already in your network?
                                            </h3>
                                        </div>
                                        <div className="mt4">
                                            <CreatableDropdown
                                                placeholder="Choose Guardian"
                                                options={[
                                                    {
                                                        label: "Math"
                                                    },
                                                    {
                                                        label: "Biology"
                                                    },
                                                    {
                                                        label: "Spanish"
                                                    },
                                                    {
                                                        label: "German"
                                                    },
                                                    {
                                                        label: "Italian"
                                                    }
                                                ]}
                                                className={`bdr-none tp-creatable-dropdown-${userType}`}
                                                dropIconColor={Colors[`${userType}Primary`]}
                                            />
                                        </div>
                                        <div className="mt4">
                                            <h3 className="mb4">
                                                Please enter the guardian’s details
                                            </h3>
                                            <span className="d-inline-block text-center">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid tph-120 tpw-120 objfit-cover rounded-circle"
                                                    alt=""
                                                />
                                                <h6 className="mb0 ff-sf-pro-rounded mt4">Marly</h6>
                                            </span>
                                        </div>
                                        <div className="text-center">
                                            <Button
                                                title="Send Invite"
                                                className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                                onClick={() => {
                                                    setInviteConfirmModal(true);
                                                }}
                                            />
                                        </div>
                                    </AvForm>
                                    <TpModal
                                        isOpen={inviteConfirmModal}
                                        centered
                                        className={`tp-modal-${userType} size-1`}
                                        children={
                                            <div className="text-center">
                                                <CheckIconCircle type={`${userType}`} />
                                                <h3 className="mt9 mb9">
                                                    An email invite has been sent to the guardian.
                                                    They will be added to your network once they
                                                    have accepted the invite
                                                </h3>
                                                <Button
                                                    onClick={() => {
                                                        setInviteConfirmModal(false);
                                                    }}
                                                    className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                    title="OK"
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(StudentNetworkInvite);
