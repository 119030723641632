const MuteIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="42"
            height="40"
            viewBox="0 0 42 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M37.2622 23.9279L29.4054 16.0711" stroke={color} strokeLinecap="round" />
            <path d="M37.2625 16.0711L29.4058 23.9279" stroke={color} strokeLinecap="round" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.9588 8.37365C20.5888 7.74368 21.6659 8.18985 21.6659 9.08075L21.6659 30.919C21.6659 31.8099 20.5888 32.2561 19.9588 31.6261L13.3326 24.9999L5.99926 24.9999C5.44697 24.9999 4.99926 24.5522 4.99926 23.9999L4.99926 15.9999C4.99926 15.4476 5.44697 14.9999 5.99926 14.9999L13.3326 14.9999L19.9588 8.37365Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.334 21.6663C24.175 20.8253 24.175 19.174 23.334 18.333"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};
export default MuteIcon;
