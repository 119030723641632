import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import DefaultLayout from "./views/DefaultLayout/DefaultLayout";
import { unAuthRoutes } from "./routes/AuthRoutes";
import { APP_ROUTES } from "./helpers/routeHelpers";

function App() {
    return (
        <React.Fragment>
            <Switch>
                <Route path={APP_ROUTES.DASHBOARD} name="DefaultLayout" component={DefaultLayout} />
                {unAuthRoutes.map((route, index) => {
                    const { path, exact, component } = route;
                    return <Route path={path} exact={exact} key={index} component={component} />;
                })}
                <Redirect to={APP_ROUTES.SELECT_USER} />
            </Switch>
            <ToastContainer
                toastClassName="toast-container"
                position="bottom-right"
                autoClose={2500}
                hideProgressBar
                closeOnClick
                draggable
                pauseOnHover
                closeButton={false}
            />
        </React.Fragment>
    );
}

export default App;
