import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./Signup.scss";
import { Colors } from "../../themes/colors";
import { ListItem } from "../../components/ListItem";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import BgShape from "../../components/SvgIcon/BgShape";
import { connect } from "react-redux";

const SignupTerms = (props) => {
    const { userType } = props;
    const history = useHistory();
    return (
        <div className="tp-signup-terms full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12}>
                        <div className="list-wrap mx-auto mt9">
                            <ListItem
                                iconColor={Colors[`${userType}Primary`]}
                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labor"
                                className="mb4"
                            />
                            <ListItem
                                iconColor={Colors[`${userType}Primary`]}
                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labor"
                                className="mb4"
                            />
                            <ListItem
                                iconColor={Colors[`${userType}Primary`]}
                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labor"
                            />
                        </div>
                    </Col>
                    <Col xs={12} className="text-center">
                        <Button
                            title="Next"
                            className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                            onClick={() => history.push(getRoute(APP_ROUTES.PERSONAL_DETAILS))}
                        />
                    </Col>
                    <Col xs={12} className="text-center">
                        <h3 className="consent-text mb0 mt4">
                            By continuing you agree to our{" "}
                            <span className={`clr-${userType}-primary`}>
                                {" "}
                                terms and conditions{" "}
                            </span>
                            and <span className={`clr-${userType}-primary`}> privacy policy.</span>
                        </h3>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(SignupTerms);
