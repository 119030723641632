const PlayIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="29"
            height="34"
            viewBox="0 0 29 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M28 17L1 32.5885L1 1.41154L28 17Z" stroke={color} strokeWidth="2" />
        </svg>
    );
};
export default PlayIcon;
