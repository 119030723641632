const CameraIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="180"
            height="180"
            viewBox="0 0 180 180"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="90" cy="90" r="90" fill="#F7F8FC" />
            <path
                d="M130.312 127.312H54.6875C52.6818 127.312 50.7582 126.516 49.34 125.097C47.9218 123.679 47.125 121.756 47.125 119.75V66.8125C47.125 64.8068 47.9218 62.8833 49.34 61.465C50.7582 60.0468 52.6818 59.25 54.6875 59.25H69.8097L77.3722 47.9062H107.622L115.185 59.25H130.312C132.318 59.25 134.242 60.0468 135.66 61.465C137.078 62.8833 137.875 64.8068 137.875 66.8125V119.75C137.875 121.756 137.078 123.679 135.66 125.097C134.242 126.516 132.318 127.312 130.312 127.312Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M92.5 108.406C101.897 108.406 109.516 100.788 109.516 91.3906C109.516 81.9932 101.897 74.375 92.5 74.375C83.1025 74.375 75.4844 81.9932 75.4844 91.3906C75.4844 100.788 83.1025 108.406 92.5 108.406Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default CameraIcon;
