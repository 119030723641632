import { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import "./SignupInvite.scss";
import { Button } from "../../components/Button";
import { Colors } from "../../themes/colors";
import { TpModal } from "../../components/TpModal";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";
import BgShape from "../../components/SvgIcon/BgShape";

const ConfirmNetworks = ({ type, userType }) => {
    const history = useHistory();
    const [addNetworks, setAddNetworks] = useState(false);

    const handleNetworkModal = () => {
        setAddNetworks(!addNetworks);
    };
    const handleNetworkDetails = () => {
        setAddNetworks(false);
        history.push(getRoute(APP_ROUTES.NETWORKS_DETAILS));
    };
    if (!type) {
        return <Redirect to={APP_ROUTES.ENTER_EMAIL} />;
    }

    return (
        <div className="tp-confirm-networks full-height text-center">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12} className="text-start">
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12}>
                        <h1 className="mb0 mt9">
                            <span className="d-block mb4">Almost there,</span>
                            <span>
                                Can you confirm if the following people are a part of <br /> your
                                network?
                            </span>
                        </h1>
                        <div className="user-info-wrap mt9">
                            <div className="avatar-img">
                                <img
                                    src={AvatarImg}
                                    className="img-fluid rounded-circle"
                                    alt="Avatar"
                                />
                            </div>
                            <h2 className={`clr-${userType}-primary mb4 mt9`}>Jane</h2>
                            <h2 className="mb9 networks-info">
                                <span className={`clr-${userType}-primary d-block mb4`}>
                                    Students:
                                </span>
                                <span>Stuti, Zain</span>
                            </h2>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <Button
                            title="No"
                            className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr9`}
                            onClick={handleNetworkModal}
                        />
                        <Button
                            title="Yes"
                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                            onClick={() => history.push(getRoute(APP_ROUTES.NETWORKS_DETAILS))}
                        />
                        <TpModal
                            isOpen={addNetworks}
                            centered
                            className={`tp-modal-${userType} size-3`}
                            children={
                                <div className="text-center">
                                    <h3 className="mb9">
                                        Are you sure that you want to decline this invite?
                                    </h3>
                                    <Button
                                        onClick={() => setAddNetworks(!addNetworks)}
                                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr9`}
                                        title="No"
                                    />
                                    <Button
                                        onClick={handleNetworkDetails}
                                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                                        title="Yes"
                                    />
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
const mapStateToProps = (state) => {
    const type = state.root.auth.selectedUserType;

    return {
        type,
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps)(ConfirmNetworks);
