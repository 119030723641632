import { useState } from "react";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./Signup.scss";
import { connect } from "react-redux";
import { Colors } from "../../themes/colors";
import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { signUpComplete } from "../../store/actions";
import BgShape from "../../components/SvgIcon/BgShape";

const SetupPassword = ({ auth, signUpComplete, isAuthenticated, userType }) => {
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const isInvited = params.get("isInvited");
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        const {
            fullName,
            emailAddress,
            selectedUserType,
            dateOfBirth,
            countryId,
            selectedSubjects,
            mediaId
        } = auth;
        setLoading(true);
        let payload = {
            FullName: fullName,
            Email: emailAddress,
            Type: selectedUserType,
            DOB: dateOfBirth,
            CountryId: countryId.Id,
            Password: password,
            SubjectIds: selectedSubjects
        };
        if (mediaId) {
            payload = { ...payload, MediaId: mediaId };
        }

        signUpComplete(payload)
            .then((res) => {
                setLoading(false);
                history.push({
                    pathname: getRoute(APP_ROUTES.WELCOME_SCREEN),
                    search: isInvited ? "?isInvited=true" : " "
                });
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    if (isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: getRoute(APP_ROUTES.DASHBOARD)
                }}
            />
        );
    }
    if (!auth.selectedSubjects) {
        return (
            <Redirect
                to={{
                    pathname: getRoute(APP_ROUTES.SUBJECT_SELECTION),
                    search: isInvited ? "?isInvited=true" : ""
                }}
            />
        );
    }

    return (
        <div className="tp-setup-password full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12} className="mt9">
                        <h1 className="mb9 text-center">
                            We care about the security of our users, that’s why <br /> we need you
                            to create a password
                        </h1>
                    </Col>
                    <Col xs={12}>
                        <AvForm onValidSubmit={handleSubmit}>
                            <TextInput
                                name="password"
                                placeholder="Password"
                                type="password"
                                value={password}
                                className={`tp-text-input-${userType} with-icon mt9`}
                                onChange={(e) => setPassword(e.target.value)}
                                options={{
                                    pattern: {
                                        value: "/^([a-zA-Z0-9 _-]+)$/",
                                        errorMessage:
                                            "password must be atleast 8 characters long and must be alphanumeric"
                                    },
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    maxLength: {
                                        value: 128,
                                        errorMessage: " Password must not exceed 128 characters "
                                    },
                                    minLength: {
                                        value: 8,
                                        errorMessage:
                                            "password must be at least 8 characters long and alphanumeric"
                                    }
                                }}
                            />
                            <TextInput
                                name="confirm password"
                                placeholder="Confirm Password"
                                type="password"
                                value={confirmpassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className={`tp-text-input-${userType} with-icon mt9`}
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    match: {
                                        value: "password",
                                        errorMessage: "Password and Confirm Password must be same"
                                    },
                                    maxLength: {
                                        value: 128,
                                        errorMessage: "Password must not exceed 128 characters"
                                    },
                                    minLength: {
                                        value: 8,
                                        errorMessage:
                                            "Password must be at least 8 characters long and alphanumeric"
                                    }
                                }}
                            />
                            <Col xs={12} className="text-center mt9">
                                <Button
                                    type="submit"
                                    title="Next"
                                    className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                    isLoading={loading}
                                />
                            </Col>
                        </AvForm>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
const mapDispatchToProps = {
    signUpComplete
};
const mapStateToProps = (state) => {
    return {
        auth: state.root.auth,
        isAuthenticated: state.auth.isAuthenticated,
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupPassword);
