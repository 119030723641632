import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./KnowledgeBank.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import AudioIcon from "../../components/SvgIcon/AudioIcon";
import PlayIcon from "../../components/SvgIcon/PlayIcon";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";

const AddResourceUploadView = (props) => {
    const { setSelectedUser, isAuthenticated, userType } = props;
    const history = useHistory();

    return (
        <div className="tp-knowledge-bank tp-add-resource-upload-view">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Add Resource
                        </div>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to knowledge bank"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="resource-upload-holder text-center mt4">
                                <h3 className="mb6">Is this the resource you want to add?</h3>
                                <div
                                    className={`resource-holder-${userType} resource-holder resource-audio radius-7 p-0`}
                                >
                                    <div className="resource-audio-inner">
                                        <div
                                            className={`resource-audio-details-${userType} resource-audio-details radius-7 d-flex`}
                                        >
                                            <div className="clr-white resource-audio-text">
                                                You are listening:
                                            </div>
                                            <div className="clr-white resource-audio-name text-center fw-600 m-auto">
                                                <AudioIcon
                                                    color={Colors.white}
                                                    className="tph-40 tpw-40 mr4"
                                                />
                                                Les grandes marches
                                            </div>
                                        </div>
                                        <div
                                            className="resource-controls resource-audio-controls d-flex align-items-center"
                                            id="resource-controls-wrap"
                                        >
                                            <audio id="resource-controls-wrap" className="d-none">
                                                <source src="horse.ogg" type="audio/ogg" />
                                                <source src="horse.mp3" type="audio/mpeg" />
                                            </audio>
                                            <Button
                                                id="btnPlayPause"
                                                className={`tp-btn-primary tp-btn-primary-${userType} play-pause-btn h-100 rounded-0`}
                                                title={
                                                    <PlayIcon
                                                        color={Colors.white}
                                                        className="tpw-40 tph-40"
                                                    />
                                                }
                                                onclick="playPauseAudio();"
                                            />
                                            <div className="progess-wrap w-100">
                                                <div className="progressbar">
                                                    <input
                                                        id="progressbar"
                                                        className=""
                                                        type="range"
                                                        min="1"
                                                        max="100"
                                                        value="0"
                                                    />
                                                </div>
                                                <h6 className="d-flex justify-content-between mb0 progres-time">
                                                    <span>0:00</span>
                                                    <span>14:00</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    title="No"
                                    className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr9 mt9`}
                                    onClick={() => history.push(getRoute(APP_ROUTES.ADD_RESOURCE))}
                                />
                                <Button
                                    title="Yes"
                                    className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                    onClick={() =>
                                        history.push(
                                            getRoute(APP_ROUTES.ADD_RESOURCE_UPLOAD_VIEW_DETAILS)
                                        )
                                    }
                                />
                            </div>
                            <div className="resource-upload-holder text-center mt4">
                                <h3 className="mb6">Is this the resource you want to add?</h3>
                                <div
                                    className={`resource-holder-${userType} resource-holder resource-video radius-7 p-0`}
                                >
                                    <div className="resource-video-inner">
                                        <div className="resource-video-details">
                                            <video
                                                width="100%"
                                                height="483"
                                                poster={AvatarImg}
                                                controls
                                                autoPlay
                                            >
                                                <source
                                                    src="https://static.videezy.com/system/resources/previews/000/048/014/original/Countdown1.mp4"
                                                    type="video/mp4"
                                                />
                                            </video>
                                        </div>
                                        <div
                                            className="resource-controls resource-video-controls d-flex align-items-center"
                                            id="resource-controls-wrap"
                                        >
                                            <Button
                                                id="btnPlayPause"
                                                className={`tp-btn-primary tp-btn-primary-${userType} play-pause-btn h-100 rounded-0`}
                                                title={
                                                    <PlayIcon
                                                        color={Colors.white}
                                                        className="tpw-30 tph-30"
                                                    />
                                                }
                                                onclick="playPauseAudio();"
                                            />
                                            <div className="progess-wrap w-100">
                                                <div className="progressbar">
                                                    <input
                                                        id="progressbar"
                                                        className=""
                                                        type="range"
                                                        min="1"
                                                        max="100"
                                                        value="0"
                                                    />
                                                </div>
                                                <h6 className="d-flex justify-content-between mb0 progres-time">
                                                    <span>0:00</span>
                                                    <span>14:00</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    title="No"
                                    className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr9 mt9`}
                                    onClick={() => history.push(getRoute(APP_ROUTES.ADD_RESOURCE))}
                                />
                                <Button
                                    title="Yes"
                                    className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                    onClick={() =>
                                        history.push(
                                            getRoute(APP_ROUTES.ADD_RESOURCE_UPLOAD_VIEW_DETAILS)
                                        )
                                    }
                                />
                            </div>
                            <div className="resource-upload-holder text-center mt4">
                                <h3 className="mb6">Is this the resource you want to add?</h3>
                                <img
                                    src={AvatarImg}
                                    className="img-fluid mb9 d-block resource-upload-view"
                                />
                                <Button
                                    title="No"
                                    className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr9`}
                                    onClick={() => history.push(getRoute(APP_ROUTES.ADD_RESOURCE))}
                                />
                                <Button
                                    title="Yes"
                                    className={`tp-btn-primary tp-btn-primary-${userType}`}
                                    onClick={() =>
                                        history.push(
                                            getRoute(APP_ROUTES.ADD_RESOURCE_UPLOAD_VIEW_DETAILS)
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    // setSelectedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(AddResourceUploadView);
