import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./EarningBilling.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { GraphChart } from "../../components/GraphChart";

import InfoIcon from "../../components/SvgIcon/InfoIcon";

const EarningBillingReport = (props) => {
    const { setSelectedUser, isAuthenticated, userType } = props;

    const options = {
        chart: {
            height: 315,
            type: "area",
            background: Colors.white
        },
        colors: [Colors[`${userType}Primary`]],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 1.5,
            curve: "smooth"
        },
        tooltip: {
            x: {
                format: "dd/MM/yy HH:mm"
            }
        },
        xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        }
    };

    const series = [
        {
            name: "series1",
            data: [31, 40, 28, 51, 42, 109, 100]
        }
    ];
    const history = useHistory();
    return (
        <div className="tp-earning-and-billing tp-earning-and-billing-report">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Get a Report: Maths
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to Earnings & Billings"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div
                                className={`mb9 scrolling-container scrolling-container-${userType}`}
                            >
                                <div className="content-inner bg-clr-light tp-earning-and-billing-report-inner no-padding pt4 pb4 pr4 pl4">
                                    <GraphChart
                                        options={options}
                                        series={series}
                                        type="area"
                                        height={315}
                                    />
                                </div>
                                <div className="content-inner bg-clr-light tp-earning-and-billing-report-inner mt9 no-padding pt4 pb4 pr4 pl4">
                                    <table className="tp-table w-100 bg-white mt4 text-center style-2">
                                        <thead>
                                            <th>Date of answering</th>
                                            <th>Question</th>
                                            <th>Subject</th>
                                            <th>Minutes</th>
                                            <th>Gross earning</th>
                                            <th>
                                                Net earning
                                                <span
                                                    className={`net-earning-info-popup ml1 ${userType}`}
                                                >
                                                    <span className="net-earning-info-popup-trigger">
                                                        <InfoIcon
                                                            color={Colors[`${userType}Primary`]}
                                                            className="pointer tpw-25 tph-25"
                                                        />
                                                        <div className="net-earning-info-popup-content">
                                                            These are the earnings after TutorPin
                                                            fee's have been deducted.
                                                        </div>
                                                    </span>
                                                </span>
                                            </th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td>Why is water wet?</td>
                                                <td>Maths</td>
                                                <td>15 min</td>
                                                <td>£5.00</td>
                                                <td>£0.75</td>
                                            </tr>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td>Why is water wet?</td>
                                                <td>Maths</td>
                                                <td>15 min</td>
                                                <td>£5.00</td>
                                                <td>£0.75</td>
                                            </tr>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td>Why is water wet?</td>
                                                <td>Maths</td>
                                                <td>15 min</td>
                                                <td>£5.00</td>
                                                <td>£0.75</td>
                                            </tr>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td>Why is water wet?</td>
                                                <td>Maths</td>
                                                <td>15 min</td>
                                                <td>£5.00</td>
                                                <td>£0.75</td>
                                            </tr>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td>Why is water wet?</td>
                                                <td>Maths</td>
                                                <td>15 min</td>
                                                <td>£5.00</td>
                                                <td>£0.75</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    // setSelectedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(EarningBillingReport);
