import React, { useState } from "react";

import "./Video.scss";

import PlayIconCircle from "../SvgIcon/PlayIconCircle";

const Video = (props) => {
    const [showVideo, setShowVideo] = useState(false);

    const { url, height, width, className = "", PlayIconColor } = props;
    return (
        <div className={`video-player ${className}`}>
            {!showVideo && (
                <div className="video-placeholder d-flex align-items-center justify-content-center mx-auto">
                    <PlayIconCircle
                        className="play-btn pointer"
                        onClick={() => {
                            setShowVideo(true);
                        }}
                        color={PlayIconColor}
                    />
                </div>
            )}
            {showVideo && (
                <video width={width} height={height} controls autoPlay>
                    <source src={url} type="video/mp4" />
                </video>
            )}
        </div>
    );
};

export default Video;
