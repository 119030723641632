import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { TpModal } from "../../components/TpModal";
import { VerifcationCodeInput } from "../../components/VerifcationCodeInput";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import SheildCheckIcon from "../../components/SvgIcon/SheildCheckIcon";
import SheildCloseIcon from "../../components/SvgIcon/SheildCloseIcon";

const ChangeEmailCodeVerify = (props) => {
    const { userType } = props;
    const history = useHistory();
    const location = useLocation();
    const [sendCode, setSendCode] = useState(false);
    const [codeError, setCodeError] = useState(false);

    const handleResendCode = () => {
        setSendCode(!sendCode);
    };

    const handleCodeConfirmRedirect = () => {
        setSendCode(!sendCode);
        history.push(
            {
                pathname: getRoute(APP_ROUTES.SETUP_NEW_EMAIL)
            },
            {
                routeParam: location.state.routeParam
            }
        );
    };

    return (
        <div className="tp-settings tp-email-code-confirmation">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Change {location.state.routeParam}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to profile"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner email-code-confirm-inner bg-clr-light">
                                <div className="email-code-inner text-center">
                                    {codeError ? (
                                        <SheildCloseIcon color={Colors.red} />
                                    ) : (
                                        <SheildCheckIcon color={Colors[`${userType}Primary`]} />
                                    )}
                                    <h3 className="mb9 mt9">
                                        {codeError
                                            ? "The verification code was not recognised"
                                            : "We have sent you a verification code by email"}
                                    </h3>
                                    <div className="code-input-wrap mx-auto">
                                        <h3 className="code-error mb4">
                                            {codeError
                                                ? "Please try your verification code again"
                                                : "Please enter your verification code"}
                                        </h3>
                                        <VerifcationCodeInput
                                            width={30}
                                            height={40}
                                            className={`tp-verification-code-input-wrap-${userType} w-100 ${
                                                codeError && "error"
                                            }`}
                                        />
                                    </div>
                                    <Button
                                        onClick={handleResendCode}
                                        className={`clr-${userType}-primary text-decoration-underline mt9`}
                                        title={"Resend verification code"}
                                    />
                                    <TpModal
                                        isOpen={sendCode}
                                        centered
                                        className={`tp-modal-${userType} size-3`}
                                        children={
                                            <div className="text-center">
                                                <h3 className="mb0">
                                                    We have re-sent a verification code to:
                                                    <span className="d-block mt4 mb4 fw-bold">
                                                        carolinam_o@gmail.com
                                                    </span>
                                                    If you do not receive the verification code
                                                    within a few minutes, please check your spam or
                                                    junk email folder.
                                                </h3>
                                                <Button
                                                    onClick={handleCodeConfirmRedirect}
                                                    className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                                    title="OK"
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(ChangeEmailCodeVerify);
