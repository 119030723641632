const QuestionBoardIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="28"
            height="33"
            viewBox="0 0 28 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.0003 3.25H25.25C25.5815 3.25 25.8995 3.3817 26.1339 3.61612C26.3683 3.85054 26.5 4.16848 26.5 4.5V30.75C26.5 31.0815 26.3683 31.3995 26.1339 31.6339C25.8995 31.8683 25.5815 32 25.25 32H2.75C2.41848 32 2.10054 31.8683 1.86612 31.6339C1.6317 31.3995 1.5 31.0815 1.5 30.75V4.5C1.5 4.16848 1.6317 3.85054 1.86612 3.61612C2.10054 3.3817 2.41848 3.25 2.75 3.25H8.99966"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.75 8.25V7C7.75 5.3424 8.40848 3.75269 9.58058 2.58058C10.7527 1.40848 12.3424 0.75 14 0.75C15.6576 0.75 17.2473 1.40848 18.4194 2.58058C19.5915 3.75269 20.25 5.3424 20.25 7V8.25H7.75Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.3061 20.6224C14.1786 20.6224 14.051 20.5714 13.9745 20.4949C13.898 20.4184 13.8469 20.2908 13.8469 20.1633C13.8469 19.8571 13.9235 19.5 14.0765 19.1429C14.2296 18.7857 14.4592 18.4541 14.7143 18.199C14.9694 17.9694 15.199 17.7398 15.4286 17.5357C15.6582 17.3316 15.8367 17.0765 15.9643 16.8214C16.0918 16.5663 16.1684 16.2347 16.1684 15.8265C16.1684 15.5204 16.0918 15.2398 15.9388 14.9847C15.7857 14.7296 15.5816 14.5255 15.301 14.3724C15.0204 14.2194 14.7143 14.1429 14.3316 14.1429C13.9235 14.1429 13.5918 14.2194 13.3112 14.398C13.0306 14.551 12.801 14.7806 12.648 15.0612C12.4949 15.3418 12.4184 15.6735 12.4184 16.0561C12.4184 16.1837 12.3673 16.3112 12.2908 16.3878C12.2143 16.4643 12.0867 16.5153 11.9592 16.5153C11.8316 16.5153 11.7041 16.4643 11.6276 16.3878C11.551 16.3112 11.5 16.1837 11.5 16.0561C11.5 15.5204 11.6276 15.0357 11.8571 14.602C12.0867 14.1939 12.4184 13.8622 12.852 13.6071C13.2857 13.3776 13.7704 13.25 14.3316 13.25C14.8673 13.25 15.3265 13.352 15.7347 13.5816C16.1429 13.8112 16.4745 14.1173 16.7041 14.5C16.9337 14.8827 17.0612 15.3418 17.0612 15.852C17.0612 16.2857 16.9847 16.6684 16.8316 16.9745C16.6786 17.2806 16.4745 17.5612 16.1939 17.8163C15.9388 18.0714 15.6582 18.352 15.352 18.6327C15.0969 18.8622 14.9439 19.1173 14.8673 19.3724C14.7908 19.6531 14.7398 19.9082 14.7398 20.1122C14.7398 20.2653 14.6888 20.3673 14.6122 20.4694C14.5357 20.5714 14.4337 20.6224 14.3061 20.6224ZM14.3061 23.25C14.102 23.25 13.949 23.1735 13.8214 23.0459C13.6939 22.9184 13.6173 22.7398 13.6173 22.5612C13.6173 22.3571 13.6939 22.2041 13.8214 22.051C13.949 21.9235 14.1276 21.8469 14.3061 21.8469C14.4847 21.8469 14.6633 21.9235 14.7908 22.051C14.9184 22.1786 14.9949 22.3571 14.9949 22.5612C14.9949 22.7398 14.9184 22.9184 14.7908 23.0459C14.6633 23.1735 14.5102 23.25 14.3061 23.25Z"
                fill={color}
            />
        </svg>
    );
};
export default QuestionBoardIcon;
