import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { RadioBtn } from "../../components/RadioBtn";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import AvatarImg from "../../assets/images/placeholder-profile.jpg";

const ReportUserReason = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [radioChecked, setRadioChecked] = useState(false);

    return (
        <div className="tp-settings tp-report-user tp-report-reason-list">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Report a User
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Go back"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner bg-clr-light tp-report-reason-inner text-center">
                                <div className="report-reason-list-options mx-auto">
                                    <Row>
                                        <Col xs={12}>
                                            <div
                                                className={`selected-reason-type selected-reason-type-${userType}`}
                                            >
                                                <h3 className="mb0">What are you reporting?</h3>
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid pointer mt9 thumbnail-rounded"
                                                    alt=""
                                                />
                                            </div>
                                            <h3 className="mb0 mt4 ff-sf-pro-rounded">Carolina</h3>
                                            <div className="report-reason d-flex align-items-center justify-content-between bg-white">
                                                <h3 className="mb0">Abusive language</h3>
                                                <RadioBtn
                                                    className={`tp-radio-btn-${userType}`}
                                                    checked={radioChecked}
                                                    onChange={() => {
                                                        setRadioChecked(!radioChecked);
                                                    }}
                                                />
                                            </div>
                                            <div className="report-reason d-flex align-items-center justify-content-between bg-white">
                                                <h3 className="mb0">Inappropriate content</h3>
                                                <RadioBtn
                                                    className={`tp-radio-btn-${userType}`}
                                                    checked={radioChecked}
                                                />
                                            </div>
                                            <div className="report-reason d-flex align-items-center justify-content-between bg-white">
                                                <h3 className="mb0">Inappropraite behaviour</h3>
                                                <RadioBtn
                                                    className={`tp-radio-btn-${userType}`}
                                                    checked={radioChecked}
                                                />
                                            </div>
                                            <div className="report-reason d-flex align-items-center justify-content-between bg-white">
                                                <h3 className="mb0">Other </h3>
                                                <RadioBtn
                                                    className={`tp-radio-btn-${userType}`}
                                                    checked={radioChecked}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="12">
                                            <Button
                                                title="Next"
                                                className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                                onClick={() =>
                                                    history.push(
                                                        getRoute(
                                                            APP_ROUTES.REPORT_USER_REASON_DESCRIPTION
                                                        )
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(ReportUserReason);
