import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./KnowledgeBank.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import { TpModal } from "../../components/TpModal";

import PlusIcon from "../../components/SvgIcon/PlusIcon";

const AddSubject = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [addResourceModal, setAddResourceModal] = useState(false);
    const [addResourceConfirmModal, setAddResourceConfirmModal] = useState(false);

    return (
        <div className="tp-knowledge-bank tp-add-subject">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>Add Subject</div>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to knowledge bank"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner add-subject-inner bg-clr-light">
                                <AvForm>
                                    <Row className="align-items-center mb9">
                                        <Col md={3}>
                                            <h3 className="mb0">Select the Subject</h3>
                                        </Col>
                                        <Col md={9}>
                                            <CreatableDropdown
                                                placeholder="Subject"
                                                options={[
                                                    {
                                                        label: "Math"
                                                    },
                                                    {
                                                        label: "Biology"
                                                    },
                                                    {
                                                        label: "Spanish"
                                                    },
                                                    {
                                                        label: "German"
                                                    },
                                                    {
                                                        label: "Italian"
                                                    }
                                                ]}
                                                className={`mw-100 dropdown-size-sm bdr-none radius-thin arrow-right tp-creatable-dropdown-${userType}`}
                                                dropIconColor={Colors[`${userType}Primary`]}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb4">
                                        <Col md={3} className="align-self-center">
                                            <h3 className="mb0">Please add a Topic</h3>
                                        </Col>
                                        <Col md={9}>
                                            <TextInput
                                                name="topicname"
                                                placeholder="Topic Name"
                                                className={`tp-text-input-${userType} bdr-0 radius-7`}
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "This is a required field"
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{ size: 9, offset: 3 }} className="mb4">
                                            <TextInput
                                                name="topicname"
                                                placeholder="Topic Name"
                                                className={`tp-text-input-${userType} bdr-0 radius-7`}
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "This is a required field"
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col md={{ size: 9, offset: 3 }}>
                                            <Button
                                                className={`bdr-0 w-100 radius-7 tp-btn-add tp-btn-add-${userType}`}
                                                title={
                                                    <PlusIcon
                                                        color={Colors[`${userType}Primary`]}
                                                        className="tpw-18 tph-18"
                                                    />
                                                }
                                                onClick={() => {
                                                    setAddResourceConfirmModal(
                                                        !addResourceConfirmModal
                                                    );
                                                }}
                                            />
                                            <TpModal
                                                isOpen={addResourceConfirmModal}
                                                centered
                                                className={`tp-modal-${userType} size-3`}
                                                children={
                                                    <div className="text-center">
                                                        <h3 className="mb0">
                                                            Do you want to add resources to this
                                                            Subject?
                                                        </h3>
                                                        <Button
                                                            className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4 mt9`}
                                                            title="Yes"
                                                            onClick={() => {
                                                                setAddResourceConfirmModal(false);
                                                            }}
                                                        />
                                                        <Button
                                                            className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                                            title="No"
                                                            onClick={() => {
                                                                setAddResourceConfirmModal(false);
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            />
                                        </Col>
                                        <Col xs={12} className="text-center">
                                            <Button
                                                className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                                title={
                                                    <>
                                                        <PlusIcon
                                                            color={Colors.white}
                                                            className="tpw-24 tph-24 mr1"
                                                        />
                                                        <span>Add Subject</span>
                                                    </>
                                                }
                                                onClick={() => {
                                                    setAddResourceModal(!addResourceModal);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <TpModal
                                        isOpen={addResourceModal}
                                        centered
                                        className={`tp-modal-${userType} size-3`}
                                        children={
                                            <div className="text-center">
                                                <h3 className="mb0">
                                                    Please select the topic you want to add the
                                                    resource to
                                                </h3>
                                                <Row className="mt4">
                                                    <Col md={6}>
                                                        <Button
                                                            className={`tp-btn-secondary tp-btn-secondary-border-${userType}`}
                                                            title="Topic 1"
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <Button
                                                            className={`tp-btn-secondary tp-btn-secondary-${userType}`}
                                                            title="Topic 2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt4">
                                                    <Col md={6}>
                                                        <Button
                                                            className={`tp-btn-secondary tp-btn-secondary-${userType}`}
                                                            title="Topic 1"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt9">
                                                    <Col md={6}>
                                                        <Button
                                                            className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType}`}
                                                            title="Cancel"
                                                            onClick={() => {
                                                                setAddResourceModal(false);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <Button
                                                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                            title="Next"
                                                            onClick={() =>
                                                                history.push(
                                                                    getRoute(
                                                                        APP_ROUTES.ADD_RESOURCE
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    />
                                </AvForm>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(AddSubject);
