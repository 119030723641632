const ReportUserIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="142"
            height="145"
            viewBox="0 0 142 145"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M113 52C115.209 52 117 50.2091 117 48C117 45.7909 115.209 44 113 44C110.791 44 109 45.7909 109 48C109 50.2091 110.791 52 113 52Z"
                fill={color}
            />
            <path
                d="M113 2V35.75"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.78906 142.868C9.70472 130.898 19.6487 120.959 31.622 114.048C43.5953 107.138 57.1763 103.5 71.0007 103.5C84.8251 103.5 98.4061 107.138 110.379 114.049C122.352 120.96 132.296 130.899 139.212 142.87"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M113.223 74.0973C110.215 82.2189 104.927 89.2993 97.992 94.488C91.0576 99.6766 82.7725 102.753 74.1321 103.347C65.4918 103.941 56.8636 102.028 49.2842 97.8372C41.7048 93.6466 35.4965 87.3567 31.4052 79.7232C27.3139 72.0897 25.5135 63.4374 26.2204 54.8055C26.9273 46.1736 30.1114 37.9294 35.3901 31.0633C40.6688 24.1972 47.8177 19.0011 55.9779 16.0994C64.1381 13.1978 72.9626 12.7138 81.3912 14.7058"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default ReportUserIcon;
