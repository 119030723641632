const BellIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="120"
            height="136"
            viewBox="0 0 120 136"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.8818 49.2496C12.8731 43.0232 14.0969 36.8566 16.4826 31.1053C18.8682 25.354 22.3686 20.1318 26.782 15.7396C31.1953 11.3474 36.4343 7.87223 42.197 5.51427C47.9597 3.15631 54.1321 1.96225 60.3585 2.00091C86.3392 2.19406 107.121 23.7893 107.121 49.8427V54.4996C107.121 78.0036 112.038 91.6427 116.369 99.0971C116.836 99.8937 117.084 100.799 117.089 101.722C117.094 102.645 116.855 103.553 116.397 104.355C115.939 105.156 115.278 105.823 114.48 106.287C113.682 106.751 112.776 106.997 111.853 107H8.14409C7.22088 106.997 6.31463 106.751 5.51675 106.287C4.71887 105.823 4.05759 105.156 3.59962 104.354C3.14165 103.553 2.90318 102.644 2.90828 101.721C2.91338 100.798 3.16187 99.8925 3.62867 99.096C7.96207 91.6413 12.8818 78.0024 12.8818 54.4996L12.8818 49.2496Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M39 107V112.25C39 117.82 41.2125 123.161 45.1508 127.099C49.089 131.038 54.4305 133.25 60 133.25C65.5695 133.25 70.911 131.038 74.8492 127.099C78.7875 123.161 81 117.82 81 112.25V107"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default BellIcon;
