import { fetchUtil } from "../../utils/fetchUtil";
import { toaster } from "../../utils/toasterUtil";
import { appendQueryParams } from "../../utils/urlUtil";

export const SUBJECT_LISTING_REQUEST = "SUBJECT_LISTING_REQUEST";
export const SUBJECT_LISTING_SUCCESS = "SUBJECT_LISTING_SUCCESS";
export const SUBJECT_LISTING_FAILURE = "SUBJECT_LISTING_FAILURE";

export const QUESTION_THREAD_SUCCESS = "QUESTION_THREAD_SUCCESS";

export const getUserSubjectListing = (queryParams) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    dispatch({ type: SUBJECT_LISTING_REQUEST });
    console.log("queryParams", queryParams);

    return fetchUtil({
        url: appendQueryParams("/subject", queryParams || {}),
        token
    })
        .then((res) => {
            dispatch({ type: SUBJECT_LISTING_SUCCESS, data: res?.Data?.Subjects || [] });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            dispatch({ type: SUBJECT_LISTING_FAILURE });
            return Promise.reject(err);
        });
};

export const getQuestionsListing = (queryParams) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/question", queryParams || {}),
        token
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getQuestionById = (id) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: `/question/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getQuestionThreads = (id) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: `/question/thread/${id}`,
        token
    })
        .then((res) => {
            dispatch({
                type: QUESTION_THREAD_SUCCESS,
                threads: res?.Data?.Threads,
                feedback: res?.Data?.Feedback
            });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const saveDraftQuestion = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    const body = JSON.stringify(payload);

    return fetchUtil({
        url: `/question/draft`,
        token,
        body,
        method: "POST"
    })
        .then((res) => {
            toaster({ title: "Draft question saved successfully", type: "success" });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const askQuestion = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    const body = JSON.stringify(payload);

    return fetchUtil({
        url: `/question`,
        token,
        body,
        method: "POST"
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const updateAskQuestion = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    const body = JSON.stringify(payload);

    return fetchUtil({
        url: `/question`,
        token,
        body,
        method: "PUT"
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const sentAnswerToUser = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    const body = JSON.stringify(payload);

    return fetchUtil({
        url: "/answer",
        token,
        body,
        method: "POST"
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const withdrawQuestion = (id) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: `/question/withdraw/${id}`,
        token,
        method: "DELETE"
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const sentQuestionFeedback = (bodyData) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    let body = JSON.stringify(bodyData);

    return fetchUtil({
        url: `/feedback`,
        token,
        body,
        method: "POST"
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const questionRemindMeLater = (bodyData) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    let body = JSON.stringify(bodyData);

    return fetchUtil({
        url: `/question/move/todo`,
        token,
        body,
        method: "POST"
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
