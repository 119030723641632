import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./KnowledgeBank.scss";
import { Colors } from "../../themes/colors";

import { Card } from "../../components/Card";
import { TpDropdownPopup } from "../../components/TpDropdownPopup";
import { AvForm } from "availity-reactstrap-validation";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { AddDropdown } from "../../components/AddDropdown";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import ArrowSortIcon from "../../components/SvgIcon/ArrowSortIcon";
import FilterIcon from "../../components/SvgIcon/FilterIcon";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";

const SearchResult = (props) => {
    const { userType } = props;
    const history = useHistory();
    return (
        <div className="tp-knowledge-bank tp-search-result">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Search: Geometry
                        </div>
                        <div className="content-inner-wrap">
                            <div className="d-flex justify-content-between mt9 mb4 align-items-center">
                                <Button
                                    title="Back to subjects"
                                    className={`tp-btn-back-${userType}`}
                                    backIcon={true}
                                    backIconColor={Colors[`${userType}Primary`]}
                                    onClick={() => history.goBack()}
                                />
                                <AvForm className="d-flex align-items-center">
                                    <TpDropdownPopup
                                        className={`mr4 size-sm tp-dropdown-popup-${userType}`}
                                        trigger={
                                            <h4 className="ff-sf-pro-rounded mb0">
                                                <FilterIcon
                                                    color={Colors.black}
                                                    className="mr1 tpw-18 tph-18"
                                                />
                                                Filter
                                            </h4>
                                        }
                                        children={
                                            <div className="text-center">
                                                <Button
                                                    title="PDF"
                                                    className={`tp-btn-secondary tp-btn-secondary-${userType}`}
                                                />
                                                <Button
                                                    title="Video"
                                                    className={`tp-btn-secondary tp-btn-secondary-${userType} mt1`}
                                                />
                                                <Button
                                                    title="Images"
                                                    className={`tp-btn-secondary tp-btn-secondary-${userType} mt1`}
                                                />
                                                <Button
                                                    title="Audio"
                                                    className={`tp-btn-secondary tp-btn-secondary-${userType} mt1`}
                                                />
                                            </div>
                                        }
                                    />
                                    <TpDropdownPopup
                                        className={`mr4 size-sm tp-dropdown-popup-${userType}`}
                                        trigger={
                                            <h4 className="ff-sf-pro-rounded mb0">
                                                <ArrowSortIcon
                                                    color={Colors.black}
                                                    className="mr1 tpw-18 tph-18"
                                                />
                                                Sort
                                            </h4>
                                        }
                                        children={
                                            <div className="text-center">
                                                <Button
                                                    title="A to Z"
                                                    className={`tp-btn-secondary tp-btn-secondary-${userType}`}
                                                />
                                                <Button
                                                    title="Z to A"
                                                    className={`tp-btn-secondary tp-btn-secondary-${userType} mt1 clr-black`}
                                                />
                                            </div>
                                        }
                                    />
                                    <TextInput
                                        name="search"
                                        placeholder=""
                                        type="search"
                                        onClick={() =>
                                            history.push(getRoute(APP_ROUTES.SEARCH_RESULT))
                                        }
                                        className={`bdr-0 tp-text-input-${userType}`}
                                    />
                                    <AddDropdown userType={userType} />
                                </AvForm>
                            </div>
                            <div className={`scrolling-container scrolling-container-${userType}`}>
                                <Row>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            className={`mb4 card-resource tp-card-${userType}`}
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                            className={`mb4 card-resource tp-card-${userType}`}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                            className={`mb4 card-resource tp-card-${userType}`}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                            className={`mb4 card-resource tp-card-${userType}`}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                            className={`mb4 card-resource tp-card-${userType}`}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                            className={`mb4 card-resource tp-card-${userType}`}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                            className={`mb4 card-resource tp-card-${userType}`}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                            className={`mb4 card-resource tp-card-${userType}`}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                            className={`mb4 card-resource tp-card-${userType}`}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                            className={`mb4 card-resource tp-card-${userType}`}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                            className={`mb4 card-resource tp-card-${userType}`}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            img={AvatarImg}
                                            title="Math.jpg"
                                            onClick={() =>
                                                history.push(getRoute(APP_ROUTES.RESOURCE_DETAILS))
                                            }
                                            className={`mb4 card-resource tp-card-${userType}`}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(SearchResult);
