import { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, connect } from "react-redux";

import "./QuestionBoard.scss";
import { Colors } from "../../themes/colors";

import { Slider } from "../../components/Slider";
import { Card } from "../../components/Card";
import { TpDropdownPopup } from "../../components/TpDropdownPopup";
import { AvForm } from "availity-reactstrap-validation";
import { Button } from "../../components/Button";
import { Spinner } from "../../components/Loader";
import { TextInput } from "../../components/TextInput";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { getUserSubjectListing } from "../../store/actions";

import EnglishIcon from "../../components/SvgIcon/EnglishIcon";
import MathsIcon from "../../components/SvgIcon/MathsIcon";
import BiologyIcon from "../../components/SvgIcon/BiologyIcon";
import SubjectIcon from "../../components/SvgIcon/SubjectIcon";
import TopicIcon from "../../components/SvgIcon/TopicIcon";
import ResourceIcon from "../../components/SvgIcon/ResourceIcon";
import PlusIcon from "../../components/SvgIcon/PlusIcon";
import { UserTypes } from "../../constants";

const QuestioBoard = (props) => {
    const {
        subjects: { fetching, data },
        userType,
        user
    } = props;
    const [search, setSearch] = useState("");

    useEffect(() => {
        dispatch(
            // TODO: hard code UserId will be removed once network api available.
            getUserSubjectListing({
                ...(search && { Q: search }),
                ...(user.Type === UserTypes.GUARDIAN && { UserId: 251 })
            })
        );
    }, [search]);

    const history = useHistory();
    const dispatch = useDispatch();

    const options = {
        margin: 102,
        items: 3,
        dots: false,
        nav: true,
        center: true,
        loop: true
    };

    const handleOnSearchChange = (evt) => {
        setSearch(evt.target.value);
    };

    return (
        <div className="tp-question-board tp-question-board-main">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Question Board
                        </div>
                        <div className="content-inner-wrap">
                            {user.Type === UserTypes.TUTOR && (
                                <AvForm className="d-flex justify-content-end">
                                    <TextInput
                                        name="search"
                                        placeholder=""
                                        type="search"
                                        value={search}
                                        onChange={handleOnSearchChange}
                                        className={`bdr-0 tp-text-input-${userType}`}
                                    />
                                    <TpDropdownPopup
                                        className={`size-lg tp-dropdown-popup-${userType}`}
                                        fancy={true}
                                        trigger={
                                            <Button
                                                className={`tp-btn-primary tp-btn-primary-${userType} ml4`}
                                                title={
                                                    <>
                                                        <PlusIcon
                                                            color={Colors.white}
                                                            className="tpw-24 tph-24 mr1"
                                                        />
                                                        <span>Add</span>
                                                    </>
                                                }
                                            />
                                        }
                                        children={
                                            <Row>
                                                <Col md={4}>
                                                    <Card
                                                        svg={
                                                            <SubjectIcon
                                                                color={Colors[`${userType}Primary`]}
                                                            />
                                                        }
                                                        className={`tp-card-xss tp-card-${userType}`}
                                                        title="Subjects"
                                                        onClick={() =>
                                                            history.push(
                                                                getRoute(APP_ROUTES.ADD_SUBJECT)
                                                            )
                                                        }
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Card
                                                        svg={
                                                            <TopicIcon
                                                                color={Colors[`${userType}Primary`]}
                                                            />
                                                        }
                                                        className={`tp-card-xss tp-card-${userType}`}
                                                        title="Topics"
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Card
                                                        svg={
                                                            <ResourceIcon
                                                                color={Colors[`${userType}Primary`]}
                                                            />
                                                        }
                                                        className={`tp-card-xss tp-card-${userType}`}
                                                        title="Resources"
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                    />
                                </AvForm>
                            )}
                            {(fetching || (!fetching && !data.length)) && (
                                <Spinner
                                    isLoading={fetching}
                                    showDataNotAvailableText={!fetching && !data.length}
                                />
                            )}
                            {!!(data?.length && !fetching) && (
                                <Slider
                                    className={`tp-center-mode-active-big tp-slider-${userType}`}
                                    options={options}
                                    children={data.map((subject, index) => {
                                        return (
                                            <Card
                                                key={index}
                                                title={subject.Title}
                                                counter={index + 1}
                                                onClick={() =>
                                                    history.push(
                                                        getRoute(APP_ROUTES.QUESTIONS_LIST, {
                                                            id: subject.Id
                                                        })
                                                    )
                                                }
                                                svg={
                                                    <EnglishIcon
                                                        color={Colors[`${userType}Primary`]}
                                                    />
                                                }
                                                className={`mt10 tp-card-xl tp-card-${userType}`}
                                            />
                                        );
                                    })}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        subjects: state.questionBoard.subjects,
        userType: state.auth.userType,
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(QuestioBoard);
