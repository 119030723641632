const OtherSubjectIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="259"
            height="210"
            viewBox="0 0 259 210"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.3089 141.549C11.6449 141.549 3 150.194 3 160.858C3 171.522 11.6449 180.167 22.3089 180.167L142.566 206.589L250.967 146.63"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
            />
            <path
                d="M22.3086 102.932L84.6392 116.144M22.3086 64.3144L143.921 87.0108L251.306 26.7127L128.677 3"
                stroke={color}
                strokeWidth="5"
            />
            <path
                d="M22.3089 102.932C11.6449 102.932 3 111.577 3 122.241C3 132.905 11.6449 141.55 22.3089 141.55L143.244 167.295L250.629 107.674"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
            />
            <path
                d="M22.6477 102.93C11.7966 102.93 3 94.2855 3 83.6214C3 72.9574 11.7966 64.3125 22.6477 64.3125"
                stroke={color}
                strokeWidth="5"
            />
            <path
                d="M116.484 122.917L144.262 129.354L253.002 68.7168"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
            />
            <path
                d="M110.045 140.197V99.8849L90.7363 94.4648V136.132L98.8664 131.05L110.045 140.197Z"
                stroke={color}
                strokeWidth="5"
            />
            <path
                d="M111.4 38.5697L138.162 25.0195L187.62 35.1821L162.213 49.7485L111.4 38.5697Z"
                stroke={color}
                strokeWidth="5"
            />
            <path d="M129.356 3.33789L14.8574 66.0073" stroke={color} strokeWidth="5" />
        </svg>
    );
};
export default OtherSubjectIcon;
