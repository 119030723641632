import { useHistory } from "react-router-dom";
import { useState, useRef, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, connect } from "react-redux";

import "./Login.scss";

import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { Button } from "../../components/Button";
import { TpModal } from "../../components/TpModal";
import { TextInput } from "../../components/TextInput";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import Logo from "../../components/SvgIcon/Logo";
import BgShape from "../../components/SvgIcon/BgShape";

import { forgotPassword } from "../../store/actions";

const ForgotPassword = (props) => {
    const history = useHistory();
    const { userType } = props;
    const [validEmail, setValidEmail] = useState(false);
    const [sendCode, setSendCode] = useState(false);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const tokenUUID = useRef(null);

    const validEmailClass = validEmail ? "not-found" : "";

    const renderText = () => {
        return (
            <div>
                {validEmail ? (
                    <span>Please enter a valid email adress</span>
                ) : (
                    <Fragment>
                        <span>
                            Please enter your email address. We will send you a verification code
                            <br /> to reset your password
                        </span>
                    </Fragment>
                )}
            </div>
        );
    };

    const handleResendCodeModal = () => {
        setSendCode(!sendCode);
        history.push(
            getRoute(APP_ROUTES.RESET_PASSWORD_CODE_CONFIRMATION, { tokenId: tokenUUID.current })
        );
    };

    const handleNextPress = () => {
        setIsLoading(true);
        dispatch(forgotPassword({ Email: email }))
            .then((res) => {
                tokenUUID.current = res.Data.TokenUUID;
                setIsLoading(false);
                setSendCode(true);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    return (
        <div className="tp-forgot text-center full-height content-centered">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="logo mb9">
                            <Logo color={Colors[`${userType}Primary`]} />
                        </div>
                        <h1 className="mb9">{renderText()}</h1>
                        <AvForm className="text-start" onValidSubmit={handleNextPress}>
                            <TextInput
                                name="email"
                                placeholder="Type the Email Address"
                                type="email"
                                className={`tp-text-input-${userType} ${validEmailClass} with-icon`}
                                onChange={(e) => setEmail(e.target.value)}
                                errorMessage="This is a required field"
                                options={{
                                    required: { value: true },
                                    email: {
                                        errorMessage: "Please enter a valid email ID"
                                    },
                                    maxLength: {
                                        value: 64,
                                        errorMessage: "Max 64 characters are allowed"
                                    }
                                }}
                            />
                            <Col xs={12} className="text-center">
                                <Button
                                    title="Send verification code"
                                    className={`tp-btn-primary tp-btn-primary-${userType} mb4 mt4`}
                                    type="submit"
                                    isLoading={isLoading}
                                />
                            </Col>
                        </AvForm>
                    </Col>
                    <Col xs={12}>
                        <Row className="flex-column">
                            <Col xs={12}>
                                <TpModal
                                    isOpen={sendCode}
                                    centered
                                    className={`tp-modal-${userType} size-3`}
                                    children={
                                        <div className="text-center">
                                            <h3 className="mb0">
                                                We have re-sent a verification code to:
                                                <span className="d-block mt4 mb4 fw-bold">
                                                    {email}
                                                </span>
                                                If you do not receive the verification code within a
                                                few minutes, please check your spam or junk email
                                                folder.
                                            </h3>
                                            <Button
                                                onClick={handleResendCodeModal}
                                                className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                                title="OK"
                                            />
                                        </div>
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps, null)(ForgotPassword);
