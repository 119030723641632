import { useState } from "react";
import { connect } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";

import "../QuestionBoard.scss";

import { TextInput } from "../../../components/TextInput";
import { Button } from "../../../components/Button";
import { TpModal } from "../../../components/TpModal";

const TimeSpentModal = (props) => {
    const { userType, showTimeSpentModal, handleClose, handleSubmitPress, isLoading } = props;
    const [minutes, setMinutes] = useState("");
    return (
        <TpModal
            isOpen={showTimeSpentModal}
            centered
            className={`tp-modal-${userType} size-3`}
            children={
                <AvForm className="text-center" onValidSubmit={() => handleSubmitPress(minutes)}>
                    <h3 className="mb5">How much time have you spent on this answer?</h3>
                    <div className="mb9 col-md-8 offset-md-2">
                        <TextInput
                            placeholder="Enter Time Spent (Mins)"
                            name="timespent"
                            className={`radius-7 tp-text-input-${userType}`}
                            options={{
                                required: {
                                    value: true,
                                    errorMessage: "This field is required"
                                },
                                pattern: {
                                    value: "/^[0-9]+$/",
                                    errorMessage: "Only numbers are allowed"
                                }
                            }}
                            value={minutes}
                            onChange={(evt) => setMinutes(evt.target.value)}
                        />
                    </div>
                    <Button
                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                        title="Cancel"
                        onClick={handleClose}
                        disabled={isLoading}
                    />
                    <Button
                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                        title="Send"
                        type="submit"
                        isLoading={isLoading}
                    />
                </AvForm>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(TimeSpentModal);
