import React from "react";
import { connect } from "react-redux";

import { TpModal } from "../../../components/TpModal";
import { Button } from "../../../components/Button";

const DraftQuestionModal = (props) => {
    const {
        userType,
        handleContinueEditing,
        handleSaveAsDraft,
        handleDiscardQuestion,
        isOpen,
        isLoading
    } = props;
    return (
        <TpModal
            isOpen={isOpen}
            centered
            className={`tp-modal-${userType} size-3`}
            children={
                <div className="text-center">
                    <div>
                        <h3 className="h3 mb9">
                            Do you want to save this question as a draft question?
                        </h3>
                        <div>
                            <Button
                                className={`tp-btn-primary tp-btn-primary-${userType} mb3`}
                                title="Continue editing"
                                onClick={handleContinueEditing}
                            />
                        </div>
                        <div>
                            <Button
                                className={`tp-btn-primary tp-btn-primary-${userType} mb3`}
                                title="Save as draft"
                                isLoading={isLoading}
                                onClick={handleSaveAsDraft}
                            />
                        </div>
                        <div>
                            <Button
                                className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border-${userType}`}
                                title="Discard question"
                                onClick={handleDiscardQuestion}
                            />
                        </div>
                    </div>
                </div>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(DraftQuestionModal);
