import { NavLink } from "react-router-dom";

import "./NavBlock.scss";

const NavBlock = (props) => {
    const { className, icon, text, link, onClick } = props;
    return (
        <NavLink
            {...props}
            activeClassName="active-navlink"
            to={{ pathname: link ? link : null }}
            onClick={onClick}
            className={`nav-block d-block ${className}`}
        >
            <div className="nav-item">
                <div className="nav-icon">{icon}</div>
                <div className="nav-text">{text}</div>
            </div>
        </NavLink>
    );
};

export default NavBlock;
