export const UserTypes = {
    STUDENT: 1,
    TUTOR: 2,
    GUARDIAN: 3,
    ADMIN: 4
};

export const UserTypesKeys = {
    [UserTypes.STUDENT]: "student",
    [UserTypes.TUTOR]: "tutor",
    [UserTypes.GUARDIAN]: "guardian",
    [UserTypes.ADMIN]: "admin"
};

export default UserTypes;
