const HelpIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="130"
            height="130"
            viewBox="0 0 130 130"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M65 128C99.7939 128 128 99.7939 128 65C128 30.2061 99.7939 2 65 2C30.2061 2 2 30.2061 2 65C2 99.7939 30.2061 128 65 128Z"
                stroke={color}
                strokeWidth="4"
                strokeMiterlimit="10"
            />
            <path
                d="M65 33.5V70.25"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M64.9992 96.1996C67.6502 96.1996 69.7992 94.0506 69.7992 91.3996C69.7992 88.7486 67.6502 86.5996 64.9992 86.5996C62.3483 86.5996 60.1992 88.7486 60.1992 91.3996C60.1992 94.0506 62.3483 96.1996 64.9992 96.1996Z"
                fill={color}
            />
        </svg>
    );
};
export default HelpIcon;
