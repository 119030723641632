import ReactCodeInput from "react-verification-code-input";
import "./VerifcationCodeInput.scss";

const VerifcationCodeInput = (props) => {
    const {
        className = "",
        type = "number",
        onChange,
        width,
        height,
        values,
        onComplete,
        ...rest
    } = props;
    return (
        <ReactCodeInput
            className={`tp-verification-code-input-wrap ${className}`}
            fieldWidth={width}
            fieldHeight={height}
            onChange={onChange}
            type={type}
            values={values}
            onComplete={onComplete}
            {...rest}
        />
    );
};

export default VerifcationCodeInput;
