import { connect } from "react-redux";

import "../QuestionBoard.scss";

import { Button } from "../../../components/Button";
import { TpModal } from "../../../components/TpModal";

const WithDrawQuestionModal = (props) => {
    const { userType, isOpen, handleClose, handleSubmitPress, isLoading } = props;

    return (
        <TpModal
            isOpen={isOpen}
            centered
            className={`tp-modal-${userType} size-3`}
            children={
                <div className="text-center">
                    <h3 className="mb5">Are you sure you want to withdraw this question?</h3>

                    <Button
                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                        title="No"
                        onClick={handleClose}
                        disabled={isLoading}
                    />
                    <Button
                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                        title="Yes"
                        isLoading={isLoading}
                        onClick={handleSubmitPress}
                    />
                </div>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(WithDrawQuestionModal);
