import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Wallet.scss";
import { Colors } from "../../themes/colors";

import { Slider } from "../../components/Slider";
import { TpModal } from "../../components/TpModal";
import { ProgressCircle } from "../../components/ProgressCircle";
import { Button } from "../../components/Button";
import GradientCircle from "../../components/SvgIcon/GradientCircle";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import CloseIconCircle from "../../components/SvgIcon/CloseIconCircle";
import EmailIconSmall from "../../components/SvgIcon/EmailIconSmall";
import DownloadIcon from "../../components/SvgIcon/DownloadIcon";
import PrintIcon from "../../components/SvgIcon/PrintIcon";
import ZoomInIcon from "../../components/SvgIcon/ZoomInIcon";
import ZoomOutIcon from "../../components/SvgIcon/ZoomOutIcon";
import Logo from "../../components/SvgIcon/Logo";
import pdfImg from "../../assets/images/placeholder-pdf.jpg";

const circleValRed = 35;

const Wallet = (props) => {
    const { setSelectedUser, isAuthenticated, userType } = props;
    const history = useHistory();
    const [packageDetailsModal, setPackageDetailsModal] = useState(false);
    const [packageDetailsPrintModal, setPackageDetailsPrintModal] = useState(false);

    const options = {
        margin: 102,
        items: 3,
        dots: false,
        nav: true,
        center: true,
        loop: true
    };

    return (
        <div className="tp-wallet tp-wallet-main">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>Wallet</div>
                        <div className="content-inner-wrap mt9">
                            <Button
                                className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary tp-btn-secondary-border-${userType} mr4`}
                                title="Remaining Balance"
                            />
                            <Button
                                className={`tp-btn-secondary tp-btn-secondary-${userType}`}
                                title="Request time"
                                onClick={() =>
                                    history.push(getRoute(APP_ROUTES.WALLET_SUBJECT_SELECTION))
                                }
                            />
                            <div className="text-center">
                                <h2 className="ff-sf-pro-rounded mb0 mt9">Remaining Balance</h2>
                            </div>
                            <GradientCircle
                                startColor={Colors[`${userType}Primary`]}
                                endColor={Colors[`${userType}Secondary`]}
                                id="tp-wallet-circle-grad"
                            />
                            <GradientCircle
                                startColor={Colors.red}
                                endColor={Colors.red}
                                id="tp-wallet-circle-grad-low"
                            />
                            <Slider
                                className={`tp-center-mode-active-big tp-slider-${userType}`}
                                options={options}
                                children={
                                    <Fragment>
                                        <div className="tp-wallet-card mt9">
                                            <ProgressCircle
                                                value={85}
                                                text={
                                                    <Fragment>
                                                        <div
                                                            className={`clr-${userType}-primary h1 mb3 ff-sf-pro-rounded`}
                                                        >
                                                            Spanish
                                                        </div>
                                                        <div className="h5 mb0">
                                                            10 mins remaining
                                                        </div>
                                                    </Fragment>
                                                }
                                            />
                                            <h5
                                                className={`clr-${userType}-primary text-decoration-underline mb0 title pointer`}
                                                onClick={() => {
                                                    setPackageDetailsModal(true);
                                                }}
                                            >
                                                See details
                                            </h5>
                                        </div>
                                        <div className="tp-wallet-card mt9">
                                            <ProgressCircle
                                                startColor={Colors[`${userType}Primary`]}
                                                endColor={Colors[`${userType}Secondary`]}
                                                value={85}
                                                text={
                                                    <Fragment>
                                                        <div
                                                            className={`clr-${userType}-primary h1 mb3 ff-sf-pro-rounded`}
                                                        >
                                                            Math
                                                        </div>
                                                        <div className="h5 mb0">
                                                            10 mins remaining
                                                        </div>
                                                    </Fragment>
                                                }
                                            />
                                            <h5
                                                className={`clr-${userType}-primary text-decoration-underline mb0 title pointer`}
                                                onClick={() => {
                                                    setPackageDetailsModal(true);
                                                }}
                                            >
                                                See details
                                            </h5>
                                        </div>
                                        <div className="tp-wallet-card mt9">
                                            <ProgressCircle
                                                startColor={Colors.red}
                                                endColor={Colors.red}
                                                value={circleValRed}
                                                className={`${
                                                    circleValRed < 40
                                                        ? "tp-progress-circle-low"
                                                        : null
                                                }`}
                                                text={
                                                    <Fragment>
                                                        <div
                                                            className={`${
                                                                circleValRed < 40 ? "clr-red" : null
                                                            } h1 mb3 ff-sf-pro-rounded`}
                                                        >
                                                            Biology
                                                        </div>
                                                        <div
                                                            className={`${
                                                                circleValRed < 40 ? "clr-red" : null
                                                            } h5 mb0`}
                                                        >
                                                            10 mins negative balance
                                                        </div>
                                                    </Fragment>
                                                }
                                            />
                                            <h5
                                                className={`clr-${userType}-primary text-decoration-underline mb0 title pointer`}
                                                onClick={() => {
                                                    setPackageDetailsModal(true);
                                                }}
                                            >
                                                See details
                                            </h5>
                                        </div>
                                    </Fragment>
                                }
                            />
                            <TpModal
                                isOpen={packageDetailsModal}
                                centered
                                className={`tp-modal-${userType} size-12`}
                                children={
                                    <Fragment>
                                        <div className="close-btn">
                                            <CloseIconCircle
                                                className="pointer tpw-40 tph-40"
                                                color={Colors[`${userType}Primary`]}
                                                onClick={() => {
                                                    setPackageDetailsModal(false);
                                                }}
                                            />
                                        </div>
                                        <div className="bg-clr-gray-secondary pl4 pb4 pr4 pt4 radius-7">
                                            <div className="d-flex justify-content-between mb4 align-items-center">
                                                <h2 className="ff-sf-pro-rounded mb0 ml4">
                                                    Details of maths purchases
                                                </h2>
                                                <div>
                                                    <EmailIconSmall
                                                        color={Colors.black}
                                                        className="tph-40 tpw-40 mr4 pointer"
                                                    />
                                                    <DownloadIcon
                                                        color={Colors.black}
                                                        className="tph-40 tpw-40 mr4 pointer"
                                                    />
                                                    <PrintIcon
                                                        color={Colors.black}
                                                        className="tph-40 tpw-40 pointer"
                                                        onClick={() => {
                                                            setPackageDetailsPrintModal(
                                                                !packageDetailsPrintModal
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <table className="tp-table w-100 text-center bg-white">
                                                <thead>
                                                    <th>Date answered</th>
                                                    <th>Question</th>
                                                    <th>Minutes</th>
                                                    <th>Amount spent</th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>12 - 09 - 2020</td>
                                                        <td>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elir...?
                                                        </td>
                                                        <td>15 min</td>
                                                        <td>£5.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12 - 09 - 2020</td>
                                                        <td>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elir...?
                                                        </td>
                                                        <td>15 min</td>
                                                        <td>£5.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12 - 09 - 2020</td>
                                                        <td>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elir...?
                                                        </td>
                                                        <td>15 min</td>
                                                        <td>£5.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12 - 09 - 2020</td>
                                                        <td>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elir...?
                                                        </td>
                                                        <td>15 min</td>
                                                        <td>£5.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12 - 09 - 2020</td>
                                                        <td>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elir...?
                                                        </td>
                                                        <td>15 min</td>
                                                        <td>£5.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12 - 09 - 2020</td>
                                                        <td>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elir...?
                                                        </td>
                                                        <td>15 min</td>
                                                        <td>£5.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Fragment>
                                }
                            />
                            <TpModal
                                isOpen={packageDetailsPrintModal}
                                centered
                                className={`tp-modal-${userType} size-5`}
                                children={
                                    <Fragment>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>
                                                <ZoomInIcon
                                                    color={Colors.black}
                                                    className="tph-40 tpw-40 mr4"
                                                />
                                                <ZoomOutIcon
                                                    color={Colors.black}
                                                    className="tph-40 tpw-40"
                                                />
                                            </div>
                                            <div>
                                                <Button
                                                    className={`tp-btn-primary tp-btn-primary-${userType} mr9`}
                                                    title="Print"
                                                />
                                                <CloseIconCircle
                                                    className="pointer tpw-40 tph-40"
                                                    color={Colors[`${userType}Primary`]}
                                                    onClick={() => {
                                                        setPackageDetailsPrintModal(false);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="bg-clr-gray-secondary pr4 pl4 pt4 pb4 mt4">
                                            <Logo
                                                color={Colors[`${userType}Primary`]}
                                                className="tpw-70 tph-40 mb3"
                                            />
                                            <h6 className="mb3">Remaining credit for Maths</h6>
                                            <img src={pdfImg} className="img-fluid" alt="" />
                                        </div>
                                    </Fragment>
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    // setSelectedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
