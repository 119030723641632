const PrintIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="34"
            height="31"
            viewBox="0 0 34 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7 7.5V1.25H27V7.5"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27 18.75H7V29.375H27V18.75Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 22.5H1.375V10C1.375 8.61929 2.58756 7.5 4.08333 7.5H29.9167C31.4124 7.5 32.625 8.61929 32.625 10V22.5H27"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.375 15C27.4105 15 28.25 14.1605 28.25 13.125C28.25 12.0895 27.4105 11.25 26.375 11.25C25.3395 11.25 24.5 12.0895 24.5 13.125C24.5 14.1605 25.3395 15 26.375 15Z"
                fill={color}
            />
        </svg>
    );
};
export default PrintIcon;
