import React, { Fragment, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import { TpModal } from "../../../components/TpModal";
import ZoomInIcon from "../../../components/SvgIcon/ZoomInIcon";
import ZoomOutIcon from "../../../components/SvgIcon/ZoomOutIcon";
import EmailIconSmall from "../../../components/SvgIcon/EmailIconSmall";
import DownloadIcon from "../../../components/SvgIcon/DownloadIcon";
import PrintIcon from "../../../components/SvgIcon/PrintIcon";
import CloseIconCircle from "../../../components/SvgIcon/CloseIconCircle";
import AvatarImg from "../../../assets/images/placeholder-profile.jpg";
import { Colors } from "../../../themes/colors";

const ImagePreviewModal = (props) => {
    const { userType, isOpen, localPreviewImage, handleModalClose } = props;

    return (
        <TpModal
            isOpen={isOpen}
            centered
            className={`tp-modal-${userType} question-gallery-modal size-12`}
            children={
                <Fragment>
                    <Row className="align-items-center">
                        <Col md={4}>
                            <ZoomInIcon color={Colors.black} className="mr5" />
                            <ZoomOutIcon color={Colors.black} />
                        </Col>
                        <Col md={8} className="text-end">
                            <EmailIconSmall color={Colors.black} className="mr5" />
                            <DownloadIcon color={Colors.black} className="mr5" />
                            <PrintIcon color={Colors.black} className="mr5" />
                            <CloseIconCircle color={Colors.black} onClick={handleModalClose} />
                        </Col>
                    </Row>
                    <div className="gallery-images text-center mt10 justify-content-center">
                        <img src={localPreviewImage} className="img-fluid"></img>
                    </div>
                </Fragment>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(ImagePreviewModal);
