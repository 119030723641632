const ClarityQuestionIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.2654 24.5391C17.9897 24.5391 17.7139 24.4288 17.5485 24.2633C17.383 24.0979 17.2727 23.8222 17.2727 23.5464C17.2727 22.8847 17.4382 22.1126 17.7691 21.3405C18.1 20.5685 18.5963 19.8516 19.1477 19.3001C19.6992 18.8038 20.1955 18.3074 20.6919 17.8663C21.1882 17.4251 21.5742 16.8736 21.85 16.3222C22.1257 15.7707 22.2911 15.0538 22.2911 14.1714C22.2911 13.5097 22.1257 12.903 21.7948 12.3516C21.4639 11.8001 21.0227 11.3589 20.4161 11.028C19.8095 10.6972 19.1477 10.5317 18.3205 10.5317C17.4382 10.5317 16.7213 10.6972 16.1147 11.0832C15.508 11.4141 15.0117 11.9104 14.6808 12.517C14.35 13.1236 14.1845 13.8405 14.1845 14.6677C14.1845 14.9435 14.0742 15.2192 13.9088 15.3847C13.7433 15.5501 13.4676 15.6604 13.1919 15.6604C12.9161 15.6604 12.6404 15.5501 12.475 15.3847C12.3095 15.2192 12.1992 14.9435 12.1992 14.6677C12.1992 13.5097 12.475 12.4619 12.9713 11.5244C13.4676 10.642 14.1845 9.92509 15.122 9.37362C16.0595 8.8773 17.1073 8.60156 18.3205 8.60156C19.4786 8.60156 20.4713 8.82215 21.3536 9.31847C22.236 9.8148 22.9529 10.4766 23.4492 11.3038C23.9455 12.131 24.2213 13.1236 24.2213 14.2266C24.2213 15.1641 24.0558 15.9913 23.725 16.653C23.3941 17.3148 22.9529 17.9214 22.3463 18.4729C21.7948 19.0244 21.1882 19.631 20.5264 20.2376C19.975 20.7339 19.6441 21.2854 19.4786 21.8369C19.3132 22.4435 19.2029 22.9949 19.2029 23.4361C19.2029 23.767 19.0926 23.9876 18.9272 24.2082C18.7617 24.4288 18.5411 24.5391 18.2654 24.5391ZM18.2654 30.2192C17.8242 30.2192 17.4933 30.0538 17.2176 29.778C16.9419 29.5023 16.7764 29.1163 16.7764 28.7302C16.7764 28.2891 16.9419 27.9582 17.2176 27.6273C17.4933 27.3516 17.8794 27.1861 18.2654 27.1861C18.6514 27.1861 19.0375 27.3516 19.3132 27.6273C19.5889 27.903 19.7544 28.2891 19.7544 28.7302C19.7544 29.1163 19.5889 29.5023 19.3132 29.778C19.0375 30.0538 18.7066 30.2192 18.2654 30.2192Z"
                fill={color}
                stroke={color}
                strokeWidth="0.25"
                strokeMiterlimit="10"
            />
            <path
                d="M3.98553 27.1145C1.88892 23.5801 1.15467 19.4019 1.92065 15.3645C2.68664 11.327 4.90016 7.7081 8.14557 5.18722C11.391 2.66634 15.445 1.41692 19.5465 1.67357C23.6479 1.93023 27.5145 3.67531 30.4204 6.58112C33.3262 9.48693 35.0713 13.3536 35.328 17.455C35.5846 21.5564 34.3352 25.6105 31.8144 28.8559C29.2935 32.1013 25.6746 34.3149 21.6372 35.0809C17.5997 35.8469 13.4215 35.1127 9.88716 33.0161L9.8872 33.0159L4.0589 34.6811C3.81776 34.75 3.56259 34.7532 3.31982 34.6903C3.07705 34.6274 2.85553 34.5007 2.67819 34.3233C2.50086 34.146 2.37418 33.9245 2.31127 33.6817C2.24837 33.4389 2.25152 33.1838 2.32042 32.9426L3.98565 27.1143L3.98553 27.1145Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default ClarityQuestionIcon;
