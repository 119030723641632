import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { TpModal } from "../../../components/TpModal";
import { Button } from "../../../components/Button";
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers";

const QuestionTypeModal = (props) => {
    const { userType, isOpen, questionId } = props;
    const history = useHistory();

    return (
        <TpModal
            isOpen={isOpen}
            centered
            className={`tp-modal-${userType} size-3`}
            children={
                <div className="text-center">
                    <div>
                        <h3 className="h3 mb9">How would you like to proceed?</h3>
                        <div>
                            <Button
                                className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border-${userType} mr2`}
                                title="Follow up question"
                                onClick={() =>
                                    history.push(
                                        getRoute(APP_ROUTES.ASK_FOLLOW_UP_QUESTION, {
                                            id: questionId
                                        })
                                    )
                                }
                            />
                            <Button
                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                title="Clarity question"
                                onClick={() =>
                                    history.push(
                                        getRoute(APP_ROUTES.ASK_CLARITY_QUESTION, {
                                            id: questionId
                                        })
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(QuestionTypeModal);
