export const CHANGE_SOCKET_CONNECTIVITY_STATUS = "CHANGE_SOCKET_CONNECTIVITY_STATUS";
export const IS_TOKEN_VERIFIED = "IS_TOKEN_VERIFIED";
export const SET_PERSONAL_DETAILS = "SET_PERSONAL_DETAILS";

export const changeSocketConnectivityStatus = (isConnected) => {
    return { type: CHANGE_SOCKET_CONNECTIVITY_STATUS, payload: isConnected };
};

export const setPersonalDetails = (bodyData) => {
    return {
        type: SET_PERSONAL_DETAILS,
        payload: bodyData
    };
};
