const ReportIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="128"
            height="128"
            viewBox="0 0 128 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M41.5 41.5H86.5"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M41.5 64H86.5"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M41.5 86.5H64"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M84.1701 125.875H7.75C6.25816 125.875 4.82742 125.282 3.77253 124.227C2.71763 123.173 2.125 121.742 2.125 120.25V7.75C2.125 6.25816 2.71763 4.82742 3.77253 3.77253C4.82742 2.71763 6.25816 2.125 7.75 2.125H120.25C121.742 2.125 123.173 2.71763 124.227 3.77253C125.282 4.82742 125.875 6.25816 125.875 7.75V84.1701C125.875 84.9087 125.73 85.6402 125.447 86.3226C125.164 87.0051 124.75 87.6252 124.227 88.1475L88.1475 124.227C87.6252 124.75 87.0051 125.164 86.3226 125.447C85.6402 125.73 84.9087 125.875 84.1701 125.875V125.875Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M125.366 86.499H86.5V125.368"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default ReportIcon;
