import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import "./TpDropdownPopup.scss";

const TpDropdownPopup = (props) => {
    const { className = "", children, trigger, fancy, isOpen, toggle } = props;
    const checkFancyEnable = fancy ? "-2px" : "16px";
    const checkFancyEnableDrop = fancy ? "1px" : "0px";
    return (
        <Dropdown
            isOpen={isOpen}
            toggle={toggle}
            className={`tp-dropdown-popup ${className} ${fancy && "fancy-dropdown-popup"}`}
        >
            <DropdownToggle onClick={toggle} tag="div" className="pointer dropdown-trigger">
                {trigger}
            </DropdownToggle>
            <DropdownMenu
                right
                modifiers={{
                    setTopPosition: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    top: checkFancyEnable,
                                    left: checkFancyEnableDrop
                                }
                            };
                        }
                    }
                }}
            >
                {children}
            </DropdownMenu>
        </Dropdown>
    );
};

export default TpDropdownPopup;
