import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Card } from "../../components/Card";
import { Button } from "../../components/Button";

import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import GuardianIcon from "../../components/SvgIcon/GuardianIcon";
import StudentIcon from "../../components/SvgIcon/StudentIcon";

const SettingsInvite = (props) => {
    const { userType } = props;
    const history = useHistory();

    const handleCardClick = (routeName, type) => {
        history.push(getRoute(routeName));
    };

    return (
        <div className="tp-settings tp-settings-user-invite">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>Send Invite</div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to my network"
                                className={`mt9 mb3 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <h1 className="mb0 text-center">Who would you like to invite?</h1>
                            <Row className="text-webkit-center">
                                <Col md={6}>
                                    <Card
                                        svg={<GuardianIcon color={Colors[`${userType}Primary`]} />}
                                        title="Guardian"
                                        onClick={() =>
                                            handleCardClick(APP_ROUTES.SETTINGS_INVITE_GUARDIAN)
                                        }
                                        className={`mt9 tp-card-lg tp-card-${userType}`}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Card
                                        svg={<StudentIcon color={Colors[`${userType}Primary`]} />}
                                        title="Student"
                                        onClick={() =>
                                            handleCardClick(APP_ROUTES.SETTINGS_INVITE_STUDENT)
                                        }
                                        className={`mt9 tp-card-lg tp-card-${userType}`}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(SettingsInvite);
