import { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./KnowledgeBank.scss";
import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { Button } from "../../components/Button";
import { TpModal } from "../../components/TpModal";
import { AccordionTab } from "../../components/AccordionTab";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import { TextInput } from "../../components/TextInput";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import InfoIcon from "../../components/SvgIcon/InfoIcon";
import PencilIcon from "../../components/SvgIcon/PencilIcon";
import FileArrowIcon from "../../components/SvgIcon/FileArrowIcon";
import SendIcon from "../../components/SvgIcon/SendIcon";
import DeleteIcon from "../../components/SvgIcon/DeleteIcon";
import CheckIconCircle from "../../components/SvgIcon/CheckIconCircle";
import AudioIcon from "../../components/SvgIcon/AudioIcon";
import PlayIcon from "../../components/SvgIcon/PlayIcon";
import CropIcon from "../../components/SvgIcon/CropIcon";
import MuteIcon from "../../components/SvgIcon/MuteIcon";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";
import pdfImg from "../../assets/images/placeholder-pdf.jpg";

const ResourceDetails = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [moveResourceModal, setMoveResourceModal] = useState(false);
    const [confirmMoveResourceModal, setConfirmMoveResourceModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [deleteResourceModal, setDeleteResourceModal] = useState(false);
    const [deleteResourceConfirmModal, setDeleteResourceConfirmModal] = useState(false);
    const [confirmCropModal, setConfirmCropModal] = useState(false);
    const [sendDescriptionModal, setSendDescriptionModal] = useState(false);
    const [sendDescriptionPreviewModal, setSendDescriptionPreviewModal] = useState(false);
    const [toggleInfo, setToggleInfo] = useState(false);
    const [timeSpentModal, setTimeSpentModal] = useState(false);
    const [sendConfirmModal, setSendConfirmModal] = useState(false);

    const handleMoveModal = () => {
        setConfirmMoveResourceModal(true);
        setMoveResourceModal(false);
    };

    const handleSendModal = () => {
        setSendModal(false);
        setSendDescriptionModal(true);
    };

    const handleDescriptionModal = () => {
        setSendDescriptionPreviewModal(true);
        setSendDescriptionModal(false);
    };

    const handleDescriptionBack = () => {
        setSendModal(true);
        setSendDescriptionModal(false);
    };

    const handleAnswerPreviewEdit = () => {
        setSendDescriptionModal(true);
        setSendDescriptionPreviewModal(false);
    };

    const handleAnswerPreviewSend = () => {
        setSendDescriptionPreviewModal(false);
        setTimeSpentModal(true);
    };

    const handleTimeSpentCancel = () => {
        setTimeSpentModal(false);
        setSendDescriptionPreviewModal(true);
    };

    const handleTimeSpentSend = () => {
        setTimeSpentModal(false);
        setSendConfirmModal(true);
    };

    const handleDeleteConfirm = () => {
        setDeleteResourceModal(false);
        setDeleteResourceConfirmModal(true);
    };

    return (
        <div className="tp-knowledge-bank tp-resouce-details">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Resource: Nugget.jpg
                        </div>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to all resources"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <Row>
                                <Col md={9}>
                                    <div
                                        className={`resource-holder-${userType} resource-holder resource-image radius-7 mb4`}
                                    >
                                        <img
                                            src={AvatarImg}
                                            className="img-fluid radius-11"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className={`resource-holder-${userType} resource-holder resource-audio radius-7 mb4`}
                                    >
                                        <div className="resource-audio-inner">
                                            <div
                                                className={`resource-audio-details-${userType} resource-audio-details radius-7 d-flex`}
                                            >
                                                <div className="clr-white resource-audio-text">
                                                    You are listening:
                                                </div>
                                                <div className="clr-white resource-audio-name text-center fw-600 m-auto">
                                                    <AudioIcon
                                                        color={Colors.white}
                                                        className="tph-40 tpw-40 mr4"
                                                    />
                                                    Les grandes marches
                                                </div>
                                            </div>
                                            <div
                                                className="resource-controls resource-audio-controls d-flex align-items-center"
                                                id="resource-controls-wrap"
                                            >
                                                <audio
                                                    id="resource-controls-wrap"
                                                    className="d-none"
                                                >
                                                    <source src="horse.ogg" type="audio/ogg" />
                                                    <source src="horse.mp3" type="audio/mpeg" />
                                                </audio>
                                                <Button
                                                    id="btnPlayPause"
                                                    className={`tp-btn-primary tp-btn-primary-${userType} play-pause-btn h-100 rounded-0`}
                                                    title={
                                                        <PlayIcon
                                                            color={Colors.white}
                                                            className="tpw-40 tph-40"
                                                        />
                                                    }
                                                    onclick="playPauseAudio();"
                                                />
                                                <div className="progess-wrap w-100">
                                                    <div className="progressbar">
                                                        <input
                                                            id="progressbar"
                                                            className=""
                                                            type="range"
                                                            min="1"
                                                            max="100"
                                                            value="0"
                                                        />
                                                    </div>
                                                    <h6 className="d-flex justify-content-between mb0 progres-time">
                                                        <span>0:00</span>
                                                        <span>14:00</span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`resource-holder-${userType} resource-holder resource-video radius-7`}
                                    >
                                        <div className="resource-video-inner">
                                            <div className="resource-video-details">
                                                <video
                                                    width="100%"
                                                    height="483"
                                                    poster={AvatarImg}
                                                    controls
                                                    autoPlay
                                                >
                                                    <source
                                                        src="https://static.videezy.com/system/resources/previews/000/048/014/original/Countdown1.mp4"
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                            <div
                                                className="resource-controls resource-video-controls d-flex align-items-center"
                                                id="resource-controls-wrap"
                                            >
                                                <Button
                                                    id="btnPlayPause"
                                                    className={`tp-btn-primary tp-btn-primary-${userType} play-pause-btn h-100 rounded-0`}
                                                    title={
                                                        <PlayIcon
                                                            color={Colors.white}
                                                            className="tpw-30 tph-30"
                                                        />
                                                    }
                                                    onclick="playPauseAudio();"
                                                />
                                                <div className="progess-wrap w-100">
                                                    <div className="progressbar">
                                                        <input
                                                            id="progressbar"
                                                            className=""
                                                            type="range"
                                                            min="1"
                                                            max="100"
                                                            value="0"
                                                        />
                                                    </div>
                                                    <h6 className="d-flex justify-content-between mb0 progres-time">
                                                        <span>0:00</span>
                                                        <span>14:00</span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-md-end mt9">
                                        <Button
                                            className={`tp-btn-secondary tp-btn-secondary-${userType} mr4`}
                                            title={
                                                <Fragment>
                                                    <MuteIcon
                                                        color={Colors[`${userType}Primary`]}
                                                        className="tpw-25 tph-25 mr2"
                                                    />
                                                    <span>Mute</span>
                                                </Fragment>
                                            }
                                        />
                                        <Button
                                            className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border tp-btn-secondary-border-${userType}  mr4`}
                                            title={
                                                <Fragment>
                                                    <CropIcon
                                                        color={Colors[`${userType}Primary`]}
                                                        className="tpw-25 tph-25 mr2"
                                                    />
                                                    <span>Crop</span>
                                                </Fragment>
                                            }
                                        />
                                        <Button
                                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                                            title="Done"
                                            onClick={() => {
                                                setConfirmCropModal(!confirmCropModal);
                                            }}
                                        />
                                        <TpModal
                                            isOpen={confirmCropModal}
                                            centered
                                            className={`tp-modal-${userType} size-3`}
                                            children={
                                                <div className="text-center">
                                                    <h3 className="mb9">
                                                        Do you want to save the changes?
                                                    </h3>
                                                    <Button
                                                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                                                        title="Yes"
                                                    />
                                                    <Button
                                                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                        title="No"
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="resource-details-actions text-md-end">
                                        <div className="action-info">
                                            <Button
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border-${userType}`}
                                                title={
                                                    <Fragment>
                                                        <InfoIcon
                                                            color={Colors[`${userType}Primary`]}
                                                            className="mr2 tpw-25 tph-25"
                                                        />
                                                        Information
                                                    </Fragment>
                                                }
                                                onClick={() => {
                                                    setToggleInfo(true);
                                                }}
                                            />
                                        </div>
                                        <div className="action-edit">
                                            <Button
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt4`}
                                                title={
                                                    <Fragment>
                                                        <PencilIcon
                                                            color={Colors[`${userType}Primary`]}
                                                            className="mr2 tpw-25 tph-25"
                                                        />
                                                        Edit
                                                    </Fragment>
                                                }
                                                onClick={() =>
                                                    history.push(
                                                        getRoute(APP_ROUTES.EDIT_RESOURCE_IMAGE)
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="action-move">
                                            <Button
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt4`}
                                                title={
                                                    <Fragment>
                                                        <FileArrowIcon
                                                            color={Colors[`${userType}Primary`]}
                                                            className="mr2 tpw-25 tph-25"
                                                        />
                                                        Move
                                                    </Fragment>
                                                }
                                                onClick={() => {
                                                    setMoveResourceModal(!moveResourceModal);
                                                }}
                                            />
                                            <TpModal
                                                isOpen={moveResourceModal}
                                                centered
                                                className={`tp-modal-${userType} size-3`}
                                                children={
                                                    <AvForm>
                                                        <h2 className="mb4 ff-sf-pro-rounded">
                                                            Move file: Nuggest.jpg
                                                        </h2>
                                                        <h3 className="mb4">Current location</h3>
                                                        <div className="text-center">
                                                            <h3 className="mb2">
                                                                Subject: Gastronomy
                                                            </h3>
                                                            <h3 className="mb4">
                                                                Topic: Junk food
                                                            </h3>
                                                        </div>
                                                        <h3 className="mb4">Move to</h3>
                                                        <CreatableDropdown
                                                            placeholder="Select the Subject"
                                                            options={[
                                                                {
                                                                    label: "Math"
                                                                },
                                                                {
                                                                    label: "Biology"
                                                                },
                                                                {
                                                                    label: "Spanish"
                                                                },
                                                                {
                                                                    label: "German"
                                                                },
                                                                {
                                                                    label: "Italian"
                                                                }
                                                            ]}
                                                            className={`mw-100 dropdown-size-sm mt4 bg-fill radius-thin tp-creatable-dropdown-${userType}`}
                                                            dropIconColor={
                                                                Colors[`${userType}Primary`]
                                                            }
                                                        />
                                                        <CreatableDropdown
                                                            placeholder="Select the Topic"
                                                            options={[
                                                                {
                                                                    label: "Math"
                                                                },
                                                                {
                                                                    label: "Biology"
                                                                },
                                                                {
                                                                    label: "Spanish"
                                                                },
                                                                {
                                                                    label: "German"
                                                                },
                                                                {
                                                                    label: "Italian"
                                                                }
                                                            ]}
                                                            className={`mw-100 dropdown-size-sm mt4 bg-fill radius-thin tp-creatable-dropdown-${userType}`}
                                                            dropIconColor={
                                                                Colors[`${userType}Primary`]
                                                            }
                                                        />
                                                        <div className="text-center">
                                                            <Button
                                                                onClick={handleMoveModal}
                                                                className={`tp-btn-primary tp-btn-primary-${userType} mt9 mr4`}
                                                                title="Cancel"
                                                            />
                                                            <Button
                                                                onClick={handleMoveModal}
                                                                className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                                                title="OK"
                                                            />
                                                        </div>
                                                    </AvForm>
                                                }
                                            />
                                            <TpModal
                                                isOpen={confirmMoveResourceModal}
                                                centered
                                                className={`tp-modal-${userType} size-1`}
                                                children={
                                                    <div className="text-center">
                                                        <CheckIconCircle type={`${userType}`} />
                                                        <h3 className="mt9 mb2 ff-sf-pro-rounded">
                                                            The file: Nuggest.jpg
                                                        </h3>
                                                        <h3 className="mb2 ff-sf-pro-rounded">
                                                            Has been moved to
                                                        </h3>
                                                        <h3 className="mb2 ff-sf-pro-rounded">
                                                            Subject: Media
                                                        </h3>
                                                        <h3 className="mb9 ff-sf-pro-rounded">
                                                            Topic: Meme
                                                        </h3>
                                                        <Button
                                                            onClick={() => {
                                                                setConfirmMoveResourceModal(false);
                                                            }}
                                                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                            title="OK"
                                                        />
                                                    </div>
                                                }
                                            />
                                        </div>
                                        <div className="action-send">
                                            <Button
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt4`}
                                                title={
                                                    <Fragment>
                                                        <SendIcon
                                                            color={Colors[`${userType}Primary`]}
                                                            className="mr2 tpw-25 tph-25"
                                                        />
                                                        Send
                                                    </Fragment>
                                                }
                                                onClick={() => {
                                                    setSendModal(!sendModal);
                                                }}
                                            />
                                            <TpModal
                                                isOpen={sendModal}
                                                centered
                                                className={`tp-modal-${userType} size-11`}
                                                children={
                                                    <>
                                                        <div className="d-flex justify-content-between mb4">
                                                            <h3 className="mb0">
                                                                Which question do you want to
                                                                answer?
                                                            </h3>
                                                            <AvForm>
                                                                <TextInput
                                                                    name="search"
                                                                    placeholder=""
                                                                    type="search"
                                                                    className={`icon-right tp-text-input-${userType}`}
                                                                />
                                                            </AvForm>
                                                        </div>
                                                        <div
                                                            onClick={handleSendModal}
                                                            className="pointer"
                                                        >
                                                            <AccordionTab
                                                                titleColor={userType}
                                                                dropDownColor={
                                                                    Colors[`${userType}Primary`]
                                                                }
                                                                title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                                                content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                                                className="bg-clr-fill mb4"
                                                                isOpen={true}
                                                            />
                                                        </div>
                                                        <AccordionTab
                                                            titleColor={userType}
                                                            dropDownColor={
                                                                Colors[`${userType}Primary`]
                                                            }
                                                            title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                                            content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                                            className="bg-clr-fill mb4"
                                                        />
                                                        <AccordionTab
                                                            titleColor={userType}
                                                            dropDownColor={
                                                                Colors[`${userType}Primary`]
                                                            }
                                                            title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                                            content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                                            className="bg-clr-fill mb4"
                                                        />
                                                        <AccordionTab
                                                            titleColor={userType}
                                                            dropDownColor={
                                                                Colors[`${userType}Primary`]
                                                            }
                                                            title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                                            content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                                            className="bg-clr-fill mb4"
                                                        />
                                                        <AccordionTab
                                                            titleColor={userType}
                                                            dropDownColor={
                                                                Colors[`${userType}Primary`]
                                                            }
                                                            title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                                            content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                                            className="bg-clr-fill mb4"
                                                        />
                                                        <AccordionTab
                                                            titleColor={userType}
                                                            dropDownColor={
                                                                Colors[`${userType}Primary`]
                                                            }
                                                            title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                                            content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                                            className="bg-clr-fill mb4"
                                                        />
                                                        <AccordionTab
                                                            titleColor={userType}
                                                            dropDownColor={
                                                                Colors[`${userType}Primary`]
                                                            }
                                                            title="Lorem ipsum dolor sit amet, ipsum dolor sit amet con?"
                                                            content="Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con. Lorem ipsum dolor sit amet, ips um dolor sit amet con, ips um dolor sit amet conips um dolor sit amet con."
                                                            className="bg-clr-fill mb4"
                                                        />
                                                        <div className="text-md-end">
                                                            <Button
                                                                onClick={() => {
                                                                    setSendModal(false);
                                                                }}
                                                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                                title="Cancel"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            />
                                            <TpModal
                                                isOpen={sendDescriptionModal}
                                                centered
                                                className={`tp-modal-${userType} size-6`}
                                                children={
                                                    <div className="text-center">
                                                        <h3 className="mb9">
                                                            Please add a description
                                                        </h3>
                                                        <AvForm>
                                                            <TextInput
                                                                name="senddesc"
                                                                placeholder="Add your comments"
                                                                type="textarea"
                                                                className={`radius-7 resize-none size-md tp-text-input-${userType}`}
                                                                options={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage:
                                                                            "This field is required"
                                                                    }
                                                                }}
                                                            />
                                                            <Button
                                                                className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4 mt9`}
                                                                title="Back"
                                                                onClick={handleDescriptionBack}
                                                            />
                                                            <Button
                                                                className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                                                title="Next"
                                                                onClick={handleDescriptionModal}
                                                            />
                                                        </AvForm>
                                                    </div>
                                                }
                                            />
                                            <TpModal
                                                isOpen={sendDescriptionPreviewModal}
                                                centered
                                                className={`tp-modal-${userType} size-6`}
                                                children={
                                                    <Row>
                                                        <Col xs={12}>
                                                            <h2 className="mb4">Answer preview</h2>
                                                        </Col>
                                                        <Col md={2}>
                                                            <span className="d-inline-block text-center">
                                                                <img
                                                                    src={AvatarImg}
                                                                    className="img-fluid rounded-circle tpw-50 tph-50 objfit-cover"
                                                                    alt=""
                                                                />
                                                                <h4 className="ff-sf-pro-rounded mt1">
                                                                    Jane
                                                                </h4>
                                                            </span>
                                                        </Col>
                                                        <Col md={10}>
                                                            <h6 className="opacity-50 expiry-date">
                                                                Expires on: Monday 12th May 2021,
                                                                10:03 am
                                                            </h6>
                                                            <p>
                                                                Lorem ipsum dolor sit amet,
                                                                consectetur adipiscing elit, sed do
                                                                eiusmod tempor incididunt ut labore
                                                                dolor sit amet, consectetur
                                                                adipiscing elit?
                                                            </p>
                                                            <img
                                                                src={pdfImg}
                                                                className="img-fluid tpw-123 mb4"
                                                            />
                                                        </Col>
                                                        <Col xs={12}>
                                                            <h4
                                                                className={`clr-${userType}-primary`}
                                                            >
                                                                Description:
                                                            </h4>
                                                            <p>
                                                                Lorem ipsum dolor sit amet,
                                                                consectetur adipiscing elit, sed do
                                                                eiusmod tempor incididunt ut labore
                                                                dolor sit amet, consectetur
                                                                adipiscing elit. Lorem ipsum dolor
                                                                sit amet, consectetur adipiscing
                                                                elit, sed do eiusmod tempor
                                                                incididunt ut labore dolor sit amet,
                                                                consectetur adipiscing elit. Lorem
                                                                ipsum dolor sit amet, consectetur
                                                                adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore dolor
                                                                sit amet, consectetur adipiscing
                                                                elit.
                                                            </p>
                                                        </Col>
                                                        <Col className="mt9 text-center" xs={12}>
                                                            <Button
                                                                className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                                                                title="Edit"
                                                                onClick={handleAnswerPreviewEdit}
                                                            />
                                                            <Button
                                                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                                title="Send"
                                                                onClick={handleAnswerPreviewSend}
                                                            />
                                                        </Col>
                                                    </Row>
                                                }
                                            />
                                            <TpModal
                                                isOpen={timeSpentModal}
                                                centered
                                                className={`tp-modal-${userType} size-3`}
                                                children={
                                                    <AvForm className="text-center">
                                                        <h3 className="mb5">
                                                            How much time have you spent on this
                                                            answer?
                                                        </h3>
                                                        <div className="mb9 col-md-8 offset-md-2">
                                                            <TextInput
                                                                placeholder="Enter Time Spent"
                                                                name="timespent"
                                                                className={`radius-7 tp-text-input-${userType}`}
                                                                options={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage:
                                                                            "This field is required"
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <Button
                                                            className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                                                            title="Cancel"
                                                            onClick={handleTimeSpentCancel}
                                                        />
                                                        <Button
                                                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                            title="Send"
                                                            onClick={handleTimeSpentSend}
                                                        />
                                                    </AvForm>
                                                }
                                            />
                                            <TpModal
                                                isOpen={sendConfirmModal}
                                                centered
                                                className={`tp-modal-${userType} size-1`}
                                                children={
                                                    <div className="text-center">
                                                        <CheckIconCircle type={`${userType}`} />
                                                        <h3 className="mt9 mb9 ff-sf-pro-rounded">
                                                            The answer has been sent
                                                        </h3>
                                                        <Button
                                                            onClick={() => {
                                                                setSendConfirmModal(false);
                                                            }}
                                                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                            title="OK"
                                                        />
                                                    </div>
                                                }
                                            />
                                        </div>
                                        <div className="action-delete">
                                            <Button
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt4`}
                                                title={
                                                    <Fragment>
                                                        <DeleteIcon
                                                            color={Colors[`${userType}Primary`]}
                                                            className="mr2 tpw-25 tph-25"
                                                        />
                                                        Delete
                                                    </Fragment>
                                                }
                                                onClick={() => {
                                                    setDeleteResourceModal(!deleteResourceModal);
                                                }}
                                            />
                                            <TpModal
                                                isOpen={deleteResourceModal}
                                                centered
                                                className={`tp-modal-${userType} size-3`}
                                                children={
                                                    <div className="text-center">
                                                        <h3 className="mb9">
                                                            Are you sure you want to delete this
                                                            file?
                                                        </h3>
                                                        <Button
                                                            className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                                                            title="Yes"
                                                            onClick={handleDeleteConfirm}
                                                        />
                                                        <Button
                                                            onClick={() => {
                                                                setDeleteResourceModal(false);
                                                            }}
                                                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                            title="No"
                                                        />
                                                    </div>
                                                }
                                            />
                                            <TpModal
                                                isOpen={deleteResourceConfirmModal}
                                                centered
                                                className={`tp-modal-${userType} size-1`}
                                                children={
                                                    <div className="text-center">
                                                        <CheckIconCircle type={`${userType}`} />
                                                        <h3 className="mt9 mb9 ff-sf-pro-rounded">
                                                            The file has been deleted
                                                        </h3>
                                                        <Button
                                                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                            title="OK"
                                                            onClick={() => {
                                                                setDeleteResourceConfirmModal(
                                                                    false
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            />
                                        </div>
                                        {toggleInfo && (
                                            <div
                                                className={`action-info-about-resource action-info-about-resource-${userType} mt4 ms-auto text-start`}
                                            >
                                                <h5 className={`clr-${userType}-primary`}>
                                                    Name: Nugget
                                                </h5>
                                                <h5 className={`clr-${userType}-primary`}>
                                                    Date: 22- 09-1992
                                                </h5>
                                                <h5 className={`clr-${userType}-primary`}>
                                                    Time Charged: 15 min
                                                </h5>
                                                <div className="resource-tags">
                                                    <h5 className="mb0">#biology</h5>
                                                    <h5 className="mb0">#hohoho</h5>
                                                    <h5 className="mb0">#hihihi</h5>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(ResourceDetails);
