import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";

import "./QuestionBoard.scss";

import { getQuestionById, getQuestionThreads } from "../../store/actions/questionBoardActions";
import { getMediaPath } from "../../utils/mediaUtils";
import { Spinner } from "../../components/Loader";
import ImagePreviewModal from "./AskQuestionModals/ImagePreviewModal";

const QuestionAnswerThread = (props) => {
    const { userType, questionThreads } = props;
    const dispatch = useDispatch();
    const { id } = useParams();
    const [questionGalleryModal, setQuestionGalleryModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(getQuestionThreads(id));
        dispatch(getQuestionById(id))
            .then((res) => {
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }, []);

    const renderMedia = (questionDetail) => {
        return questionDetail?.Media && questionDetail?.Media?.length
            ? questionDetail?.Media?.map((media) => (
                  <img src={getMediaPath(media)} className="img-fluid"></img>
              ))
            : null;
    };

    return (
        <>
            {!isLoading && !!questionThreads.length
                ? questionThreads.map((question, index) => (
                      <div className="question-details-wrap mb-4" key={index}>
                          <Row>
                              <Col md={"auto"}>
                                  <div className="avatar-img">
                                      <img
                                          src={getMediaPath(question?.User?.Media)}
                                          className="img-fluid rounded-circle tpw-50 tph-50 objfit-cover"
                                      />
                                  </div>
                                  <h4 className="ff-sf-pro-rounded mt1 text-center">
                                      {question?.User?.FullName}
                                  </h4>
                              </Col>
                              <Col md={11}>
                                  <div className="question-details">
                                      <h6 className="opacity-50 expiry-date">
                                          {`Expires on: ${moment(question.CreatedAt)
                                              .add(7, "days")
                                              .format("dddd Do MMMM YYYY, hh:mm a")}`}
                                      </h6>
                                      <p>{question?.Title}</p>
                                      <div
                                          className="gallery-images"
                                          onClick={() => {
                                              setQuestionGalleryModal(!questionGalleryModal);
                                          }}
                                      >
                                          {renderMedia()}
                                      </div>
                                      <h4 className={`clr-${userType}-primary`}>Description:</h4>
                                      <p>{question?.Description}</p>
                                  </div>
                              </Col>
                          </Row>
                      </div>
                  ))
                : (isLoading || (!isLoading && !questionThreads.length)) && (
                      <Spinner
                          textMessage="Question Details Not Available"
                          isLoading={isLoading}
                          showDataNotAvailableText={!isLoading && !questionThreads.length}
                      />
                  )}
            <ImagePreviewModal isOpen={false} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType,
        user: state.auth.user,
        questionThreads: state.questionBoard.questionThreads
    };
};

export default connect(mapStateToProps)(QuestionAnswerThread);
