const DropFilesIcon = (props) => {
    const { className, onClick, color, bgColor } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="150"
            height="148"
            viewBox="0 0 150 148"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="74" cy="73" r="73" fill={bgColor} />
            <path
                d="M148 73.0017C148 86.7526 144.116 100.224 136.796 111.864C129.475 123.505 119.016 132.841 106.622 138.797C94.228 144.754 80.4038 147.089 66.7411 145.533C53.0785 143.977 40.1331 138.594 29.3957 130.004C18.6582 121.413 10.5655 109.965 6.04932 96.9767C1.53317 83.9885 0.777296 69.9889 3.86875 56.59C6.9602 43.1911 13.7732 30.9378 23.5233 21.2412C33.2734 11.5446 45.564 4.79899 58.9797 1.78125"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
            />
            <path
                d="M86.2148 110.792L96.7917 114.174"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M84.8613 115.023L95.4382 118.405"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M74.0332 119.721C74.5943 119.9 75.2036 119.849 75.7271 119.58C76.2507 119.31 76.6456 118.843 76.825 118.282L81.8983 102.416C81.988 102.136 82.1854 101.903 82.4472 101.768C82.709 101.633 83.0136 101.607 83.2942 101.697L104.448 108.461C104.728 108.551 104.962 108.748 105.097 109.01C105.232 109.272 105.257 109.577 105.167 109.857L100.094 125.723C99.9148 126.284 99.5199 126.75 98.9963 127.02C98.4728 127.29 97.8634 127.341 97.3024 127.162L74.0332 119.721Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M74.0327 119.721C73.4717 119.541 73.0049 119.146 72.735 118.623C72.4652 118.099 72.4144 117.49 72.5938 116.929L76.6523 104.237"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M111.094 64.4375C112.906 64.4375 114.375 62.9684 114.375 61.1562C114.375 59.3441 112.906 57.875 111.094 57.875C109.282 57.875 107.812 59.3441 107.812 61.1562C107.812 62.9684 109.282 64.4375 111.094 64.4375Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M96.0938 68.1875C97.9059 68.1875 99.375 66.7184 99.375 64.9062C99.375 63.0941 97.9059 61.625 96.0938 61.625C94.2816 61.625 92.8125 63.0941 92.8125 64.9062C92.8125 66.7184 94.2816 68.1875 96.0938 68.1875Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M99.375 64.9062V48.5L114.375 44.75V61.1562"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M59.9469 45.3661L23.5247 55.1254C22.6104 55.3704 22.0677 56.3102 22.3127 57.2245L30.2976 87.0245C30.5426 87.9388 31.4824 88.4814 32.3968 88.2364L68.819 78.4771C69.7333 78.2321 70.2759 77.2923 70.0309 76.378L62.046 46.578C61.801 45.6637 60.8612 45.1211 59.9469 45.3661Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.523 80.4024L36.1497 67.1926C36.2622 66.9977 36.4121 66.8268 36.5906 66.6898C36.7692 66.5528 36.973 66.4523 37.1904 66.394C37.4078 66.3358 37.6346 66.3209 37.8577 66.3503C38.0809 66.3797 38.2961 66.4527 38.491 66.5653L50.2164 73.335C50.4114 73.4475 50.6265 73.5205 50.8497 73.5499C51.0728 73.5793 51.2996 73.5644 51.517 73.5062C51.7344 73.4479 51.9382 73.3474 52.1168 73.2104C52.2954 73.0734 52.4452 72.9025 52.5577 72.7076L55.6916 67.2796C55.8041 67.0847 55.954 66.9139 56.1325 66.7768C56.3111 66.6398 56.5149 66.5393 56.7323 66.4811C56.9497 66.4228 57.1765 66.4079 57.3996 66.4373C57.6228 66.4667 57.838 66.5397 58.0329 66.6523L69.1435 73.067"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M51.0798 61.9376C52.4513 61.5701 53.2652 60.1604 52.8977 58.7889C52.5302 57.4174 51.1205 56.6035 49.749 56.9709C48.3774 57.3384 47.5635 58.7482 47.931 60.1197C48.2985 61.4912 49.7083 62.3051 51.0798 61.9376Z"
                fill={color}
            />
        </svg>
    );
};
export default DropFilesIcon;
