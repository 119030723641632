import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Wallet.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { TpModal } from "../../components/TpModal";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import BagIcon from "../../components/SvgIcon/BagIcon";
import CheckIconCircle from "../../components/SvgIcon/CheckIconCircle";
import CheckIconNormalCircle from "../../components/SvgIcon/CheckIconNormalCircle";

const circleValRed = 35;

const PackagesList = (props) => {
    const { setSelectedUser, isAuthenticated, userType } = props;
    const history = useHistory();
    const [packageSelectionModal, setPackageSelectionModal] = useState(false);
    const [packageSelectionConfirmModal, setPackageSelectionConfirmModal] = useState(false);

    const options = {
        margin: 102,
        items: 3,
        dots: false,
        nav: true,
        center: true,
        loop: true
    };

    return (
        <div className="tp-wallet tp-packages-list">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Request Balance
                        </div>
                        <div className="content-inner-wrap mt9">
                            <Button
                                title="Back to Remaining Credit"
                                className={`mb3 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="text-center">
                                <h2 className="ff-sf-pro-rounded mb0">
                                    How many minutes would you like to request?
                                </h2>
                            </div>
                            <Row>
                                <Col md={4}>
                                    <div
                                        className={`tp-package-card mt10 align-items-baseline tp-package-card-${userType}`}
                                    >
                                        <span className="pakage-price-tag ff-sf-pro-rounded clr-white text-center">
                                            <h6 className>For</h6>
                                            <h4 className="pakage-price fw-bold">£10</h4>
                                        </span>
                                        <span className="package-image text-center d-inline-block mx-auto">
                                            <BagIcon
                                                color={Colors[`${userType}Primary`]}
                                                bgColor={Colors[`${userType}SecondaryLight`]}
                                                className="tph-160 tpw-160"
                                            />
                                            <h3
                                                className={`clr-${userType}-primary ff-sf-pro-rounded fw-bold mb0 package-img-text`}
                                            >
                                                60 mins
                                            </h3>
                                            <h5
                                                className={`clr-${userType}-primary ff-sf-pro-rounded fw-600 mb9`}
                                            >
                                                French
                                            </h5>
                                        </span>
                                        <h3 className="d-flex align-items-center mb4">
                                            <CheckIconNormalCircle
                                                color={Colors.white}
                                                bgColor={Colors[`${userType}Primary`]}
                                                className="tpw-16 tph-16 mr2"
                                            />
                                            These minutes will expire in 1 month
                                        </h3>
                                        <h3 className="d-flex align-items-center mb9">
                                            <CheckIconNormalCircle
                                                color={Colors.white}
                                                bgColor={Colors[`${userType}Primary`]}
                                                className="tpw-16 tph-16 mr2"
                                            />
                                            This purchase is non-refundable.
                                        </h3>
                                        <Button
                                            className={`tp-btn-primary tp-btn-primary-${userType} mx-auto`}
                                            title="Select"
                                            onClick={() =>
                                                setPackageSelectionModal(!packageSelectionModal)
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div
                                        className={`tp-package-card mt10 align-items-baseline tp-package-card-${userType}`}
                                    >
                                        <span className="pakage-price-tag ff-sf-pro-rounded clr-white text-center">
                                            <h6 className>For</h6>
                                            <h4 className="pakage-price fw-bold">£10</h4>
                                        </span>
                                        <span className="package-image text-center d-inline-block mx-auto">
                                            <BagIcon
                                                color={Colors[`${userType}Primary`]}
                                                bgColor={Colors[`${userType}SecondaryLight`]}
                                                className="tph-160 tpw-160"
                                            />
                                            <h3
                                                className={`clr-${userType}-primary ff-sf-pro-rounded fw-bold mb0 package-img-text`}
                                            >
                                                90 mins
                                            </h3>
                                            <h5
                                                className={`clr-${userType}-primary ff-sf-pro-rounded fw-600 mb9`}
                                            >
                                                French
                                            </h5>
                                        </span>
                                        <h3 className="d-flex align-items-center mb4">
                                            <CheckIconNormalCircle
                                                color={Colors.white}
                                                bgColor={Colors[`${userType}Primary`]}
                                                className="tpw-16 tph-16 mr2"
                                            />
                                            These minutes will expire in 2 months
                                        </h3>
                                        <h3 className="d-flex align-items-center mb9">
                                            <CheckIconNormalCircle
                                                color={Colors.white}
                                                bgColor={Colors[`${userType}Primary`]}
                                                className="tpw-16 tph-16 mr2"
                                            />
                                            This purchase is non-refundable.
                                        </h3>
                                        <Button
                                            className={`tp-btn-primary tp-btn-primary-${userType} mx-auto`}
                                            title="Select"
                                            onClick={() =>
                                                setPackageSelectionModal(!packageSelectionModal)
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div
                                        className={`tp-package-card mt10 align-items-baseline tp-package-card-${userType}`}
                                    >
                                        <span className="pakage-price-tag ff-sf-pro-rounded clr-white text-center">
                                            <h6 className>For</h6>
                                            <h4 className="pakage-price fw-bold">£10</h4>
                                        </span>
                                        <span className="package-image text-center d-inline-block mx-auto">
                                            <BagIcon
                                                color={Colors[`${userType}Primary`]}
                                                bgColor={Colors[`${userType}SecondaryLight`]}
                                                className="tph-160 tpw-160"
                                            />
                                            <h3
                                                className={`clr-${userType}-primary ff-sf-pro-rounded fw-bold mb0 package-img-text`}
                                            >
                                                120 mins
                                            </h3>
                                            <h5
                                                className={`clr-${userType}-primary ff-sf-pro-rounded fw-600 mb9`}
                                            >
                                                French
                                            </h5>
                                        </span>
                                        <h3 className="d-flex align-items-center mb4">
                                            <CheckIconNormalCircle
                                                color={Colors.white}
                                                bgColor={Colors[`${userType}Primary`]}
                                                className="tpw-16 tph-16 mr2"
                                            />
                                            These minutes will expire in 3 months
                                        </h3>
                                        <h3 className="d-flex align-items-center mb9">
                                            <CheckIconNormalCircle
                                                color={Colors.white}
                                                bgColor={Colors[`${userType}Primary`]}
                                                className="tpw-16 tph-16 mr2"
                                            />
                                            This purchase is non-refundable.
                                        </h3>
                                        <Button
                                            className={`tp-btn-primary tp-btn-primary-${userType} mx-auto`}
                                            title="Select"
                                            onClick={() =>
                                                setPackageSelectionModal(!packageSelectionModal)
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <TpModal
                                isOpen={packageSelectionModal}
                                centered
                                className={`tp-modal-${userType} size-3`}
                                children={
                                    <div className="text-center">
                                        <h3 className="mb9">Please check if this is correct </h3>
                                        <div
                                            className={`tp-package-card mb9 align-items-baseline tp-package-card-${userType} mx-auto`}
                                        >
                                            <span className="pakage-price-tag ff-sf-pro-rounded clr-white text-center">
                                                <h6 className>For</h6>
                                                <h4 className="pakage-price fw-bold">£10</h4>
                                            </span>
                                            <span className="package-image text-center d-inline-block mx-auto">
                                                <BagIcon
                                                    color={Colors[`${userType}Primary`]}
                                                    bgColor={Colors[`${userType}SecondaryLight`]}
                                                    className="tph-160 tpw-160"
                                                />
                                                <h3
                                                    className={`clr-${userType}-primary ff-sf-pro-rounded fw-bold mb0 package-img-text`}
                                                >
                                                    60 mins
                                                </h3>
                                                <h5
                                                    className={`clr-${userType}-primary ff-sf-pro-rounded fw-600 mb9`}
                                                >
                                                    French
                                                </h5>
                                            </span>
                                            <h3 className="d-flex align-items-center mb4">
                                                <CheckIconNormalCircle
                                                    color={Colors.white}
                                                    bgColor={Colors[`${userType}Primary`]}
                                                    className="tpw-16 tph-16 mr2"
                                                />
                                                These minutes will expire in 1 month
                                            </h3>
                                            <h3 className="d-flex align-items-center mb0">
                                                <CheckIconNormalCircle
                                                    color={Colors.white}
                                                    bgColor={Colors[`${userType}Primary`]}
                                                    className="tpw-16 tph-16 mr2"
                                                />
                                                This purchase is non-refundable.
                                            </h3>
                                        </div>
                                        <Button
                                            onClick={() => setPackageSelectionModal(false)}
                                            className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr9`}
                                            title="Cancel"
                                        />
                                        <Button
                                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                                            title="Request package"
                                            onClick={() => {
                                                setPackageSelectionConfirmModal(
                                                    !packageSelectionConfirmModal
                                                );
                                            }}
                                        />
                                    </div>
                                }
                            />
                            <TpModal
                                isOpen={packageSelectionConfirmModal}
                                centered
                                className={`tp-modal-${userType} size-1`}
                                children={
                                    <div className="text-center">
                                        <CheckIconCircle type={`${userType}`} />
                                        <h3 className="mt9 mb9">
                                            Your request has been sent to your guardian
                                            <span className="d-block mt4">
                                                You will be notified when there is an update
                                            </span>
                                        </h3>
                                        <Button
                                            onClick={() => {
                                                setPackageSelectionConfirmModal(false);
                                            }}
                                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                                            title="OK"
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    // setSelectedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(PackagesList);
