const NetworkIcon = (props) => {
    const { className, onClick, color, bgColor } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="150"
            height="150"
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="75" cy="75" r="75" fill={bgColor} />
            <path
                d="M52.25 63.625H36C34.2051 63.625 32.75 65.0801 32.75 66.875V83.125C32.75 84.9199 34.2051 86.375 36 86.375H52.25C54.0449 86.375 55.5 84.9199 55.5 83.125V66.875C55.5 65.0801 54.0449 63.625 52.25 63.625Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M110.75 39.25H91.25C89.4551 39.25 88 40.7051 88 42.5V62C88 63.7949 89.4551 65.25 91.25 65.25H110.75C112.545 65.25 114 63.7949 114 62V42.5C114 40.7051 112.545 39.25 110.75 39.25Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M110.75 84.75H91.25C89.4551 84.75 88 86.2051 88 88V107.5C88 109.295 89.4551 110.75 91.25 110.75H110.75C112.545 110.75 114 109.295 114 107.5V88C114 86.2051 112.545 84.75 110.75 84.75Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M55.5 75H71.75"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M88 97.75H81.5C78.9141 97.75 76.4342 96.7228 74.6057 94.8943C72.7772 93.0658 71.75 90.5858 71.75 88V62C71.75 59.4141 72.7772 56.9342 74.6057 55.1057C76.4342 53.2772 78.9141 52.25 81.5 52.25H88"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default NetworkIcon;
