const FollowupQuestionIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.8552 13.4082C11.5105 13.7529 11.1659 14.0976 10.8212 14.4853C10.3904 14.9162 10.0026 15.3039 9.57178 15.7347C9.39944 15.9071 9.14094 15.9502 8.92552 15.8209C8.7101 15.6917 8.62394 15.4762 8.66702 15.2608C8.7101 15.1747 8.75319 15.0454 8.79627 15.0023C9.87336 13.9252 10.9505 12.8051 12.0275 11.728C12.243 11.5126 12.5876 11.5126 12.8031 11.728C13.8801 12.8051 14.9572 13.8822 16.0343 15.0023C16.2497 15.2177 16.2497 15.5624 16.0343 15.7778C15.8189 15.9933 15.4742 15.9933 15.2588 15.7778C14.5264 15.0454 13.8371 14.3561 13.1046 13.6236C13.0616 13.5806 13.0185 13.5375 12.9323 13.4513C12.9323 13.796 12.9323 14.0545 12.9323 14.3561C12.9323 15.7778 12.9323 17.1996 12.9323 18.6214C12.9323 21.5941 15.1296 24.1361 18.0593 24.5669C18.3178 24.61 18.6193 24.61 18.8778 24.6531C19.1794 24.6531 19.4379 24.9116 19.4379 25.2131C19.4379 25.5147 19.2225 25.7732 18.8778 25.7732C17.5423 25.7732 16.3359 25.4286 15.1727 24.7392C13.3201 23.576 12.243 21.9388 11.8983 19.7846C11.8121 19.2676 11.8121 18.7075 11.8121 18.1474C11.8121 16.6826 11.8121 15.1747 11.8121 13.7098C11.8983 13.4944 11.8983 13.4513 11.8552 13.4082C11.8983 13.4082 11.8552 13.4082 11.8552 13.4082Z"
                fill={color}
            />
            <path
                d="M11.8552 13.4082C11.5105 13.7529 11.1659 14.0976 10.8212 14.4853C10.3904 14.9162 10.0026 15.3039 9.57178 15.7347C9.39944 15.9071 9.14094 15.9502 8.92552 15.8209C8.7101 15.6917 8.62394 15.4762 8.66702 15.2608C8.7101 15.1747 8.75319 15.0454 8.79627 15.0023C9.87336 13.9252 10.9505 12.8051 12.0275 11.728C12.243 11.5126 12.5876 11.5126 12.8031 11.728C13.8801 12.8051 14.9572 13.8822 16.0343 15.0023C16.2497 15.2177 16.2497 15.5624 16.0343 15.7778C15.8189 15.9933 15.4742 15.9933 15.2588 15.7778C14.5264 15.0454 13.8371 14.3561 13.1046 13.6236C13.0616 13.5806 13.0185 13.5375 12.9323 13.4513C12.9323 13.796 12.9323 14.0545 12.9323 14.3561C12.9323 15.7778 12.9323 17.1996 12.9323 18.6214C12.9323 21.5941 15.1296 24.1361 18.0593 24.5669C18.3178 24.61 18.6193 24.61 18.8778 24.6531C19.1794 24.6531 19.4379 24.9116 19.4379 25.2131C19.4379 25.5147 19.2225 25.7732 18.8778 25.7732C17.5423 25.7732 16.3359 25.4286 15.1727 24.7392C13.3201 23.576 12.243 21.9388 11.8983 19.7846C11.8121 19.2676 11.8121 18.7075 11.8121 18.1474C11.8121 16.6826 11.8121 15.1747 11.8121 13.7098C11.8983 13.4944 11.8983 13.4513 11.8552 13.4082ZM11.8552 13.4082C11.8983 13.4082 11.8552 13.4082 11.8552 13.4082Z"
                stroke={color}
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M25.2977 23.7907C25.6423 23.446 25.987 23.1013 26.3317 22.7136C26.7625 22.2827 27.1503 21.895 27.5811 21.4642C27.7534 21.2918 28.0119 21.2487 28.2274 21.378C28.4428 21.5072 28.5289 21.7227 28.4859 21.9381C28.4428 22.0242 28.3997 22.1535 28.3566 22.1966C27.2795 23.2737 26.2024 24.3938 25.1253 25.4709C24.9099 25.6864 24.5653 25.6864 24.3498 25.4709C23.2727 24.3938 22.1957 23.3168 21.1186 22.1966C20.9031 21.9812 20.9031 21.6365 21.1186 21.4211C21.334 21.2057 21.6787 21.2057 21.8941 21.4211C22.6265 22.1535 23.3158 22.8428 24.0483 23.5753C24.0913 23.6183 24.1344 23.6614 24.2206 23.7476C24.2206 23.4029 24.2206 23.1444 24.2206 22.8428C24.2206 21.4211 24.2206 19.9993 24.2206 18.5776C24.2206 15.6048 22.0233 13.0628 19.0936 12.632C18.749 12.632 18.4905 12.5889 18.232 12.5889C17.9304 12.5889 17.6719 12.3304 17.6719 12.0288C17.6719 11.7273 17.8873 11.4688 18.232 11.4688C19.5676 11.4688 20.7739 11.8134 21.9372 12.5028C23.7897 13.666 24.8668 15.3032 25.2115 17.4574C25.2977 17.9744 25.2977 18.5345 25.2977 19.0946C25.2977 20.5594 25.2977 22.0673 25.2977 23.5322C25.2546 23.6614 25.2546 23.7045 25.2977 23.7907C25.2546 23.7476 25.2977 23.7476 25.2977 23.7907Z"
                fill={color}
            />
            <path
                d="M25.2977 23.7907C25.6423 23.446 25.987 23.1013 26.3317 22.7136C26.7625 22.2827 27.1503 21.895 27.5811 21.4642C27.7534 21.2918 28.0119 21.2487 28.2274 21.378C28.4428 21.5072 28.5289 21.7227 28.4859 21.9381C28.4428 22.0242 28.3997 22.1535 28.3566 22.1966C27.2795 23.2737 26.2024 24.3938 25.1253 25.4709C24.9099 25.6864 24.5653 25.6864 24.3498 25.4709C23.2727 24.3938 22.1957 23.3168 21.1186 22.1966C20.9031 21.9812 20.9031 21.6365 21.1186 21.4211C21.334 21.2057 21.6787 21.2057 21.8941 21.4211C22.6265 22.1535 23.3158 22.8428 24.0483 23.5753C24.0913 23.6183 24.1344 23.6614 24.2206 23.7476C24.2206 23.4029 24.2206 23.1444 24.2206 22.8428C24.2206 21.4211 24.2206 19.9993 24.2206 18.5776C24.2206 15.6048 22.0233 13.0628 19.0936 12.632C18.749 12.632 18.4905 12.5889 18.232 12.5889C17.9304 12.5889 17.6719 12.3304 17.6719 12.0288C17.6719 11.7273 17.8873 11.4688 18.232 11.4688C19.5676 11.4688 20.7739 11.8134 21.9372 12.5028C23.7897 13.666 24.8668 15.3032 25.2115 17.4574C25.2977 17.9744 25.2977 18.5345 25.2977 19.0946C25.2977 20.5594 25.2977 22.0673 25.2977 23.5322C25.2546 23.6614 25.2546 23.7045 25.2977 23.7907ZM25.2977 23.7907C25.2546 23.7476 25.2977 23.7476 25.2977 23.7907Z"
                stroke={color}
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M3.98553 27.1145C1.88892 23.5801 1.15467 19.4019 1.92065 15.3645C2.68664 11.327 4.90016 7.70811 8.14557 5.18722C11.391 2.66634 15.445 1.41692 19.5465 1.67357C23.6479 1.93023 27.5145 3.67531 30.4204 6.58112C33.3262 9.48693 35.0713 13.3536 35.328 17.455C35.5846 21.5564 34.3352 25.6105 31.8144 28.8559C29.2935 32.1013 25.6746 34.3149 21.6372 35.0809C17.5997 35.8469 13.4215 35.1127 9.88716 33.0161L9.8872 33.0159L4.0589 34.6811C3.81776 34.75 3.56259 34.7532 3.31982 34.6903C3.07705 34.6274 2.85553 34.5007 2.67819 34.3233C2.50086 34.146 2.37418 33.9245 2.31127 33.6817C2.24837 33.4389 2.25152 33.1838 2.32042 32.9426L3.98565 27.1143L3.98553 27.1145Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default FollowupQuestionIcon;
