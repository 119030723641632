import { useHistory, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";

import "./Login.scss";

import { Colors } from "../../themes/colors";
import { Card } from "../../components/Card";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import Logo from "../../components/SvgIcon/Logo";
import Guardian from "../../components/SvgIcon/GuardianIcon";
import Student from "../../components/SvgIcon/StudentIcon";
import Tutor from "../../components/SvgIcon/TutorIcon";
import { changeUserType } from "../../store/actions";
import BgShape from "../../components/SvgIcon/BgShape";
import { UserTypes } from "../../constants";

const ChooseUser = (props) => {
    const { isAuthenticated } = props;
    const history = useHistory();
    const dispatch = useDispatch();

    const handleCardClick = (routeName, type) => {
        dispatch(changeUserType(type));
        history.push(getRoute(routeName));
    };

    if (isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: getRoute(APP_ROUTES.DASHBOARD)
                }}
            />
        );
    }
    return (
        <div className="tp-choose-user-type text-center full-height content-centered">
            <BgShape type="guardianPrimary" />
            <Container>
                <Row className="text-webkit-center">
                    <Col xs={12}>
                        <div className="logo mb4">
                            <Logo color={Colors.guardianPrimary} />
                        </div>
                        <h1 className="mb0">
                            Thanks for expressing an interest <br /> to join TutorPin. Please select
                            your user type
                        </h1>
                    </Col>
                    <Col md={4}>
                        <Card
                            title="Guardian"
                            onClick={() => handleCardClick(APP_ROUTES.LOGIN, UserTypes.GUARDIAN)}
                            svg={<Guardian color={Colors.guardianPrimary} />}
                            className={`mt9 tp-card-lg tp-card-guardian`}
                        />
                    </Col>
                    <Col md={4}>
                        <Card
                            title="Student"
                            onClick={() => handleCardClick(APP_ROUTES.LOGIN, UserTypes.STUDENT)}
                            svg={<Student color={Colors.guardianPrimary} />}
                            className={`mt9 tp-card-lg tp-card-guardian`}
                        />
                    </Col>
                    <Col md={4}>
                        <Card
                            title="Tutor"
                            onClick={() => handleCardClick(APP_ROUTES.LOGIN, UserTypes.TUTOR)}
                            svg={<Tutor color={Colors.guardianPrimary} />}
                            className={`mt9 tp-card-lg tp-card-guardian`}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps)(ChooseUser);
