const ProfileIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="132"
            height="125"
            viewBox="0 0 132 125"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M66 86C89.196 86 108 67.196 108 44C108 20.804 89.196 2 66 2C42.804 2 24 20.804 24 44C24 67.196 42.804 86 66 86Z"
                stroke={color}
                strokeWidth="4"
                strokeMiterlimit="10"
            />
            <path
                d="M2.33594 122.744C8.79055 111.572 18.0716 102.295 29.2467 95.8449C40.4218 89.3952 53.0974 85.9999 66.0001 86C78.9029 86.0001 91.5785 89.3957 102.753 95.8457C113.928 102.296 123.209 111.573 129.664 122.745"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default ProfileIcon;
