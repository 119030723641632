import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./QuestionBoard.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import AskQuestion from "./AskQuestion";
import QuestionAnswerThread from "./QuestionAnswerThread";
import MessageModal from "./AskQuestionModals/MessageModal";

const AskFollowUpQuestion = (props) => {
    const { userType, user, questionThreads } = props;
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();

    const canShowTextArea = () => {
        if (questionThreads.length) {
            let lastThread = questionThreads[questionThreads.length - 1];
            if (lastThread.QuestionId) {
                return true;
            }
            return false;
        } else return false;
    };

    return (
        <div className="tp-question-board tp-question-details">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Question Board
                        </div>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to answered questions"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner question-details-inner no-padding">
                                <QuestionAnswerThread />
                            </div>
                        </div>
                    </Col>
                </Row>
                {canShowTextArea() && (
                    <AskQuestion
                        isFromAskFollowUp={true}
                        handleShowSuccessModal={() => setShowSuccessModal(true)}
                    />
                )}
            </Container>
            <MessageModal
                text={`Your follow up question has been sent`}
                showMessageModal={showSuccessModal}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType,
        user: state.auth.user,
        questionThreads: state.questionBoard.questionThreads
    };
};

export default connect(mapStateToProps)(AskFollowUpQuestion);
