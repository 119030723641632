import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import ArrowRight from "../../components/SvgIcon/ArrowRight";

const MyReport = (props) => {
    const { userType } = props;
    const history = useHistory();

    return (
        <div className="tp-settings tp-my-reports">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>My Reports</div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to report"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div
                                className={`mb9 scrolling-container scrolling-container-${userType}`}
                            >
                                <div className="content-inner bg-clr-light my-reports-inner no-padding pt4 pb4 pr4 pl4">
                                    <h2 className="mb0 ff-sf-pro-rounded ml10">
                                        Report a technical Issue
                                    </h2>
                                    <table className="tp-table w-100 bg-white mt4 text-center">
                                        <thead>
                                            <th>
                                                Date of report
                                                <ArrowRight
                                                    color={Colors.black}
                                                    className="pointer tpw-25 tph-25 ml1 arrow-down"
                                                />
                                            </th>
                                            <th>
                                                Report Number
                                                <ArrowRight
                                                    color={Colors.black}
                                                    className="pointer tpw-25 tph-25 ml1 arrow-down"
                                                />
                                            </th>
                                            <th>
                                                Cause
                                                <ArrowRight
                                                    color={Colors.black}
                                                    className="pointer tpw-25 tph-25 ml1 arrow-down"
                                                />
                                            </th>
                                            <th>
                                                Status
                                                <ArrowRight
                                                    color={Colors.black}
                                                    className="pointer tpw-25 tph-25 ml1 arrow-down"
                                                />
                                            </th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td
                                                    className="text-decoration-underline pointer"
                                                    onClick={() =>
                                                        history.push(
                                                            getRoute(APP_ROUTES.VIEW_REPORT)
                                                        )
                                                    }
                                                >
                                                    113343556778
                                                </td>
                                                <td>I cant see my earnings</td>
                                                <td className="status">Pending</td>
                                            </tr>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td className="text-decoration-underline pointer">
                                                    113343556778
                                                </td>
                                                <td>I cant see my earnings</td>
                                                <td className="status">Pending</td>
                                            </tr>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td className="text-decoration-underline pointer">
                                                    113343556778
                                                </td>
                                                <td>I cant see my earnings</td>
                                                <td className="status">Pending</td>
                                            </tr>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td className="text-decoration-underline pointer">
                                                    113343556778
                                                </td>
                                                <td>I cant see my earnings</td>
                                                <td className="status">Pending</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="content-inner bg-clr-light my-reports-inner mt9 pt4 pb4 pr4 pl4 no-padding">
                                    <h2 className="mb0 ff-sf-pro-rounded ml10">Report a User</h2>
                                    <table className="tp-table w-100 bg-white mt4 text-center bg-clr-light">
                                        <thead>
                                            <th>
                                                Date of report
                                                <ArrowRight
                                                    color={Colors.black}
                                                    className="pointer tpw-25 tph-25 ml1 arrow-down"
                                                />
                                            </th>
                                            <th>
                                                Report Number
                                                <ArrowRight
                                                    color={Colors.black}
                                                    className="pointer tpw-25 tph-25 ml1 arrow-down"
                                                />
                                            </th>
                                            <th>
                                                Cause
                                                <ArrowRight
                                                    color={Colors.black}
                                                    className="pointer tpw-25 tph-25 ml1 arrow-down"
                                                />
                                            </th>
                                            <th>
                                                Status
                                                <ArrowRight
                                                    color={Colors.black}
                                                    className="pointer tpw-25 tph-25 ml1 arrow-down"
                                                />
                                            </th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td className="text-decoration-underline pointer">
                                                    113343556778
                                                </td>
                                                <td>I cant see my earnings</td>
                                                <td className="status">Pending</td>
                                            </tr>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td className="text-decoration-underline pointer">
                                                    113343556778
                                                </td>
                                                <td>I cant see my earnings</td>
                                                <td className="status">Pending</td>
                                            </tr>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td className="text-decoration-underline pointer">
                                                    113343556778
                                                </td>
                                                <td>I cant see my earnings</td>
                                                <td className="status">Pending</td>
                                            </tr>
                                            <tr>
                                                <td>12 - 09 - 2020</td>
                                                <td className="text-decoration-underline pointer">
                                                    113343556778
                                                </td>
                                                <td>I cant see my earnings</td>
                                                <td className="status">Pending</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(MyReport);
