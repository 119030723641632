import "./Card.scss";

const Card = (props) => {
    const { svg = null, img = null, title, onClick = () => {}, className = "", counter } = props;
    return (
        <div className={`tp-card pointer ${className}`} onClick={onClick}>
            {counter && (
                <span className="counter fw-600 ff-sf-pro-rounded text-white position-absolute rounded-circle d-flex align-items-center justify-content-center">
                    {counter}
                </span>
            )}
            <div className="image">
                {svg && svg}
                {img && <img src={img} alt={title} className="img-fluid" />}
            </div>
            <div className="title">{title}</div>
        </div>
    );
};

export default Card;
