import { useRef, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { TpModal } from "../../components/TpModal";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import { UserStatus } from "../../components/UserStatus";
import { Button } from "../../components/Button";
import { PictureUpload } from "../../components/PictureUpload";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import PencilIcon from "../../components/SvgIcon/PencilIcon";
import CloseIconCircle from "../../components/SvgIcon/CloseIconCircle";
import UserImg from "../../assets/images/placeholder-profile.jpg";

const ProfileSettings = (props) => {
    const { userType } = props;
    const history = useHistory();
    const inputFileRef = useRef();
    const profilePictureRef = useRef(null);
    const [showImageUpload, setImageUpload] = useState(false);
    const [showCrop, setShowCrop] = useState(false);
    const [changeStatus, setChangeStatus] = useState(false);

    const handleFileChange = (e) => {
        profilePictureRef.current.setImage(e.target.files[0]);
        setShowCrop(true);
    };

    return (
        <div className="tp-settings tp-profile-settings">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>Profile</div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to settings"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner profile-settings-inner bg-clr-light">
                                <div className="profile-img text-center">
                                    <div className={`img-wrap img-wrap-${userType}`}>
                                        <img src={UserImg} className="img-fluid rounded-circle" />
                                        <span className="edit-field pointer position-absolute rounded-circle d-flex tpw-50 tph-50">
                                            <PencilIcon
                                                className="tpw-40 tph-40 m-auto"
                                                color={Colors.white}
                                                onClick={() => {
                                                    setImageUpload(!showImageUpload);
                                                }}
                                            />
                                        </span>
                                        <TpModal
                                            isOpen={showImageUpload}
                                            centered
                                            className={`confirmation-pic-upload-modal tp-modal-${userType} ${
                                                showCrop ? " crop-open size-2" : " size-3"
                                            }`}
                                            // className={
                                            //     "confirmation-pic-upload-modal " +
                                            //     (showCrop ? " crop-open size-2" : " size-3")
                                            // }
                                            children={
                                                <Fragment>
                                                    <div className="close-btn">
                                                        <CloseIconCircle
                                                            className="pointer tpw-40 tph-40"
                                                            onClick={() => {
                                                                setImageUpload(false);
                                                            }}
                                                            color={Colors[`${userType}Primary`]}
                                                        />
                                                    </div>

                                                    {!showCrop && (
                                                        <div className="text-center">
                                                            <h3 className="mb9">
                                                                Edit Profile picture
                                                            </h3>
                                                            <Button
                                                                className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                                                                title="From the Gallery"
                                                                onClick={() =>
                                                                    inputFileRef.current.click()
                                                                }
                                                            />
                                                            <Button
                                                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                                title="Take a picture"
                                                            />
                                                            <input
                                                                className="d-none"
                                                                type="file"
                                                                ref={inputFileRef}
                                                                onChange={handleFileChange}
                                                            />
                                                        </div>
                                                    )}
                                                    <div
                                                        className={
                                                            "profile-crop-wrap text-center " +
                                                            (!showCrop ? "d-none" : "")
                                                        }
                                                    >
                                                        <PictureUpload
                                                            componentRef={profilePictureRef}
                                                            className="mb9 mx-auto"
                                                        />
                                                        <Button
                                                            title="Cancel"
                                                            className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                                                            onClick={() => {
                                                                setShowCrop(!showCrop);
                                                            }}
                                                        />
                                                        <Button
                                                            title="Apply"
                                                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                        />
                                                    </div>
                                                </Fragment>
                                            }
                                        />
                                    </div>

                                    <div
                                        className={`fw-600 ff-sf-pro-rounded mb0 clr-${userType}-primary`}
                                    >
                                        German Moyano
                                    </div>
                                    <h3 className={`mt1 mb5 clr-${userType}-primary`}>
                                        DoB: 22-09-1990
                                    </h3>
                                </div>
                                <AvForm className="text-left">
                                    <Row>
                                        <Col md={6}>
                                            <div className="field-wrap mb4">
                                                <span className="position-absolute edit-field pointer">
                                                    <PencilIcon
                                                        className="tpw-40 tph-40"
                                                        color={Colors.black}
                                                        onClick={() => setChangeStatus(true)}
                                                    />
                                                </span>
                                                <TextInput
                                                    label="Status:"
                                                    name="status"
                                                    className={`bdr-0 radius-7 status active tp-text-input-${userType}`}
                                                    /* inactive replace class with active for red color */
                                                    placeholder="Active"
                                                />
                                                {changeStatus && <UserStatus userType={userType} />}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="field-wrap mb4">
                                                <div className="field-text">Country:</div>
                                                <span className="position-absolute edit-field pointer">
                                                    <PencilIcon
                                                        className="tpw-40 tph-40"
                                                        color={Colors.black}
                                                    />
                                                </span>
                                                <CreatableDropdown
                                                    placeholder="Mexico"
                                                    hideArrow={true}
                                                    options={[
                                                        {
                                                            label: "Math"
                                                        },
                                                        {
                                                            label: "Biology"
                                                        },
                                                        {
                                                            label: "Spanish"
                                                        },
                                                        {
                                                            label: "German"
                                                        },
                                                        {
                                                            label: "Italian"
                                                        }
                                                    ]}
                                                    className={`mw-100 radius-thin bdr-none tp-creatable-dropdown-${userType}`}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="field-wrap mb4">
                                                <span className="position-absolute edit-field pointer">
                                                    <PencilIcon
                                                        className="tpw-40 tph-40"
                                                        color={Colors.black}
                                                        onClick={() =>
                                                            history.push(
                                                                {
                                                                    pathname: getRoute(
                                                                        APP_ROUTES.CHANGE_EMAIL
                                                                    )
                                                                },
                                                                {
                                                                    routeParam: "Email"
                                                                }
                                                            )
                                                        }
                                                    />
                                                </span>
                                                <TextInput
                                                    name="email"
                                                    label="Email:"
                                                    placeholder="cmoyanoizquierdo@gmail.com"
                                                    options={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required"
                                                        }
                                                    }}
                                                    className={`bdr-0 radius-7 tp-text-input-${userType}`}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="field-wrap">
                                                <div className="field-text">Subjects taught:</div>
                                                <span className="position-absolute edit-field pointer">
                                                    <PencilIcon
                                                        className="tpw-40 tph-40"
                                                        color={Colors.black}
                                                    />
                                                </span>
                                                <CreatableDropdown
                                                    isMulti={true}
                                                    placeholder="Maths, Spanish"
                                                    hideArrow={true}
                                                    className={`mw-100 radius-thin bdr-none tp-creatable-dropdown-${userType}`}
                                                    options={[
                                                        {
                                                            label: "Math"
                                                        },
                                                        {
                                                            label: "Biology"
                                                        },
                                                        {
                                                            label: "Spanish"
                                                        },
                                                        {
                                                            label: "German"
                                                        },
                                                        {
                                                            label: "Italian"
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="field-wrap">
                                                <span className="position-absolute edit-field pointer">
                                                    <PencilIcon
                                                        className="tpw-40 tph-40"
                                                        color={Colors.black}
                                                        onClick={() =>
                                                            history.push(
                                                                {
                                                                    pathname: getRoute(
                                                                        APP_ROUTES.CHANGE_EMAIL
                                                                    )
                                                                },
                                                                {
                                                                    routeParam: "Password"
                                                                }
                                                            )
                                                        }
                                                    />
                                                </span>
                                                <TextInput
                                                    label="Password:"
                                                    name="password"
                                                    placeholder="•••••••"
                                                    options={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required"
                                                        }
                                                    }}
                                                    className={`bdr-0 radius-7 tp-text-input-${userType}`}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(ProfileSettings);
