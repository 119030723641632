import { UserTypesKeys, UserTypes } from "../../constants/UserTypes";
import { ME_SUCCESS, SET_TOKEN, UPDATE_CLARITY_QUESTION_COUNT, CHANGE_USER_TYPE } from "../actions";

const initialState = {
    isAuthenticated: false,
    user: {
        Type: UserTypes.TUTOR
    },
    userType: "tutor"
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ME_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: {
                    ...state.user,
                    ...action.payload.User
                },
                userType: UserTypesKeys[action?.payload?.User?.Type]
            };
        case CHANGE_USER_TYPE:
            return {
                ...state,
                user: {
                    ...state.user,
                    Type: action.userType
                },
                userType: UserTypesKeys[action.userType]
            };
        case SET_TOKEN:
            return {
                ...state,
                user: {
                    ...state.user,
                    Token: action.token
                }
            };
        case UPDATE_CLARITY_QUESTION_COUNT:
            return {
                ...state,
                user: {
                    ...state.user,
                    AllowedClarityQuestion: state.user.AllowedClarityQuestion - 1
                }
            };

        default:
            return state;
    }
};
