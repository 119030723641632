const AudioIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.2275 21.25H30.2275C29.5644 21.25 28.9285 21.5134 28.4597 21.9822C27.9909 22.4511 27.7275 23.087 27.7275 23.75V30C27.7275 30.663 27.9909 31.2989 28.4597 31.7678C28.9285 32.2366 29.5644 32.5 30.2275 32.5H32.7275C33.3905 32.5 34.0264 32.2366 34.4952 31.7678C34.9641 31.2989 35.2275 30.663 35.2275 30V21.25ZM35.2275 21.25C35.2275 19.2706 34.8357 17.3108 34.0748 15.4835C33.3138 13.6562 32.1987 11.9976 30.7938 10.6033C29.3888 9.20894 27.7218 8.10646 25.8888 7.35939C24.0558 6.61232 22.0931 6.23542 20.1137 6.25043C18.1344 6.23542 16.1717 6.61232 14.3386 7.35939C12.5056 8.10646 10.8386 9.20894 9.43369 10.6033C8.02874 11.9976 6.91366 13.6562 6.15271 15.4835C5.39177 17.3108 5 19.2706 5 21.25V30C5 30.663 5.26339 31.2989 5.73223 31.7678C6.20107 32.2366 6.83696 32.5 7.5 32.5H10C10.663 32.5 11.2989 32.2366 11.7678 31.7678C12.2366 31.2989 12.5 30.663 12.5 30V23.75C12.5 23.087 12.2366 22.4511 11.7678 21.9822C11.2989 21.5134 10.663 21.25 10 21.25H5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default AudioIcon;
