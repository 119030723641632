const WalletIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M40.625 10.9375H9.375C7.64911 10.9375 6.25 12.3366 6.25 14.0625V35.9375C6.25 37.6634 7.64911 39.0625 9.375 39.0625H40.625C42.3509 39.0625 43.75 37.6634 43.75 35.9375V14.0625C43.75 12.3366 42.3509 10.9375 40.625 10.9375Z"
                stroke={color}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.25 23.4375H17.3457C17.7056 23.4374 18.0545 23.5618 18.3333 23.7896C18.612 24.0174 18.8034 24.3346 18.875 24.6873C19.1634 26.0989 19.9305 27.3676 21.0466 28.2787C22.1627 29.1899 23.5592 29.6875 25 29.6875C26.4408 29.6875 27.8373 29.1899 28.9534 28.2787C30.0695 27.3676 30.8366 26.0989 31.125 24.6873C31.1966 24.3346 31.388 24.0174 31.6667 23.7896C31.9455 23.5618 32.2944 23.4374 32.6543 23.4375H43.75"
                stroke={color}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.25 17.1875H43.75"
                stroke={color}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
export default WalletIcon;
