import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import EarningIcon from "../../components/SvgIcon/EarningIcon";
import UsabilityIcon from "../../components/SvgIcon/UsabilityIcon";
import GeneralIcon from "../../components/SvgIcon/GeneralIcon";
import OtherIcon from "../../components/SvgIcon/OtherIcon";

const DeleteAccount = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [selectedReason, setSelectedReason] = useState(false);

    const selectedReasonClass = selectedReason ? " selected-reason selected-reason-student" : "";

    return (
        <div className="tp-settings tp-delete-account tp-delete-account-reason">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Delete Account
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to profile"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner bg-clr-light delete-account-inner text-center">
                                <Row>
                                    <Col xs={12}>
                                        <h3 className="mb0">
                                            Please help us improve TutorPin by telling us why you
                                            wish to leave
                                        </h3>
                                    </Col>
                                    <Col md={3}>
                                        <div
                                            className={
                                                "delete-account-reason-type mt9" +
                                                selectedReasonClass
                                            }
                                        >
                                            <EarningIcon
                                                color={Colors[`${userType}Primary`]}
                                                bgColor={Colors[`${userType}Secondary`]}
                                                onClick={() => {
                                                    setSelectedReason(!selectedReason);
                                                }}
                                                className="pointer tph-150 tpw-150"
                                            />
                                            <h3 className="mt4 mb0">Earnings</h3>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="delete-account-reason-type mt9">
                                            <UsabilityIcon
                                                color={Colors[`${userType}Primary`]}
                                                bgColor={Colors[`${userType}Secondary`]}
                                                className="pointer tph-150 tpw-150"
                                            />
                                            <h3 className="mt4 mb0">Usability</h3>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="delete-account-reason-type mt9">
                                            <GeneralIcon
                                                color={Colors[`${userType}Primary`]}
                                                bgColor={Colors[`${userType}Secondary`]}
                                                className="pointer tph-150 tpw-150"
                                            />
                                            <h3 className="mt4 mb0">General</h3>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="delete-account-reason-type mt9">
                                            <OtherIcon
                                                color={Colors[`${userType}Primary`]}
                                                bgColor={Colors[`${userType}Secondary`]}
                                                className="pointer tph-150 tpw-150"
                                            />
                                            <h3 className="mt4 mb0">Other</h3>
                                        </div>
                                    </Col>
                                    <Col xs="12">
                                        <Button
                                            title="Next"
                                            className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                            onClick={() =>
                                                history.push(
                                                    getRoute(APP_ROUTES.DELETE_ACCOUNT_REASON)
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(DeleteAccount);
