import { Collapse } from "reactstrap";

import "./AccordionTab.scss";

import ArrowRight from "../SvgIcon/ArrowRight";

const AccordionTab = (props) => {
    const {
        isOpen,
        title,
        content,
        onClick = () => {},
        className = "",
        dropDownColor,
        titleColor
    } = props;
    return (
        <div className={`tp-accordion-tab ${className}`}>
            <div
                className="tp-accordion-tab-title d-flex justify-content-between align-items-center pointer"
                onClick={onClick}
            >
                <h3 className={`mb0 clr-${titleColor}-primary`}>{title}</h3>
                <div className="arrow-indicator">
                    <ArrowRight
                        color={dropDownColor}
                        className={isOpen ? "arrow-down" : "arrow-right"}
                    />
                </div>
            </div>
            <Collapse isOpen={isOpen}>
                <div className="tp-accordion-tab-content">{content}</div>
            </Collapse>
        </div>
    );
};
export default AccordionTab;
