import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./KnowledgeBank.scss";
import { Colors } from "../../themes/colors";

import { Slider } from "../../components/Slider";
import { Card } from "../../components/Card";
import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { TpModal } from "../../components/TpModal";
import { Button } from "../../components/Button";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import SocialScienceIcon from "../../components/SvgIcon/SocialScienceIcon";
import LanguagesIcon from "../../components/SvgIcon/LanguagesIcon";
import OtherSubjectIcon from "../../components/SvgIcon/OtherSubjectIcon";
import FoodHealthIcon from "../../components/SvgIcon/FoodHealthIcon";
import PlusIcon from "../../components/SvgIcon/PlusIcon";
import CloseIconCircle from "../../components/SvgIcon/CloseIconCircle";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";

const AddResourceUploadViewDetails = (props) => {
    const { setSelectedUser, isAuthenticated, userType } = props;
    const history = useHistory();
    const [addResourceConfirmModal, setAddResourceConfirmModal] = useState(false);
    const [addAnotherResourceModal, setAddAnotherResourceModal] = useState(false);

    const optionsTopicModal = {
        margin: 32,
        items: 3,
        dots: false,
        nav: true,
        center: true,
        loop: true
    };

    const handleAnotherResource = () => {
        setAddAnotherResourceModal(true);
        setAddResourceConfirmModal(false);
    };

    return (
        <div className="tp-knowledge-bank tp-add-resource-upload-view-details">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Add Resource
                        </div>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to knowledge bank"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner add-resource-upload-view-details bg-clr-light">
                                <AvForm>
                                    <div className="text-center">
                                        <h3 className="mb4">
                                            Please tell us more about this resource
                                        </h3>
                                    </div>
                                    <Row className="align-items-center mt4">
                                        <Col md={3}>
                                            <h3 className="mb0">Resource Uploaded:</h3>
                                        </Col>
                                        <Col md={9}>
                                            <div
                                                className={`uploaded-resource radius-3 uploaded-resource-${userType}`}
                                            >
                                                <Row>
                                                    <Col xs={6}>
                                                        <img
                                                            src={AvatarImg}
                                                            className="img-fluid radius-4 objfit-cover"
                                                            alt=""
                                                        />
                                                    </Col>
                                                    <Col xs={6} className="d-flex">
                                                        <h6 className="mb0 mt-auto">Nugget.jpg</h6>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt4">
                                        <Col md={3}>
                                            <h3 className="mb0">Subject:</h3>
                                        </Col>
                                        <Col md={9}>
                                            <CreatableDropdown
                                                isDisabled={true}
                                                placeholder="Select Subject"
                                                options={[
                                                    {
                                                        label: "Math"
                                                    },
                                                    {
                                                        label: "Biology"
                                                    },
                                                    {
                                                        label: "Spanish"
                                                    },
                                                    {
                                                        label: "German"
                                                    },
                                                    {
                                                        label: "Italian"
                                                    }
                                                ]}
                                                className={`bdr-none mw-100 dropdown-size-sm tp-creatable-dropdown-${userType}`}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt4">
                                        <Col md={3}>
                                            <h3 className="mb0">Topic:</h3>
                                        </Col>
                                        <Col md={9}>
                                            <CreatableDropdown
                                                isDisabled={true}
                                                placeholder="Select Topic"
                                                options={[
                                                    {
                                                        label: "Math"
                                                    },
                                                    {
                                                        label: "Biology"
                                                    },
                                                    {
                                                        label: "Spanish"
                                                    },
                                                    {
                                                        label: "German"
                                                    },
                                                    {
                                                        label: "Italian"
                                                    }
                                                ]}
                                                className={`bdr-none mw-100 dropdown-size-sm tp-creatable-dropdown-${userType}`}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt4">
                                        <Col md={3}>
                                            <h3 className="mb0">Name of the resource:</h3>
                                        </Col>
                                        <Col md={9}>
                                            <TextInput
                                                placeholder="Type the name of the resouce"
                                                name="nameofresource"
                                                className={`bdr-0 radius-7 tp-text-input-${userType}`}
                                                options={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "This field is required"
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt4">
                                        <Col md={3}>
                                            <h3 className="mb0">Suggested time:</h3>
                                        </Col>
                                        <Col md={9}>
                                            <TextInput
                                                placeholder="5 minutes"
                                                name="suggestedtime"
                                                className={`bdr-0 radius-7 tp-text-input-${userType}`}
                                                options={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "This field is required"
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt4">
                                        <Col md={3}>
                                            <h3 className="mb0">Tags:</h3>
                                        </Col>
                                        <Col md={9}>
                                            <Row>
                                                <Col md={6}>
                                                    <TextInput
                                                        placeholder="Type tags"
                                                        name="tags"
                                                        className={`bdr-0 radius-7 tp-text-input-${userType}`}
                                                        options={{
                                                            required: {
                                                                value: true,
                                                                errorMessage:
                                                                    "This field is required"
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <Button
                                                        className={`bdr-0 w-100 radius-7 tp-btn-add tp-btn-add-${userType}`}
                                                        title={
                                                            <PlusIcon
                                                                color={Colors[`${userType}Primary`]}
                                                            />
                                                        }
                                                    ></Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <div className="text-center mt9">
                                        <Button
                                            className={`mt9 tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                                            title="Go back"
                                            onClick={() =>
                                                history.push(
                                                    getRoute(APP_ROUTES.ADD_RESOURCE_UPLOAD_VIEW)
                                                )
                                            }
                                        />
                                        <Button
                                            className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                                            title="Add resource"
                                            onClick={() => {
                                                setAddResourceConfirmModal(
                                                    !addResourceConfirmModal
                                                );
                                            }}
                                        />
                                        <TpModal
                                            isOpen={addResourceConfirmModal}
                                            centered
                                            className={`tp-modal-${userType} size-3`}
                                            children={
                                                <div className="text-center">
                                                    <h3 className="mb0">
                                                        The resource has been added
                                                    </h3>
                                                    <Button
                                                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mt9 mr4`}
                                                        title="Add another resource"
                                                        onClick={handleAnotherResource}
                                                    />

                                                    <Button
                                                        className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                                        title="OK"
                                                        onClick={() =>
                                                            history.push(
                                                                getRoute(APP_ROUTES.KNOWLEDGE_BANK)
                                                            )
                                                        }
                                                    />
                                                </div>
                                            }
                                        />
                                        <TpModal
                                            isOpen={addAnotherResourceModal}
                                            centered
                                            className={`tp-modal-${userType} size-7`}
                                            children={
                                                <Fragment>
                                                    <div className="close-btn">
                                                        <CloseIconCircle
                                                            className="pointer tpw-40 tph-40"
                                                            onClick={() =>
                                                                setAddAnotherResourceModal(false)
                                                            }
                                                            color={Colors[`${userType}Primary`]}
                                                        />
                                                    </div>
                                                    <h3 className="text-center mb0">
                                                        Please select a subject
                                                    </h3>
                                                    <Slider
                                                        className={`tp-center-mode-active-big tp-slider-${userType} text-webkit-center`}
                                                        options={optionsTopicModal}
                                                        children={
                                                            <Fragment>
                                                                <Card
                                                                    title="Social Science"
                                                                    svg={
                                                                        <SocialScienceIcon
                                                                            color={
                                                                                Colors[
                                                                                    `${userType}Primary`
                                                                                ]
                                                                            }
                                                                        />
                                                                    }
                                                                    className={`mt9 tp-card-sm tp-card-${userType}`}
                                                                />
                                                                <Card
                                                                    svg={
                                                                        <OtherSubjectIcon
                                                                            color={
                                                                                Colors[
                                                                                    `${userType}Primary`
                                                                                ]
                                                                            }
                                                                        />
                                                                    }
                                                                    className={`mt9 tp-card-sm tp-card-${userType}`}
                                                                    title="Others"
                                                                />
                                                                <Card
                                                                    svg={
                                                                        <LanguagesIcon
                                                                            color={
                                                                                Colors[
                                                                                    `${userType}Primary`
                                                                                ]
                                                                            }
                                                                        />
                                                                    }
                                                                    className={`mt9 tp-card-sm tp-card-${userType}`}
                                                                    title="Languages"
                                                                />
                                                                <Card
                                                                    svg={
                                                                        <FoodHealthIcon
                                                                            color={
                                                                                Colors[
                                                                                    `${userType}Primary`
                                                                                ]
                                                                            }
                                                                        />
                                                                    }
                                                                    className={`mt9 tp-card-sm tp-card-${userType}`}
                                                                    title="Food & Health"
                                                                />
                                                            </Fragment>
                                                        }
                                                    />
                                                    <div className="text-center">
                                                        <Button
                                                            className={`tp-btn-primary tp-btn-primary-${userType} mt4`}
                                                            title={"Next"}
                                                        />
                                                    </div>
                                                </Fragment>
                                            }
                                        />
                                    </div>
                                </AvForm>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    // setSelectedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(AddResourceUploadViewDetails);
