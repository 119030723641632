const TutorIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="204"
            height="210"
            viewBox="0 0 204 210"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M84 157.567C110.888 157.567 132.686 135.357 132.686 107.959C132.686 80.561 110.888 58.3506 84 58.3506C57.1117 58.3506 35.3145 80.561 35.3145 107.959C35.3145 135.357 57.1117 157.567 84 157.567Z"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
            />
            <path
                d="M161.189 202.29C145.549 175.301 116.792 157.309 83.9983 157.309C51.7094 157.309 23.2044 175.044 7.56445 201.262"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M11.8555 28.791V7.71387H196.255V144.972H175.57"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default TutorIcon;
