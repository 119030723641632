import { Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "../QuestionBoard.scss";

import { AvForm } from "availity-reactstrap-validation";
import { Button } from "../../../components/Button";
import { TpModal } from "../../../components/TpModal";

import { CreatableDropdown } from "../../../components/CreatableDropdown";

const KnowledgeBankSubjectSelection = (props) => {
    const { userType, showSubjectSelectionModal } = props;
    return (
        <TpModal
            isOpen={showSubjectSelectionModal}
            centered
            className={`tp-modal-${userType} size-8`}
            children={
                <AvForm>
                    <div className="text-center">
                        <h3 className="mb4">Knowledge Bank</h3>
                        <h3 className="mb0">
                            Please select the subject and the topic
                            <br /> from which you want to add the resource
                            {/* Change Text For Resource Selection Please select the resource that you wish to <br/> add */}
                        </h3>
                    </div>
                    <Row className="align-items-center mt9">
                        <Col md={3}>
                            <h3 className="mb0">Select the Subject:</h3>
                        </Col>
                        <Col md={9}>
                            <CreatableDropdown
                                placeholder="Select Subject"
                                options={[
                                    {
                                        label: "Math"
                                    },
                                    {
                                        label: "Biology"
                                    },
                                    {
                                        label: "Spanish"
                                    },
                                    {
                                        label: "German"
                                    },
                                    {
                                        label: "Italian"
                                    }
                                ]}
                                className={`mw-100 dropdown-size-sm tp-creatable-dropdown-${userType}`}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center mt9">
                        <Col md={3}>
                            <h3 className="mb0">Select the Topic:</h3>
                        </Col>
                        <Col md={9}>
                            <CreatableDropdown
                                placeholder="Select Topic"
                                options={[
                                    {
                                        label: "Math"
                                    },
                                    {
                                        label: "Biology"
                                    },
                                    {
                                        label: "Spanish"
                                    },
                                    {
                                        label: "German"
                                    },
                                    {
                                        label: "Italian"
                                    }
                                ]}
                                className={`mw-100 dropdown-size-sm tp-creatable-dropdown-${userType}`}
                            />
                        </Col>
                    </Row>
                    {/* Enable Scrolling div for resource selection and hide above rows */}
                    {/* <div
                                                                            className={`maxh-35 scrolling-container scrolling-container-${userType}`}
                                                                        >
                                                                            <Row>
                                                                                <Col md={4}>
                                                                                    <Card
                                                                                        img={
                                                                                            AvatarImg
                                                                                        }
                                                                                        title="Math.jpg"
                                                                                        className={`mb4 card-resource tp-card-${userType}`}
                                                                                    />
                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <Card
                                                                                        img={
                                                                                            AvatarImg
                                                                                        }
                                                                                        title="Math.jpg"
                                                                                        className={`mb4 card-resource tp-card-${userType}`}
                                                                                    />
                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <Card
                                                                                        img={
                                                                                            AvatarImg
                                                                                        }
                                                                                        title="Math.jpg"
                                                                                        className={`mb4 card-resource tp-card-${userType}`}
                                                                                    />
                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <Card
                                                                                        img={
                                                                                            AvatarImg
                                                                                        }
                                                                                        title="Math.jpg"
                                                                                        className={`mb4 card-resource tp-card-${userType}`}
                                                                                    />
                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <Card
                                                                                        img={
                                                                                            AvatarImg
                                                                                        }
                                                                                        title="Math.jpg"
                                                                                        className={`mb4 card-resource tp-card-${userType}`}
                                                                                    />
                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <Card
                                                                                        img={
                                                                                            AvatarImg
                                                                                        }
                                                                                        title="Math.jpg"
                                                                                        className={`mb4 card-resource tp-card-${userType}`}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </div> */}
                    <div className="text-center">
                        <Button
                            className={`mt9 tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4`}
                            title="Cancel"
                            onClick={() => {
                                // setKnowledgeBankModal(false);
                            }}
                        />
                        <Button
                            className={`mt9 tp-btn-primary tp-btn-primary-${userType}`}
                            title="OK"
                            onClick={() => {
                                // setEditAttachmentModal(!editAttachmentModal);
                            }}
                        />
                    </div>
                </AvForm>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(KnowledgeBankSubjectSelection);
