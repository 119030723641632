const ArrowDown = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="28"
            height="15"
            viewBox="0 0 28 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.5 0.999999L14 13.5L1.5 1"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default ArrowDown;
