import { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Settings.scss";
import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { TpModal } from "../../components/TpModal";

import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import EmailIcon from "../../components/SvgIcon/EmailIcon";
import KeyIcon from "../../components/SvgIcon/KeyIcon";
import CheckIconCircle from "../../components/SvgIcon/CheckIconCircle";

const SetupNewEmail = (props) => {
    const { userType } = props;
    const history = useHistory();
    const location = useLocation();
    const [emailConfirm, setEmailConfirm] = useState(false);

    const handleEmailConfirm = () => {
        setEmailConfirm(!emailConfirm);
        history.push(getRoute(APP_ROUTES.PROFILE_SETTINGS));
    };

    return (
        <div className="tp-settings tp-setup-new-email">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Change {location.state.routeParam}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="content-inner-wrap size-sm">
                            <Button
                                title="Back to profile"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner setup-new-emai-inner bg-clr-light">
                                <div className="change-email-wrap text-center">
                                    {location.state.routeParam === "Password" ? (
                                        <KeyIcon
                                            className="tpw-140 tph-140"
                                            color={Colors[`${userType}Primary`]}
                                        />
                                    ) : (
                                        <EmailIcon
                                            className="tpw-140 tph-140"
                                            color={Colors[`${userType}Primary`]}
                                        />
                                    )}
                                    <h3 className="mt9 mb9">
                                        {location.state.routeParam === "Password"
                                            ? "Please enter your new password"
                                            : "Please enter your email address"}
                                    </h3>
                                    <AvForm>
                                        <TextInput
                                            name="email"
                                            placeholder={
                                                location.state.routeParam === "Password"
                                                    ? "New Password"
                                                    : "New Email Address"
                                            }
                                            type="email"
                                            options={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required"
                                                }
                                            }}
                                            className={`tp-text-input-${userType} bdr-0 radius-7 mb4`}
                                        />
                                        <TextInput
                                            name="confirmemail"
                                            placeholder={
                                                location.state.routeParam === "Password"
                                                    ? "Confirm Password"
                                                    : "Confirm Email Address"
                                            }
                                            type="email"
                                            options={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required"
                                                }
                                            }}
                                            className={`tp-text-input-${userType} bdr-0 radius-7`}
                                        />
                                    </AvForm>
                                    <Button
                                        title="Save changes"
                                        className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                        onClick={() => {
                                            setEmailConfirm(!emailConfirm);
                                        }}
                                    />
                                    <TpModal
                                        isOpen={emailConfirm}
                                        centered
                                        className={`tp-modal-${userType} size-1`}
                                        children={
                                            <div className="text-center">
                                                <CheckIconCircle type={`${userType}`} />
                                                <h3 className="h3 mt9 mb9">
                                                    Your {location.state.routeParam}
                                                    &nbsp;has been changed
                                                </h3>
                                                <Button
                                                    onClick={handleEmailConfirm}
                                                    className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                    title="OK"
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(SetupNewEmail);
