import { ServerCodes } from "../constants";
import { toaster } from "./toasterUtil";
export const handleFetchError = async (res, showToaster) => {
    const response = await res.clone().json();
    if (
        response.Status === ServerCodes.INTERNAL_SERVER_ERROR ||
        response.Status === ServerCodes.SERVICE_UNAVAILABLE
    ) {
        toaster({ title: response.Message, type: "danger" });

        throw await res.json();
    } else if (
        response.Status >= ServerCodes.BAD_REQUEST &&
        response.Status < ServerCodes.CONNECTION_TIMED_OUT
    ) {
        showToaster && toaster({ title: response.Message, type: "danger" });

        throw await res.json();
    }
    return res.json();
};
