import SimpleReactCalendar from "simple-react-calendar";

import "./DateRangeCalendar.scss";

import { Colors } from "../../themes/colors";

import ArrowLeft from "../SvgIcon/ArrowLeft";
import ArrowRight from "../SvgIcon/ArrowRight";

const DateRangeCalendar = (props) => {
    const { activeMonth, renderDay, className = "" } = props;
    return (
        <div className={`date-range-calendar ${className}`}>
            <SimpleReactCalendar
                daysOfWeek={["M", "T", "W", "T", "F", "S", "S"]}
                headerPrevArrow={<ArrowLeft color={Colors.black} />}
                headerNextArrow={<ArrowRight color={Colors.black} />}
                activeMonth={new Date()}
                renderDay={(day) => {
                    return <span>{day.ISODate.slice(8, 10)}</span>;
                }}
            />
        </div>
    );
};

export default DateRangeCalendar;
