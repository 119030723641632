import Chart from "react-apexcharts";

import "./GraphChart.scss";

const GraphChart = (props) => {
    const { options, series, type, height, className = "" } = props;
    return (
        <div className={`tp-graph-chart ${className}`}>
            <Chart options={options} series={series} type={type} height={height} />
        </div>
    );
};

export default GraphChart;
