import React from "react";
import { connect } from "react-redux";

import { TpModal } from "../../../components/TpModal";
import { Button } from "../../../components/Button";

const InsufficientMinutesModal = (props) => {
    const { userType } = props;
    return (
        <TpModal
            isOpen={true}
            centered
            className={`tp-modal-${userType} size-3`}
            children={
                <div className="text-center">
                    <div>
                        <h3 className="h3 mb9">
                            Sorry you have insufficient minutes to ask a new question
                        </h3>
                        <Button
                            className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border-${userType} mr2`}
                            title="Cancel"
                        />
                        <Button
                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                            title="Request Funds"
                        />
                    </div>
                </div>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(InsufficientMinutesModal);
