const EarningIcon = (props) => {
    const { className, onClick, color, bgColor } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="150"
            height="150"
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="75" cy="75" r="75" fill={bgColor} />
            <path
                d="M75 84.75C98.334 84.75 117.25 76.0196 117.25 65.25C117.25 54.4804 98.334 45.75 75 45.75C51.666 45.75 32.75 54.4804 32.75 65.25C32.75 76.0196 51.666 84.75 75 84.75Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M75 84.75V104.25"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.75 65.25V84.75C32.75 94.5 49 104.25 75 104.25C101 104.25 117.25 94.5 117.25 84.75V65.25"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M101 80.7285V100.229"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M49 80.7285V100.229"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default EarningIcon;
