const IconHome = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="150"
            height="154"
            viewBox="0 0 150 154"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M94.6827 144.616V105.24C94.6827 103.5 93.9913 101.83 92.7606 100.6C91.5299 99.3689 89.8607 98.6775 88.1202 98.6775H61.8702C60.1297 98.6775 58.4605 99.3689 57.2298 100.6C55.9991 101.83 55.3077 103.5 55.3077 105.24V144.616C55.3077 146.356 54.6164 148.025 53.3859 149.256C52.1553 150.487 50.4864 151.178 48.746 151.178L9.37582 151.184C8.51395 151.184 7.6605 151.014 6.86421 150.684C6.06791 150.354 5.34437 149.871 4.7349 149.262C4.12543 148.652 3.64197 147.929 3.31212 147.133C2.98227 146.336 2.8125 145.483 2.8125 144.621V68.7746C2.8125 67.8603 3.00354 66.9561 3.37339 66.12C3.74323 65.2838 4.28371 64.5342 4.96017 63.9191L70.5807 4.2539C71.7886 3.15556 73.3626 2.54693 74.9953 2.54688C76.6279 2.54682 78.202 3.15534 79.41 4.25361L145.04 63.9191C145.716 64.5342 146.257 65.2839 146.627 66.1201C146.996 66.9562 147.188 67.8605 147.188 68.7749V144.621C147.188 145.483 147.018 146.336 146.688 147.133C146.358 147.929 145.875 148.652 145.265 149.262C144.656 149.871 143.932 150.355 143.136 150.684C142.339 151.014 141.486 151.184 140.624 151.184L101.244 151.178C99.504 151.178 97.835 150.487 96.6045 149.256C95.374 148.025 94.6827 146.356 94.6827 144.616V144.616Z"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default IconHome;
