import React, { useState } from "react";
import { connect } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";

import { TpModal } from "../../../components/TpModal";
import { Button } from "../../../components/Button";
import { TextInput } from "../../../components/TextInput";
import { RadioBtn } from "../../../components/RadioBtn";

const AddDescriptionModal = (props) => {
    const { userType, isOpen, handleClose, description, onChange, handleSendBtnPress, isLoading } =
        props;
    const [isChecked, setIsChecked] = useState(false);

    return (
        <TpModal
            isOpen={isOpen}
            centered
            className={`tp-modal-${userType} size-6`}
            children={
                <div>
                    <h3 className="h4 text-center">Would you like to add a description?</h3>
                    <AvForm>
                        <TextInput
                            name="answerquestion"
                            type="textarea"
                            value={description}
                            onChange={onChange}
                            className={`radius-7 resize-none size-sm tp-text-input-${userType}`}
                            options={{
                                required: {
                                    value: true,
                                    errorMessage: "This field is required"
                                }
                            }}
                        />
                        <div className="d-flex align-items-center mt4 mb9">
                            <h3 className="mb0 mr1">Mark this question as urgent</h3>
                            <RadioBtn
                                className={`tp-radio-btn-${userType}`}
                                checked={isChecked}
                                onClick={() => setIsChecked(!isChecked)}
                            />
                        </div>
                        <div className="text-center d-flex justify-content-center">
                            <Button
                                onClick={handleClose}
                                className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border-${userType} mr2`}
                                disabled={isLoading}
                                title="Cancel"
                            />
                            <Button
                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                title="Send"
                                isLoading={isLoading}
                                onClick={() => handleSendBtnPress(isChecked)}
                            />
                        </div>
                    </AvForm>
                </div>
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(AddDescriptionModal);
