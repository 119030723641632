export const toBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        callback(reader.result);
    };
    reader.onerror = function (error) {};
};

export const getMediaThumbPath = (mediaObj) => {
    if (mediaObj && Object.keys(mediaObj).length) {
        return `${mediaObj.BasePath}${mediaObj.ThumbPath}`;
    }
    return null;
};

export const getMediaPath = (mediaObj) => {
    if (mediaObj && Object.keys(mediaObj).length) {
        return `${mediaObj.BasePath}${mediaObj.Path}`;
    }
    return null;
};

export const getFileSize = (size) => {
    let sizeInMb = size / 1024 / 1024;
    if (sizeInMb < 0) {
        return `${Number(sizeInMb).toFixed(2)} KB`;
    }
    return `${Number(sizeInMb).toFixed(2)} MB`;
};

export const supportedMediaTypes = (type) => {
    const supportedMedia = [
        "pdf",
        // "doc",
        // "docx",
        // "xlsx",
        // "pptx",
        // "ppt",
        // "txt",
        // "csv",
        "jpg",
        "jpeg",
        "png",
        "gif",
        "m4a",
        "mp3",
        "wav",
        "avi",
        "mp4",
        "mov",
        "flv"
    ];
    console.log("typeeeee", type);
    return supportedMedia.includes(type);
};

function resizeImage(img) {
    const max_width = 400;
    const max_height = 400;
    let canvas = document.createElement("canvas");

    let width = img.width;
    let height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
        if (width > max_width) {
            //height *= max_width / width;
            height = Math.round((height *= max_width / width));
            width = max_width;
        }
    } else {
        if (height > max_height) {
            //width *= max_height / height;
            width = Math.round((width *= max_height / height));
            height = max_height;
        }
    }

    // resize the canvas and draw the image data into it
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    return canvas.toDataURL("image/jpeg", 0.5);
}

export const getMinifiedImagePreviewURL = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = (event) => {
            // blob stuff
            let blob = new Blob([event.target.result]); // create blob...
            window.URL = window.URL || window.webkitURL;
            let blobURL = window.URL.createObjectURL(blob); // and get it's URL

            // helper Image object
            let image = new Image();
            image.src = blobURL;

            image.onload = function () {
                // have to wait till it's loaded
                let resized = resizeImage(image); // send it to canvas
                resolve(resized);
                window.URL.revokeObjectURL(blobURL);
            };
        };
        reader.onerror = (err) => {
            reject(err);
        };
        reader.readAsArrayBuffer(file);
    });
};

export const getVideoPreviewURL = (file) => {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        var video = document.createElement("video");
        fileReader.onload = () => {
            var blob = new Blob([fileReader.result], { type: file.type });
            var url = URL.createObjectURL(blob);
            var timeupdate = () => {
                if (snapImage()) {
                    video.removeEventListener("timeupdate", timeupdate);
                    video.pause();
                }
            };
            video.addEventListener("loadeddata", function () {
                if (snapImage()) {
                    video.removeEventListener("timeupdate", timeupdate);
                }
            });
            var snapImage = () => {
                var canvas = document.createElement("canvas");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
                var image = canvas.toDataURL();
                var success = image.length > 100000;
                if (success) {
                    resolve(image);
                    URL.revokeObjectURL(url);
                }
                return success;
            };
            video.addEventListener("timeupdate", timeupdate);
            video.preload = "metadata";
            video.src = url;
            // Load video in Safari / IE11
            video.muted = true;
            video.playsInline = true;
            video.play();
        };
        fileReader.onerror = (err) => {
            reject(err);
        };
        fileReader.readAsArrayBuffer(file);
    });
};

export const getPdfText = (file, page) => {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onload = function () {
            let typedarray = new Uint8Array(this.result);

            const loadingTask = window.pdfjsLib.getDocument(typedarray);
            loadingTask.promise.then((pdf) => {
                pdf.getPage(page).then(function (page) {
                    let scale = 1.5;
                    let viewport = page.getViewport({
                        scale: scale
                    });

                    let canvas = document.getElementById("pdfCanvas");
                    if (canvas) {
                        let context = canvas.getContext("2d");
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        // Render PDF page into canvas context
                        let renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };
                        let renderTask = page.render(renderContext);
                        renderTask.promise.then(function () {
                            resolve(pdf._pdfInfo.numPages);
                        });
                    }
                });
            });
        };
        fileReader.readAsArrayBuffer(file);
    });
};
