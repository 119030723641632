import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./KnowledgeBank.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { TpModal } from "../../components/TpModal";
import { AvForm } from "availity-reactstrap-validation";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { CreatableDropdown } from "../../components/CreatableDropdown";

import DropFilesIcon from "../../components/SvgIcon/DropFilesIcon";
import UploadIcon from "../../components/SvgIcon/UploadIcon";
import CheckIconCircle from "../../components/SvgIcon/CheckIconCircle";

const AddResource = (props) => {
    const { setSelectedUser, isAuthenticated, userType } = props;
    const history = useHistory();
    const [addResourceWarningModal, setAddResourceWarningModal] = useState(false);

    return (
        <div className="tp-knowledge-bank tp-add-resource">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Add Resource
                        </div>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to knowledge bank"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner add-resource-inner bg-clr-light">
                                <AvForm>
                                    <Row className="align-items-center mb9">
                                        <Col md={3}>
                                            <h3 className="mb0">Select the Subject</h3>
                                        </Col>
                                        <Col md={9}>
                                            <CreatableDropdown
                                                placeholder="Select Subject"
                                                options={[
                                                    {
                                                        label: "Math"
                                                    },
                                                    {
                                                        label: "Biology"
                                                    },
                                                    {
                                                        label: "Spanish"
                                                    },
                                                    {
                                                        label: "German"
                                                    },
                                                    {
                                                        label: "Italian"
                                                    }
                                                ]}
                                                className={`mw-100 dropdown-size-sm bdr-none radius-thin arrow-open-up tp-creatable-dropdown-${userType}`}
                                                dropIconColor={Colors[`${userType}Primary`]}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb9">
                                        <Col md={3}>
                                            <h3 className="mb0">Select the Topic</h3>
                                        </Col>
                                        <Col md={9}>
                                            <CreatableDropdown
                                                placeholder="Select Topic"
                                                options={[
                                                    {
                                                        label: "Math"
                                                    },
                                                    {
                                                        label: "Biology"
                                                    },
                                                    {
                                                        label: "Spanish"
                                                    },
                                                    {
                                                        label: "German"
                                                    },
                                                    {
                                                        label: "Italian"
                                                    }
                                                ]}
                                                className={`mw-100 dropdown-size-sm bdr-none radius-thin arrow-open-up tp-creatable-dropdown-${userType}`}
                                                dropIconColor={Colors[`${userType}Primary`]}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-center">
                                        <DropFilesIcon
                                            className="tpw-147 tph-146"
                                            color={Colors[`${userType}Primary`]}
                                            bgColor={Colors[`${userType}SecondaryLight`]}
                                        />
                                        <h5 className="mb9 mt6">Drop the file here</h5>
                                        <Button
                                            className={`tp-btn-primary tp-btn-primary-${userType}`}
                                            title={
                                                <>
                                                    <UploadIcon
                                                        color={Colors.white}
                                                        className="tpw-25 tph-25 mr1"
                                                    />
                                                    <span>Upload a file </span>
                                                </>
                                            }
                                            onClick={() => {
                                                setAddResourceWarningModal(
                                                    !addResourceWarningModal
                                                );
                                            }}
                                        />
                                        <TpModal
                                            isOpen={addResourceWarningModal}
                                            centered
                                            className={`tp-modal-${userType} size-1`}
                                            children={
                                                <div className="text-center">
                                                    <CheckIconCircle type={`${userType}`} />
                                                    <h3 className="mt9 mb9">
                                                        Please select a subject and topic
                                                    </h3>
                                                    <Button
                                                        onClick={() =>
                                                            history.push(
                                                                getRoute(
                                                                    APP_ROUTES.ADD_RESOURCE_UPLOAD_VIEW
                                                                )
                                                            )
                                                        }
                                                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                        title="OK"
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                </AvForm>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    // setSelectedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(AddResource);
