import { useRef, useState, Fragment, useEffect } from "react";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { setPersonalDetails } from "../../store/actions";
import "./Signup.scss";
import { Colors } from "../../themes/colors";
import { PictureUpload } from "../../components/PictureUpload";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import BgShape from "../../components/SvgIcon/BgShape";
import CameraIcon from "../../components/SvgIcon/CameraIcon";
import { convertBase64ToFileObj } from "../../utils/commonUtil";
import { toaster } from "../../utils/toasterUtil";
import {
    initializeMediaUtil,
    getMediaStream,
    uploadOnS3,
    finalizeMediaUtil
} from "../../utils/mediaService";

const ProfilePicture = ({ setPersonalDetails, selectedSubjects, fullName, userType }) => {
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const isInvited = params.get("isInvited");
    const inputFileRef = useRef();
    const profilePictureRef = useRef(null);
    const mediaAbortController = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [showCrop, setShowCrop] = useState(false);

    useEffect(() => {
        if (isReady) {
            if (isInvited) {
                history.push({
                    pathname: getRoute(APP_ROUTES.VIDEO_INTRODUCTION),
                    search: "?isInvited=true"
                });
            } else {
                history.push(getRoute(APP_ROUTES.SETUP_PASSWORD));
            }
        }
    }, [isReady]);

    const handleFileChange = (e) => {
        const { type } = e.target.files[0];
        let isImage = type.includes("image");
        if (isImage) {
            profilePictureRef.current.setImage(e.target.files[0]);
            setShowCrop(true);
        } else {
            toaster({
                title: "Only files with the following extensions are allowed; jpg, jpeg, png",
                type: "danger"
            });
        }
    };

    const handleUpload = async () => {
        setIsLoading(true);
        const profilePicture = profilePictureRef.current;
        if (!profilePicture.state.file) {
            return;
        }
        const imageAsDataURL = profilePicture.getImageAsDataUrl();
        let cropedFile = await convertBase64ToFileObj(imageAsDataURL, profilePicture.state.file);

        try {
            let res = await uploadMedia(cropedFile);
            setPersonalDetails({ mediaId: res.Id });
            setIsReady(true);

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            return;
        }
    };
    const uploadMedia = async (file) => {
        mediaAbortController.current = new AbortController();

        try {
            let credentialsRequest = initializeMediaUtil(file, mediaAbortController.current.signal);
            let mediaStreamRequest = getMediaStream(file);
            const [credentials, mediaStream] = await Promise.all([
                credentialsRequest,
                mediaStreamRequest
            ]);
            await uploadOnS3(mediaStream, credentials.Data, (progress) => {});
            let finalizeRes = await finalizeMediaUtil(
                credentials?.Data?.MediaId,
                mediaAbortController.current.signal
            );

            return finalizeRes?.Data?.Media;
        } catch (err) {}
    };
    const handleCancel = () => {
        if (mediaAbortController.current) {
            mediaAbortController.current.abort();
        }
        setShowCrop(!showCrop);
        setIsLoading(false);
        setIsReady(false);
    };
    const handleSkip = () => {
        setIsLoading(false);
        setIsReady(false);
        if (isInvited) {
            history.push({
                pathname: getRoute(APP_ROUTES.VIDEO_INTRODUCTION),
                search: "?isInvited=true"
            });
        } else {
            history.push(getRoute(APP_ROUTES.SETUP_PASSWORD));
        }
    };

    if (!selectedSubjects && !isInvited) {
        return <Redirect to={APP_ROUTES.SUBJECT_SELECTION} />;
    } else if (!fullName && isInvited) {
        return (
            <Redirect
                to={{
                    pathname: getRoute(APP_ROUTES.PERSONAL_DETAILS),
                    search: "?isInvited=true"
                }}
            />
        );
    }

    return (
        <div className="tp-profile-picture full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12} className="text-center mt9">
                        {!showCrop && (
                            <Fragment>
                                <h1 className="mb0">
                                    <span className="d-block mb4">Fantastic,</span>
                                    <span>
                                        would you like to add a profile <br />
                                        picture?
                                    </span>
                                </h1>
                                <div className="picture-upload-wrap mt9">
                                    <CameraIcon
                                        className="camera-icon d-block mx-auto mb9"
                                        color={Colors[`${userType}Primary`]}
                                    />
                                    <Button
                                        title="Skip"
                                        className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr9`}
                                        onClick={handleSkip}
                                    />
                                    <input
                                        className="d-none"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        ref={inputFileRef}
                                        onChange={handleFileChange}
                                    />
                                    <Button
                                        title="Upload"
                                        className={`tp-btn-primary tp-btn-primary-${userType}`}
                                        onClick={() => inputFileRef.current.click()}
                                    />
                                </div>
                            </Fragment>
                        )}
                        <div className={"profile-crop-wrap " + (!showCrop ? "d-none" : "")}>
                            <PictureUpload
                                componentRef={profilePictureRef}
                                className="mb9 mx-auto"
                            />
                            <Button
                                title="Cancel"
                                className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr9`}
                                onClick={handleCancel}
                            />
                            <Button
                                title="Apply"
                                onClick={handleUpload}
                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                isLoading={isLoading}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
const mapDispatchToProps = {
    setPersonalDetails
};
const mapStateToProps = (state) => {
    const selectedSubjects = state.root.auth.selectedSubjects;
    const fullName = state.root.auth.fullName;
    return {
        selectedSubjects,
        fullName,
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture);
