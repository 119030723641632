import "./ListItem.scss";
import CheckIcon from "../../components/SvgIcon/CheckIcon";

const ListItem = (props) => {
    const { text, className = "", iconColor } = props;
    return (
        <div className={`list-item d-flex align-items-center ${className}`}>
            <div className="list-icon-check">
                <CheckIcon color={iconColor} className="tpw-40 tph-40" />
            </div>
            <div className="item-text h1 mb0 ml5">{text}</div>
        </div>
    );
};

export default ListItem;
