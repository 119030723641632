const ReportTechnicalIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="140"
            height="123"
            viewBox="0 0 140 123"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.75 104L126.25 104C132.463 104 137.5 98.9632 137.5 92.75V14C137.5 7.7868 132.463 2.75 126.25 2.75L13.75 2.75C7.53679 2.75 2.5 7.7868 2.5 14V92.75C2.5 98.9632 7.53679 104 13.75 104Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M92.5 120.875H47.5"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M73.7529 44C73.7529 47 73.7529 50 73.7529 53.1875C73.7529 55.625 72.0654 57.3125 70.0029 57.125C67.9404 57.125 66.2529 55.4375 66.2529 53.1875C66.2529 47 66.2529 41 66.2529 34.8125C66.2529 32.5625 67.9404 30.875 70.0029 30.875C72.0654 30.875 73.7529 32.5625 73.7529 34.8125C73.7529 37.8125 73.7529 40.8125 73.7529 44Z"
                fill={color}
            />
            <path
                d="M64.375 72.1248C64.375 68.9373 66.8125 66.4998 70 66.4998C73.1875 66.4998 75.625 68.9373 75.625 72.1248C75.625 75.1248 73 77.7498 70 77.7498C66.8125 77.7498 64.375 75.1248 64.375 72.1248Z"
                fill={color}
            />
        </svg>
    );
};
export default ReportTechnicalIcon;
