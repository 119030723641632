const SubjectIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M68.75 12.5V50L56.2473 40.625L43.75 50V12.5"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.75 84.375C18.75 81.8886 19.7377 79.504 21.4959 77.7459C23.254 75.9877 25.6386 75 28.125 75H81.25V12.5H28.125C25.6386 12.5 23.254 13.4877 21.4959 15.2459C19.7377 17.004 18.75 19.3886 18.75 21.875V84.375Z"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.75 84.375V87.5H75"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default SubjectIcon;
