export const APP_ROUTES = {
    CHOOSE_USER: "/choose-user",
    SIGNUP_TERMS: "/signup-terms/:id",
    PERSONAL_DETAILS: "/personal-details",
    SUBJECT_SELECTION: "/subject-selection",
    VIDEO_INTRODUCTION: "/video-introduction",
    PROFILE_PICTURE: "/profile-picture",
    SETUP_PASSWORD: "/setup-password",
    WELCOME_SCREEN: "/welcome",
    USER_INVITE: "/user-invite",
    GUARDIAN_INVITE_FORM: "/guardian-invite",
    STUDENT_INVITE_FORM: "/student-invite",
    INVITE_CONFIRMATION: "/invite-confirmation",
    ENTER_EMAIL: "/enter-email",
    RESET_PASSWORD_CODE_CONFIRMATION: "/verification-confirmation/:tokenId",
    VERIFICATION_CODE_CONFIRMATION: "/verification-confirmation",
    CONFIRM_NETWORKS: "/confirm-networks",
    NETWORKS_DETAILS: "/network-details",
    LOGIN: "/login",
    SELECT_USER: "/select-user",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/password-reset",
    DASHBOARD: "/dashboard",
    SETTINGS: "/dashboard/settings",
    PROFILE_SETTINGS: "/dashboard/profile-settings",
    CHANGE_EMAIL: "/dashboard/change-email",
    CHANGE_EMAIL_CODE_VERIFICATION: "/dashboard/email-code-confirmation",
    SETUP_NEW_EMAIL: "/dashboard/setup-new-email",
    NOTIFICATION_SETTINGS: "/dashboard/notification-settings",
    ABOUT_US: "/dashboard/about-us",
    TERMS_AND_CONDITIONS: "/dashboard/terms-and-conditons",
    PRIVACY_POLICY: "/dashboard/privacy-policy",
    ABOUT_TUTORPIN: "/dashboard/about-tutorpin",
    HELP_CENTER: "/dashboard/help-center",
    FAQ: "/dashboard/faqs",
    REPORT: "/dashboard/report",
    DELETE_ACCOUNT: "/dashboard/delete-account",
    DELETE_ACCOUNT_REASON: "/dashboard/delete-account-reason",
    DELETE_ACCOUNT_REASON_DESCRIPTION: "/dashboard/delete-account-reason-description",
    MY_REPORTS: "/dashboard/my-reports",
    REPORT_TECHNICAL_ISSUES: "/dashboard/report-technical-issues",
    VIEW_REPORT: "/dashboard/view-report",
    REPORT_USERS: "/dashboard/report-user",
    QUESTION_BOARD: "/dashboard/question-board",
    GUARDIAN_USERS: "/dashboard/users",
    QUESTION_DETAILS: "/dashboard/question-details/:id",
    ANSWERED_QUESTION_DETAILS: "/dashboard/question-details/answered/:id",
    ASK_FOLLOW_UP_QUESTION: "/dashboard/question-details/follow-up/:id",
    ASK_CLARITY_QUESTION: "/dashboard/question-details/clarity-question/:id",
    QUESTIONS_LIST: "/dashboard/questions-list/:id",
    ASK_QUESTION: "/dashboard/questions/ask/:subjectId",
    DRAFT_QUESTION: "/dashboard/questions/draft/ask/:id",
    ANSWER_QUESTION: "/dashboard/answer-question/:id",
    TOPIC_LISTINGS: "/dashboard/topic-listings",
    SEARCH_RESULT: "/dashboard/search-result",
    RESOURCE_DETAILS: "/dashboard/resource-details",
    ADD_SUBJECT: "/dashboard/add-subject",
    MY_NETWORK: "/dashboard/my-network",
    NETWORK_INVITE: "/dashboard/network-invite",
    TODOLIST: "/dashboard/todo-list",
    STUDENT_NETWORK_INVITE_FORM: "/dashboard/network-invite-student",
    GUARDIAN_NETWORK_INVITE_FORM: "/dashboard/network-invite-guardian",
    EARNING_AND_BILLING: "/dashboard/earning-and-billing",
    EARNING_AND_BILLING_REPORT: "/dashboard/earning-and-billing-report",
    WALLET: "/dashboard/wallet",
    WALLET_SUBJECT_SELECTION: "/dashboard/wallet-subject-selection",
    PACKAGES_LIST: "/dashboard/packages-list",
    KNOWLEDGE_BANK: "/dashboard/knowledge-bank",
    TOPIC_RESOURCE_LISTINGS: "/dashboard/topic-resource-listings",
    ADD_RESOURCE: "/dashboard/add-resource",
    ADD_RESOURCE_UPLOAD_VIEW: "/dashboard/add-resource-upload-view",
    ADD_RESOURCE_UPLOAD_VIEW_DETAILS: "/dashboard/add-resource-upload-view-details",
    ADD_TOPIC: "/dashboard/add-topic",
    SETTINGS_INVITE: "/dashboard/invite-user",
    SETTINGS_INVITE_GUARDIAN: "/dashboard/invite-user-guardian",
    SETTINGS_INVITE_STUDENT: "/dashboard/invite-user-student",
    REPORT_TECHNICAL_REASON: "/dashboard/report-technical-reason",
    REPORT_USER_REASON: "/dashboard/report-user-reason",
    REPORT_TECHNICAL_REASON_DESCRIPTION: "/dashboard/report-technical-reason-description",
    REPORT_USER_REASON_DESCRIPTION: "/dashboard/report-user-reason-description",
    EDIT_RESOURCE_IMAGE: "/dashboard/edit-resource-image"
};

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj);
        objectKeys.forEach((item) => {
            route = route.replace(new RegExp(/:([\d\w?])+/, "i"), (match) => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
};
