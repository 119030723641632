import Ratings from "react-ratings-declarative";

import "./StarRating.scss";
import { Colors } from "../../themes/colors";

const StarRating = (props) => {
    const { className = "", rating, changeRating = () => {} } = props;
    return (
        <div className={`tp-star-rating ${className}`}>
            <Ratings
                rating={rating}
                changeRating={changeRating}
                widgetSpacings="5px"
                widgetDimensions="24px"
                svgIconViewBoxes="24px"
                widgetRatedColors={Colors.orange}
                widgetEmptyColors={Colors.white}
                widgetHoverColors={Colors.orange}
            >
                <Ratings.Widget svgIconPath="M9.99956 15.8441L4.18281 18.9021L5.29371 12.4251L0.587863 7.838L7.09119 6.89301L9.99956 1L12.9079 6.89301L19.4113 7.838L14.7054 12.4251L15.8163 18.9021L9.99956 15.8441Z" />
                <Ratings.Widget svgIconPath="M9.99956 15.8441L4.18281 18.9021L5.29371 12.4251L0.587863 7.838L7.09119 6.89301L9.99956 1L12.9079 6.89301L19.4113 7.838L14.7054 12.4251L15.8163 18.9021L9.99956 15.8441Z" />
                <Ratings.Widget svgIconPath="M9.99956 15.8441L4.18281 18.9021L5.29371 12.4251L0.587863 7.838L7.09119 6.89301L9.99956 1L12.9079 6.89301L19.4113 7.838L14.7054 12.4251L15.8163 18.9021L9.99956 15.8441Z" />
                <Ratings.Widget svgIconPath="M9.99956 15.8441L4.18281 18.9021L5.29371 12.4251L0.587863 7.838L7.09119 6.89301L9.99956 1L12.9079 6.89301L19.4113 7.838L14.7054 12.4251L15.8163 18.9021L9.99956 15.8441Z" />
                <Ratings.Widget svgIconPath="M9.99956 15.8441L4.18281 18.9021L5.29371 12.4251L0.587863 7.838L7.09119 6.89301L9.99956 1L12.9079 6.89301L19.4113 7.838L14.7054 12.4251L15.8163 18.9021L9.99956 15.8441Z" />
            </Ratings>
        </div>
    );
};
export default StarRating;
