import { Fragment } from "react";

const CheckIconCircle = (props) => {
    const { type } = props;
    return (
        <Fragment>
            {type === "student" && (
                <svg
                    width="180"
                    height="180"
                    viewBox="0 0 180 180"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M90 157.5C127.279 157.5 157.5 127.279 157.5 90C157.5 52.7208 127.279 22.5 90 22.5C52.7208 22.5 22.5 52.7208 22.5 90C22.5 127.279 52.7208 157.5 90 157.5Z"
                        fill="#FFF4E9"
                        stroke="#F8AA4B"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M120.938 73.125L79.6873 112.5L59.0625 92.8125"
                        stroke="#F8AA4B"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
            {type === "tutor" && (
                <svg
                    width="180"
                    height="180"
                    viewBox="0 0 180 180"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M90 157.5C127.279 157.5 157.5 127.279 157.5 90C157.5 52.7208 127.279 22.5 90 22.5C52.7208 22.5 22.5 52.7208 22.5 90C22.5 127.279 52.7208 157.5 90 157.5Z"
                        fill="#EBF2F5"
                        stroke="#06729D"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M120.938 73.125L79.6873 112.5L59.0625 92.8125"
                        stroke="#06729D"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
            {type === "guardian" && (
                <svg
                    width="180"
                    height="180"
                    viewBox="0 0 180 180"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M90 157.5C127.279 157.5 157.5 127.279 157.5 90C157.5 52.7208 127.279 22.5 90 22.5C52.7208 22.5 22.5 52.7208 22.5 90C22.5 127.279 52.7208 157.5 90 157.5Z"
                        fill="#DFF7F4"
                        stroke="#1EA99D"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M120.938 73.125L79.6873 112.5L59.0625 92.8125"
                        stroke="#1EA99D"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
        </Fragment>
    );
};
export default CheckIconCircle;
