import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import "./Dashboard.scss";
import { Colors } from "../../themes/colors";
import { QuestionTypes, UserTypes } from "../../constants";

import { Question } from "../../components/Question";

import PaperClipIcon from "../../components/SvgIcon/PaperClipIcon";
import EarningIcon from "../../components/SvgIcon/EarningIcon";
import AddNetworkIconCircle from "../../components/SvgIcon/AddNetworkIconCircle";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";
import { APP_ROUTES } from "../../helpers/routeHelpers";

const Dashboard = (props) => {
    const { userType, user } = props;
    const history = useHistory();

    if (user.Type === UserTypes.STUDENT) {
        return <Redirect to={APP_ROUTES.QUESTION_BOARD} />;
    }

    if (user.Type === UserTypes.GUARDIAN) {
        return <Redirect to={APP_ROUTES.GUARDIAN_USERS} />;
    }
    return (
        <div className="tp-dashboard tp-dashboard-main">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>Dashboard</div>
                        <div className="content-inner-wrap mt9">
                            <Row>
                                <Col md={8}>
                                    <Row className="align-items-center">
                                        <Col xs={"auto"} className="text-center">
                                            <img
                                                src={AvatarImg}
                                                className="img-fluid objfit-cover tpw-80 tph-80 rounded-circle"
                                                alt=""
                                            />
                                            <h5 className="ff-sf-pro-rounded mt2 mb0">Carolina</h5>
                                        </Col>
                                        <Col>
                                            <Question
                                                text={
                                                    <Fragment>
                                                        Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor
                                                        incididunt ut labore dolor sit amet,
                                                        consectetur adipiscing elit?
                                                        <PaperClipIcon
                                                            color={Colors.black}
                                                            className="pointer"
                                                        />
                                                    </Fragment>
                                                }
                                                type={QuestionTypes.CLARITY}
                                                date="Monday"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt4">
                                        <Col xs={"auto"} className="text-center">
                                            <img
                                                src={AvatarImg}
                                                className="img-fluid objfit-cover tpw-80 tph-80 rounded-circle"
                                                alt=""
                                            />
                                            <h5 className="ff-sf-pro-rounded mt2 mb0">Carolina</h5>
                                        </Col>
                                        <Col>
                                            <Question
                                                text={
                                                    <Fragment>
                                                        Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor
                                                        incididunt ut labore dolor sit amet,
                                                        consectetur adipiscing elit?
                                                        <PaperClipIcon
                                                            color={Colors.black}
                                                            className="pointer"
                                                        />
                                                    </Fragment>
                                                }
                                                type={QuestionTypes.URGENT}
                                                date="Monday"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt4">
                                        <Col xs={"auto"} className="text-center">
                                            <img
                                                src={AvatarImg}
                                                className="img-fluid objfit-cover tpw-80 tph-80 rounded-circle"
                                                alt=""
                                            />
                                            <h5 className="ff-sf-pro-rounded mt2 mb0">Carolina</h5>
                                        </Col>
                                        <Col>
                                            <Question
                                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolor sit amet, consectetur adipiscing elit?"
                                                date="Yesterday"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt4">
                                        <Col xs={"auto"} className="text-center">
                                            <img
                                                src={AvatarImg}
                                                className="img-fluid objfit-cover tpw-80 tph-80 rounded-circle"
                                                alt=""
                                            />
                                            <h5 className="ff-sf-pro-rounded mt2 mb0">Carolina</h5>
                                        </Col>
                                        <Col>
                                            <Question
                                                text={
                                                    <Fragment>
                                                        Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor
                                                        incididunt ut labore dolor sit amet,
                                                        consectetur adipiscing elit?
                                                        <PaperClipIcon
                                                            color={Colors.black}
                                                            className="pointer"
                                                        />
                                                    </Fragment>
                                                }
                                                type={QuestionTypes.CLARITY}
                                                date="Monday"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt4">
                                        <Col xs={"auto"} className="text-center">
                                            <img
                                                src={AvatarImg}
                                                className="img-fluid objfit-cover tpw-80 tph-80 rounded-circle"
                                                alt=""
                                            />
                                            <h5 className="ff-sf-pro-rounded mt2 mb0">Carolina</h5>
                                        </Col>
                                        <Col>
                                            <Question
                                                text={
                                                    <Fragment>
                                                        Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor
                                                        incididunt ut labore dolor sit amet,
                                                        consectetur adipiscing elit?
                                                        <PaperClipIcon
                                                            color={Colors.black}
                                                            className="pointer"
                                                        />
                                                    </Fragment>
                                                }
                                                type={QuestionTypes.FOLLOWUP}
                                                date="20/20/2020"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={{ size: 3, offset: 1 }}>
                                    <div className="d-flex align-items-center">
                                        <EarningIcon
                                            color={Colors[`${userType}Primary`]}
                                            bgColor={Colors[`${userType}Secondary`]}
                                            className="tpw-86 tph-86"
                                        />
                                        <div className="ff-sf-pro-rounded ml2">
                                            <h1 className="mb2 fs-60">£450</h1>
                                            <h2 className="mb0">Current Earnings</h2>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col xs={12}>
                                            <h2
                                                className={`mt9 mb0 ff-sf-pro-rounded clr-${userType}-primary`}
                                            >
                                                Your Network
                                            </h2>
                                        </Col>
                                        <Col md={4} className="mt4">
                                            <span className="d-inline-block">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid objfit-cover tpw-83 tph-83 rounded-circle d-block mx-auto"
                                                    alt=""
                                                />
                                                <div className="d-flex mt3 align-items-center justify-content-center">
                                                    <h5 className="ff-sf-pro-rounded mb0">Jane</h5>
                                                    <span className="tp-network-tag tp-network-tag-student ml1">
                                                        S
                                                    </span>
                                                </div>
                                            </span>
                                        </Col>
                                        <Col md={4} className="mt4">
                                            <span className="d-inline-block">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid objfit-cover tpw-83 tph-83 rounded-circle d-block mx-auto"
                                                    alt=""
                                                />
                                                <div className="d-flex mt3 align-items-center justify-content-center">
                                                    <h5 className="ff-sf-pro-rounded mb0">Jane</h5>
                                                    <span className="tp-network-tag tp-network-tag-guardian ml1">
                                                        S
                                                    </span>
                                                </div>
                                            </span>
                                        </Col>
                                        <Col md={4} className="mt4">
                                            <span className="d-inline-block">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid objfit-cover tpw-83 tph-83 rounded-circle d-block mx-auto"
                                                    alt=""
                                                />
                                                <div className="d-flex mt3 align-items-center justify-content-center">
                                                    <h5 className="ff-sf-pro-rounded mb0">Jane</h5>
                                                    <span className="tp-network-tag tp-network-tag-guardian ml1">
                                                        S
                                                    </span>
                                                </div>
                                            </span>
                                        </Col>
                                        <Col md={4} className="mt4">
                                            <span className="d-inline-block">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid objfit-cover tpw-83 tph-83 rounded-circle d-block mx-auto"
                                                    alt=""
                                                />
                                                <div className="d-flex mt3 align-items-center justify-content-center">
                                                    <h5 className="ff-sf-pro-rounded mb0">Jane</h5>
                                                    <span className="tp-network-tag tp-network-tag-guardian ml1">
                                                        S
                                                    </span>
                                                </div>
                                            </span>
                                        </Col>
                                        <Col md={4} className="mt4">
                                            <span className="d-inline-block">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid objfit-cover tpw-83 tph-83 rounded-circle d-block mx-auto"
                                                    alt=""
                                                />
                                                <div className="d-flex mt3 align-items-center justify-content-center">
                                                    <h5 className="ff-sf-pro-rounded mb0">Jane</h5>
                                                    <span className="tp-network-tag tp-network-tag-student ml1">
                                                        S
                                                    </span>
                                                </div>
                                            </span>
                                        </Col>
                                        <Col md={4} className="mt4">
                                            <span className="d-inline-block">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid objfit-cover tpw-83 tph-83 rounded-circle d-block mx-auto"
                                                    alt=""
                                                />
                                                <div className="d-flex mt3 align-items-center justify-content-center">
                                                    <h5 className="ff-sf-pro-rounded mb0">Jane</h5>
                                                    <span className="tp-network-tag tp-network-tag-student ml1">
                                                        S
                                                    </span>
                                                </div>
                                            </span>
                                        </Col>
                                        <Col md={4} className="mt4">
                                            <span className="d-inline-block">
                                                <img
                                                    src={AvatarImg}
                                                    className="img-fluid objfit-cover tpw-83 tph-83 rounded-circle d-block mx-auto"
                                                    alt=""
                                                />
                                                <div className="d-flex mt3 align-items-center justify-content-center">
                                                    <h5 className="ff-sf-pro-rounded mb0">Jane</h5>
                                                    <span className="tp-network-tag tp-network-tag-student ml1">
                                                        S
                                                    </span>
                                                </div>
                                            </span>
                                        </Col>
                                        <Col md={"auto"} className="mt4">
                                            <AddNetworkIconCircle
                                                color={Colors[`${userType}Primary`]}
                                                bgColor={Colors[`${userType}Secondary`]}
                                                className="tpw-83 tph-83 pointer"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType,
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(Dashboard);
