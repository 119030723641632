const EnglishIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="194"
            height="194"
            viewBox="0 0 194 194"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.364 144.849C4.71623 125.214 0.637065 102.002 4.89253 79.5717C9.14799 57.1415 21.4453 37.0363 39.4754 23.0314C57.5055 9.02654 80.028 2.08531 102.814 3.51118C125.599 4.93705 147.081 14.6319 163.224 30.7753C179.368 46.9187 189.063 68.4001 190.489 91.1858C191.915 113.971 184.974 136.494 170.969 154.524C156.964 172.554 136.859 184.852 114.429 189.107C91.9985 193.363 68.7864 189.284 49.1509 177.636L49.1511 177.635L16.7717 186.886C15.432 187.269 14.0144 187.287 12.6657 186.937C11.317 186.588 10.0863 185.884 9.10108 184.899C8.1159 183.914 7.4121 182.683 7.06262 181.334C6.71314 179.985 6.73069 178.568 7.11346 177.228L16.3647 144.849L16.364 144.849Z"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M56 126H83.4609C86.2344 126 88.0703 124.359 88.0703 121.742C88.0703 119.125 86.2344 117.484 83.4609 117.484H61.0391V101.391H82.2891C84.9062 101.391 86.625 99.8281 86.625 97.3672C86.625 94.9062 84.8672 93.3828 82.2891 93.3828H61.0391V78.1484H83.4609C86.2344 78.1484 88.0703 76.5078 88.0703 73.8906C88.0703 71.2734 86.2344 69.6328 83.4609 69.6328H56C52.875 69.6328 50.9609 71.5859 50.9609 74.8672V120.766C50.9609 124.047 52.875 126 56 126ZM101.078 126.664C104.086 126.664 105.922 124.789 105.922 121.586V86.4688H106.273L132.602 123.656C134.203 125.922 135.531 126.664 137.836 126.664C141.117 126.664 142.953 124.828 142.953 121.625V74.0469C142.953 70.8438 141.156 68.9688 138.109 68.9688C135.102 68.9688 133.266 70.8438 133.266 74.0469V109.125H132.914L106.664 71.9766C105.023 69.75 103.656 68.9688 101.43 68.9688C98.1875 68.9688 96.1953 70.9219 96.1953 74.1641V121.586C96.1953 124.789 98.0312 126.664 101.078 126.664Z"
                fill={color}
            />
        </svg>
    );
};
export default EnglishIcon;
