import { useHistory } from "react-router";
import { connect } from "react-redux";

import "./SideNavbar.scss";
import { Colors } from "../../themes/colors";

import { NavBlock } from "../../components/NavBlock";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import Logo from "../../components/SvgIcon/Logo";
import IconHome from "../../components/SvgIcon/IconHome";
import QuestionBoardIcon from "../../components/SvgIcon/QuestionBoardIcon";
import KnowledgeBankIcon from "../../components/SvgIcon/KnowledgeBankIcon";
import TodoListIcon from "../../components/SvgIcon/TodoListIcon";
import EarnBillIcon from "../../components/SvgIcon/EarnBillIcon";
import WalletIcon from "../../components/SvgIcon/WalletIcon";
import { UserTypes } from "../../constants";

const SideNavbar = (props) => {
    const history = useHistory();
    const { className = "", userType, user } = props;

    const renderTodoListNav = () => (
        <NavBlock
            link={getRoute(APP_ROUTES.TODOLIST)}
            icon={<TodoListIcon color={Colors.black} />}
            text="To do list"
            className="text-decoration-none ff-sf-pro-rounded clr-black"
        ></NavBlock>
    );
    return (
        <div className={`tp-side-navbar vh-100 position-fixed text-center ${className}`}>
            <div className="logo d-none d-lg-block">
                <Logo
                    color={Colors[`${userType}Primary`]}
                    className="tpw-115 tph-24 pointer"
                    onClick={() => history.push(getRoute(APP_ROUTES.DASHBOARD))}
                />
            </div>
            <div className="navbar-items-wrap">
                {user.Type === UserTypes.TUTOR && (
                    <NavBlock
                        link={getRoute(APP_ROUTES.DASHBOARD)}
                        icon={<IconHome color={Colors[`${userType}Primary`]} />}
                        text="Dashboard"
                        className={`text-decoration-none ff-sf-pro-rounded clr-${userType}-primary`}
                    ></NavBlock>
                )}
                <NavBlock
                    link={getRoute(APP_ROUTES.QUESTION_BOARD)}
                    icon={
                        <QuestionBoardIcon
                            color={
                                user.Type === UserTypes.STUDENT || user.Type === UserTypes.GUARDIAN
                                    ? Colors[`${userType}Primary`]
                                    : Colors.black
                            }
                        />
                    }
                    text="Question board"
                    className="text-decoration-none ff-sf-pro-rounded clr-black"
                ></NavBlock>
                {user.Type === UserTypes.STUDENT && renderTodoListNav()}
                {user.Type === UserTypes.STUDENT && (
                    <NavBlock
                        link={getRoute(APP_ROUTES.QUESTION_BOARD)}
                        icon={<WalletIcon color={Colors.black} />}
                        text="Wallet"
                        className="text-decoration-none ff-sf-pro-rounded clr-black"
                    ></NavBlock>
                )}
                {user.Type === UserTypes.TUTOR && (
                    <>
                        <NavBlock
                            link={getRoute(APP_ROUTES.KNOWLEDGE_BANK)}
                            icon={<KnowledgeBankIcon color={Colors.black} />}
                            text="Knowledge bank"
                            className="text-decoration-none ff-sf-pro-rounded clr-black"
                        ></NavBlock>
                        {renderTodoListNav()}
                        <NavBlock
                            link={getRoute(APP_ROUTES.EARNING_AND_BILLING)}
                            icon={<EarnBillIcon color={Colors.black} />}
                            text="Earnings  & billings"
                            className="text-decoration-none ff-sf-pro-rounded clr-black"
                        ></NavBlock>
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType,
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(SideNavbar);
