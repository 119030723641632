import { Progress } from "reactstrap";

import "./ProgressBar.scss";

const ProgressBar = (props) => {
    const { value = null, text, className = "" } = props;
    return (
        <div className={`tp-progress-bar d-flex align-items-center ${className}`}>
            <Progress value={value} className="w-100">
                <h5 className="mb0 ff-sf-pro-rounded">{text}</h5>
            </Progress>
        </div>
    );
};
export default ProgressBar;
