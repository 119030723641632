import {
    SUBJECT_LISTING_REQUEST,
    SUBJECT_LISTING_SUCCESS,
    SUBJECT_LISTING_FAILURE,
    QUESTION_THREAD_SUCCESS
} from "../actions";

const initialState = {
    subjects: {
        fetching: false,
        data: []
    },
    questionThreads: [],
    questionFeedback: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SUBJECT_LISTING_REQUEST:
            return {
                ...state,
                subjects: {
                    ...state.subjects,
                    fetching: true
                }
            };
        case SUBJECT_LISTING_SUCCESS:
            return {
                ...state,
                subjects: {
                    ...state.subjects,
                    fetching: false,
                    data: action.data
                }
            };
        case SUBJECT_LISTING_FAILURE:
            return {
                ...state,
                subjects: {
                    ...state.subjects,
                    fetching: false
                }
            };
        case QUESTION_THREAD_SUCCESS:
            return {
                ...state,
                questionThreads: action.threads,
                questionFeedback: action.feedback
            };
        default:
            return state;
    }
};
