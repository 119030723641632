import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import "./SignupInvite.scss";
import { Colors } from "../../themes/colors";
import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { getSubjectListing } from "../../store/actions";
import PlusIcon from "../../components/SvgIcon/PlusIcon";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";
import BgShape from "../../components/SvgIcon/BgShape";

const NetworkDetails = ({ getSubjectListing, type, userType }) => {
    const history = useHistory();
    const [subjects, setSubjects] = useState([]);
    let [interSub, setInterSub] = useState("initialState");
    const [subjectErr, setSubjectErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedSubjectsArr, setSelectedSubjectsArr] = useState([
        { label: "", Id: "", hours: "" }
    ]);

    const [currentIndex, setCurrentIndex] = useState(0);

    const formatSubjects = (subjects) => {
        return subjects.map((item) => {
            return {
                ...item,
                label: item.Title
            };
        });
    };
    useEffect(() => {
        getSubjectListing()
            .then((res) => {
                setInterSub(formatSubjects([...res?.Data?.Subjects]));
                setSubjects(formatSubjects([...res?.Data?.Subjects]));
            })
            .catch((err) => {});
    }, []);
    const handleAddSubject = () => {
        let subjectId = selectedSubjectsArr[selectedSubjectsArr.length - 1].Id;
        let subjectHours = selectedSubjectsArr[selectedSubjectsArr.length - 1].hours;
        if (subjectId && subjectHours) {
            setSubjectErr(false);
            setCurrentIndex((previous) => previous + 1);
            setSelectedSubjectsArr([...selectedSubjectsArr, { Id: "", label: "", hours: "" }]);
        } else if (!subjectId) {
            setSubjectErr(true);
        }
    };

    const handleSubmit = () => {
        let subjectId = selectedSubjectsArr[selectedSubjectsArr.length - 1].Id;
        let subjectHours = selectedSubjectsArr[selectedSubjectsArr.length - 1].hours;
        if (subjectId) {
            setSubjectErr(false);
            if (subjectHours) {
                const finalSubjectWithHoursArr = selectedSubjectsArr.map((subject) => {
                    return { SubjectId: subject.Id, HourlyRate: parseFloat(subject.hours) };
                });
            }

            history.push({
                pathname: getRoute(APP_ROUTES.SETUP_PASSWORD),
                search: "?isInvited=true"
            });
        } else {
            setSubjectErr(true);
        }
    };
    const handleInvalidSubmit = () => {
        let subjectId = selectedSubjectsArr[selectedSubjectsArr.length - 1].Id;
        if (subjectId) {
            setSubjectErr(false);
        } else {
            setSubjectErr(true);
        }
    };
    const handleOnChange = (option, index) => {
        setSubjectErr(false);
        const newSubjectArr = selectedSubjectsArr.map((subject, subjectIndex) => {
            if (index === subjectIndex) {
                subject.Id = option.Id;
                subject.label = option.label;
            }
            return subject;
        });
        let filtered = subjects.filter(
            (item) => !selectedSubjectsArr.map((subject) => subject.Id).includes(item.Id)
        );
        setSelectedSubjectsArr(newSubjectArr);
        setInterSub(filtered);
    };
    const handleOnChangeHours = (value, index) => {
        const newSubjectArr = selectedSubjectsArr.map((subject, subjectIndex) => {
            if (index === subjectIndex) {
                subject.hours = value;
            }
            return subject;
        });
        setSelectedSubjectsArr(newSubjectArr);
    };
    if (!type) {
        return <Redirect to={APP_ROUTES.ENTER_EMAIL} />;
    }
    return (
        <div className="tp-network-details full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12}>
                        <h1 className="mb9 mt9 text-center">
                            Please add the subject and the subject hourly rate for <br />
                            the students
                        </h1>
                        <AvForm onValidSubmit={handleSubmit} onInvalidSubmit={handleInvalidSubmit}>
                            <div className="networks-info-wrap mx-auto">
                                <div className="network-info mb9">
                                    <Row>
                                        <Col md={4}>
                                            <span className="d-d-inline-block text-center">
                                                <div className="avatar-img">
                                                    <img
                                                        src={AvatarImg}
                                                        className="img-fluid rounded-circle"
                                                        alt=""
                                                    />
                                                </div>
                                                <h2
                                                    className={`text-center clr-${userType}-primary mt4 mb0`}
                                                >
                                                    Stuti
                                                </h2>
                                            </span>
                                        </Col>
                                        <Col md={8}>
                                            <div className="subject-select-dropdown">
                                                <Row>
                                                    <Col className="row-wrap position-relative">
                                                        {selectedSubjectsArr.map((item, index) => {
                                                            return (
                                                                <Row key={index}>
                                                                    <Col>
                                                                        <div className="position-relative">
                                                                            <CreatableDropdown
                                                                                key={index}
                                                                                placeholder="Select Subject"
                                                                                onChange={(
                                                                                    option
                                                                                ) =>
                                                                                    handleOnChange(
                                                                                        option,
                                                                                        index
                                                                                    )
                                                                                }
                                                                                options={interSub}
                                                                                dropIconColor={
                                                                                    Colors[
                                                                                        `${userType}Primary`
                                                                                    ]
                                                                                }
                                                                                className={`tp-creatable-dropdown-${userType} ${
                                                                                    index ===
                                                                                        currentIndex &&
                                                                                    subjectErr
                                                                                        ? "mb0"
                                                                                        : "mb4"
                                                                                }`}
                                                                            />

                                                                            {index ===
                                                                                currentIndex &&
                                                                                subjectErr && (
                                                                                    <div className="date-error mb2 ">
                                                                                        <span className="is-invalid"></span>
                                                                                        <div className="invalid-feedback ">
                                                                                            This is
                                                                                            a
                                                                                            required
                                                                                            field
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                        <TextInput
                                                                            name={`selectsubject-${index}`}
                                                                            placeholder="Subject Hourly Rate"
                                                                            options={{
                                                                                required: {
                                                                                    value: true,
                                                                                    errorMessage:
                                                                                        "This is a required field"
                                                                                },
                                                                                max: {
                                                                                    value: 100,
                                                                                    errorMessage:
                                                                                        "Valid hourly rate must be minimum 1 GBP and must not exceed 100 GBP"
                                                                                },
                                                                                min: {
                                                                                    value: 1,
                                                                                    errorMessage:
                                                                                        "Valid hourly rate must be minimum 1 GBP and must not exceed 100 GBP"
                                                                                },
                                                                                pattern: {
                                                                                    value: "/^[0-9]+$/",
                                                                                    errorMessage:
                                                                                        "Valid hourly rate must be minimum 1 GBP and must not exceed 100 GBP"
                                                                                }
                                                                            }}
                                                                            onChange={(e) =>
                                                                                handleOnChangeHours(
                                                                                    e.target.value,
                                                                                    index
                                                                                )
                                                                            }
                                                                            className={`tp-text-input-${userType}`}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            );
                                                        })}

                                                        {Boolean(interSub?.length) && (
                                                            <Button
                                                                className={`tp-btn-add tp-btn-add-${userType}`}
                                                                onClick={handleAddSubject}
                                                                title={
                                                                    <PlusIcon
                                                                        color={
                                                                            Colors[
                                                                                `${userType}Primary`
                                                                            ]
                                                                        }
                                                                    />
                                                                }
                                                            ></Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <Col xs={12} className="text-center mt9">
                                <Button
                                    title="Done"
                                    type="submit"
                                    className={`tp-btn-primary tp-btn-primary-${userType}`}
                                />
                            </Col>
                        </AvForm>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
const mapDispatchToProps = {
    getSubjectListing
};
const mapStateToProps = (state) => {
    return {
        type: state.root.auth.selectedUserType,
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NetworkDetails);
