const AccountIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="130"
            height="100"
            viewBox="0 0 130 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M117.5 2.75H12.5C6.70101 2.75 2 7.45101 2 13.25V86.75C2 92.549 6.70101 97.25 12.5 97.25H117.5C123.299 97.25 128 92.549 128 86.75V13.25C128 7.45101 123.299 2.75 117.5 2.75Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 44.75H39.2814C40.4909 44.7498 41.6633 45.1678 42.5998 45.9331C43.5363 46.6985 44.1794 47.7641 44.42 48.9495C45.389 53.6924 47.9666 57.9552 51.7166 61.0165C55.4667 64.0779 60.1591 65.75 65 65.75C69.841 65.75 74.5334 64.0779 78.2834 61.0165C82.0335 57.9552 84.6111 53.6924 85.5801 48.9495C85.8207 47.7642 86.4637 46.6985 87.4002 45.9331C88.3368 45.1678 89.5091 44.7498 90.7186 44.75H128"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 23.75H128"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default AccountIcon;
