import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./KnowledgeBank.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { TpModal } from "../../components/TpModal";

import PlusIcon from "../../components/SvgIcon/PlusIcon";

const AddTopic = (props) => {
    const { setSelectedUser, isAuthenticated, userType } = props;
    const history = useHistory();
    const [addResourceModal, setAddResourceModal] = useState(false);

    return (
        <div className="tp-knowledge-bank tp-add-topic">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>Add Topic</div>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to knowledge bank"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner add-topic-inner bg-clr-light">
                                <AvForm>
                                    <h2 className="ff-sf-pro-rounded text-center mb9">
                                        Subject: Math
                                    </h2>
                                    <Row className="mb4">
                                        <Col md={3} className="align-self-center">
                                            <h3 className="mb0">Please add topic</h3>
                                        </Col>
                                        <Col md={9}>
                                            <TextInput
                                                name="topicname"
                                                placeholder="Topic Name"
                                                className={`tp-text-input-${userType} bdr-0 radius-7`}
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "This is a required field"
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb4">
                                        <Col md={{ size: 9, offset: 3 }}>
                                            <Button
                                                className={`bdr-0 w-100 radius-7 tp-btn-add tp-btn-add-${userType}`}
                                                title={
                                                    <PlusIcon
                                                        color={Colors[`${userType}Primary`]}
                                                        className="tpw-18 tph-18"
                                                    />
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb4">
                                        <Col md={3} className="align-self-center">
                                            <h3 className="mb0">Current topics</h3>
                                        </Col>
                                        <Col md={9}>
                                            <Row>
                                                <Col md={6}>
                                                    <CreatableDropdown
                                                        isMulti={true}
                                                        placeholder="Topic 1"
                                                        options={[
                                                            {
                                                                label: "Math"
                                                            },
                                                            {
                                                                label: "Biology"
                                                            },
                                                            {
                                                                label: "Spanish"
                                                            },
                                                            {
                                                                label: "German"
                                                            },
                                                            {
                                                                label: "Italian"
                                                            }
                                                        ]}
                                                        hideArrow={true}
                                                        className={`mw-100 bdr-none tp-creatable-dropdown-${userType}`}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <CreatableDropdown
                                                        isMulti={true}
                                                        hideArrow={true}
                                                        placeholder="Topic 2"
                                                        options={[
                                                            {
                                                                label: "Math"
                                                            },
                                                            {
                                                                label: "Biology"
                                                            },
                                                            {
                                                                label: "Spanish"
                                                            },
                                                            {
                                                                label: "German"
                                                            },
                                                            {
                                                                label: "Italian"
                                                            }
                                                        ]}
                                                        className={`mw-100 bdr-none tp-creatable-dropdown-${userType}`}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="text-center">
                                            <Button
                                                className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                                title="Add Topic"
                                                onClick={() => {
                                                    setAddResourceModal(!addResourceModal);
                                                }}
                                            />
                                            <TpModal
                                                isOpen={addResourceModal}
                                                centered
                                                className={`tp-modal-${userType} size-3`}
                                                children={
                                                    <div className="text-center">
                                                        <h3 className="mb0">
                                                            Do you want to add resources to this
                                                            Subject?
                                                        </h3>
                                                        <Button
                                                            className={`tp-btn-primary-inverse tp-btn-primary-inverse-${userType} mr4 mt9`}
                                                            title="Yes"
                                                            onClick={() =>
                                                                history.push(
                                                                    getRoute(
                                                                        APP_ROUTES.ADD_RESOURCE
                                                                    )
                                                                )
                                                            }
                                                        />
                                                        <Button
                                                            className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                                            title="No"
                                                            onClick={() => {
                                                                setAddResourceModal(false);
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </AvForm>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    // setSelectedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTopic);
