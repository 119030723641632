import { AvField } from "availity-reactstrap-validation";
import "./TextInput.scss";

const TextInput = (props) => {
    const {
        options,
        name = "",
        type = "text",
        placeholder = "Enter Text",
        className = "",
        onFocus,
        onChange,
        value,
        label,
        ...rest
    } = props;
    return (
        <AvField
            label={label}
            onFocus={onFocus}
            name={name}
            type={type}
            validate={options}
            placeholder={placeholder}
            className={`tp-text-input shadow-none ${className}`}
            onChange={onChange}
            value={value}
            {...rest}
        />
    );
};

export default TextInput;
