import "./Question.scss";
import { Colors } from "../../themes/colors";
import { QuestionTypes } from "../../constants";

import ClarityQuestionIcon from "../SvgIcon/ClarityQuestionIcon";
import FollowupQuestionIcon from "../SvgIcon/FollowupQuestionIcon";
import UrgentQuestionIcon from "../SvgIcon/UrgentQuestionIcon";

const Question = (props) => {
    const { text, className = "", type = "", date, onClick = () => {} } = props;
    return (
        <div className={`tp-question d-flex align-items-center ${className}`} onClick={onClick}>
            <h3 className="question-text mb0">{text}</h3>
            <div className="question-details text-end">
                {type === QuestionTypes.CLARITY ? (
                    <div className="tooltip-info clarity">
                        <ClarityQuestionIcon color={Colors.black} className="tpw-33 tph-33" />
                    </div>
                ) : type === QuestionTypes.URGENT ? (
                    <div className="tooltip-info urgent">
                        <UrgentQuestionIcon color={Colors.red} className="tpw-33 tph-33" />
                    </div>
                ) : type === QuestionTypes.FOLLOWUP ? (
                    <div className="tooltip-info followup">
                        <FollowupQuestionIcon color={Colors.black} className="tpw-33 tph-33" />
                    </div>
                ) : null}
                <h6 className={`post-date mb0 ${type && "mt1"}`}>{date}</h6>
            </div>
        </div>
    );
};
export default Question;
