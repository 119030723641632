const LogoutIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="120"
            height="115"
            viewBox="0 0 120 115"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M59.9941 2.5V52.375"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M91.5012 6.58803C101.935 13.3787 109.895 23.3593 114.196 35.0422C118.496 46.7251 118.905 59.4847 115.364 71.4194C111.822 83.3541 104.518 93.8248 94.5416 101.271C84.5648 108.717 72.449 112.74 59.9999 112.74C47.5508 112.74 35.4349 108.717 25.4582 101.271C15.4814 93.8246 8.17796 83.3539 4.6363 71.4192C1.09464 59.4845 1.50443 46.7248 5.80458 35.0419C10.1047 23.3591 18.065 13.3785 28.499 6.58789"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default LogoutIcon;
