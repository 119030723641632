const SocialScienceIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="189"
            height="250"
            viewBox="0 0 189 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M90.9607 194.444C107.58 194.444 121.053 180.971 121.053 164.351C121.053 147.732 107.58 134.259 90.9607 134.259C74.341 134.259 60.8682 147.732 60.8682 164.351C60.8682 180.971 74.341 194.444 90.9607 194.444Z"
                stroke={color}
                strokeWidth="5"
                strokeMiterlimit="10"
            />
            <path
                d="M129.976 135.381C134.115 134.215 138.455 133.949 142.706 134.602C146.956 135.254 151.017 136.81 154.615 139.165C158.214 141.519 161.266 144.617 163.566 148.251C165.866 151.884 167.361 155.968 167.95 160.228C168.539 164.487 168.208 168.824 166.98 172.945C165.752 177.066 163.655 180.876 160.83 184.118C158.005 187.36 154.519 189.96 150.605 191.741C146.691 193.522 142.441 194.444 138.141 194.445"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M49.293 216.087C53.9928 209.402 60.2321 203.945 67.4842 200.179C74.7362 196.412 82.7881 194.446 90.96 194.445C99.1319 194.445 107.184 196.411 114.436 200.177C121.689 203.943 127.928 209.399 132.629 216.084"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M138.139 194.445C146.311 194.439 154.365 196.403 161.618 200.169C168.871 203.936 175.109 209.395 179.805 216.084"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M49.2959 135.386C45.289 134.22 41.0866 133.955 36.972 134.607C32.8573 135.26 28.9258 136.816 25.4423 139.17C21.9588 141.524 19.0041 144.622 16.7774 148.255C14.5507 151.888 13.1035 155.971 12.5334 160.23C11.9634 164.49 12.2836 168.825 13.4725 172.946C14.6615 177.067 16.6916 180.876 19.4261 184.118C22.1606 187.36 25.5359 189.959 29.3249 191.74C33.1138 193.521 37.2283 194.443 41.3912 194.444"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M40.0371 194.445C32.7726 194.439 25.6137 196.539 19.1667 200.569C12.7197 204.598 7.17431 210.438 3.00016 217.593"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M86.3331 105.323C111.263 105.323 131.472 85.1141 131.472 60.1847C131.472 35.2552 111.263 15.0459 86.3331 15.0459C61.4037 15.0459 41.1943 35.2552 41.1943 60.1847C41.1943 85.1141 61.4037 105.323 86.3331 105.323Z"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M49.4639 86.2318L54.9547 82.9171C55.5063 82.5841 55.9631 82.1151 56.2813 81.5549C56.5996 80.9947 56.7686 80.3622 56.7722 79.7179L56.8676 62.7302C56.8716 62.0204 57.0763 61.3263 57.4581 60.7279L66.788 46.106C67.0637 45.6738 67.4249 45.3025 67.8494 45.015C68.2738 44.7275 68.7526 44.5298 69.2562 44.434C69.7599 44.3382 70.2778 44.3464 70.7781 44.458C71.2785 44.5697 71.7508 44.7824 72.1659 45.0832L81.408 51.7791C82.1908 52.3462 83.1618 52.5902 84.1197 52.4605L98.9217 50.4559C99.8297 50.333 100.662 49.8828 101.261 49.19L111.679 37.1526C112.312 36.4219 112.639 35.4757 112.592 34.5104L112.043 23.083"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M114.106 95.7705L109.06 90.7145C108.588 90.2416 107.999 89.9029 107.352 89.7333L97.2628 87.0855C96.3673 86.8505 95.5899 86.2935 95.0795 85.5211C94.569 84.7487 94.3613 83.8151 94.4962 82.8992L95.6175 75.2839C95.7122 74.641 95.9717 74.0337 96.3709 73.521C96.77 73.0083 97.2952 72.6077 97.8952 72.3583L112.213 66.4072C112.874 66.1323 113.6 66.052 114.306 66.1756C115.011 66.2992 115.667 66.6216 116.195 67.105L127.901 77.8104"
                stroke={color}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default SocialScienceIcon;
