const KnowledgeBankIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="36"
            height="33"
            viewBox="0 0 36 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.978 4.15484C17.8534 3.73973 17.2723 2.36986 15.8194 1.58115C14.9892 1.12453 14.1589 1.04151 13.7023 1C9.59272 1 5.44162 1 1.33203 1V24.3292H12.4155C12.7476 24.3292 13.1212 24.3707 14.325 24.6613"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.6225 16.0685V1C30.5129 1 26.3618 1 22.2522 1C21.7956 1 21.0069 1.12453 20.1351 1.58115C18.6823 2.41137 18.1011 3.78124 17.9766 4.15484V13.8269"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.6633 32.1726L30.6367 28.1875"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.407 30.1813C29.5108 30.1813 32.8375 26.8545 32.8375 22.7508C32.8375 18.6471 29.5108 15.3203 25.407 15.3203C21.3033 15.3203 17.9766 18.6471 17.9766 22.7508C17.9766 26.8545 21.3033 30.1813 25.407 30.1813Z"
                fill="white"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
        </svg>
    );
};
export default KnowledgeBankIcon;
