import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, connect } from "react-redux";

import "./Login.scss";

import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import BgShape from "../../components/SvgIcon/BgShape";

import Logo from "../../components/SvgIcon/Logo";
import { resetPassword } from "../../store/actions";

const ResetPassword = (props) => {
    const { userType } = props;
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const dispatch = useDispatch();
    const {
        location: { state }
    } = history;

    const handleNextPress = () => {
        setIsLoading(true);
        dispatch(
            resetPassword({ Code: state?.code, TokenUUID: state.tokenUUID, Password: password })
        )
            .then((res) => {
                setIsLoading(false);
                history.replace(getRoute(APP_ROUTES.LOGIN));
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    return (
        <div className="tp-reset-password full-height text-center content-centered">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="logo">
                            <Logo color={Colors[`${userType}Primary`]} />
                        </div>
                        <h1 className="mb9 mt9">
                            Welcome back <br />
                            Please enter your new password
                        </h1>
                    </Col>
                    <Col xs={12}>
                        <AvForm
                            className="change-pass-form text-start mx-auto"
                            onValidSubmit={handleNextPress}
                        >
                            <TextInput
                                className={`tp-text-input-${userType} mb4`}
                                name="password"
                                placeholder="Enter your new password"
                                type="password"
                                errorMessage="This is a required field"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                options={{
                                    required: {
                                        value: true
                                    },
                                    maxLength: {
                                        value: 128,
                                        errorMessage: "Password must not exceed 128 characters"
                                    },
                                    minLength: {
                                        value: 8,
                                        errorMessage:
                                            "Password must be at least 8 characters long and alphanumeric"
                                    }
                                }}
                            />
                            <TextInput
                                className={`tp-text-input-${userType}`}
                                name="confirmPassword"
                                placeholder="Confirm your new password"
                                type="password"
                                errorMessage="This is a required field"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                options={{
                                    required: { value: true },
                                    match: {
                                        value: "password",
                                        errorMessage:
                                            "Confirm Password and New Password should be same."
                                    }
                                }}
                            />
                            <Col xs={12} className="text-center">
                                <Button
                                    title="Change password"
                                    className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                    type="submit"
                                    isLoading={isLoading}
                                />
                            </Col>
                        </AvForm>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps)(ResetPassword);
