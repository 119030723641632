import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import "./TodoList.scss";
import { Colors } from "../../themes/colors";

import { TpDropdownPopup } from "../../components/TpDropdownPopup";
import { Button } from "../../components/Button";
import { RadioBtn } from "../../components/RadioBtn";
import { TodoItem } from "../../components/TodoItem";

import PlusIcon from "../../components/SvgIcon/PlusIcon";
import FilterIcon from "../../components/SvgIcon/FilterIcon";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";
import { connect } from "react-redux";

const TodoList = (props) => {
    const { userType } = props;
    const history = useHistory();
    return (
        <div className="tp-todo-list tp-todo-list-main">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>To Do List</div>
                        <div className="content-inner-wrap">
                            <div className="d-flex justify-content-end align-items-center mt9">
                                <TpDropdownPopup
                                    className={`mr4 size-sm tp-dropdown-popup-${userType}`}
                                    trigger={
                                        <h4 className="ff-sf-pro-rounded mb0">
                                            <FilterIcon
                                                color={Colors.black}
                                                className="mr1 tpw-16 tph-16"
                                            />
                                            Filter
                                        </h4>
                                    }
                                    children={
                                        <div className="text-center">
                                            <h5
                                                className={`mb2 ff-sf-pro-rounded text-start clr-${userType}-primary`}
                                            >
                                                Subject
                                            </h5>
                                            <Button
                                                title="Maths"
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border tp-btn-secondary-border-${userType}`}
                                            />
                                            <Button
                                                title="English"
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt2`}
                                            />
                                            <Button
                                                title="Biology"
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt2`}
                                            />
                                            <h5
                                                className={`mt4 mb0 ff-sf-pro-rounded text-start clr-${userType}-primary`}
                                            >
                                                Student
                                            </h5>
                                            <Button
                                                title="Stuti"
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt2`}
                                            />
                                            <Button
                                                title="Zain"
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} tp-btn-secondary-border tp-btn-secondary-border-${userType} mt2`}
                                            />
                                            <Button
                                                title="Carolina"
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt2`}
                                            />
                                            <Button
                                                title="Riya"
                                                className={`tp-btn-secondary tp-btn-secondary-${userType} mt2`}
                                            />
                                        </div>
                                    }
                                />
                                <Button
                                    className={`tp-btn-primary tp-btn-primary-${userType} ml4`}
                                    title={
                                        <>
                                            <PlusIcon
                                                color={Colors.white}
                                                className="tpw-24 tph-24"
                                            />
                                            <span>Add task</span>
                                        </>
                                    }
                                />
                            </div>
                            <div
                                className={`scrolling-container-${userType} scrolling-container mt4`}
                            >
                                <Row>
                                    <Col xs={12}>
                                        <div className="d-flex align-items-center mt4">
                                            <RadioBtn className={`mr2 tp-radio-btn-${userType}`} />
                                            <TodoItem
                                                editing={true}
                                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolor sit amet, consectetur adipiscing elit."
                                                date="Monday 12th May 2021, 10:03 am"
                                            />
                                        </div>
                                        <div className="d-flex align-items-center mt4">
                                            <RadioBtn className={`mr2 tp-radio-btn-${userType}`} />
                                            <TodoItem
                                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolor sit amet, consectetur adipiscing elit."
                                                date="Monday 12th May 2021, 10:03 am"
                                            />
                                        </div>
                                        <div className="d-flex align-items-center mt4">
                                            <RadioBtn className={`mr2 tp-radio-btn-${userType}`} />
                                            <TodoItem
                                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolor sit amet, consectetur adipiscing elit."
                                                date="Monday 12th May 2021, 10:03 am"
                                                action={true}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center mt4">
                                            <RadioBtn className={`mr2 tp-radio-btn-${userType}`} />
                                            <TodoItem
                                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolor sit amet, consectetur adipiscing elit."
                                                date="Monday 12th May 2021, 10:03 am"
                                                actionList={true}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center mt4">
                                            <RadioBtn className={`mr2 tp-radio-btn-${userType}`} />
                                            <TodoItem
                                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolor sit amet, consectetur adipiscing elit."
                                                date="Monday 12th May 2021, 10:03 am"
                                                actionList={true}
                                                className={`tp-todo-item-border tp-todo-item-border-${userType}`}
                                                editdone={true}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center mt4">
                                            <RadioBtn className={`mr2 tp-radio-btn-${userType}`} />
                                            <TodoItem
                                                text={
                                                    <Fragment>
                                                        Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor
                                                        incididunt ut labore dolor sit amet,
                                                        consectetur adipiscing elit.
                                                        <img
                                                            src={AvatarImg}
                                                            className="img-fluid mt4 d-block"
                                                            alt=""
                                                        />
                                                        <h4
                                                            className={`clr-${userType}-primary mt4`}
                                                        >
                                                            Description:
                                                        </h4>
                                                        <p>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do eiusmod tempor
                                                            incididunt ut labore dolor sit amet,
                                                            consectetur adipiscing elit. Lorem ipsum
                                                            dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt
                                                            ut labore dolor sit amet, consectetur
                                                            adipiscing elit. Lorem ipsum dolor sit
                                                            amet, consectetur adipiscing elit, sed
                                                            do eiusmod tempor incididunt ut labore
                                                            dolor sit amet, consectetur adipiscing
                                                            elit.
                                                        </p>
                                                    </Fragment>
                                                }
                                                date="Monday 12th May 2021, 10:03 am"
                                                className={`tp-todo-item-border tp-todo-item-border-${userType}`}
                                                deleteTask={true}
                                                user={true}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center mt4">
                                            <RadioBtn className={`mr2 tp-radio-btn-${userType}`} />
                                            <TodoItem
                                                text={
                                                    <Fragment>
                                                        Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor
                                                        incididunt ut labore dolor sit amet,
                                                        consectetur adipiscing elit.
                                                        <div className="gallery-images">
                                                            <img
                                                                src={AvatarImg}
                                                                className="img-fluid mt4 d-block"
                                                                alt=""
                                                            />
                                                            <img
                                                                src={AvatarImg}
                                                                className="img-fluid mt4 d-block"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <h4
                                                            className={`clr-${userType}-primary mt4`}
                                                        >
                                                            Description:
                                                        </h4>
                                                        <p>
                                                            Lorem ipsum dolor sit amet, consectetur
                                                            adipiscing elit, sed do eiusmod tempor
                                                            incididunt ut labore dolor sit amet,
                                                            consectetur adipiscing elit. Lorem ipsum
                                                            dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt
                                                            ut labore dolor sit amet, consectetur
                                                            adipiscing elit. Lorem ipsum dolor sit
                                                            amet, consectetur adipiscing elit, sed
                                                            do eiusmod tempor incididunt ut labore
                                                            dolor sit amet, consectetur adipiscing
                                                            elit.
                                                        </p>
                                                    </Fragment>
                                                }
                                                date="Monday 12th May 2021, 10:03 am"
                                                className={`tp-todo-item-border tp-todo-item-border-${userType}`}
                                                deleteTask={true}
                                                user={true}
                                            />
                                        </div>

                                        <div className="d-flex align-items-center mt4">
                                            <RadioBtn className={`mr2 tp-radio-btn-${userType}`} />
                                            <TodoItem
                                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolor sit amet, consectetur adipiscing elit."
                                                date="Monday 12th May 2021, 10:03 am"
                                                user={true}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center mt4">
                                            <RadioBtn
                                                checked={true}
                                                className={`mr2 tp-radio-btn-${userType}`}
                                            />
                                            <TodoItem
                                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolor sit amet, consectetur adipiscing elit."
                                                date="Monday 12th May 2021, 10:03 am"
                                                done={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps)(TodoList);
