import { useState, useEffect, Fragment } from "react";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./Signup.scss";
import { Colors } from "../../themes/colors";
import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { CreatableDropdown } from "../../components/CreatableDropdown";
import BgShape from "../../components/SvgIcon/BgShape";
import { connect } from "react-redux";
import {
    setPersonalDetails,
    signUpWithNoInvite,
    getCountries,
    updateDeviceId
} from "../../store/actions";

const PersonalDetails = ({
    setPersonalDetails,
    signUpWithNoInvite,
    getCountries,
    updateDeviceId,
    deviceUUID,
    auth,
    userType
}) => {
    const { selectedUserType: type, fullName, emailAddress, dateOfBirth, countryId } = auth;
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isInvited = params.get("isInvited");
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [countryError, setCountryError] = useState(false);
    const handleSubmit = () => {
        if (countryId) {
            const payload = {
                FullName: fullName,
                Email: emailAddress,
                Type: type,
                DOB: dateOfBirth,
                CountryId: countryId?.Id
            };
            setIsLoading(true);
            signUpWithNoInvite(payload)
                .then((res) => {
                    if (isInvited) {
                        history.push({
                            pathname: getRoute(APP_ROUTES.PROFILE_PICTURE),
                            search: "?isInvited=true"
                        });
                    } else {
                        updateDeviceId(res.Data.DeviceUUID);

                        history.push(getRoute(APP_ROUTES.VERIFICATION_CODE_CONFIRMATION));
                    }
                })

                .catch((err) => {})
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setCountryError(true);
        }
    };
    const handleInvalidSubmit = () => {
        if (countryId) {
            setCountryError(false);
        } else {
            setCountryError(true);
        }
    };

    const handleChange = (value) => {
        setCountryError(false);
        handleOnChange("countryId", value);
    };

    const renderText = () => {
        return (
            <div>
                {!isInvited ? (
                    <span>
                        Let's start by taking your name, email address and <br /> your date of birth
                    </span>
                ) : (
                    <Fragment>
                        <span>Thanks for expressing an interest to join TutorPin </span>
                        <span className="d-block mt4">
                            Let's start by taking your name and your date of birth
                        </span>
                    </Fragment>
                )}
            </div>
        );
    };

    const handleOnChange = (key, value) => {
        setPersonalDetails({ [key]: value });
    };

    const formatCountries = (countries) => {
        return countries.map((item) => {
            return {
                ...item,
                label: item.Name
            };
        });
    };

    useEffect(() => {
        getCountries()
            .then((res) => {
                setCountries(formatCountries([...res?.Data?.Countries]));
            })
            .catch((err) => {});
        return () => {
            setCountries({});
        };
    }, []);

    if (!type && !isInvited) {
        return <Redirect to={APP_ROUTES.CHOOSE_USER} />;
    } else if (!deviceUUID && isInvited) {
        return <Redirect to={APP_ROUTES.ENTER_EMAIL} />;
    }

    return (
        <div className="tp-personal-details full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12}>
                        <AvForm onValidSubmit={handleSubmit} onInvalidSubmit={handleInvalidSubmit}>
                            <h1 className="mt9 mb0 text-center">{renderText()}</h1>
                            <TextInput
                                className={`tp-text-input-${userType} mt9`}
                                name="fullname"
                                placeholder="Full Name"
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    maxLength: {
                                        value: 55,
                                        errorMessage:
                                            "A valid name must be of min 6 & max 55 characters with no special characters"
                                    },
                                    minLength: {
                                        value: 6,
                                        errorMessage:
                                            "A valid name must be of min 6 & max 55 characters with no special characters"
                                    },
                                    pattern: {
                                        value: "/^[a-zA-Z ]+$/",
                                        errorMessage:
                                            "A valid name must be of min 6 & max 55 characters with no special characters"
                                    }
                                }}
                                value={fullName}
                                onChange={(e) => handleOnChange("fullName", e.target.value)}
                            />
                            {!isInvited && (
                                <TextInput
                                    className={`tp-text-input-${userType} mt9`}
                                    name="email"
                                    placeholder="Email Address"
                                    type="email"
                                    options={{
                                        required: {
                                            value: true,
                                            errorMessage: "This is a required field"
                                        },
                                        email: {
                                            errorMessage: "Please enter a valid email address"
                                        },
                                        maxLength: {
                                            value: 64,
                                            errorMessage:
                                                "Email address must not exceed 64 characters "
                                        }
                                    }}
                                    value={emailAddress}
                                    onChange={(e) => handleOnChange("emailAddress", e.target.value)}
                                />
                            )}

                            <TextInput
                                name="date"
                                type="text"
                                placeholder="Date Of Birth"
                                className={`tp-text-input shadow-none radius-11 tp-text-input-${userType} mt9`}
                                onFocus={(e) => {
                                    e.currentTarget.type = "date";
                                }}
                                onBlur={(e) => {
                                    if (!dateOfBirth) {
                                        e.currentTarget.type = "text";
                                    }
                                }}
                                options={{
                                    required: {
                                        value: true,
                                        errorMessage: "This is a required field"
                                    },
                                    dateRange: {
                                        start: { value: -100, units: "years" },
                                        end: { value: -18, units: "years" },
                                        errorMessage:
                                            "A valid age should be greater than 18 and less than 100 years "
                                    }
                                }}
                                value={dateOfBirth}
                                onChange={(e) => handleOnChange("dateOfBirth", e.target.value)}
                            />

                            <CreatableDropdown
                                placeholder="Country"
                                className={`mw-100 tp-creatable-dropdown-${userType} mt9`}
                                hideArrow={true}
                                onChange={handleChange}
                                options={countries}
                                value={countryId}
                            />
                            {countryError && (
                                <div>
                                    <span class="is-invalid"></span>
                                    <div class="invalid-feedback position-absolute">
                                        This is a required field
                                    </div>
                                </div>
                            )}
                            <div className="text-center mt9">
                                <Button
                                    title="Next"
                                    type="submit"
                                    onClick={handleSubmit}
                                    className={`tp-btn-primary tp-btn-primary-${userType}`}
                                    isLoading={isLoading}
                                />
                            </div>
                        </AvForm>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapDispatchToProps = {
    setPersonalDetails,
    signUpWithNoInvite,
    getCountries,
    updateDeviceId
};
const mapStateToProps = (state) => {
    const auth = state.root.auth;
    const deviceUUID = state.deviceId.deviceUUID;
    return {
        auth,
        deviceUUID,
        userType: state.auth.userType
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
