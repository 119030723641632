const QuestionIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.09825 27.6551C5.2346 24.5135 4.58193 20.7995 5.2628 17.2107C5.94368 13.6219 7.91126 10.405 10.7961 8.16425C13.6809 5.92346 17.2845 4.81287 20.9302 5.04101C24.5759 5.26915 28.0129 6.82033 30.5959 9.40327C33.1788 11.9862 34.73 15.4232 34.9582 19.0689C35.1863 22.7147 34.0758 26.3183 31.835 29.2031C29.5942 32.0879 26.3774 34.0555 22.7886 34.7364C19.1998 35.4173 15.4858 34.7646 12.3441 32.901L7.16347 34.381C6.94912 34.4423 6.7223 34.4451 6.50651 34.3892C6.29072 34.3333 6.0938 34.2206 5.93617 34.063C5.77854 33.9054 5.66594 33.7085 5.61002 33.4927C5.5541 33.2769 5.55691 33.0501 5.61815 32.8357L7.09825 27.6551Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default QuestionIcon;
