const PaperClipIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="18"
            height="21"
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.6244 5.8125L4.4889 14.0831C4.19587 14.3761 4.03125 14.7736 4.03125 15.188C4.03125 15.6024 4.19587 15.9998 4.4889 16.2928C4.78192 16.5859 5.17935 16.7505 5.59375 16.7505C6.00815 16.7505 6.40558 16.5859 6.69861 16.2928L16.3966 6.45971C16.6868 6.16953 16.917 5.82503 17.0741 5.44589C17.2311 5.06674 17.3119 4.66038 17.3119 4.25C17.3119 3.83962 17.2311 3.43326 17.0741 3.05411C16.917 2.67497 16.6868 2.33047 16.3966 2.04029C16.1065 1.75011 15.762 1.51992 15.3828 1.36288C15.0037 1.20583 14.5973 1.125 14.1869 1.125C13.7765 1.125 13.3702 1.20583 12.991 1.36288C12.6119 1.51992 12.2674 1.75011 11.9772 2.04029L2.27919 11.8734C1.40011 12.7525 0.90625 13.9448 0.90625 15.188C0.90625 16.4312 1.40011 17.6235 2.27919 18.5025C3.15826 19.3816 4.35055 19.8755 5.59375 19.8755C6.83695 19.8755 8.02924 19.3816 8.90831 18.5025L16.9213 10.5"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default PaperClipIcon;
