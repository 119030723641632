import { fetchUtil } from "../../utils/fetchUtil";
import { updateDeviceId } from "./";
export const ME_REQUEST = "ME_REQUEST";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_ERROR = "ME_ERROR";
export const SIGN_OUT = "SIGN_OUT";
export const SET_TOKEN = "SET_TOKEN";
export const UPDATE_CLARITY_QUESTION_COUNT = "UPDATE_CLARITY_QUESTION_COUNT";
export const CHANGE_USER_TYPE = "CHANGE_USER_TYPE";

export const login = (bodyData) => (dispatch, getState) => {
    let deviceUUID = getState().deviceId.deviceUUID;
    const body = JSON.stringify({ ...bodyData, ...(deviceUUID && { DeviceUUID: deviceUUID }) });

    return fetchUtil({
        url: "/user/login",
        method: "POST",
        body
    })
        .then(async (res) => {
            dispatch({ type: SET_TOKEN, token: res.Data.Token });
            await dispatch(getMe(res.Data.Token));
            dispatch(updateDeviceId(res.Data.DeviceUUID));
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getMe = (token) => (dispatch, getState) => {
    dispatch({ type: ME_REQUEST });
    return fetchUtil({
        url: "/user/me",
        token: token || getState().auth.user.Token
    })
        .then((res) => {
            localStorage.isAuthenticated = true;
            dispatch({ type: ME_SUCCESS, payload: res.Data });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            dispatch({ type: ME_ERROR });
            return Promise.reject(err);
        });
};

export const forgotPassword = (payload) => (dispatch, getState) => {
    let body = JSON.stringify(payload);

    return fetchUtil({
        url: "/user/forget-password",
        method: "POST",
        body
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const forgotPasswordVerification = (payload) => (dispatch, getState) => {
    let body = JSON.stringify(payload);

    return fetchUtil({
        url: "/user/forget-password-verification",
        method: "POST",
        body
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const resetPassword = (payload) => (dispatch, getState) => {
    let body = JSON.stringify(payload);

    return fetchUtil({
        url: "/user/reset-password",
        method: "POST",
        body
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getSubjectListing = () => (dispatch, getState) => {
    return fetchUtil({
        url: "/subject/public"
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getCountries = () => (dispatch, getState) => {
    return fetchUtil({
        url: "/common/country"
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getVideoUrl = () => (dispatch, getState) => {
    return fetchUtil({
        url: "/media/intro-video"
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const signUpWithNoInvite = (payload) => (dispatch, getState) => {
    let body = JSON.stringify(payload);

    return fetchUtil({
        url: "/user/signup",
        method: "POST",
        body
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const signUpComplete = (payload) => (dispatch, getState) => {
    let body = JSON.stringify(payload);
    let token = getState().auth.user.Token;

    return fetchUtil({
        url: "/user/signup/complete",
        method: "POST",
        token,
        body
    })
        .then(async (res) => {
            await dispatch(getMe());
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const sendEmailVerification = (payload) => (dispatch, getState) => {
    let body = JSON.stringify(payload);
    return fetchUtil({
        url: "/user/signup/invite",
        method: "POST",
        body
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const sendVerificationCode = (payload) => (dispatch, getState) => {
    let body = JSON.stringify(payload);
    return fetchUtil({
        url: "/user/signup-verification",
        method: "POST",
        body,
        showToaster: false
    })
        .then(async (res) => {
            dispatch({ type: SET_TOKEN, token: res.Data.Token });
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const reSendVerificationCode = (payload) => (dispatch, getState) => {
    let body = JSON.stringify(payload);
    return fetchUtil({
        url: "/user/signup/resend/code",
        method: "POST",
        body
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const updateClarityQuestionCount = () => {
    return {
        type: UPDATE_CLARITY_QUESTION_COUNT
    };
};

export const changeUserType = (userType) => {
    return {
        type: CHANGE_USER_TYPE,
        userType
    };
};
