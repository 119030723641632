import { useState, useRef, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import randomize from "randomatic";

import "./QuestionBoard.scss";
import { Colors } from "../../themes/colors";

import { AvForm } from "availity-reactstrap-validation";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import QuestionAnswerThread from "./QuestionAnswerThread";
// import { Card } from "../../components/Card";

import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import Attachement from "./Attachement";
import KnowledgeBankSubjectSelection from "./AskQuestionModals/KnowledgeBankSubjectSelection";
import TimeSpentModal from "./AskQuestionModals/TimeSpentModal";
import ResourceDetailsModal from "./AskQuestionModals/ResourceDetailsModal";
import AttachmentEditModal from "./AskQuestionModals/AttachmentEditModal";
import {
    getFileSize,
    getMediaThumbPath,
    getMinifiedImagePreviewURL,
    getVideoPreviewURL,
    supportedMediaTypes
} from "../../utils/mediaUtils";
import MessageModal from "./AskQuestionModals/MessageModal";
import { toaster } from "../../utils/toasterUtil";
import {
    finalizeMediaUtil,
    getMediaStream,
    initializeMediaUtil,
    uploadOnS3
} from "../../utils/mediaService";

import ScribbleLoopIcon from "../../components/SvgIcon/ScribbleLoopIcon";
import PaperClipIcon from "../../components/SvgIcon/PaperClipIcon";
import KnowledgeBankIcon from "../../components/SvgIcon/KnowledgeBankIcon";
import AvatarImg from "../../assets/images/placeholder-profile.jpg";
import { sentAnswerToUser } from "../../store/actions";
import { SupportedMedias } from "../../constants";

const MEDIA_TYPES = {
    IMAGE: 2,
    VIDEO: 3,
    AUDIO: 4,
    DOCUMENT: 6
};

const AnswerQuestion = (props) => {
    const { userType, questionThreads } = props;
    const fileRef = useRef();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [knowledgeBankModal, setKnowledgeBankModal] = useState(false);
    const [timeSpentModal, setTimeSpentModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [media, setMedia] = useState({});
    const [description, setDescription] = useState("");

    const question = useMemo(() => questionThreads[0], [questionThreads]);

    const handlePinButtonPress = () => {
        fileRef.current.click(function () {
            fileRef.current.onClick();
        });
    };

    const updatePreviewData = (data, uuid) => {
        setMedia((prev) => ({
            ...prev,
            [uuid]: {
                ...prev[uuid],
                ...data
            }
        }));
    };

    const addPreviewToData = (file, uuid, type) => {
        const onSuccess = (previewUrl) => {
            updatePreviewData({ previewUrl, isLoading: false, mediaType: type }, uuid);
        };

        const onFail = () => {
            updatePreviewData({ isLoading: false }, uuid);
        };

        if (type === MEDIA_TYPES.IMAGE) {
            getMinifiedImagePreviewURL(file).then(onSuccess).catch(onFail);
        } else {
            getVideoPreviewURL(file).then(onSuccess).catch(onFail);
        }
    };

    const handleFileChange = (e, fileObj) => {
        let cloneMedia = { ...media };
        for (var file of fileObj) {
            let uuid = randomize("Aa0", 8);
            let fileType = file.name.split(".").pop().toLowerCase();
            let fileData = {
                isLoading: true,
                fileObj: file,
                progress: 0,
                type: fileType,
                fileSize: getFileSize(file.size),
                uuid,
                previewUrl: null
            };
            if (supportedMediaTypes(fileType)) {
                if (SupportedMedias.imageType.includes(fileType)) {
                    addPreviewToData(file, uuid, MEDIA_TYPES.IMAGE);
                    fileData.isLoading = true;
                } else if (SupportedMedias.videoTypes.includes(fileType)) {
                    addPreviewToData(file, uuid, MEDIA_TYPES.VIDEO);
                    fileData.isLoading = true;
                } else if (SupportedMedias.documentTypes.includes(fileType)) {
                    fileData.mediaType = MEDIA_TYPES.DOCUMENT;
                } else {
                    fileData.invalid = true;
                    continue;
                }
                cloneMedia[uuid] = fileData;
            }
        }
        if (!Object.keys(cloneMedia).length) {
            toaster({ title: "Only image, videos and pdf files are allowed", type: "danger" });
        }
        setMedia(cloneMedia);
        e.target.value = null;
    };

    const handleRemoveMedia = (uuid) => {
        const files = { ...media };
        delete files[uuid];
        setMedia(files);
    };

    const uploadMedia = async (file) => {
        try {
            if (file.fileObj) {
                let credentialsRequest = initializeMediaUtil(file.fileObj);
                let mediaStreamRequest = getMediaStream(file.fileObj);
                const [credentials, mediaStream] = await Promise.all([
                    credentialsRequest,
                    mediaStreamRequest
                ]);
                await uploadOnS3(mediaStream, credentials.Data, (progress) => {});
                let finalizeRes = await finalizeMediaUtil(credentials?.Data?.MediaId);

                return Promise.resolve(finalizeRes?.Data?.Media);
            } else {
                return;
            }
        } catch (err) {
            return Promise.reject(err);
        }
    };

    const handleSendBtnPress = async (timeSpent) => {
        let mediaIds = [];
        let filesArray = Object.values(media);
        setIsLoading(true);
        if (filesArray.length) {
            let imageResponses = filesArray.map((file) => {
                return uploadMedia(file);
            });
            filesArray = (await Promise.allSettled(imageResponses))
                .filter((item) => item.status === "fulfilled")
                .map((val) => val.value);
            mediaIds = filesArray.map((obj) => obj.Id);
        }
        let lastQuestion = questionThreads[questionThreads.length - 1];
        if (lastQuestion) {
            const payload = {
                Description: description,
                ...(mediaIds.length && { MediaIds: mediaIds }),
                QuestionId: lastQuestion.Id,
                TimeSpent: Number(timeSpent)
            };
            dispatch(sentAnswerToUser(payload))
                .then((res) => {
                    setIsLoading(false);
                    setTimeSpentModal(false);
                    setShowSuccessModal(true);
                })
                .catch((err) => {
                    setIsLoading(false);
                    setTimeSpentModal(false);
                });
        } else {
            toaster({ title: "Something went wrong", type: "danger" });
        }
    };

    const renderAvatar = (media) => {
        return getMediaThumbPath(media);
    };

    return (
        <div className="tp-question-board tp-answer-question">
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div className={`tp-main-heading clr-${userType}-primary`}>
                            Answer Question
                        </div>
                        <div className="content-inner-wrap">
                            <Button
                                title="Back to question board"
                                className={`mt9 mb4 tp-btn-back-${userType}`}
                                backIcon={true}
                                backIconColor={Colors[`${userType}Primary`]}
                                onClick={() => history.goBack()}
                            />
                            <div className="content-inner answer-question-inner no-padding">
                                <QuestionAnswerThread />
                                {!!questionThreads.length && (
                                    <div className="question-details-wrap mt4">
                                        <Row>
                                            <Col md={"auto"}>
                                                <div className="avatar-img">
                                                    <img
                                                        src={renderAvatar(question?.User?.Media)}
                                                        className="img-fluid rounded-circle tpw-50 tph-50 objfit-cover"
                                                        alt=""
                                                    />
                                                </div>
                                                <h4 className="ff-sf-pro-rounded mt1 text-center text-trim tpw-60">
                                                    {question?.User.FullName}
                                                </h4>
                                            </Col>
                                            <Col md={11}>
                                                <div className="question-details">
                                                    <AvForm
                                                        onValidSubmit={() =>
                                                            setTimeSpentModal(true)
                                                        }
                                                    >
                                                        <div className="answer-add-files-wrap">
                                                            <TextInput
                                                                name="answerquestion"
                                                                type="textarea"
                                                                className={`bdr-0 radius-7 resize-none size-lg tp-text-input-${userType}`}
                                                                options={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage:
                                                                            "This field is required"
                                                                    }
                                                                }}
                                                                value={description}
                                                                onChange={(evt) =>
                                                                    setDescription(evt.target.value)
                                                                }
                                                            />
                                                            <div
                                                                className={`answer-add-files-dropdown ${
                                                                    Object.keys(media).length > 4
                                                                        ? "mlti"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <Container fluid={true}>
                                                                    <Row className="justify-content-end">
                                                                        {!!Object.keys(media)
                                                                            .length &&
                                                                            Object.keys(media).map(
                                                                                (id, index) => (
                                                                                    <Attachement
                                                                                        key={index}
                                                                                        mediaObj={
                                                                                            media[
                                                                                                id
                                                                                            ]
                                                                                        }
                                                                                        handleRemoveMedia={
                                                                                            handleRemoveMedia
                                                                                        }
                                                                                    />
                                                                                )
                                                                            )}
                                                                    </Row>
                                                                </Container>
                                                            </div>
                                                        </div>
                                                        <div class="answer-question-actions mt4 d-flex justify-content-between align-items-center">
                                                            <div className="answer-question-actions-list d-flex">
                                                                <ScribbleLoopIcon
                                                                    color={Colors.black}
                                                                    className="tpw-30 tph-30 pointer"
                                                                />
                                                                <div>
                                                                    <PaperClipIcon
                                                                        color={Colors.black}
                                                                        onClick={
                                                                            handlePinButtonPress
                                                                        }
                                                                        className="tpw-30 tph-30 pointer"
                                                                    />
                                                                    <input
                                                                        type="file"
                                                                        ref={fileRef}
                                                                        onChange={(e) =>
                                                                            handleFileChange(
                                                                                e,
                                                                                e.target.files
                                                                            )
                                                                        }
                                                                        multiple
                                                                        className="opacity-0 d-none"
                                                                    />
                                                                </div>
                                                                <KnowledgeBankIcon
                                                                    color={Colors.black}
                                                                    className="tpw-30 tph-30 pointer"
                                                                    onClick={() => {
                                                                        setKnowledgeBankModal(
                                                                            !knowledgeBankModal
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                            <Button
                                                                type="submit"
                                                                title="Answer now"
                                                                className={`tp-btn-primary tp-btn-primary-${userType}`}
                                                            />
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <KnowledgeBankSubjectSelection showSubjectSelectionModal={false} />
            <TimeSpentModal
                isLoading={isLoading}
                showTimeSpentModal={timeSpentModal}
                handleClose={() => setTimeSpentModal(false)}
                handleSubmitPress={handleSendBtnPress}
            />
            <ResourceDetailsModal showResourceDetailModal={false} />
            <AttachmentEditModal showAttachmentEditModal={false} />
            <MessageModal
                text={`The answer has been sent to ${question?.User.FullName || "User"}`}
                showMessageModal={showSuccessModal}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType,
        questionThreads: state.questionBoard.questionThreads
    };
};

export default connect(mapStateToProps)(AnswerQuestion);
