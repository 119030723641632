import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useState, useEffect } from "react";
import "./Signup.scss";
import { connect } from "react-redux";
import { Colors } from "../../themes/colors";
import { Video } from "../../components/Video";
import { Button } from "../../components/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { getVideoUrl } from "../../store/actions";
import BgShape from "../../components/SvgIcon/BgShape";

const VideoIntroduction = ({ getVideoUrl, userType }) => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isInvited = params.get("isInvited");
    const [videoUrl, setVideoUrl] = useState("");
    useEffect(() => {
        getVideoUrl()
            .then((res) => {
                setVideoUrl(res?.Data?.VideoUrl);
            })
            .catch((err) => {});
    }, []);

    const handleClick = () => {
        if (isInvited) {
            history.push({
                pathname: getRoute(APP_ROUTES.SUBJECT_SELECTION),
                search: "?isInvited=true"
            });
        } else {
            history.push(getRoute(APP_ROUTES.PROFILE_PICTURE));
        }
    };
    return (
        <div className="tp-video-introduction full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt6 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12} className="text-center">
                        <h1 className="mt9 mb0">Introduction</h1>
                        <Video
                            url={videoUrl}
                            width={709}
                            height={386}
                            className="mt9"
                            PlayIconColor={Colors[`${userType}Primary`]}
                        />
                    </Col>
                    <Col xs={12} className="text-center">
                        <Button
                            title="Next"
                            className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                            onClick={handleClick}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType
    };
};

const mapDispatchToProps = {
    getVideoUrl
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoIntroduction);
