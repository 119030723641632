const QuestionBoardIconCircle = (props) => {
    const { className, onClick, color, bgColor } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="150"
            height="150"
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="75" cy="75" r="75" fill={bgColor} />
            <path
                d="M88.0008 39.25H104.25C105.112 39.25 105.939 39.5924 106.548 40.2019C107.158 40.8114 107.5 41.638 107.5 42.5V110.75C107.5 111.612 107.158 112.439 106.548 113.048C105.939 113.658 105.112 114 104.25 114H45.75C44.888 114 44.0614 113.658 43.4519 113.048C42.8424 112.439 42.5 111.612 42.5 110.75V42.5C42.5 41.638 42.8424 40.8114 43.4519 40.2019C44.0614 39.5924 44.888 39.25 45.75 39.25H61.9991"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M58.75 52.25V49C58.75 44.6902 60.462 40.557 63.5095 37.5095C66.557 34.462 70.6902 32.75 75 32.75C79.3098 32.75 83.443 34.462 86.4905 37.5095C89.538 40.557 91.25 44.6902 91.25 49V52.25H58.75Z"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M75.7959 84.4184C75.4643 84.4184 75.1327 84.2857 74.9337 84.0867C74.7347 83.8878 74.602 83.5561 74.602 83.2245C74.602 82.4286 74.801 81.5 75.199 80.5714C75.5969 79.6429 76.1939 78.7806 76.8571 78.1173C77.5204 77.5204 78.1173 76.9235 78.7143 76.3929C79.3112 75.8622 79.7755 75.199 80.1071 74.5357C80.4388 73.8724 80.6378 73.0102 80.6378 71.949C80.6378 71.1531 80.4388 70.4235 80.0408 69.7602C79.6429 69.0969 79.1122 68.5663 78.3827 68.1684C77.6531 67.7704 76.8571 67.5714 75.8622 67.5714C74.801 67.5714 73.9388 67.7704 73.2092 68.2347C72.4796 68.6327 71.8827 69.2296 71.4847 69.9592C71.0867 70.6888 70.8878 71.551 70.8878 72.5459C70.8878 72.8776 70.7551 73.2092 70.5561 73.4082C70.3571 73.6071 70.0255 73.7398 69.6939 73.7398C69.3622 73.7398 69.0306 73.6071 68.8316 73.4082C68.6327 73.2092 68.5 72.8776 68.5 72.5459C68.5 71.1531 68.8316 69.8929 69.4286 68.7653C70.0255 67.7041 70.8878 66.8418 72.0153 66.1786C73.1429 65.5816 74.4031 65.25 75.8622 65.25C77.2551 65.25 78.449 65.5153 79.5102 66.1122C80.5714 66.7092 81.4337 67.5051 82.0306 68.5C82.6275 69.4949 82.9592 70.6888 82.9592 72.0153C82.9592 73.1429 82.7602 74.1378 82.3622 74.9337C81.9643 75.7296 81.4337 76.4592 80.7041 77.1224C80.0408 77.7857 79.3112 78.5153 78.5153 79.2449C77.852 79.8418 77.4541 80.5051 77.2551 81.1684C77.0561 81.898 76.9235 82.5612 76.9235 83.0918C76.9235 83.4898 76.7908 83.7551 76.5918 84.0204C76.3929 84.2857 76.1275 84.4184 75.7959 84.4184ZM75.7959 91.25C75.2653 91.25 74.8673 91.051 74.5357 90.7194C74.2041 90.3878 74.0051 89.9235 74.0051 89.4592C74.0051 88.9286 74.2041 88.5306 74.5357 88.1327C74.8673 87.801 75.3316 87.602 75.7959 87.602C76.2602 87.602 76.7245 87.801 77.0561 88.1327C77.3878 88.4643 77.5867 88.9286 77.5867 89.4592C77.5867 89.9235 77.3878 90.3878 77.0561 90.7194C76.7245 91.051 76.3265 91.25 75.7959 91.25Z"
                fill={color}
                stroke={color}
                strokeMiterlimit="10"
            />
        </svg>
    );
};
export default QuestionBoardIconCircle;
