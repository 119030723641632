const LanguagesIcon = (props) => {
    const { className, onClick, color } = props;
    return (
        <svg
            className={className}
            onClick={onClick}
            width="116"
            height="116"
            viewBox="0 0 116 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.61842 86.7096C2.62974 74.9284 0.182239 61.0011 2.73552 47.543C5.28879 34.0849 12.6672 22.0218 23.4852 13.6189C34.3033 5.21592 47.8168 1.05119 61.4882 1.90671C75.1596 2.76223 88.0485 8.57915 97.7346 18.2652C107.421 27.9512 113.238 40.8401 114.093 54.5115C114.949 68.1829 110.784 81.6964 102.381 92.5145C93.9784 103.333 81.9153 110.711 68.4572 113.264C54.9991 115.818 41.0718 113.37 29.2905 106.382L29.2907 106.381L9.863 111.932C9.05921 112.161 8.20863 112.172 7.39941 111.962C6.59018 111.753 5.85175 111.33 5.26065 110.739C4.66954 110.148 4.24726 109.41 4.03757 108.6C3.82788 107.791 3.83842 106.941 4.06808 106.137L9.61884 86.7092L9.61842 86.7096Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M57.9999 84.4695C72.6192 84.4695 84.4705 72.6182 84.4705 57.9989C84.4705 43.3796 72.6192 31.5283 57.9999 31.5283C43.3806 31.5283 31.5293 43.3796 31.5293 57.9989C31.5293 72.6182 43.3806 84.4695 57.9999 84.4695Z"
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M33.0352 49.1748H82.9611"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.0371 66.8223H82.9628"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M58.0001 83.7583C64.0915 83.7583 69.0295 72.2251 69.0295 57.9983C69.0295 43.7714 64.0915 32.2383 58.0001 32.2383C51.9087 32.2383 46.9707 43.7714 46.9707 57.9983C46.9707 72.2251 51.9087 83.7583 58.0001 83.7583Z"
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
            />
        </svg>
    );
};
export default LanguagesIcon;
