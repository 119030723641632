import { useState } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";

// import "./SignupInvite.scss";
import { Colors } from "../../themes/colors";

import { Button } from "../../components/Button";
import { TpModal } from "../../components/TpModal";
import { VerifcationCodeInput } from "../../components/VerifcationCodeInput";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import BgShape from "../../components/SvgIcon/BgShape";

import { forgotPasswordVerification } from "../../store/actions";

const CodeConfirmation = (props) => {
    const { isAuthenticated, userType } = props;
    const history = useHistory();
    const [sendCode, setSendCode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const dispatch = useDispatch();
    const { tokenId } = useParams();

    const handleResendCode = () => {
        setSendCode(!sendCode);
    };

    const handleOnChange = (code) => {
        if (codeError) {
            setCodeError(false);
        }
        if (code?.length === 6) {
            setIsLoading(true);
            dispatch(forgotPasswordVerification({ Code: "0000", TokenUUID: tokenId }))
                .then((res) => {
                    history.push(getRoute(APP_ROUTES.RESET_PASSWORD), {
                        tokenUUID: res.TokenUUID,
                        code: res.Code
                    });
                })
                .catch((err) => {
                    setCodeError(true);
                    setIsLoading(false);
                });
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <div className="tp-verification-code-confirmation full-height">
            <BgShape type={`${userType}Primary`} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Button
                            title="Back"
                            className={`mt10 tp-btn-back-${userType}`}
                            backIcon={true}
                            backIconColor={Colors[`${userType}Primary`]}
                            onClick={() => history.goBack()}
                        />
                    </Col>
                    <Col xs={12}>
                        <div className="confirmation-wrap text-center mt9 mx-auto">
                            <h1 className="mb0">
                                {codeError
                                    ? "Please try the verification code again"
                                    : "Please enter the verification code"}
                            </h1>
                            <VerifcationCodeInput
                                onChange={handleOnChange}
                                width={50}
                                disabled={isLoading}
                                height={67}
                                className={`tp-verification-code-input-wrap-${userType} mx-auto w-100 mt9 ${
                                    codeError && "error"
                                }`}
                            />
                        </div>
                    </Col>
                    <Col xs={12} className="text-center">
                        <Button
                            onClick={handleResendCode}
                            className={`clr-${userType}-primary text-decoration-underline mt9`}
                            title={"Resend verification code"}
                        />
                        <TpModal
                            isOpen={sendCode}
                            centered
                            className={`tp-modal-${userType} size-3`}
                            children={
                                <div className="text-center">
                                    <h3 className="mb0">
                                        We have re-sent a verification code to:
                                        <span className="d-block mt4 mb4 fw-bold">
                                            carolinam_o@gmail.com
                                        </span>
                                        If you do not receive the verification code within a few
                                        minutes, please check your spam or junk email folder.
                                    </h3>
                                    <Button
                                        onClick={() => setSendCode(!sendCode)}
                                        className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                                        title="OK"
                                    />
                                </div>
                            }
                        />
                        <Col xs={12} className="text-center">
                            <Button
                                title="Next"
                                isLoading={isLoading}
                                className={`tp-btn-primary tp-btn-primary-${userType} mt9`}
                            />
                        </Col>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        userType: state.auth.userType
    };
};

export default connect(mapStateToProps, null)(CodeConfirmation);
